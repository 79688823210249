.ui-dialog {
    position: absolute;
    top: 0;
    left: 0;
    outline: 0;
    z-index: 501;
    width: 100%;
    background-color: #ffffff;
    padding: 30px 40px 40px 40px;
    box-sizing: border-box;

    &.diaglog-pdpGalleryCarouselContainer {
        padding: 75px 35px 31px 35px;
    }

    .ui-dialog-titlebar {
        position: absolute;
        background-color: #ffffff;
        right: 32px;
        top: 35px;

        .ui-dialog-titlebar-close {
            border: 0;
            background-color: transparent;
            -webkit-appearance: none;

            .dialog-closecontainer {
                position: relative;
                cursor: pointer;

                .dialog-closetext {
                    @include typeset(body-normal);
                    // font-family: "Gill Sans Light", sans-serif;
                    // font-size: 16px;
                    
                    padding-right: 27px;
                }

                .dialog-closeicon {
                    width: 13px;
                    height: 13px;
                    position: absolute;
                    right: 3px;
                    top: 13px;

                    &:before,
                    &:after {
                        content: "";
                        width: 17px;
                        height: 1px;
                        background-color: #292929;
                        display: inline-block;
                        transform: rotate(45deg);
                        position: absolute;
                        top: -1px;
                        right: -4px;
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }

                }
            }
        }
    }

    .overlay-header {
        h1 {
            @include typeset(display-3);
            @include typeface(serif);
            // font-family: $font-serif;
            // font-size: 29px;
            // font-style: normal;
            // text-transform: none;
            // line-height: normal;
            margin-top: 5px;
            text-align: center;
            margin-bottom: 22px;
            padding: 0
        }

        &.special-delivery {
            padding-bottom: 25px;

            p {
                @include typeset(body-small);
                // font-size: 14px;
                text-align: center;

                a {
                    // font-size: 14px;
                    // color: #292929;
                    text-decoration: underline;
                }
            }
        }
    }

    .overlay-content {
        @include typeset(body-normal);
        // font-size: 16px;
        // font-family: "Gill Sans Light", sans-serif;
        line-height: 24px;
        border-top: 1px solid #F5F5F5;
        border-bottom: 1px solid #F5F5F5;
        overflow-y: auto;

        &.special-delivery {
            padding-top: 27px;

            h2 {
                @include typeset(display-4);
                // font-family: $font-sans-serif;
                // font-size: 18px;
                // font-weight: 600;
                // text-align: left;
                margin-bottom: 0;
            }

            .list_container {
                margin: 24px 0;
            }
        }
    }

    .overlay-footer {
        .overlay-close-button {
            display: block;
            max-width: 400px;
            width: 100%;
            margin: 40px auto 0 auto;
        }
    }

    #pdpGalleryCarouselContainer {
        display: none;
    }
}

.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 500;
}

.closexbuttoncontainer {
    height: 12px;
    width: 12px;
    overflow: hidden;
    cursor: pointer;

    span {
        @include typeset(body-normal);
        // font-size: 16px;
        position: absolute;
        top: -3px;
        left: -35px;
    }

    .closexbutton {
        height: 16px;
        width: 1px;
        transform: rotate(45deg);
        margin-left: 5px;
        background-color: #292929;

        .closexbuttoninner {
            transform: rotate(90deg);
            margin: -2px 0 0 0;
        }
    }
}

.closebutton {
    @include typeset(body-small);
    // font-size: 12px;
    // line-height: 12px;
    display: inline-block;
    margin: 0 0 5px 10px;
    width: 30px;

    .button {
        // line-height: 12px;
        margin: -6px;
    }
}

.dl {
    display: none;

    .dimmedlayer {
        width: 100%;
        height: 100%;
        opacity: 0.60;
        filter: alpha(opacity=60);
        background-color: #000000;
        z-index: 1000;
        position: fixed;
    }

    .boxondlayer {
        position: absolute;
        z-index: 1100;
        width: 100%;
        padding-bottom: 20px;

        .logo {
            float: left;
        }

        .rightlinks {
            float: right;
            position: relative;
            z-index: 20;

            .secondarylink {
                float: left;
                margin-right: 10px;
            }

            .closebutton {
                float: left;
                margin-top: -6px;
            }
        }

        .head {
            padding-top: 12px;
            border-bottom: 1px solid #a19589;

            .headline {
                @include typeface(serif);
                @include typeset(display-3);
                // font-family: Georgia, serif;
                // background-color: transparent;
                // font-size: 30px;
                // color: #292929;
                // text-transform: uppercase;
                // font-style: italic;
                
                float: left;
                padding: 2px 0px 15px 0px;
            }

            .ordernumber {
                @include typeset(body-large);
                // font-size: 24px;

                font-weight: bold;
                float: right;

                span {
                    @include typeset(body-large);
                    // font-size: 18px;
                    font-weight: normal;
                }
            }
        }

        .headlinecontainer {
            margin: 0 15px 0 10px;
            padding: 10px 0;
            border-bottom: 1px solid #a3988b;

            .headline {
                @include typeface(serif);
                @include typeset(body-large);
                // font-family: Georgia;
                // font-size: 24px;
                // font-style: italic;
                display: inline-block;
                padding-right: 15px;
            }

            .checkmarkicon {
                display: inline-block;
            }
        }

        .crossprofileprompt {
            background-color: #ffffff;
            max-width: 780px;
            margin: auto;
            padding: 0 82px;

            @media screen and (max-width: 640px) {
                padding: 0 6.25%;
            }

            &.crossprofileprompt--registered {
                max-width: 700px;
                position: relative;
                box-sizing: border-box;

                .closexbuttoncontainer {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }

        .overlaybox710 {
            position: relative;
            margin: 107px auto 10px auto;
            background-color: #ffffff;
            max-width: 670px;
            -moz-box-shadow: 0px 0px 25px -5px #000000;
            -webkit-box-shadow: 0px 0px 25px -5px #000000;
            box-shadow: 0px 0px 25px -5px #000000;
            padding: 20px;

            &.removegoodie {
                max-width: 825px;
                text-align: center;

                .contenttop {
                    .closebutton {
                        margin: 0;
                        width: auto;

                        .button {
                            margin: 0;

                            .small {
                                background-image: none;

                                button {
                                    background-image: url('../images/luk_modal.png');
                                }
                            }
                        }
                    }
                }

                .button {
                    margin: 0;

                    .large {
                        background-image: none;
                        margin: 0;

                        button {
                            background-image: none;
                            margin: 0;
                            height: 43px;
                            width: 160px;
                            margin-bottom: 30px;
                        }
                    }
                }

                .continuebutton {
                    margin: 0;
                    display: inline-block;
                    float: none;

                    button {
                        background-color: #292929;
                    }
                }

                .closebutton {
                    @include typeset(body-normal);
                    //font-size: 15px;
                    
                    width: 160px;
                    margin: 0;
                    text-align: center;
                    margin-right: 20px;
                }

                .orderflowcontent {
                    .closebutton {
                        button {
                            color: #292929;
                            border: 1px solid;
                        }
                    }
                }

                .goodieadvantages {
                    @include typeset(body-normal);
                    // font-size: 16px;
                    padding: 0 90px;

                    >img {
                        width: calc(100% + 180px);
                        width: -webkit-calc(100% + 180px);
                        width: -moz-calc(100% + 180px);
                        margin: 0 -90px 25px;
                    }

                    .subheader {
                        @include typeface(serif);
                        @include typeset(display-3);
                        // font-family: $font-serif;
                        // font-size: 27px;
                        // font-style: normal;
                        // text-transform: none;
                        // line-height: 30px;
                        
                        padding: 0 0 10px 0;
                    }

                    .text {
                        padding: 0;
                    }

                    .infotext {
                        padding: 0 65px;
                        margin-bottom: 45px;

                        a {
                            color: #aaaaaa;
                            text-decoration: underline;
                        }
                    }

                    .advantages {
                        text-align: center;
                        padding: 28px 0 23px 0;

                        .advantage {
                            display: inline-block;
                            width: 20%;
                            float: left;
                            padding: 0px 20px;
                            box-sizing: border-box;

                            .description {
                                display: none;
                            }

                            img {
                                width: 60px;
                            }

                            .title {
                                @include typeset(display-5);
                                // color: #292929;
                                // font-weight: bold;
                                // font-size: 13px;
                                // text-transform: uppercase;
                                
                                word-wrap: break-word;
                            }
                        }
                    }
                }
            }
        }

        .overlaybox780 {
            position: relative;
            margin: 107px auto 10px auto;
            background-color: #ffffff;
            max-width: 740px;
            padding: 20px 20px 0 20px;

            &--addtobasket {
                min-height: 500px;

                .rrspinner {
                    background-image: url(../images/loader.gif);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 32px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 247px;
                }

                &.overlaybox780--no-rr {
                    min-height: 0;
                    position: fixed;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-top: 0;

                    .addtobasket-top {
                        margin-top: 0;
                    }

                    .rrplaceholder {
                        margin: 0;
                    }
                }
            }

            .matchescarousel-container {
                .matchescarousel {
                    padding: 0 0 40px;

                    &:after {
                        content: none;
                    }
                }
            }

            .contenttop {
                padding-bottom: 15px;

                .logo {
                    width: 136px;

                    img {
                        width: 100%;
                    }
                }
            }

            .rightlinks {
                float: right;
                position: relative;
                z-index: 20;

                .closebutton {
                    margin: 0;
                    width: auto;
                    float: left;

                    .button {
                        margin: 0;
                        line-height: 12px;

                        .small {
                            background-image: none;

                            button {
                                background-image: url(../images/luk_modal.png);
                            }
                        }
                    }
                }

                .primarylink {
                    margin-right: 0;
                    margin-top: 0;
                }
            }

            .orderflowcontent {
                margin-top: 0;

                .head {
                    border-bottom: none;
                    padding-top: 0;
                }

                .twocolls {
                    display: table;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    border-top: 1px solid #bbbbbb;

                    .leftside {
                        padding-bottom: 10px;
                        display: table-cell;
                        height: 100%;
                        vertical-align: top;
                        position: relative;
                        float: none;

                        .form-row {
                            float: none;
                        }

                        .secondarylink {
                            display: inline-block;
                            float: right;
                            font-family: inherit;
                            font-style: inherit;
                            font-weight: inherit;

                            a {
                                color: #a19589;
                            }
                        }

                        .rememberme {
                            .label {
                                display: none;
                                width: auto;
                            }

                            label {
                                line-height: 20px;
                                display: inline-block;
                            }
                        }
                    }

                    .rightside {
                        display: table-cell;
                        height: 100%;
                        vertical-align: top;
                        position: relative;
                        float: none;

                        .continuebutton {
                            margin-right: 0;
                            width: 100%;
                            position: absolute;
                            bottom: 10px;

                            .button {
                                margin: 0;

                                button {
                                    @include typeset(body-normal);
                                    // font-size: 17px;
                                    // font-weight: normal;
                                    background: #292929;
                                    line-height: 42px;
                                    -webkit-appearance: none;
                                    border: none;
                                    width: 50%;
                                }

                                .large {
                                    background-image: none;
                                    margin: 0;

                                    button {
                                        padding: 0;
                                        background-image: none;
                                        height: auto;
                                        margin: 0;
                                        zoom: 0;
                                        // font-family: $font-sans-serif;
                                    }
                                }
                            }
                        }

                        .primarylink {
                            font-family: inherit;
                            font-style: inherit;
                            font-weight: inherit;
                            margin-right: 0;
                            margin-top: 0;
                        }
                    }
                }

                .leftfloat {
                    float: none;
                }

                .headline {
                    text-transform: none;
                    font-size: 26px;
                }

                fieldset {
                    margin-top: 20px;

                    .label {
                        font-weight: bold;
                        padding-bottom: 5px;
                    }

                    .input-text {
                        background-color: transparent;
                        font-weight: normal;
                        padding: 13px;
                        width: 100%;
                        box-sizing: border-box;
                        border: 1px solid #a19589;
                    }

                    .rememberme {
                        display: inline-block;
                        padding: 0;

                        .checkbox {
                            float: left;

                            .checker {
                                padding: 0px 5px 0px 0px;

                                span {
                                    display: inline-block;
                                    background-image: none;
                                    width: 20px;
                                    height: 20px;
                                    border: 1px solid #a19589;
                                    box-sizing: border-box;

                                    &.checked {
                                        background-image: url(../images/tickmark_black.png);
                                        background-repeat: no-repeat;
                                        background-size: 20px;
                                        background-size: 11px;
                                        background-position: center center;
                                    }
                                }
                            }
                        }
                    }
                }

                .bottom {
                    padding-top: 25px;

                    .continuebutton {
                        float: left;
                        margin-right: 0;
                        width: 100%;
                        position: relative;
                        bottom: 0;

                        .button {
                            margin: 0;

                            .lockimg {
                                background-image: none;
                                margin: 0;

                                button {
                                    @include typeset(body-normal);
                                    // font-family: $font-sans-serif;
                                    // font-size: 17px;
                                    line-height: 42px;
                                    // font-weight: normal;
                                    padding: 0;
                                    background-image: none;
                                    height: auto;
                                    margin: 0;
                                    zoom: 0;
                                    -webkit-appearance: none;
                                    border: none;
                                    background: #65ba9c;
                                    width: 50%;
                                }
                            }
                        }
                    }
                }

                .newsletterpopup {
                    padding: 0;
                    border: none;

                    .leftside {
                        text-align: center;
                        line-height: 22px;
                        font-size: 13px;
                        padding-top: 33px;
                        padding-left: 20px;

                        .logo {
                            width: 100%;
                            float: none;
                            margin-bottom: 30px;

                            img {
                                width: 100px;
                            }
                        }

                        h1 {
                            text-transform: none;
                            margin-bottom: 25px;
                            line-height: 40px;
                            padding: 0;
                        }

                        input[type="submit"] {
                            font-size: 15px;
                            color: #ffffff;
                            text-transform: uppercase;
                            border: none;
                            background-color: #292929;
                            width: 160px;
                            height: 43px;
                            margin-top: 30px;
                            -webkit-appearance: none;
                        }
                    }

                    .rightside {
                        padding: 0;
                        width: 65%;

                        >img {
                            width: 100%;
                            height: auto;
                            margin-bottom: 40px;
                        }

                        .subheader {
                            @include typeface(serif);
                            // font-family: Georgia;
                            // font-style: italic;
                            // text-transform: uppercase;
                            font-size: 20px;
                            line-height: 40px;
                        }

                        .text {
                            @include typeface(serif);
                            // font-family: Georgia;
                            // font-style: italic;
                            font-size: 17px;
                        }

                        .advantages {
                            padding: 45px 0 55px 20px;

                            .advantage {
                                width: 19%;
                                display: inline-block;
                                text-align: center;
                                float: left;
                                padding: 7px;
                                box-sizing: border-box;

                                img {
                                    width: 50px;
                                    height: 50px;
                                }

                                .title {
                                    color: #292929;
                                    font-weight: bold;
                                    word-break: break-word;
                                    text-transform: uppercase;
                                }

                                .description {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .popular-owl-carousel {
                .item {
                    .producttext .productname {
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                        //                        + div {
                        //                            white-space: normal;
                        //                        }
                    }

                }
            }

            .addbasketproduct~.rrplaceholder .popular-owl-carousel {
                margin-top: -12px;
            }
        }

        .overlaybox550 {
            position: relative;
            margin: 107px auto 10px auto;
            background-color: #ffffff;
            max-width: 510px;
            -moz-box-shadow: 0px 0px 25px -5px #000000;
            -webkit-box-shadow: 0px 0px 25px -5px #000000;
            box-shadow: 0px 0px 25px -5px #000000;
            padding: 20px 35px;
            text-align: left;

            &.overlaybox550-ext560 {
                box-shadow: none;
                padding: 17px 35px 35px 35px;
                max-width: 490px;

                .headline {
                    @include typeface(serif);
                    // font-family: Georgia, serif;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 18px;
                    margin-top: 20px;
                }

                .stocknotifyoverlay .narrowtext {
                    // font-family: Arial, sans-serif;
                    font-size: 13px;
                    line-height: 13px;
                    margin-top: 3px;
                }
            }

            .contenttop {
                padding-bottom: 0;

                .logo {
                    display: none;
                }

                .rightlinks {
                    float: right;
                    position: relative;
                    z-index: 20;

                    .closebutton {
                        margin: 0;
                        width: auto;

                        .button {
                            margin: 0;

                            .small {
                                background-image: none;

                                button {
                                    background-image: url(../images/luk_modal.png);
                                    width: 11px;
                                    margin: 0;
                                    background-position: center;
                                    color: transparent;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }

            .orderflowcontent {
                margin-top: 0;
                font-size: 15px;

                .contenttop {
                    padding-bottom: 0;

                    .headline {
                        @include typeface(serif);
                        @include typeset(body-large);
                        // background-color: transparent;
                        // font-family: Georgia, serif;
                        // color: #292929;
                        // text-transform: uppercase;
                        // font-style: italic;
                        // font-size: 20px;
                        // line-height: 20px;
                        padding: 2px 0px 5px 0px;

                        h1 {
                            @include typeset(body-large);
                            // text-transform: none;
                            // font-size: 20px;
                            // font-style: normal;
                            // font-weight: bold;
                            // line-height: normal;
                            // color: #292929;
                            // line-height: 30px;
                            
                            padding: 0px;
                            border: none;
                            width: auto;
                            width: 270px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }

                #PasswordResetForm {
                    .orderflowcontent {
                        width: 100%;

                        fieldset {
                            border: none;
                            padding: 0px;
                            margin: 0px;

                            .form-row {
                                padding: 0;
                                width: 100%;

                                .leftfloat {
                                    width: 100%;

                                    input {
                                        font-weight: normal;
                                        padding: 13px;
                                        width: 100%;
                                        box-sizing: border-box;
                                        border: 1px solid #a19589;
                                        background-color: transparent;
                                    }
                                }

                                .label {
                                    float: none;
                                    display: inline-block;
                                    width: 55px;
                                    padding-bottom: 5px;

                                    label {
                                        font-weight: bold;
                                    }
                                }

                                .required-indicator {
                                    float: none;
                                }

                                div.error-validationtype2 {
                                    margin: 0;
                                    font-style: normal;
                                }

                                div.error-validationtype2 {
                                    background-color: transparent;
                                    color: #fe3e64;
                                    float: left;
                                }
                            }
                        }

                        .ofbottom {
                            border-top: 1px solid #e7e3df;
                            padding: 15px 0px 36px 0px;

                            .continuebutton {
                                float: none;
                                margin-right: 0;
                                width: 100%;

                                .button {
                                    margin: 0;

                                    .large {
                                        background-image: none;
                                        margin: 0;

                                        button {
                                            @include typeset(body-normal);
                                            // font-size: 17px;
                                            // font-weight: normal;
                                            line-height: 42px;
                                            padding: 0 30px;
                                            background-image: none;
                                            height: auto;
                                            margin: 0;
                                            zoom: 0;
                                            -webkit-appearance: none;
                                            border: none;
                                            background: #65ba9c;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .addressdetails {
                .headline {
                    border-bottom: 1px solid #a3988b;
                    text-transform: none;
                    font-size: 21px;
                    padding-bottom: 10px;
                    margin-bottom: 14px;
                }

                .inputFieldset {
                    margin: 0 -8.5px;
                }

                .form-row {

                    &.companyName,
                    &.firstName,
                    &.lastName {
                        width: 33.33%;
                        padding: 14px 8.5px;
                        box-sizing: border-box;

                        .error,
                        .error-validationtype2 {
                            white-space: normal;
                        }
                    }

                    &.country {
                        width: 50%;
                        padding: 14px 8.5px;
                        clear: left;
                    }

                    &.address1 {
                        width: 100%;
                        padding: 14px 8.5px;
                        box-sizing: border-box;
                    }

                    &.city,
                    &.zip {
                        width: 50%;
                        padding: 14px 8.5px;
                        box-sizing: border-box;

                        .error,
                        .error-validationtype2 {
                            white-space: normal;
                        }
                    }

                    //					&.country{
                    //						display: none;
                    //					}
                    .leftfloat {
                        width: 100%;

                        .required-indicator {
                            float: none;
                            margin-left: 0;
                            color: #292929;
                        }

                        input {
                            width: 100%;
                            height: 42px;
                            border-radius: 3px;
                            background-color: #ffffff;
                            border: 1px solid #797979;
                            margin: 0;
                            box-sizing: border-box;
                            font-weight: normal;
                        }
                    }

                    .label {
                        float: none;
                        display: inline-block;
                        width: auto;
                        padding-bottom: 5px;
                    }
                }

                .ofbottom {
                    border: none;
                    padding: 15px 0px 12px 0px;
                    margin: 0;
                    text-align: right;

                    .cancelbutton {
                        float: none;
                        margin-right: 0;
                        width: 160px;
                        display: inline-block;

                        .button {
                            margin: 0;

                            .large {
                                background-image: none;
                                margin: 0;

                                input[type="button"] {
                                    @include typeset(body-normal);
                                    // font-size: 17px;
                                    // font-weight: normal;
                                    line-height: 42px;
                                    padding: 0;
                                    background-image: none;
                                    height: auto;
                                    margin: 0;
                                    zoom: 0;
                                    -webkit-appearance: none;
                                    background: #ffffff;
                                    color: #292929;
                                    border: 1px solid #292929;
                                    width: 160px;
                                }
                            }
                        }
                    }

                    .continuebutton {
                        float: none;
                        margin-left: 15px;
                        margin-right: 0;
                        width: 160px;
                        display: inline-block;

                        .button {
                            margin: 0;

                            .large {
                                background-image: none;
                                margin: 0;

                                button {
                                    @include typeset(body-normal);
                                    // font-size: 17px;
                                    // font-weight: normal;
                                    line-height: 42px;
                                    padding: 0;
                                    background-image: none;
                                    height: auto;
                                    margin: 0;
                                    zoom: 0;
                                    -webkit-appearance: none;
                                    border: none;
                                    background: #292929;
                                    width: 160px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .advlist {
            float: left;
            padding: 0px 5px 0px 0px;

            ul {
                list-style-image: none;
                padding: 20px 0px 8px 0px;
                margin: 0px;

                li {
                    background-image: url(../images/sb_checkmark.png);
                    padding: 7px 0px 5px 18px;
                }
            }
        }

        .stocknotifyoverlay {
            display: contents;

            .overlaybox550-ext560 {
                max-width: 500px;
                box-sizing: border-box;
                padding: 17px 40px 40px 40px;

                .rightlinks {
                    margin: -20px -18px 0 0;
                }

                .paragraf {
                    max-width: 262px;
                    margin: auto;
                    text-align: center;

                    .headline {
                        font-family: $font-serif;
                        font-style: normal;
                        font-weight: normal;
                        white-space: normal;
                        font-size: 30px;
                        line-height: 40px;
                    }

                    .narrowtext {
                        // untested
                        @include typeface(sans-serif);
                        // font-family: "Gill Sans Light", sans-serif;
                        line-height: 25px;
                        font-size: 16px;
                        text-align: center;
                        margin: 20px auto 0 auto;
                        font-weight: 600;
                    }
                }

                .orderflowcontent {
                    .form-row {
                        padding: 0;
                        margin: 28px 0 0 0;
                        float: none;

                        .leftfloat {
                            border: 1px solid #E7E7E7;
                            display: block;
                            margin: auto;
                            width: 100%;
                            line-height: 8px;

                            .input-text {
                                // untested
                                @include typeface(sans-serif);
                                // font-family: "Gill Sans Light", sans-serif;
                                display: block;
                                float: none;
                                width: 100%;
                                box-sizing: border-box;
                                font-size: 16px;
                                font-weight: 300;
                                line-height: 20px;
                                border: none;
                                padding: 14px 20px;
                                margin-top: 0;
                            }
                        }

                        //                        .leftfloat {
                        //                            width: 100%;
                        //                            .input-text {
                        //                                font-size: 12px;
                        //                                clear: left;
                        //                                display: block;
                        //                                background-color: #ffffff;
                        //                                height: 37px;
                        //                                width: 100%;
                        //                                border-radius: 2px;
                        //                                padding: 0 0 0 17px;
                        //                                margin: 22px 0 0 0;
                        //                                font-weight: normal;
                        //                                box-sizing: border-box;
                        //                            }
                        //                        }
                    }

                    .infotext {
                        // untested
                        @include typeface(sans-serif);
                        // font-family: "Gill Sans Light", sans-serif;
                        margin: 8px auto 25px auto;
                        line-height: 21px;

                        &.infotext--center {
                            text-align: center;
                            margin-bottom: 6px;
                        }

                        &.infotext--bold {
                            font-weight: 600;
                            margin-bottom: 36px;
                        }
                    }
                }

                .bottom {
                    .stocknotifysubmitbutton {
                        .button-style {
                            width: 100%;
                        }
                    }
                }
            }

            &.savedoverlay {
                .overlaybox550-ext560 {
                    .rightlinks {
                        margin: -20px -18px 0 0;
                    }

                    .paragraf {
                        margin-bottom: 23px;
                    }

                    .orderflowcontent {
                        .form-row {
                            .infotext {
                                @include typeset(body-normal);
                                // font-family: "Gill Sans Light", sans-serif;
                                // font-size: 16px;
                                // line-height: 21px;
                                margin: 8px auto 25px auto;

                                &.infotext--center {
                                    text-align: center;
                                    margin-bottom: 6px;
                                }

                                &.infotext--bold {
                                    font-weight: 600;
                                    margin-bottom: 36px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .narrow {
            padding: 0;

            .contenttop {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-color: #ffffff;
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;

                button {
                    background-position: center;
                    height: 25px;
                    padding: 0;
                    width: 25px;
                }
            }
        }

        .orderflowcontent {
            .headline {
                @include typeface(serif);
                @include typeset(body-large);
                // background-color: transparent;
                // font-family: Georgia, serif;
                // font-size: 20px;
                // color: #292929;
                // text-transform: uppercase;
                // font-style: italic;
                // line-height: 20px;
                padding: 2px 0px 5px 0px;
            }
        }
    }

    .orderflowcontent {
        padding: 0px 0px 0px 0px;
        margin-top: 9px;

        .twocolls {
            padding: 24px 0 26px 0;

            .leftside {
                border-right: 1px solid #e7e3df;
                box-sizing: border-box;
                float: left;
                width: 362px;
                padding-right: 20px;

                .head {
                    padding: 0px 0px 22px 0px;
                }

                .form-row {
                    float: left;
                    padding: 5px 0px 10px 0px;
                    //                    .error {
                    //                        color: #ff0000;
                    //                        font-size: 11px;
                    //                        margin: 2px;
                    //                        background-color: transparent;
                    //                        font-style: normal;
                    //                    }
                }
            }

            .rightside {
                float: left;
                padding: 0px 20px 0px 25px;
                width: 313px;

                .primarylink {
                    float: left;
                    margin-right: 5px;
                    margin-top: 20px;
                }
            }
        }

        fieldset {
            border: none;
            padding: 0px;
            margin: 0px;

            .form-row {
                float: left;
                padding: 5px 0px 5px 70px;

                .input-text {
                    border: 1px solid #cfcfcf;
                    width: 230px;
                    // font-family: Arial;
                    font-weight: bold;
                    font-size: 12px;
                    background-color: #ffffff;
                    margin: -2px 0px 0px 0px;
                    padding: 3px 5px 1px 5px;
                }
            }
        }

        .kvitinfo {
            padding: 5px 0px 5px 0px;
        }

        .collheadbar {
            background-color: #ebebeb;
            font-size: 11px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;

            .product {
                float: left;
                width: 57%;
            }

            .amount {
                float: left;
                width: 13%;
            }

            .pricestk {
                float: left;
                width: 21%;
            }

            .price {
                float: left;
                text-align: right;
                width: 9%;
            }
        }

        .basketitem_of {
            margin: 10px 0px 0px 0px;
            padding-bottom: 5px;

            .productname {
                // font-family: Georgia, serif;
                float: left;
                font-size: 15px;
                text-transform: uppercase;
                width: 53%;
                overflow: hidden;
                white-space: nowrap;
            }

            .amountinbasket {
                float: left;
                text-align: right;
                width: 12%;
            }

            .productpricestk {
                float: left;
                text-align: right;
                width: 15%;
            }

            .productprice {
                float: left;
                text-align: right;
                width: 20%;
            }

            .productimg {
                float: left;
                min-width: 64px;
                min-height: 64px;
                text-align: center;
                padding-top: 12px;
                padding-right: 6px;
            }

            .productinfo {
                float: left;
                background-image: url(../images/basket_divider_99.png);
                background-repeat: no-repeat;
                padding: 10px 10px 0px 10px;
                min-height: 79px;
            }

            .rightsidetext {
                float: right;
                font-weight: normal;
                padding: 10px 19px 30px 0px;
            }
        }

        .sbslleft {
            float: left;
        }

        .sbslright {
            float: right;
        }

        .sbsumline {
            border-bottom: 1px solid #dddad7;
            padding: 4px 0px 4px 0px;
            margin-right: 0px;
        }

        .sbtotalline {
            border-top: 1px solid #a19589;
            padding: 8px 0px 8px 0px;
            margin: -1px 0px 0px 0px;

            .sbslright {
                font-weight: bold;
                padding-bottom: 10px;
            }
        }

        .barcodeimg {
            width: 50%;
            height: auto;
            display: block;
            margin: auto;
        }

        .standardinfo {
            margin: 0px 0px 20px 0px;

            .label {
                float: left;
                width: 20%;
            }

            .value {
                float: left;
                width: 80%;
                font-weight: bold;
            }

            .info {
                background-color: transparent;
                padding-left: 20%;
                font-weight: bold;
                color: #a19589;
                font-style: italic;
            }
        }
    }
}

.returnCode {
    padding: 0 0 14px 0;
}

.giftwrappingReceipt {
    display: block;
    height: 15px;
    padding-bottom: 11px;

    &.giftwrappingReceipt-total {
        border-bottom: 1px solid #d0cac4;
    }
}

.goodiebuttons {
    width: 93%;
    margin: 0px 0 0 19px;
}

input#skiftkort {
    background-color: #97BEC4;
    color: #ffffff;
    border: solid 1px;
    padding: 13px;
    width: 49%;
    font-family: $font-sans-serif;
    font-size: 18px;
    margin: 0px 0 25px 0;
    cursor: pointer;
}

input#fortrydkortskift {
    background-color: #ffffff;
    border: solid 1px;
    width: 49%;
    font-family: $font-sans-serif;
    padding: 14px;
    margin: 15px 0 18px 0;
    cursor: pointer;
}

.goodiecardappbanner {
    img {
        width: 100%;
    }
}

.onecoll.goodiecartpopup {
    .leftside {
        h1 {
            font-style: normal;
            font-family: $font-serif;
            font-size: 27px;
            margin-bottom: 15px;
            line-height: 35px;
            text-align: center;
            margin-right: 29px;
            text-transform: none;
        }

        p {
            // untested
            @include typeface(sans-serif);
            // font-family: "Gill Sans Light", sans-serif;
            font-size: 16px;
            margin: 0 20px 20px 20px;
            color: #292929;
            line-height: 24px;
            text-align: justify;

        }
    }

}


@media screen and (min-width: 770px) {
    .overlaybox780--addtobasket .popular-owl-carousel {
        padding: 40px 0 59px;
    }

    .overlaybox780--addtobasket {
        .addtobasket-top {
            background-color: #f4f4f4;
            margin: -20px -20px 0 -20px;
        }
    }

    .ui-dialog[aria-describedby="special_del_overlay"] {
        top: 200px !important;

        .overlay-header.special-delivery {
            padding-bottom: 34px;

            p,
            p a {
                // untested
                @include typeface(sans-serif);
                // font-family: "Gill Sans Light", sans-serif;
                font-size: 16px;
                line-height: 24px;
            }
        }

        //        .overlay-content.special-delivery {
        //            padding-top: 27px;
        //            h2 {
        //                font-family: $font-sans-serif;
        //                font-size: 18px;
        //                font-weight: 600;
        //                text-align: left;
        //                margin-bottom: 0;
        //            }
        //            .list_container {
        //                margin: 24px 0;
        //            }
        //        }
    }
}



@media screen and (max-width: 769px) {

    .goodiebuttons {
        width: 93%;
        margin: 0px 0 0 17px;
    }

    input#skiftkort {
        background-color: #97BEC4;
        color: #ffffff;
        border: none;
        padding: 14px;
        width: 100%;
        font-family: $font-sans-serif;
        font-size: 21px;
        margin: 0px 0 25px 0;
    }

    input#fortrydkortskift {
        margin-top: 15px;
        background-color: #ffffff;
        border: solid 1px;
        width: 100%;
        font-family: $font-sans-serif;
        padding: 14px;
        margin: 0px -0 18px 0;
    }

    .onecoll.goodiecartpopup {
        .leftside {
            h1 {
                font-style: normal;
                font-family: $font-serif;
                font-size: 18px;
                margin-bottom: 15px;
                line-height: 27px;
                text-align: center;
                margin-right: 29px;
            }

            p {
                font-size: 15px;
                margin: 0 20px 20px 20px;
                color: #292929;
            }
        }

    }

    input#skiftkort {
        font-size: 17px
    }


    .closexbuttoncontainer {
        span {
            left: -32px;
            font-size: 14px;
        }
    }

    .ui-dialog {
        padding: 40px 20px 40px 20px;
        top: 70px !important;

        .ui-dialog-titlebar {
            right: 14px;
            top: 14px;
        }

        .overlay-content {
            .overlay-footer {
                .overlay-close-button {
                    max-width: none;
                }
            }
        }
    }

    .dl {
        .boxondlayer {
            //          position: fixed;
            text-align: center;

            //          margin-top: 0 !important;
            .headlinecontainer {
                .headline {
                    // untested
                    @include typeface(serif);
                    // font-family: Georgia;
                    // font-style: italic;
                    font-size: 24px;
                    display: inline-block;
                    padding-right: 15px;
                }

                .checkmarkicon {
                    display: inline-block;
                }
            }

            .overlaybox710 {
                &.removegoodie {
                    .goodieadvantages {
                        padding: 0 30px;

                        >img {
                            width: calc(100% + 60px);
                            width: -webkit-calc(100% + 60px);
                            width: -moz-calc(100% + 60px);
                            margin: 0 -30px 25px;
                        }

                        .infotext {
                            padding: 0;
                        }

                        .advantages {
                            display: -webkit-box !important;
                            display: -ms-flexbox !important;
                            display: flex !important;
                            -webkit-box-pack: center !important;
                            -ms-flex-pack: center !important;
                            justify-content: center !important;
                            -ms-flex-wrap: wrap !important;
                            flex-wrap: wrap !important;

                            .advantage {
                                float: none;
                                width: 50%;
                                padding: 10px;
                            }
                        }
                    }
                }
            }

            .overlaybox780 {
                display: inline-block;
                text-align: left;
                box-sizing: border-box;
                margin: 0;
                width: 100%;
                overflow: auto;

                &.overlaybox780--addtobasket {
                    &.overlaybox780--no-rr {
                        .addtobasket-top {
                            margin-top: 0;
                        }
                    }

                    .addtobasket-top {
                        background-color: #f4f4f4;
                        margin: -20px -20px 0 -20px;
                    }
                }

                .contenttop {}

                .orderflowcontent {
                    .twocolls {
                        display: block;
                        border-top: none;

                        .leftside {
                            display: block;
                            border: none;
                            width: 100%;
                        }

                        .rightside {
                            display: block;
                            padding: 20px 0 0 0;
                            width: 100%;

                            .continuebutton {
                                position: static;
                            }
                        }
                    }
                }

                .popular-owl-carousel {
                    background-color: white;
                    margin-bottom: -20px;
                    padding-right: 0px;

                    h1,
                    h2 {
                        margin-right: 0;
                        margin-top: 0;
                    }
                }

                .matchescarousel-container .matchescarousel {
                    background-color: white;
                    padding-right: 0px;
                    margin: 0 0 -20px 0;

                    h1,
                    h2 {
                        margin-right: 0;
                        margin-top: 10px;
                    }

                    .owl-carousel {
                        width: 100%;
                    }

                    .owl-dots {
                        padding: 0;
                    }
                }
            }

            .overlaybox550 {
                display: inline-block;
                text-align: left;
                box-sizing: border-box;
                margin: 0;

                .contenttop {}

                .orderflowcontent {
                    .twocolls {
                        display: block;
                        border-top: none;

                        .leftside {
                            display: block;
                            border: none;
                        }

                        .rightside {
                            display: block;
                            padding: 20px 0 0 0;

                            .continuebutton {
                                position: static;
                            }
                        }
                    }
                }
            }

            .head {
                .headline {
                    float: none;
                }

                .ordernumber {
                    font-size: 18px;
                    float: none;
                }
            }
        }

        .orderflowcontent {
            .collheadbar {
                text-align: left;

                .product {
                    width: 53%;
                }

                .amount {
                    width: 15%;
                }

                .pricestk {
                    width: 18%;
                    text-align: right;
                }

                .price {
                    width: 14%;
                }
            }

            .basketitem_of {
                margin: 10px 0px 0px 0px;
                padding-bottom: 5px;
                text-align: left;

                .amountinbasket {
                    float: left;
                    text-align: right;
                    width: 14%;
                }

                .productpricestk {
                    float: left;
                    text-align: right;
                    width: 19%;
                }

                .productprice {
                    float: left;
                    text-align: right;
                    width: 14%;
                }
            }

            .standardinfo {
                text-align: left;

                .label {
                    width: 25%;
                }

                .value {
                    width: 75%;
                }

                .info {
                    padding-left: 25%;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {

    .dl .boxondlayer .overlaybox550 {
        padding: 20px 7px 50px 15px;
    }

    .dl .boxondlayer .overlaybox780 .matchescarousel-container .matchescarousel {
        margin-right: 0px;
        margin-top: 0px;
    }

    .goodiebuttons {
        display: grid;
        height: 91px;
        margin: 0 0px 0 10px;
    }

    input#skiftkort {
        background-color: #97BEC4;
        color: #ffffff;
        border: none;
        padding: 2px 0;
        font-size: inherit;
        width: 100%;
        margin-bottom: 15px;
        -webkit-appearance: none;
        border-radius: 0;
    }

    input#fortrydkortskift {
        // untested
        @include typeface(sans-serif);
        // font-family: gill sans, sans-serif;
        width: 100%;
        margin-top: 15px;
        background-color: #ffffff;
        border: solid 1px;
        display: grid;
        font-size: inherit;
        margin: 0;
        padding: 2px 0;
        -webkit-appearance: none;
        border-radius: 0;
    }

    .goodiecardappbanner {
        img {
            width: 100%;
        }
    }

    .onecoll.goodiecartpopup {
        .leftside {
            h1 {
                font-style: normal;
                font-family: $font-serif;
                font-size: 20px;
                margin-bottom: 15px;
                line-height: 27px;
            }

            p {
                font-size: 14px;
                margin-bottom: 20px;
                max-width: 82%;
                text-align: left;
            }
        }

    }

    .ui-dialog {
        left: 0px !important;
        width: 100% !important;
        top: 0px !important;
    }

    .dl {
        .boxondlayer {

            //          margin-top: 0 !important;
            .overlaybox780 {
                display: block;

                //                &.overlaybox780--addtobasket {
                //                
                &.overlaybox780--addtobasket {
                    margin-top: 0px;
                    padding: 20px 12px 0 12px;

                    &.overlaybox780--no-rr {
                        padding-top: 0;

                        .addtobasket-top {
                            margin-top: 0;
                        }
                    }

                    .addtobasket-top {
                        background-color: #f4f4f4;
                        margin: -20px -12px 0 -12px;
                        padding-bottom: 20px;
                    }
                }

                .matchescarousel-container {
                    .matchescarousel {
                        .owl-dots {
                            margin-left: 5px;
                        }
                    }
                }

                .orderflowcontent {
                    .newsletterpopup {
                        .rightside {
                            .advantages {
                                .advantage {
                                    width: 50%;

                                    .title {
                                        min-height: 36px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .overlaybox550 {
                display: block;

                .addressdetails {
                    .form-row {

                        &.companyName,
                        &.firstName,
                        &.lastName {
                            width: 100%;
                        }

                        &.city,
                        &.zip {
                            width: 100%;
                        }
                    }

                    .ofbottom {
                        text-align: center;

                        .cancelbutton {
                            float: left;
                            width: 50%;

                            .button {
                                .large {
                                    input[type="button"] {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .continuebutton {
                            float: left;
                            margin-left: 0;
                            padding-left: 15px;
                            box-sizing: border-box;
                            width: 50%;

                            .button {
                                .large {
                                    button {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .orderflowcontent {
            .barcodeimg {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 321px) {
    .twocolls {
        .leftside {
            .secondarylink {
                font-size: 10px;
                margin-top: 1px;
            }
        }
    }
}