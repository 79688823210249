.standardcontent {
    .widetopbanner {
        margin: -20px 0 25px -24px;

        img {
            width: 100%;
            height: auto;
        }
    }

    &.minheight500 {
        min-height: 500px;
    }
}

.first a {
    // sorthvid
    // color: #292929;
}


.rightcontent {
    .headlinebar {
        margin: 14px 14px 10px 0px;
    }
}

.disclaimer {
    max-width: 1170px;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 95px 0;
    box-sizing: border-box;
    width: 100%;
    color: #A2988B;
    font-size: 11px;
    margin-top: -80px;
}

.campaignbanner {
    margin: 0 -30px;
    overflow: hidden;

    h1 {
        padding: 0;
        line-height: 0;
    }

    img {
        width: auto;
        min-width: 100%;
        height: auto;
        margin: 0 0 30px 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        vertical-align: middle;
    }
}

.pagecontent {
    .maincontent {
        margin: auto;
        max-width: 1440px;
        padding: 0 30px;
        box-sizing: border-box;

        .leftcoll {
            float: left;
            padding: 60px 0px 50px 0px;
            width: 20%;
            box-sizing: border-box;
        }

        .rightcontent {
            float: right;
            padding: 50px 0px 50px 0;
            width: 80%;
            box-sizing: border-box;

            &.registrationreceipt {
                float: none;
                margin: 0 auto;
                padding-right: 17px;
            }

            &.rightcontent--brandsearch {
                float: none;
                width: 100%;
                max-width: 780px;
                margin: auto;

                .notonlinebutinstore-container {
                    margin-bottom: 34px;
                    padding-top: 50px;
                    text-align: center;

                    .notonlinebutinstore {
                        // sorthvid
                        @include typeset(display-3);
                        // font-family: $font-serif;
                        // font-size: 28px;
                        // line-height: 34px;
                        display: inline;
                    }
                }
            }
        }

        .leftcontent {
            float: left;
            width: 80%;

            .contenttop {
                padding-bottom: 17px;

                .headline {
                    padding: 5px 0px 0px 0px;

                    h1 {
                        // sorthvid
                        @include typeset(body-large);
                        // font-size: 20px;
                        // line-height: 30px;
                        // font-style: normal;
                        // line-height: normal;
                        // color: #292929;
                        
                        text-transform: none;
                        padding: 0px;
                        border: none;
                        width: auto;
                        width: 270px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .required-indicator {
                    float: right;
                    padding-top: 26px;
                    margin-right: 120px;
                }
            }

            .orderflowcontent {
                margin-right: 28px;
                padding: 0;

                .ofbottom {
                    padding: 12px 0px 12px 0px;
                    margin: 10px 0px 0px 0px;
                    border-top: 1px solid #a19589;
                }
            }

            .ofbottom {
                .continuebutton {
                    float: right;
                    margin: 0px -5px 0px 0px;
                }
            }
        }
    }

    .maincontentcontainer {
        .maincontent {
            .fullcontent {
                float: left;
                padding: 0px 0px 50px 0;
                width: 100%;

                .standardcontent {
                    padding: 30px 14px 20px 24px;
                }
            }

            &.product-page {
                .navigationbar {
                    display: block;

                    .breadcrumb {
                        display: block;
                    }
                }
            }

            .fullhead {
                display: none;

                &.brandshead {
                    display: block;
                }

                &.productsearch {
                    text-align: right;
                    font-size: 0;

                    h1 {
                        // sorthvid NOTE: defined in _productsort.scss
                        // font-family: $font-serif;
                        // font-size: 36px;
                        // line-height: 44px;
                        // font-style: normal;
                        
                        width: 100%;
                        text-align: center;
                        padding: 0;
                    }

                    .paging .results {
                        // sorthvid
                        @include typeset(body-small);
                        // font-size: 16px;
                        // line-height: 1.4;
                        float: left;
                        padding: 3px 15px 0px 0px;
                    }
                }

                h1 {
                    padding-bottom: 10px;
                    text-transform: none;
                }
            }

            .leftcoll {
                width: 251px;
                padding: 60px 0 50px;
                margin-top: -1px;
            }

            .rightcontent {
                width: calc(100% - 251px);
                padding: 50px 0;
            }

            .rightcoll {
                float: right;
                width: 20%;
                padding-right: 16px;
                margin-bottom: -9px;
                box-sizing: border-box;

                .navigation {
                    background-image: url(../images/rightcoll_bg.png);
                    background-repeat: repeat-y;
                    padding: 0px;
                    margin-right: 14px;

                    .navgroup {
                        background-image: url(../images/rightnav_shadow_divider.png);
                        background-repeat: no-repeat;
                        padding: 10px 0px 10px 14px;
                        margin-right: -14px;

                        .groupname {
                            @include typeset(body-normal);
                            @include typeface(serif);
                        }
                    }
                }

                .rightcollbottom {
                    height: 100px;
                }
            }
        }
    }
}

.pagecontent .maincontentcontainer .maincontent.searchresults {
    .fullhead {
        display: block;
    }

    .leftcoll {
        padding: 0;
    }

    .rightcontent {
        padding: 0px 0px 50px 0;

        .paging {
            float: none;
            text-align: center;
            margin-bottom: 50px;
            //	    width: 96.8%; Contact BSD if old value is needed!
            width: 100%;
            padding: 2px 0 0 0;
        }
    }

    .navigationbar {
        display: block;

        .onpagetop {
            .breadcrumb {
                display: block;
                margin-top: 0px;
            }
        }
    }
}



@media screen and (max-width: 1280px) {
    .pagecontent .maincontentcontainer {
        .maincontent.searchresults {
            .rightcontent {
                .paging {
                    //					width: 94%; Contact BSD if old value is needed!
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1032px) {
    .pagecontent .maincontent.searchresults {
        margin: auto;

        .fullhead.productsearch {
            h1 {
                // font-size: 30px;
                // line-height: 36px;
                padding: 0;
            }

            .paging {
                display: block;
                padding-top: 0;
                padding-bottom: 23px;
                text-align: center;

                .results {
                    // font-size: 11px;
                    float: none;
                    padding: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 770px) {
    .pagecontent .maincontentcontainer {
        .maincontent.searchresults {
            .topbanner .tbbanner {

                //            	margin-bottom: 92px;
                //            	margin-top: -93px;   //Contact BSD if this style is to be used!
                .topbanner-carousel-container.topbanner {
                    margin-bottom: 0;
                }
            }

            .navigationbar .breadcrumb {
                margin-top: -80px;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .pagecontent .maincontentcontainer {
        .maincontent {
            padding: 0 2.75%;
            .leftcoll {
                display: none;
            }

            .rightcontent {
                float: none;
                width: 100%;
                padding-top: 0;
            }

            .leftcontent {
                float: none;
                width: 100%;
            }

            .rightcoll {
                display: none;
            }
        }

        .maincontent.searchresults {
            padding: 0 2.75%;
            .navigationbar .onpagetop .breadcrumb {
                display: none;
            }

            .fullhead.productsearch {
                // font-size: 40px;
                // line-height: 45px;
                margin-top: 20px;

                .paging {
                    padding: 0;

                    .results {
                        // font-size: 20px;
                        color: #6c6c6c;
                    }
                }
            }

            .rightcontent {
                width: 100%;
                padding: 0 6.25%;

                .paging {
                    //					width: 98.8%;
                    width: 100%;
                    margin-bottom: 40px;
                }
            }

            .categoryinfo-container {
                margin: auto;
            }
        }
    }

    .campaignbanner {
        margin: 0 -20px;
    }

    .disclaimer {
        padding: 0 0 35px 0;

        .frontpage & {
            margin-top: -25px;
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 769px) {
    .pagecontent .maincontentcontainer {

        // Front page only compensation!
        .maincontent {
            &.maincontent--frontpage {
                padding: 0 30px;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .pagecontent .maincontentcontainer {

        .maincontent,
        .maincontent.searchresults {
            padding: 0 6.25%;

        }

        .maincontent.searchresults {
            .categoryinfo-container {
                width: 114.2857%;
                margin: 0 -7.1428%;
                box-sizing: border-box;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .campaignbanner {
        margin: 0 -10px;
    }

    .pagecontent .maincontent.searchresults {
        margin: auto;

        .fullhead.productsearch {
            h1 {
                // font-size: 24px;
                // line-height: 36px;
                padding: 0;
            }
        }
    }

    .pagecontent .maincontentcontainer {
        .maincontent {
            padding: 0 6.25%;

            &.product-page {
                .producttop {
                    margin-top: 20px;
                    margin-bottom: 40px;
                }

                .navigationbar {
                    display: none;

                    .breadcrumb {
                        display: none;
                    }
                }
            }
        }

        .maincontent.searchresults {
            padding: 0 6.25%;

            .rightcontent {
                padding: 0;
            }

            .fullhead.productsearch {
                .paging {
                    padding: 0;

                    .results {
                        // font-size: 11px;
                        // color: #8e8e8e;
                    }
                }
            }
        }
    }

    .pagecontent {
        .maincontent {
            .rightcontent.rightcontent--brandsearch {
                .stores-container {
                    .nohitsstore {
                        margin: 0 -7.143% 20px -7.143%;
                        padding: 0;

                        .storeimage_small,
                        .storeinfo {
                            padding: 0 6.25%;
                            box-sizing: border-box;
                        }

                        .storeinfo {
                            border-width: 0;

                            &:after {
                                content: "";
                                border-bottom: 1px solid #e9e9e9;
                                display: block;
                                margin-top: 16px;
                            }
                        }

                        .openingcarousel-container {
                            padding: 0 6.25%;
                            box-sizing: border-box;

                            .owl-prev {
                                left: 6%;
                            }

                            .owl-next {
                                right: 6%;
                            }
                        }
                    }
                }
            }
        }
    }
}