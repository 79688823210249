.pagecontent {
    .maincontentcontainer {
        .maincontent {
            .nrofrescontainer {
                padding: 10px 0px 0px 4px;
                border-bottom: 1px solid #d0cac4;
                max-width: 928px;

                h1 {
                    float: left;
                    padding-bottom: 10px;
                    text-transform: none;
                }
            }

            .leftcontent {
                &.nohits {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}

.leftcontent {
    .nosearchres {
        text-align: center;
        padding: 70px 0px 70px 0px;

        h3 {
            padding: 0px 0px 20px 0px;
        }
    }

    .nohitsstore {
        border-bottom: 1px solid #a19589;
        margin-bottom: 20px;

        &:after {
            clear: both;
            content: "";
            display: block;
        }

        &:last-child {
            border: 0;
            margin: 0;
        }

        .storeinfo {
            float: left;
            width: 31%;

            .brandcategories {
                background-color: #F7F5F4;
                padding: 10px 10px 10px 14px;
                margin-bottom: 5px;
                margin-top: 5px;
            }

            .storeaddress {
                border-top: 1px solid #d0cac4;
                border-bottom: 1px solid #d0cac4;
                padding: 10px 0;
            }

            .storecontact {
                padding: 10px 0;
            }
        }

        .openingcarousel {
            float: left;
            width: 353px;

            .jcarousel-container {
                padding-top: 0;
                width: 238px;
                margin-left: auto;
                margin-right: auto;

                .jcarousel-next-horizontal,
                .jcarousel-prev-horizontal {
                    top: 55px;
                }
            }

            .ohday {
                padding-left: 28px;
            }
        }

        .storeimage_small {
            float: left;
            width: 31%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.openinghours {
    height: 195px;
    width: 275px;

    .ohhead {
        @include typeface(serif);
        // font-family: Georgia, serif;
        // text-transform: uppercase;
        // font-style: italic;
        
        background-color: transparent;
        font-size: 17px;
        color: #292929;
        text-align: center;
        padding: 0px 0px 15px 0px;
    }

    .ohday {
        float: left;
        width: 90px;
        padding: 0px 0px 0px 60px;
    }
}

.searchcontainer {
    float: right;
    max-width: 1100px;
    width: calc(100% - 29px);
    padding-top: 30px;
    border-top: 1px solid #e7e7e7;

    // NOTE: We want less space than the default one that's on the frontpage
    .squarespots-container,
    .brandspot-container,
    .logobanner,
    .product-carousel-container.shopthelook-container,
    .product-carousel-container {
        margin-bottom: 0;
        padding-bottom: rem(46);
    }

    .searchresult {
        overflow: visible;
        margin-bottom: 23px;

        .productitemcontainer {
            display: inline-block;
            vertical-align: top;
            width: 22.6364%;
            position: relative;
            float: left;
            margin: 0 1.818% 10px 0;

            &:nth-of-type(4n) {
                margin-right: 0;
            }

            .productitem {
                position: relative;
                background-repeat: no-repeat;
                width: 100%;
                height: auto;
                min-height: 331px;
                box-sizing: border-box;
                text-align: center;
                margin: 0;
                padding: 0 0 9px 0;

                .productimg {
                    .image {

                        //                        max-height: 200px;
                        a:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            width: 0;
                            vertical-align: middle;
                        }
                    }

                    &:after {
                        content: "";
                        display: block;
                        padding-top: 100%;
                    }
                }

                .producttext .productoverlay {
                    z-index: 10;
                    top: -1px;
                    left: -1px;
                    bottom: 0;
                    min-height: 100%;
                    background-image: none;
                    background-color: transparent;
                    text-align: center;
                    background-color: #ffffff;
                    border: 1px solid #dddad7;
                    width: calc(100% + 2px);
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0 0 33px 0;

                    .maincontent {
                        padding: 0;
                        text-align: center;

                        //                          margin: 0 -1px;
                        .stock {
                            @include typeset(body-small);
                            // font-family: "Gill Sans Light", sans-serif;
                            // font-size: 16px;
                            // font-weight: 600;
                            text-transform: none;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    .paging {

        //        padding: 2px 0 0 0;
        //        width: 100%;
        .progresspaging-container {
            display: inline-block;
            text-align: center;
            font-size: 0px;
            line-height: 0px;

            .progress-wrapper {
                display: inline-block;
                width: auto;

                .progressinfo {
                    font-size: 16px;
                    line-height: normal;
                    margin-bottom: 5px;
                }

                .progressbar {
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    height: 2px;
                    background-color: #e8e8e8;
                    text-align: left;
                    margin-bottom: 20px;

                    .progressbar-progress {
                        position: absolute;
                        display: block;
                        width: 0%; //default value;
                        height: 2px;
                        background-color: #9fbdc3;
                        margin: 0 auto 0 0;
                    }
                }
            }

            .button-style {
                font-family: $font-sans-serif;
                display: block;
            }
        }
    }
}

.toplink {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    width: 50px;
    font-size: 0;
    line-height: 0;

    img {
        width: 100%;
        height: auto;
    }
}

.pagecontent {
    .maincontent.searchresults {
        //         padding: 0;
        /*        .categoryinfo-container {
            .categoryinfo {
                h1, h2 {
                    font-family: $font-serif;
                    font-size: 36px;
                    line-height: 44px;
                    display: block;
                    background-color: transparent;
                    color: #292929;
                    text-transform: none;
                    padding: 0;
                    float: right;
                    clear: right;
                    width: 48.718%;
                    margin: 0 0 27px 0;
                    text-align: left;
                    font-style: normal;
                }
                > p {
                    clear: right;
                    float: right;
                    font-size: 16px;
                    line-height: 25px;
                    width: 48.718%;
                    text-align: justify;
                    color: #292929;
                }
            }
        } */
    }
}

@media screen and (max-width: 1280px) {
    .searchcontainer .searchresult .productitemcontainer {
        width: 31.143%;
        margin-right: 0;
    }
}

@media screen and (max-width: 992px) {
    .leftcontent {
        .nohitsstore {
            .openingcarousel {
                width: 38%;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .leftcontent {
        .nohitsstore {
            .openingcarousel {
                float: none;
                width: 100%;
                margin-bottom: 10px;
            }

            .storeinfo {
                float: none;
                width: 100%;
                margin-bottom: 10px;
            }

            .storeimage_small {
                float: none;
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .searchcontainer {
        float: none;
        margin: auto;
        width: 100%;
        border-width: 0;

        // NOTE: We want less space than the default one that's on the frontpage
        .squarespots-container,
        .brandspot-container,
        .logobanner,
        .product-carousel-container.shopthelook-container,
        .product-carousel-container {
            margin-bottom: 0;
            padding-bottom: rem(24);
        }

        .searchresult {
            margin-bottom: 0; // Contact BSD if this value is changed!

            .productitemcontainer {
                //                width: 50%;
                width: 32.3333%;

                .producttext>.wishlist-wishbutton-container {
                    display: inline-block !important;
                    position: absolute;
                    right: 8px;
                }

                .productitem .producttext .productoverlay {
                    display: none !important;
                }
            }

            &:after {
                visibility: hidden;
                display: block;
                font-size: 0;
                content: " ";
                clear: both;
                height: 0;
            }
        }

        .paging {
            .progresspaging-container {
                .progress-wrapper {
                    .progressinfo {
                        font-size: 13px;
                        margin-bottom: 6px;
                    }

                    .progressbar {
                        margin-bottom: 15px;
                    }
                }

                .button-style {
                    display: block;
                    // height: 42px;
                    // line-height: 42px;
                    font-size: 14px;
                    // width: 161px;
                }
            }
        }
    }

    .toplink {
        width: 40px;
        right: 15px;
        bottom: 70px;
    }
}

@media screen and (max-width: 640px) {
    .searchcontainer {
        .searchresult {
            .productitemcontainer {
                width: 49%;
            }
        }
    }

}

.searchcontainer {
    .product-carousel-container {
        max-width: 1100px;
        padding: 85px 10% 50px 10%;

        .productitem {
            .productimg .image a {
                min-height: auto;
            }

            .producttext,
            .productprice {
                display: block !important;
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .searchcontainer {
        .product-carousel-container {
            .product-carousel-innercontainer {
                .product-carousel-header-container {
                    width: 100%;
                    float: none;
                    position: static;

                    &:before {
                        display: none;
                    }

                    h2 {
                        margin: auto;
                        text-align: center;
                    }

                    .tilelink-style {
                        display: none;
                    }
                }

                .product-carousel-window {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .searchcontainer {
        .product-carousel-container {
            .product-carousel-innercontainer {
                .productitem {
                    padding-top: 0;
                }

                .owl-dots {
                    text-align: center;
                    margin-top: 10px;

                    .owl-dot {
                        display: inline-block;
                        font-size: 0px;
                        line-height: 0;
                        margin: 5px;
                        vertical-align: middle;

                        span {
                            background-color: #E8E8E8;
                            height: 9px;
                            width: 9px;
                            display: inline-block;
                            border-radius: 17px;
                            display: inline-block;
                            box-sizing: border-box;
                        }

                        &.active span {
                            background-color: #292929;
                        }
                    }
                }
            }
        }

        .searchresult {
            .productitemcontainer {
                .producttext>.wishlist-wishbutton-container {
                    display: inline-block !important;
                    position: absolute;
                    right: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .searchcontainer {
        .product-carousel-container {
            padding: 50px 5% 30px 5%;
        }
    }

}