.termsandconditions {
	position: absolute;
	top: 75px;
	left: calc((100% - 900px)/2);
	left: -webkit-calc((100% - 900px)/2);
	left: -mox-calc((100% - 900px)/2);
	background-color: #ffffff;
	max-width: 900px;
	height: auto;
	-moz-box-shadow: 0px 0px 25px -5px #000000;
	-webkit-box-shadow: 0px 0px 25px -5px #000000;
	box-shadow: 0px 0px 25px -5px #000000;
	text-align: left;
	overflow: hidden;

	.underlined {
		cursor: auto;
	}

	.termsandconditions {
		&-header {
			background-color: #f5f5f5;
			text-align: center;
			min-height: 100px;
			padding-top: 25px;
			padding-bottom: 25px;
			padding-left: 25px;
			padding-right: 25px;
			margin-left: -25px;
			margin-right: -25px;

			h2 {
				@include typeset(display-3);
				// font-size: 30px;
				
				margin-bottom: 10px;
			}

			h3 {
				@include typeset(body-normal);
				// font-family: $font-sans-serif;
				// font-size: 14px;
				// line-height: 16px;
				// color: black;
				// text-transform: none;
				// font-style: normal;
			}
		}

		&-content {
			@include typeset(body-normal);
			// font-family: "Gill Sans light", sans-serif;
			// font-size: 16px;
			
			max-height: calc(100vh - 475px);
			max-height: -webkit-calc(100vh - 475px);
			max-height: -moz-calc(100vh - 475px);
			overflow-y: scroll;
			padding-left: 25px;
			padding-right: 25px;

			.permission {
				height: 100%;
				padding-top: 35px;

				.permission-box {
					border: 1px solid #f5f5f5;
					padding: 20px;
					display: table-cell;
					cursor: pointer;

					.checkbox {
						width: 30px;
						float: left;

						&.type1 {
							.checker {
								span {
									width: 19px;
									height: 19px;
									background-size: 19px;
									margin-right: 6px;

									input {
										width: 19px;
										height: 19px;
									}
								}
							}
						}

						.required-indicator {
							display: none;
						}

						div.error {
							display: none !important;
						}
					}

					.text {
						@include typeset(body-small);
						// font-size: 12px;
						
						width: calc(100% - 30px);
						width: -webkit-calc(100% - 30px);
						width: -moz-calc(100% - 30px);
						float: left;
					}
				}

				&-error {
					display: none;
					padding-top: 5px;
					color: red;
					font-size: 12px;
				}
			}

			.productassortment {
				margin-top: 25px;

				h2 {
					@include typeset(display-3);
					// font-size: 30px;
					// text-align: left;
					
					margin-bottom: 10px;
				}

				.productassortment-content {
					border-top: 1px solid #f5f5f5;
					border-bottom: 1px solid #f5f5f5;
					margin-top: 10px;
					margin-bottom: 10px;

					.top {
						// font-family: "Gill Sans light", sans-serif;
						margin-top: 15px;
						margin-bottom: 15px;
						line-height: 30px;
						cursor: pointer;

						p {
							@include typeset(body-normal);
							// font-size: 16px;
							float: left;
						}

						.accordionheader-icon {
							position: relative;
							float: right;
							width: 13px;
							height: 13px;
							padding-top: 14px;

							.outerdash {
								position: absolute;
								height: 1px;
								width: 13px;
								background-color: #292929;
								transform-origin: center center;

								.dash {
									position: absolute;
									height: 1px;
									width: 13px;
									background-color: #292929;
								}
							}
						}

						&.ui-accordion-header-collapsed {
							.accordionheader-icon .outerdash .dash {
								transform: rotate(90deg);
							}
						}

						&.ui-state-active {
							font-weight: 600;
						}

						&:after {
							content: "";
							display: table;
							clear: both;
						}

						&:focus {
							outline: none;
						}
					}

					.text {
						display: none;

						.open {
							display: block;
						}
					}
				}
			}

			.personaldata {
				padding-top: 15px;

				h2 {
					@include typeset(display-3);
					// font-size: 30px;
					// text-align: left;
					
					margin-bottom: 10px;
				}

				.ui-accordion {
					margin-top: 10px;
					margin-bottom: 10px;
				}

				h3 {
					@include typeset(body-normal);
					// font-family: "Gill Sans light", sans-serif;
					// font-size: 16px;
					// text-transform: none;
					// font-style: normal;
					
					line-height: 30px;
					padding-top: 15px;
					padding-bottom: 15px;
					cursor: pointer;
					border-top: 1px solid #f5f5f5;

					.accordionheader-icon {
						position: relative;
						float: right;
						width: 13px;
						height: 13px;
						padding-top: 14px;

						.outerdash {
							position: absolute;
							height: 1px;
							width: 13px;
							background-color: #292929;
							transform-origin: center center;

							.dash {
								position: absolute;
								height: 1px;
								width: 13px;
								background-color: #292929;
							}
						}
					}

					&.ui-accordion-header-collapsed {
						.accordionheader-icon .outerdash .dash {
							transform: rotate(90deg);
						}
					}

					&.ui-state-active {
						font-weight: 600;
					}

					&:after {
						content: "";
						display: table;
						clear: both;
					}

					&:focus {
						outline: none;
					}
				}

				.ui-accordion-content {
					margin-bottom: 10px;
				}

				.no-accordion {
					display: none;
				}
			}

			ul {
				margin-bottom: 10px;

				li {
					list-style: disc;
					margin-left: 30px;
					padding-left: 0;
				}
			}
		}

		&-actions {
			background-color: #f5f5f5;

			.actions-wrapper {
				height: 40px;
				padding: 25px;

				.button-logout,
				.button-accept {
					float: left;
					width: calc(50% - 5px);

					button {
						// font-family: $font-sans-serif;
						width: 100%;
						height: 40px;
						background-color: #d8e2e3;
						border: 1px solid #d8e2e3;
						color: white;
					}

					&.active {
						button {
							background-color: #9fbdc3;
							border: 1px solid #9fbdc3;
						}
					}
				}

				.button-logout {
					margin-right: 10px;

					button {
						// font-family: $font-sans-serif;
						background-color: #fff;
						border: 1px solid black;
						color: black;
					}
				}
			}
		}
	}
}

.personaldata-content {
	h3 {
		padding-top: 15px;
	}
}

@media screen and (max-width: 900px) {
	.termsandconditions {
		left: 10%;
		max-width: 80%;
	}
}

@media screen and (max-width: 520px) {
	.termsandconditions {
		left: 0;
		max-width: 100%;
		top: 0;
		right: 0;
		position: fixed;
		bottom: 0;

		.termsandconditions {
			&-crollarea {
				overflow-y: scroll;
				height: calc(100vh - 130px);
				height: -webkit-calc(100vh - 130px);
				height: -moz-calc(100vh - 130px);
			}

			&-header {
				h2 {
					// font-size: 20px;
				}

				h3 {
					line-height: 20px;
				}
			}

			&-content {
				max-height: calc(100vh - 310px);
				max-height: -webkit-calc(100vh - 310px);
				max-height: -moz-calc(100vh - 310px);
				overflow-y: visible;
				padding-left: 20px;
				padding-right: 20px;

				.permission {
					padding-top: 20px;
				}

				.productassortment {
					h2 {
						// font-size: 20px;
					}
				}

				.personaldata {
					h2 {
						font-size: 20px;
					}
				}
			}

			&-actions {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;

				.actions-wrapper {
					height: auto;
					padding: 20px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: reverse;
					-ms-flex-direction: column-reverse;
					flex-direction: column-reverse;

					.button-logout,
					.button-accept {
						float: none;
						width: 100%;
						margin-bottom: 10px;
						pointer-events: auto;
					}

					.button-logout {
						margin-right: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}