.menupageblur {
    display: none;
}

.mainmenucontainer .mainmenu {
    opacity: 0;
}

.desktopmenu {
    &.menuopen {
        .mainmenucontainer {
            .mainmenu {
                .menuitem {
                    .submenu {
                        padding-right: 17px;
                    }

                    &:hover {
                        .submenu {
                            display: block;
                        }
                    }
                }
            }
        }

        .menupageblur {
            display: block;
            position: absolute;
            width: 100%;
            height: calc(100vh - 300px);
            background-color: rgba(0, 0, 0, 0.6);
            top: 300px;
            z-index: 4;
        }
    }

    .mainmenucontainer {
        // font-family: "Gill Sans Light", sans-serif;
        // font-size: 13px;
        // line-height: 16px;
        // font-weight: 600;
        // text-align: left;

        display: block;
        border-bottom: 1px solid #E7E7E7;
        padding: 7px 0 0 10px;
        background-color: #ffffff;
        
        .mainmenu {
            transition: opacity .2s;
            opacity: 1;
            display: inline-block;
            margin-bottom: -5px;

            .menuitem {
                float: left;
                vertical-align: top;
                padding: 0 10px;

                .menuitemlink {
                    // sorthvid
                    @include typeset(trumpet);
                    // text-transform: uppercase;
                    
                    color: #292929; // overwriting default a:hover
                    line-height: 30px;
                    display: inline-block;
                    text-decoration: none;
                    border-bottom: 2px solid #ffffff;
                    padding: 0 10px 18px 10px;

                    &:hover {
                        border-bottom: 2px solid #292929;
                    }

                    &.active {
                        border-bottom: 2px solid #292929;
                    }

                    img {
                        margin-bottom: -8px;
                    }
                }

                .submenu {
                    display: none;
                    position: absolute;
                    background-color: #ffffff;
                    z-index: 4;
                    width: 100%;
                    left: 0;
                    // text-transform: none;
                    box-sizing: border-box;
                    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.4);

                    &.type1 {
                        .area1 {
                            width: 62%;

                            .column {
                                width: 25%;
                                margin-right: -4px;
                            }
                        }

                        .area2 {
                            width: 20%;

                            .menu-campaign {
                                padding: 0 12%;
                            }
                        }

                        .area3 {
                            width: 18%;
                        }
                    }

                    &.type2 {
                        .area1 {
                            width: 57%;

                            .column {
                                width: 25%;
                                margin-right: -4px;

                                &:last-child {
                                    width: 50%;
                                }
                            }
                        }

                        .area2 {
                            width: 25%;
                        }

                        .area3 {
                            width: 18%;
                        }
                    }

                    &.type3 {
                        .area1 {
                            width: 50%;

                            .column {
                                width: 30%;
                                margin-right: -4px;

                                &:last-child {
                                    width: 40%;
                                }
                            }
                        }

                        .area2 {
                            width: 20%;
                        }

                        .area3 {
                            width: 30%;
                        }
                    }

                    &.type4 {
                        .area1 {
                            width: 60%;

                            .column {
                                width: 33.333%;
                                margin-right: -4px;
                            }
                        }

                        .area2 {
                            width: 20%;
                        }

                        .area3 {
                            width: 20%;
                        }
                    }

                    &.type5 {
                        .area1 {
                            width: 66.667%;

                            .column {
                                width: 50%;
                                margin-right: -4px;
                                box-sizing: border-box;
                            }
                        }

                        .area2 {
                            width: 33.333%;

                            .mainmenucontainer .mainmenu .menuitem .submenu.type5 .area2 .column {
                                width: 100%;
                                margin-right: -4px;
                                box-sizing: border-box;
                            }
                        }
                    }

                    .submenuinner {
                        max-width: 1440px;
                        padding: 20px 20px 0 29px;
                        margin: auto;
                        box-sizing: border-box;
                        border-top: 1px solid #e9e9e9;
                        height: 452px;
                        overflow: hidden;

                        .area1 {
                            display: inline-block;
                            vertical-align: top;
                            margin-right: -4px;
                            box-sizing: border-box;
                        }

                        .area2 {
                            display: inline-block;
                            vertical-align: top;
                            margin-right: -4px;
                        }

                        .area3 {
                            display: inline-block;
                            vertical-align: top;
                            margin-right: -4px;
                        }

                        .column {
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;

                            ul {
                                padding: 0 0 18px 0;
                                margin: 0;

                                li {
                                    margin: 0;

                                    // font-family: $font-sans-serif;
                                    // font-size: 13px;
                                    // line-height: 19px;
                                    // font-weight: 300;
                                    a {
                                        // color: #292929;
                                        padding: 0;
                                        text-decoration: none;
                                    }

                                    &.category {
                                        @include typeset(display-5);

                                        // font-size: 14px;
                                        // font-weight: 600;
                                        // text-transform: uppercase;
                                        a {
                                            // font-size: 14px;
                                            // font-weight: 600;
                                            // text-transform: uppercase;
                                        }
                                    }
                                }
                            }
                        }

                        .menu-campaign {
                            li {
                                padding: 0 15px 0 0;

                                &:not(:last-of-type) a {
                                    margin-bottom: 40px;
                                }

                                a {
                                    // sorthvid
                                    font-weight: 600;
                                    // font-size: 14px;
                                    // font-family: $font-sans-serif;
                                    // color: #292929;
                                    // padding: 0;
                                    max-width: 220px;
                                    text-align: center;
                                    display: block;
                                    text-decoration: none;

                                    img {
                                        margin-bottom: 10px;
                                        width: 100%;
                                        padding: 0px 6px;
                                        box-sizing: border-box;
                                        vertical-align: top;
                                        font-size: 14px;
                                        max-width: 220px;
                                    }
                                }
                            }
                        }

                        .brands {
                            // font-family: $font-sans-serif;
                            // font-size: 12px;
                            
                            background-color: #f4f4f4;
                            margin: -20px 0 0 0;
                            padding: 10px;
                            height: 430px;
                            line-height: 30px;
                            position: relative;

                            &.brands-1-column {
                                ul {
                                    width: 100%;
                                }
                            }

                            >div {
                                text-align: left;
                                bottom: 0;
                                position: absolute;
                                width: 100%;
                                padding-bottom: 20px;
                                left: 0;
                                padding-left: 30px;

                                a {
                                    color: #292929;
                                }
                            }

                            ul {
                                display: inline-block;
                                vertical-align: top;
                                width: 50%;
                                margin-right: -4px;
                                padding: 10px;
                                box-sizing: border-box;

                                li {
                                    &.category {
                                        @include typeset(display-5);
                                        // font-weight: 600;
                                        // text-transform: uppercase;

                                        a {
                                            // font-weight: 600;
                                        }
                                    }

                                    a {
                                        // color: #292929;
                                        // font-weight: 300;
                                    }
                                }
                            }
                        }

                        .ajourtopbanner {
                            height: 40px;
                        }

                        .cu-grid-item.cu-grid-item-dynamic {
                            margin-bottom: 30px;
                            padding-right: 10%;

                            &:after {
                                content: "";
                                display: table;
                                clear: both;
                            }

                            .cu-teaser-image-container {
                                display: inline-block;
                                float: left;
                                width: 90px;
                                overflow: hidden;
                                height: auto;

                                img {
                                    width: auto;
                                    height: 135px;
                                    margin-left: 50%;
                                    transform: translateX(-50%);
                                }

                                .cu-teaser-image-window {
                                    &.mblog-focus-teaser {
                                        display: none;
                                    }

                                    &.mblog-focus-related-desktop {
                                        display: none;
                                    }

                                    &.mblog-focus-related-mobile {
                                        display: none;
                                    }
                                }
                            }

                            .cu-grid-item-content {

                                //PLA 2018-02-19:	Jeg har kopieret stylingen fra "&.cu-grid-item-content--related-desktop"
                                //					ind her, da der ikke længere vil komme --related-desktop med i classes
                                //					fra Sitecore.

                                display: inline-block;
                                width: 180px;
                                overflow: hidden;
                                padding-left: 15px;
                                float: left;
                                box-sizing: border-box;
                                height: 140px;

                                .cu-tag {
                                    // font-family: $font-sans-serif;
                                    // color: #292929;
                                    font-size: 14px;
                                    line-height: 16px;
                                    text-transform: uppercase;
                                    
                                    margin-bottom: 10px;
                                    border-bottom: 2px solid;
                                    display: inline-block;
                                    padding: 0;
                                }

                                .card-headline {
                                    font-family: $font-serif;
                                    font-size: 12px;
                                    line-height: 20px;
                                    background-color: transparent;
                                    color: #292929;
                                    font-weight: bold;
                                    display: block;
                                    margin-bottom: 10px;
                                    overflow: hidden;
                                    position: relative;
                                    text-transform: none;
                                    text-align: left;
                                }

                                .card-text {
                                    font-size: 12px;
                                    color: #292929;
                                    line-height: 15px;
                                    overflow: hidden;
                                    position: relative;
                                }

                                &.cu-grid-item-content--desktop {
                                    display: none;
                                }

                                &.cu-grid-item-content--mobile {
                                    display: none;
                                }

                                &.cu-grid-item-content--related-desktop {
                                    display: inline-block;
                                    width: 180px;
                                    overflow: hidden;
                                    padding-left: 15px;
                                    float: left;
                                    box-sizing: border-box;
                                    height: 140px;

                                    .cu-tag {
                                        font-size: 14px;
                                        color: #292929;
                                        line-height: 16px;
                                        margin-bottom: 10px;
                                        text-transform: uppercase;
                                        font-family: $font-sans-serif;
                                        border-bottom: 2px solid;
                                        display: inline-block;
                                        padding: 0;
                                    }

                                    .card-headline {
                                        font-size: 12px;
                                        line-height: 20px;
                                        background-color: transparent;
                                        color: #292929;
                                        font-weight: bold;
                                        display: block;
                                        margin-bottom: 10px;
                                        overflow: hidden;
                                        position: relative;
                                        text-transform: none;
                                        font-family: $font-serif;
                                        text-align: left;
                                    }

                                    .card-text {
                                        font-size: 12px;
                                        color: #292929;
                                        line-height: 15px;
                                        overflow: hidden;
                                        position: relative;
                                    }
                                }

                                &.cu-grid-item-content--related-mobile {
                                    display: none;
                                }
                            }
                        }

                        div.category {
                            @include typeset(display-5);
                            // font-family: $font-sans-serif;
                            // font-weight: 600;
                            // font-size: 14px;
                            margin-bottom: 10px;

                            a {
                                // color: #292929;
                                // text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .mainmenucontainer {
            .mainmenu {
                .menuitem {
                    .submenu {
                        &.type1 {
                            .area1 {
                                width: 80%;
                            }

                            .area2 {
                                width: 20%;

                                .menu-campaign {
                                    padding: 0 6%;
                                }
                            }

                            .area3 {
                                display: none;
                            }
                        }

                        &.type2 {
                            .area1 {
                                width: 65%;
                            }

                            .area2 {
                                width: 35%;
                            }

                            .area3 {
                                display: none;
                            }
                        }

                        &.type3 {
                            .area1 {
                                width: 70%;
                            }

                            .area2 {
                                width: 30%;
                            }

                            .area3 {
                                display: none;
                            }
                        }

                        &.type4 {
                            .area1 {
                                width: 75%;
                            }

                            .area2 {
                                width: 25%;
                            }

                            .area3 {
                                display: none;
                            }
                        }

                        &.type5 {
                            .area1 {
                                width: 66.667%;
                            }

                            .area2 {
                                width: 33.333%;
                            }

                            .area3 {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }


    @media screen and (max-width: 1100px) {
        .mainmenucontainer {
            .mainmenu {
                margin-left: 7px;
            }
        }
    }

    @media screen and (max-width: 1032px) {
        .mainmenucontainer {
            .mainmenu {
                .html-slot-container {
                    display: flex;
                }

                .menuitem {
                    .submenu {
                        &.type1 {
                            .area1 {
                                width: 100%;
                            }

                            .area2 {
                                display: none;
                            }
                        }

                        &.type2 {
                            .area1 {
                                width: 100%;
                            }

                            .area2 {
                                display: none;
                            }
                        }

                        &.type3 {
                            .area1 {
                                width: 100%;
                            }

                            .area2 {
                                display: none;
                            }
                        }

                        &.type4 {
                            .area1 {
                                width: 100%;
                            }

                            .area2 {
                                display: none;
                            }
                        }

                        &.type5 {
                            .area1 {
                                width: 100%;
                            }

                            .area2 {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 920px) {
        .mainmenucontainer {
            .mainmenu {
                margin-left: 10px;
            }
        }
    }

    @media screen and (max-width: 857px) {
        .mainmenucontainer {
            .mainmenu {
                margin-left: 13px;
            }
        }
    }

}

.burgericon{
    display: none;
}

@media screen and (max-width: 1024px){
    .mainmenucontainer{
        .burgericon{
            display: block;
            position: absolute;
            background-image: url(../images/mobilemenuicon.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 22px auto;
            border: none;
            background-color: transparent;
            width: 43px;
            height: 43px;
            padding: 0;
            margin: 0;
            cursor: pointer;
            top: 24px;
            left: 10px;
        }
    }
}


.mobilemenu{
    .mainmenucontainer{
        @media screen and (min-width: 769px) and (max-width: 1024px){
            left: 9px;
        }
        display: block;
        // position: absolute;
        width: 100%;
        top: 68px;
        left: 0px;
        background-color: transparent;
        z-index: 100;

        .mainmenu {
            transition: opacity .2s;
            opacity: 1;
            display: none;
            background-color: #ffffff;

            &.showmenu {
                display: block;
            }

            .menuitem {
                float: none;
                vertical-align: top;
                padding: 0 10px;

                .menuitemlink {
                    @include typeset(body-large);
                    // font-size: 24px;
                    // text-transform: none;
                    display: block;
                    padding: 20px;
                    position: relative;
                    border-top: 1px solid #dcdcdc;
                }

                .submenu {
                    display: none;
                    position: relative;

                    .submenuinner {
                        height: auto;

                        .column {
                            width: 100% !important;
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
}