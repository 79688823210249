.google-map {
    padding-bottom: 140%;
    position: relative;
    @include mq($from: screen-md) {
        padding-bottom: 50%;
    }
   
    .mapcontainer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
