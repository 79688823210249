$pageMarginWidth-desktop: 30px;
$pageMarginWidth-tablet: perc(20, 768);
$pageMarginWidth-mobile: perc(20, 320);

//TODO: temp-start

.livecarouselcontainer {
    display: none;
}

.pagecontent .maincontentcontainer .maincontent.maincontent--frontpage {
    padding: 0 $pageMarginWidth-desktop;
    @media screen and (max-width: 1024px) {
        padding: 0 $pageMarginWidth-tablet;
    }
    @media screen and (max-width: 640px) {
        padding: 0 $pageMarginWidth-mobile;
    }
    
}
//TODO: temp-end

.product-carousel-container.shopthelook-container {
    padding: 0 0 100px 0;
    font-size: 0;
    line-height: 0;

    .shopthelook-innercontainer {
        width: 100%;
        max-width: 1170px;
        margin: auto;
        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0;
        }
        .shopthelook-header {
            float: right;
            width: perc(690, 1170);
            padding: 35px 0 25px 0;
            .categoryheader { // h2
                padding: 0;
                text-align: left;
                margin: -5px auto -12px auto;
            }
            .tilelink-style {
                margin-top: 22px;
            }
            .moduleheader {
                margin: 0;
                padding: 0;
                text-align: left;
            }
        }
        .shopthelook-imgcontainer {
            display: inline-block;
            width: perc(370, 1170);
            float: left;
            img {
                width: 100%;
                height: auto;
            }
        }
        .product-carousel-window {
            display: inline-block;
            width: perc(690, 1170);
            float: right;
            .product-owl-carousel { // owl-carousel
                &.grey-overlay .productitem {
                    .productimg {
//                        .image:after {
//                            content: "";
//                            position: absolute;
//                            width: 100%;
//                            height: 100%;
//                            top: 0;
//                            left: 0;
//                            z-index: 10;
//                            background-color: #d8d8d8;
//                            opacity: .2;
//                        }
                        width: 100%;
                        margin: 0;
                        display: inline-block;
                        height: auto;
                        position: relative;
                        cursor: pointer;
                        &:after {
                            content: "";
                            display: block;
                            padding-top: perc(300, 210);
                        }
                        .image {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            font-size: 0;
                            max-height: none;
                            &:after {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                z-index: 10;
                                background-color: #d8d8d8;
                                opacity: .2;
                            }
                            a {
                                min-height: auto;
                                img {
                                    vertical-align: middle;
                                    display: inline-block;
                                    max-width: 80%;
                                    max-height: 80%;
                                    height: auto;
                                    width: auto;
                                }
                            }
                        }
                    }
                    .productbadge {
                        margin-top: 14px;
                    }
                }                
                .owl-stage-outer {
                    
                }
                .owl-nav {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 300px;
                    z-index: -1;
                    .owl-prev {
                        position: absolute;
                        top: calc(150px - 12px);
                        right: calc(100% + 42px)
                    }
                    .owl-next {
                        position: absolute;
                        top: calc(150px - 12px);
                        left: calc(100% + 42px)
                    }

                }
                .owl-dots {
                    display: none;
                }
            }

        }
    }
}

$pageContentWidth-tablet: 768 - 2*768*strip-unit($pageMarginWidth-tablet)/100;
@media screen and (max-width: 1024px) {
    .product-carousel-container.shopthelook-container {
        padding: 60px 0 60px 0;
        .shopthelook-innercontainer {
            &:after {
            }
            .shopthelook-header {
                width: perc(394, $pageContentWidth-tablet);
                padding: 35px 0 25px 0;
                .categoryheader {
                    margin: -4px auto -9px;
                }
                .tilelink-style {
                    margin-top: 7px;
                }
            }
            .shopthelook-imgcontainer {
                width: perc(285, $pageContentWidth-tablet);
                img {
                }
            }
            .product-carousel-window {  //width 414 - 20 = 394;
                width: perc(394 + 768*strip-unit($pageMarginWidth-tablet)/100, $pageContentWidth-tablet);
//                margin-right: 768*strip-unit($pageMarginWidth-tablet)/100 * -1px;
                margin-right: perc(-768*strip-unit($pageMarginWidth-tablet)/100, $pageContentWidth-tablet) ;
                overflow: hidden;
                .product-owl-carousel {
                    width: perc(320, 414);
                    text-align: right;
                    &.grey-overlay .productitem {
                        .productimg {
                            margin-bottom: 14px;
                            &:after {
                            }
                            .image {
                                a {
                                    img {
                                    }
                                }
                            }
                        }
                        .productbadge {
                            margin: 0 auto 8px auto
                        }
                    }                
                    .owl-stage-outer {
                        overflow: visible;

                    }
                    .owl-nav {
                        display: none;
//                        .owl-prev {
//                        }
//                        .owl-next {
//                        }
                    }
                    .owl-dots {
                        display: inline-block;
                        position: static;
                        width: calc(50% - 10px);
                        text-align: center;
                        margin-top:  17px;
                        .owl-dot {
                            &.active {
                                background-color: #292929;
                            }
                            background-color: #e7e7e7;
                            display: inline-block;
                            width: 9px;
                            height: 9px;
                            border-radius: 50%;
                            margin: 0 4.5px;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 768px) {
    .product-carousel-container.shopthelook-container {
        overflow-x: hidden;
    }
}

@media screen and (max-width: 640px) {
    .product-carousel-container.shopthelook-container {
        padding: 0 0 40px 0;
        overflow-x: initial;

        .shopthelook-innercontainer {
            &:after {
            }
            .shopthelook-header {
                width: 100%;
                text-align: center;
                padding: 0 0 20px 0;
                .categoryheader {
                    text-align: center;
                    margin: -3px auto -8px;
                }
                .tilelink {
                    margin: auto;
                }
                .moduleheader {
                    text-align: center;
                }
            }
            .shopthelook-imgcontainer {
                width: 100%;
                padding-bottom: 25px;
            }
            .product-carousel-window {
                width: perc(265, 280);
                width: perc(300, 280);
                float: none;
                margin: auto;
                .product-owl-carousel {
                    width: perc(265, 300);
                    &.grey-overlay .productitem {
                        .productimg {

                            &:after {
                            }
                            .image {
                                a {
                                    img {
                                    }
                                }
                            }
                        }
                        .productbadge {
                        }
                    }                
                    .owl-stage-outer {

                    }
                    .owl-nav {
                        .owl-prev {
                        }
                        .owl-next {
                        }
                    }
                    .owl-dots {
                        width: perc(280,265);
                        text-align: center;
                    }
                }

            }
        }
    }    
}