// $font-sizes: (
//     large: (
//         font-size: 12px,
//         line-height: 24px (=> 2)
//     ),
//     small: (
//         font-size: 11px,
//         line-height: 1.5 (=> 1.5)
//         )
//     )
// );
// ex. with media queries. Remember to point to a breakpoint for a base-font size to calculate line-height and letter-spacing
// $font-sizes: (
//     large: (
//      base-font-size: screen-sm,
//         screen: (
//             font-size: 12px,
//             line-height: 24px
//         ),
//         screen-sm:(
//             font-size: 11px,
//             line-height: 15px
//         )
//     )
// );
//
// $font-types is where you define your fonts
// Ex.
// $font-types: (
//    display-1: (
//     font-size: xl,
//     font-weight: bold,
//     font-style: normal,
//     text-transform: uppercase,
//     letter-spacing: 6px,
// ));
//
// $typefaces
// $typefaces: (
//     sans-serif: (
//         font-family: $font-primary,
//         color: $color-black
//     ),
//     serif: $font-secondary
// );
//

$base-font-size: 15px;
$base-line-height: 24px;

$font-sans-serif: "Lato", "Gill Sans", sans-serif;
$font-serif: "EB Garamond", "Old Standard TT", serif;

$font-sans-serif-old: "Gill Sans", sans-serif;
$font-serif-old: "Old Standard TT", serif;

$typefaces: (
    sans-serif: $font-sans-serif,
    serif: $font-serif
);

$font-sizes: (
    // Specials are only used in the hero
    special-1: (
        base-font-size: screen,
        screen: (
            font-size: 40px,
            line-height: 1
        ),
        screen-xs: (
            font-size: 100px
        ),
        screen-md: (
            font-size: 140px
        )
    ),
    special-2: (
        base-font-size: screen,
        screen: (
            font-size: 30px,
            line-height: 1
        ),
        screen-xs: (
            font-size: 56px
        ),
        screen-md: (
            font-size: 80px
        )
    ),
    special-3: (
        base-font-size: screen,
        screen: (
            font-size: 20px,
            line-height: 1
        ),
        screen-xs: (
            font-size: 48px
        ),
        screen-md: (
            font-size: 72px
        )
    ),
    special-4: (
        base-font-size: screen,
        screen: (
            font-size: 19px,
            line-height: 1
        ),
        screen-xs: (
            font-size: 30px
        ),
        screen-md: (
            font-size: 46px
        )
    ),
    special-5: (
        base-font-size: screen,
        screen: (
            font-size: 16px,
            line-height: 18px
        ),
        screen-xs: (
            font-size: 24px,
            line-height: 28px
        ),
        screen-md: (
            font-size: 36px,
            line-height: 43px
        )
    ),
    special-6: (
        base-font-size: screen,
        screen: (
            font-size: 12px,
            line-height: 1
        ),
        screen-xs: (
            font-size: 16px
        ),
        screen-md: (
            font-size: 22px
        )
    ),

    // Edge cases, typically used for one specific component
    edge-1: (
        base-font-size: screen,
        screen: (
            font-size: 22px,
            line-height: 1
        ),
        screen-md: (
            font-size: 40px
        )
    ),

    // Clothing sizes are used for everything else
    xxl: (
        base-font-size: screen,
        screen: (
            font-size: 36px,
            line-height: 47px
        ),
        screen-lg: (
            font-size: 40px
        )
    ),
    xl: (
        base-font-size: screen,
        screen: (
            font-size: 30px,
            line-height: 36px
        ),
        screen-sm: (
            font-size: 32px
        ),
        screen-md: (
            font-size: 36px
        )
    ),
    l: (
        font-size: 27px,
        line-height: 1.6
    ),
    m: (
        font-size: 20px,
        line-height: 1.6
    ),
    s: (
        font-size: 17px,
        line-height: 1.6
    ),
    xs: (
        font-size: 15px,
        line-height: 1.6
    ),
    xxs: (
        font-size: 13px,
        line-height: 1.6
    ),
    xxxs: (
        font-size: 11px,
        line-height: 1.6
    ),
    trumpet: (
        font-size: 12px,
        line-height: 1.4
    ),
    banner: (
        base-font-size: screen,
        screen: (
            font-size: 20px,
            line-height: 32px
        ),
        screen-sm: (
            font-size: 15px
        ),
        screen-md: (
            font-size: 20px
        )
    )
);

// line-height bliver automatisk sat fra $font-sizes map
$font-types: (
    hero-1: (
        font-size: special-1,
        font-weight: 600
    ),
    hero-2: (
        font-size: special-2,
        font-weight: 600,
        text-transform: uppercase
    ),
    hero-3: (
        font-size: special-3,
        font-weight: 600
    ),
    hero-4: (
        font-size: special-4,
        font-weight: 600,
        letter-spacing: -.02em,
        text-transform: uppercase
    ),
    hero-5: (
        font-size: special-5,
        font-weight: 600
    ),
    hero-6: (
        font-size: special-6,
        font-weight: 600
    ),

    edge-1: (
        font-size: edge-1,
        font-weight: 600
    ),


    display-1: (
        font-size: xxl,
        font-weight: normal,
        font-style: normal,
        text-transform: none
        ),
    display-2: (
        font-size: xl,
        font-weight: normal,
        font-style: normal
    ),
    display-3: (
        font-size: l,
        font-weight: normal,
        font-style: normal
    ),
    display-4: (
        font-size: s,
        font-weight: 600,
        font-style: normal
    ),
    display-5: (
        font-size: xxs,
        letter-spacing: 1px,
        text-transform: uppercase,
        font-weight: 600,
        font-style: normal
    ),
    body-medium: (
        font-size: s,
        font-weight: normal
    ),
    body-normal: (
        font-size: xs,
        font-weight: normal
    ),
    body-small: (
        font-size: xxs,
        letter-spacing: 0,
        font-weight: normal
    ),
    blow-up: (
        font-size: banner,
        font-weight: 600
    ),
    trumpet: (
        font-size: trumpet,
        letter-spacing: 1px,
        text-transform: uppercase
    ),
    micro: (
        font-size: xxxs
    )
);