.categoryinfo-container {
    padding: 100px;
    background-color: #AAB7AC;

    .categoryinfo {
        max-width: 1170px;
        margin: auto;
        position: relative;

        h2 {
            @include typeface(serif);
            @include typeset(display-2);
            // font-family: $font-serif;
            // font-size: 36px;
            // line-height: 44px;
            // color: #292929;
            // text-transform: none;
            // background-color: transparent;
            
            text-align: left;
            display: block;
            padding: 0;
            float: right;
            clear: right;
            width: 48.718%;
            margin: 45px 0 15px 0;
        }

        >p {
            @include typeset(body-normal);
            // font-size: 16px;
            // line-height: 25px;
            // color: #292929;
            
            text-align: justify;
            clear: right;
            float: right;
            width: 48.718%;
        }

        .categoryinfo-imagecontainer {
            width: 45.726%;
            float: left;
            font-size: 0;
            line-height: 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        .categoryinfo-text {
            @include typeset(body-normal);
            // font-family: "Gill Sans Light", sans-serif;
            // font-size: 16px;
            // line-height: 25px;
            // color: #292929;
            
            clear: right;
            float: right;
            width: 48.718%;
            text-align: justify;
            margin-bottom: -7px;

            h1,
            h2,
            h3 {
                width: 100%;
                margin: auto;
                padding: 0;
                float: none;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .categoryinfo-container {
        padding: 50px;

        .categoryinfo {
            h2 {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .categoryinfo-container {
        padding: 20px;
    }
}

@media screen and (max-width: 640px) {
    .categoryinfo-container {
        padding: 40px 7.1428%;

        .categoryinfo {

            h1,
            h2 {
                // font-size: 24px;
                // line-height: 28px;
                
                text-align: center;
                float: none;
                width: 100%;
                margin: -3px auto 17px auto;
            }

            .categoryinfo-imagecontainer {
                width: 100%;
                position: relative;
                top: auto;
                transform: none;
                margin-bottom: 21px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            p,
            .categoryinfo-text {
                // font-size: 14px;
                // line-height: 18px;
                
                float: none;
                width: 100%;
                margin-bottom: -4px;
                clear: both;

                h1,
                h2,
                h3 {
                    float: none;
                    text-align: center;
                }
            }
        }
    }
}