// Animations
//
// There are a collection of different on-load-animations to spice things up with.<br>
// If you for some reason want multiple animations on a single element, you can write them all in the same data-animate. Separated with a space.<br><br>
// Uses <a href="https://greensock.com/gsap">GSAP by Greensock</a>
//
// Style guide tools.animations


// Stagger
//
// Put this on a parent element and its children with fade in, in a staggering motion, when it appears in the viewport.
//
// Markup: ../uilibrary/templates/core/animations/stagger.twig
//
// Style guide tools.animations.stagger


// Fade in
//
// Put this on an element and it will fade in, when it appears in the viewport.
//
// Markup: ../uilibrary/templates/core/animations/fade-in.twig
//
// Style guide tools.animations.fade-in

[data-animate~="fade-in"] {
    transition: opacity $transition-slow;
    opacity: 0;
   
    &[data-has-faded] {
        opacity: 1;
    }
}

[data-animate~="stagger"] > * {
    opacity: 0;
}

// [data-animate~="fade-in-slide"] {
//     transition: transform $transition-page-load, opacity $transition-page-load;
//     transform: translateY(rem(10));
//     opacity: 0;
// }

// [data-animation-done] {
//     transform: translateY(0);
//     opacity: 1;
// }

[data-animate~="fade-in-slide"] {
    transition: transform $transition-very-slow, opacity $transition-very-slow;
    transform: translateY(rem(10));
    opacity: 0;
}

[data-animation-done] {
    transform: translateY(0);
    opacity: 1;
}
