.pagecontent {
	.maincontent.searchresults {
		.productsearch {
			.sortandfiltercontainer {
				display: none;
			}
		}

		.sortandfilter-footer-mobile {
			display: none;
		}

		.sortandfilter-header-mobile {
			display: none;
		}

		.navcontainer-header-mobile {
			display: none;
		}
	}
}

@media screen and (max-width: 769px) {
	.pagecontent {
		.maincontentcontainer {
			.maincontent.searchresults {
				.productsearch {
					.selectmenu {
						display: none;
						position: absolute;
						z-index: 110;
						top: 80px;
						left: 0;
						padding: 0 20px;
						width: 100%;
						text-align: center;
						box-sizing: border-box;

						.ui-selectmenu-button {
							width: 100%;
							border: 1px solid #dcdcdc;

							.ui-icon {
								bottom: calc(50% - 13px);
								padding: 11px;
								background-size: 9px;
								background-position: center;
							}

							.ui-selectmenu-text {
								margin: 0;
								padding: 11px 25px 11px 11px;
							}
					    }
	                }
	                .sortandfiltercontainer{
	                    display: block;
	                    text-align: center;
	                    padding-top: 20px;
	                    .sortandfilterbutton {
	                        display: inline-block;
	                        cursor: pointer;
	                        border: 1px solid #292929;
	                        padding: 10px 30px;
	                        font-size: 18px;
	                        line-height: normal;
	                    }
	                }
	
	            }
	            .leftcoll{
// 	            	padding-top: 144px;
	            	padding-bottom: 68px;
	                display: none;
	                position: fixed;
				    width: 100vw;
				    top: 0;
				    bottom: 0;
				    left: 0;
				    right: 0;
	                background-color: #ffffff;
	                z-index: 105;
//                 	overflow-y: auto;
	                .navigation{
	                	border-top: 0;
	                	position: relative;
	                	padding-left: 0;
	                	-webkit-transition: -webkit-transform .3s ease;
					    transition: -webkit-transform .3s ease;
					    transition: transform .3s ease;
					    transition: transform .3s ease,-webkit-transform .3s ease;
					    overflow: auto;
    					height: calc(100% - 144px);
    					height: -webkit-calc(100% - 144px);
    					height: -moz-calc(100% - 144px);
    					padding-bottom: 68px;
					    &.slided{
					    	-webkit-transform: translateX(-100%);
						    -ms-transform: translateX(-100%);
						    transform: translateX(-100%);
					    	overflow: visible;
					    }
	                	.navgroup{
	                		position: static;
	                		padding-left: 20px;
	                		&.Pris{
	                			.navcontainer{
	                				padding-top: 0;	
	                			}
	                		}
	                		.navcollapse{
	                			background-image: url(../images/svg/frem_pil_ikon.svg);
	                			background-repeat: no-repeat;
	                            background-position: center center;
	                            background-size: 100%;
	                            position: static;
	                            margin-top: 32px;
	                            margin-right: 6px;
	                			.outerdash{
	                				background-color: transparent;
	                				.dash{
	                					background-color: transparent;
	                				}
	                			}
	                		}
	                		.navcontainer{
	                			left: 100%;
							    display: none;
							    position: absolute;
							    top: 0;
							    bottom: 0;
							    width: 100%;
							    margin: 0;
//     							padding-bottom: 68px;
//     							height: 100%;
    							overflow: auto;
    							box-sizing: border-box;
    							.navcontainer-header-mobile{
    								display: block;
    								padding: 20px;
    								text-align: center;
    								border-bottom: 1px solid #e7e7e7;
    								box-sizing: border-box;
    								.navcontainer-header-title{
    									padding: 0;
    								}
    							}
							    li{
							    	padding: 20px;
							    	border-bottom: 1px solid #dcdcdc;
							    }
							   	.checkboxinput {
								    padding: 20px 0 20px 20px;
								}
							}

							.navcollapse {
								background-image: url(../images/svg/frem_pil_ikon.svg);
								background-repeat: no-repeat;
								background-position: center center;
								background-size: 100%;
								position: static;
								margin-top: 32px;
								margin-right: 6px;

								.outerdash {
									background-color: transparent;

									.dash {
										background-color: transparent;
									}
								}
							}

							.navcontainer {
								left: 100%;
								display: none;
								position: absolute;
								top: 0;
								bottom: 0;
								width: 100%;
								margin: 0;
								// padding-bottom: 68px;
								// height: 100%;
								overflow: auto;
								box-sizing: border-box;

								.navcontainer-header-mobile {
									display: block;
									padding: 20px;
									text-align: center;
									border-bottom: 1px solid #e7e7e7;
									box-sizing: border-box;

									.navcontainer-header-title {
										@include typeset(display-4);
										padding: 0;
									}
								}

								li {
									padding: 20px;
									border-bottom: 1px solid #dcdcdc;
								}

								.checkboxinput {
									padding: 20px 0 20px 20px;
								}

								.checkboxlabel {
									margin: 0;
									padding: 20px 20px 20px 40px;
									border-bottom: 1px solid #dcdcdc;
								}

								.primarylink {
									padding: 20px;
								}

								.minamount,
								.maxamount {
									float: none;
									width: 100%;
									padding: 20px;
									box-sizing: border-box;

									input {
										width: 100%;
										-webkit-appearance: none;
										box-sizing: border-box;
										margin: 0;
										padding: 11px;
										border: 1px solid #dcdcdc;
										border-radius: 3px;
									}
								}

								.navoverlay {
									position: fixed;
									transform: translate(0%, 0%);
									-ms-transform: translate(0%, 0%);
									-moz-transform: translate(0%, 0%);
									-o-transform: translate(0%, 0%);
									-webkit-transform: translate(0%, 0%);
									padding: 0;
									top: -144px;
									left: 100%;
									bottom: 0;
									right: 0;
									// 								    height: 100vh;
									width: 100%;

									.navoverlaycontent {
										position: absolute;
										overflow: auto;
										box-sizing: border-box;
										padding: 0;
										top: 0;
										left: 0;
										bottom: 0;
										right: 0;
										-moz-box-shadow: none;
										-webkit-box-shadow: none;
										box-shadow: none;

										ul {
											height: calc(100% - 190px);
											height: -webkit-calc(100% - 190px);
											height: -moz-calc(100% - 190px);

											li {
												width: 100%;
												box-sizing: border-box;

												.checkboxinput {
													padding: 0;
												}

												.checkboxlabel {
													padding: 0 0 0 20px;
													border-bottom: none;
												}
											}
										}

										.navoverlaybottom {
											padding-top: 0;
											padding-bottom: 20px;
										}
									}
								}
							}

							&.active {
								.navcontainer {
									display: block;
								}
							}
						}
					}

					.sortandfilter-header-mobile {
						display: block;
						padding: 20px 20px 83px;
						border-bottom: 1px solid #e7e7e7;
						// 	               		position: absolute;
						top: 0;
						width: 100%;
						background-color: #ffffff;
						z-index: 110;
						box-sizing: border-box;

						h2 {
							@include typeface(serif);
							@include typeset(display-1);
							text-align: center;
							// font-size: 2.25rem;
						}

						&.no-select {
							padding-bottom: 20px;
						}
					}

					.sortandfilter-footer-mobile {
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						box-sizing: border-box;
						border-top: 1px solid #dcdcdc;
						background-color: #f5f5f5;
						padding: 15px;

						.sortandfilter-back-btn {
							padding: 10px 30px;
							-webkit-appearance: none;
							border: 1px solid #dcdcdc;
							background-color: #ffffff;
							width: 48%;
							text-align: center;
						}

						.sortandfilter-ok-btn {
							padding: 10px 30px;
							-webkit-appearance: none;
							border: 1px solid #a4bcc3;
							background-color: #a4bcc3;
							color: #ffffff;
							width: 48%;
							text-align: center;
							float: right;
						}
	                }
	            }
	            &.showsortandfilter{
	                .productsearch {
	                    .selectmenu {
	                        display: block;
	                    }
	                }
	                .leftcoll{
	                    display: block;
	                }
	            }
	        }
	    }
   }
   .noScroll{
   		// position: fixed;
   		width: 100%;
   		.rightcontent, .maincontentbottom{
   			height: 0;
   			overflow: hidden;
   		}
   		.dimmedlayer{
   			display: none;
   		}
   }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.navigation .navgroup .navcontainer {

		.maxamount,
		.minamount {
			input {
				font-size: 16px;
			}
		}
	}
}