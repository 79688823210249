.featured-banner-container {
    background-color: #fafafa;
    padding: 42px 0 43px 0;
    max-width: 1170px;
    margin: 0 auto 100px auto;
    -webkit-margin-after-collapse: separate;

    .featured-banner {
        width: 100%;
        padding: 0 98px;
        box-sizing: border-box;

        .featured-banner-textcontainer {
            width: 45.3%;
            width: 50.308%;
            float: left;
            box-sizing: border-box;
            padding-right: 20px;

            .categoryheader {
                text-align: left;
                margin: 62px 0 0 -1px;
                padding: 0;
            }

            .subheadline {
                // sorthvid
                @include typeset(body-large);
                // font-family: $font-sans-serif;
                // font-size: 20px;
                // font-weight: bold;
                // letter-spacing: .6px;
                // line-height: normal;
            }

            .text {
                @include typeset(body-normal);
                // font-size: 16px;
                // line-height: 22px;
                margin: 25px 0 51px 0;
            }

            .button-style {
                width: 200px;
            }
        }

        .featured-banner-imagecontainer {
            float: right;
            width: 41.0678%;
            font-size: 0px;
            line-height: 0px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &.featured-banner-magasincard {
        padding: 55px 0 55px 0;
        max-width: 780px;
        margin: 0 auto 0 0;

        .featured-banner {
            padding: 0 50px;
            position: relative;

            &:after {
                content: "";
                display: table;
                clear: both;
            }

            .featured-banner-textcontainer {
                width: 55%;

                .categoryheader {
                    margin: 5px 0 3px -1px;
                    font-size: 30px;
                }

                .subheadline {
                    font-weight: 300;
                }

                .text {
                    margin: 18px 0 35px 0;
                }

                .button-style {
                    width: 88%;
                }
            }

            .featured-banner-imagecontainer {
                width: 33%;
                position: absolute;
                right: 50px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .featured-banner-container {
        padding: 6%;
        margin: 0;

        .featured-banner {
            padding: 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .featured-banner-container {
        .featured-banner {
            .featured-banner-textcontainer {
                float: none;
                width: 100%;
            }

            .featured-banner-imagecontainer {
                float: none;
                width: 100%;
            }
        }

        &.featured-banner-magasincard {
            .featured-banner {
                .featured-banner-textcontainer {
                    float: none;
                    width: 100%;
                    padding-right: 0;
                    text-align: center;

                    .categoryheader {
                        text-align: center;
                    }

                    .button-style {
                        width: 100%;
                    }
                }

                .featured-banner-imagecontainer {
                    float: none;
                    width: 100%;
                    position: static;
                    transform: none;
                    margin-top: 35px;
                }
            }
        }
    }
}