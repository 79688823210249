fieldset {
    @include typeface(sans-serif);
    border: none;
    margin: 0;
    padding: 0;
}

input {
    @include typeface(sans-serif);
    outline: none;
}

.validationtype2 {
    .form-row {
        div {
            &.error {
                display: none!important;
            }
        }
    }
}

.orderflowcontent{
	.form-row {
	    padding: 5px 0px 6px 145px;
        &.country {
            width: 50%;
            padding: 14px 8.5px;
            box-sizing: border-box;
            .selector {
                background-image: none;
                height: 39px;
                box-sizing: border-box;
                width: 100%;
                border: 1px solid #797979;
                position: relative;
                overflow: initial;
                margin-left: 0;
                padding: 0;
                span {
                    background-image: none;
                    height: 37px;
                    position: relative;
                    line-height: 37px;
                    padding: 0 47px 0px 10px;
                    overflow: initial;
                    &:after {
                        content: "";
                        width: 37px;
                        height: 37px;
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        border-left: 1px solid #797979;
                    }
                }
                select {
                    height: 37px;
                    width: calc(100% + 1px);
                    left: -1px;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #333333;
                    position: absolute;
                    right: 13px;
                    pointer-events: none;
                    top: 16px;
                }
            }
        }
        .label {
		    float: left;
		    width: 114px;
		}
		.required-indicator {
		    float: left;
		    margin-left: -8px;
		}
		.input-text {
            @include typeset(body-small);
            @include typeface(sans-serif);
		    // font-family: $font-sans-serif;
		    // font-weight: bold;
		    // font-size: 12px;
		    border: 1px solid #cfcfcf;
		    width: 230px;
		    background-color: #ffffff;
		    margin: -2px 0px 0px 0px;
		    padding: 3px 5px 2px 5px;
		}
	}
	.required{ 
		.input-text {
		    background-color: #e9fdf6;
		    margin-left: 0px;
		}
	}
	.smallfield {
		.input-text {
		    width: 60px;
		}
	}
	.infolink {
        @include typeface(serif);
	    // font-family: Georgia,serif;
	    // font-weight: bold;
	    // font-style: italic;
	    float: left;
	    padding: 0px 0px 5px 10px;
	    a {
		    background-color: transparent;
		    color: #292929;
		    text-decoration: underline;
		}
	}
	.twocolls{
		padding: 24px 0 26px 0;
		.leftside{
			float: left;
		    width: 362px;
		    padding-right: 20px;
			border-right: 1px solid #e7e3df;
    		box-sizing: border-box;
    		.head {
			    padding: 0px 0px 2px 0px;
			    border: none;
			}
    		.form-row {
			    float: left;
			    padding: 5px 0px 10px 0px;
			    .label {
				    width: 100px;
				}
			}
		}
		.rightside {
		    float: left;
		    padding: 0px 20px 0px 25px;
		    width: 313px;
		    .continuebutton {
			    padding-top: 10px!important;
			}
		}
		.bottom {
		    padding-top: 70px;
	    	.continuebutton {
			    float: right;
			    margin-right: -5px;
			}
		}
	}
}

.error-validationtype2 {
    color: #fe3e64;
    a {
        color: #fe3e64;
        text-decoration: underline;
    }
}

.form-row div.error, .form-row div.error-validationtype2 {
    background-color: transparent;
    color: #fe3e64;
    float: left;
	white-space: nowrap;
}

.continuebutton {
    float: right;
    margin: 0px -5px 0px 0px;
}

.boxondlayer{
    .addtobasket-top {
        padding: 20px 20px 40px 20px;
        .addtobasket-header {
            margin-top: 7px;
            padding: 20px;
            font-size: 22px;
            text-align: center;
            line-height: 28px;
            margin: 12px auto 29px auto;
            max-width: 440px;
            .itemname {
                font-weight: 600;
            }
        }
        .buttoncontainer {
            padding: 0 75px;
            font-size: 0;
            text-align: center;
            
            .buttonkeepshopping {
                height: 50px;
                line-height: 48px;
                width: calc(50% - 10px);
                max-width: 320px;
                box-sizing: border-box;
                border-color: #292929;
                text-transform: none;
            }
            .buttontobasket {
                height: 50px;
                line-height: 48px;
                width: calc(50% - 10px);
                max-width: 320px;
                box-sizing: border-box;
                margin-left: 20px;
                text-transform: none;
                border: 1px solid #a3bcc4;
                &:hover {
                    color: #ffffff;
                    background-color: #a3bcc4;
                    text-decoration: none;
                }
            }
        }
    }
}

.rightcontent{
	.required-indicator {
	    text-align: right;
	    padding: 2px 14px 0px 0px;
	    span {
		    background-color: transparent;
		    color: #292929;
		}
	}
}

.required-indicator {
    background-color: transparent;
    color: #a3988b;
}

.selector {
    background-image: url(../images/select_left.gif);
    height: 20px;
    font-size: 12px;
    position: relative;
    padding: 0px 0px 0px 2px;
    overflow: hidden;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    text-align: left;
    margin-left: 10px;
    span {
	    background-image: url(../images/select_right.gif);
	    background-position: right top;
	    height: 20px;
	    padding: 1px 24px 0px 2px;
	    cursor: pointer;
	    display: block;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}
    select {
	    top: 0px;
	    left: 0px;
	    position: absolute;
	    opacity: 0;
	    filter: alpha(opacity=0);
	    height: 20px;
	    border: none;
	    background: none;
	    cursor: pointer;
	    z-index: 2;
	}
}

.selectors.selectmenu {
    display: inline-block;
    vertical-align: bottom;
    font-size: 0;
    line-height: 0;
}

.addtobasket {
    .amount {
        select {
            display: none;
        }
        .selector {
            display: none;
        }
    }
}

.orderflowcontent .checkbox {
    float: left;
}

.checkbox {
    &.type2{
    	.checker{
    		span{
    			float: left;
			    background-image: url(../images/checkbox_type2.gif);
			    background-repeat: no-repeat;
			    background-position: center;
			    box-sizing: border-box;
			    width: 14px;
			    height: 14px;
			    line-height: 0px;
			    margin-top: -1px;
			    &.checked{
			    	background-image: url(../images/checkbox_checked_type2.gif);
			    }
    		}
    	}
    }
   	&.type1{
   		.checker{
    		span{
			    float: left;
			    background-image: url(../images/checkbox_type1.gif);
			    background-repeat: no-repeat;
			    width: 12px;
			    height: 12px;
			    line-height: 0px;
			    &.checked{
			    	    background-image: url(../images/checkbox_checked_type1.gif);
			    }
    		}
    	}
    }
}

.checker{ 
	padding: 2px 5px 0px 0px;
	.checked {
	    width: 15px;
	    height: 15px;
	    background-image: url(../images/svg/checkmark_black.svg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    background-size: 13px 11px;
	}
}


.newselect .selector {
    background-image: url(../images/newselect_left.gif);
    background-repeat: no-repeat;
    height: 39px;
    font-size: 12px;
    position: relative;
    padding: 0px 0px 0px 2px;
    overflow: hidden;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    text-align: left;
    width: 285px;
    margin: 0 2px;
}

.orderflowcontent .radios {
    float: left;
    width: auto;
    height: auto;
    .radio {
        float: left;
        height: auto;
        width: auto;
    }
}

.radio {
    padding: 1px 5px 0px 0px;
    &.type2{
    	.radio{
    		span{
    			float: left;
			    background-image: url(../images/radiobutton_type2.png);
			    background-repeat: no-repeat;
			    width: 13px;
			    height: 13px;
			    margin-top: 0px;
			    line-height: 0px;
			    &.checked{
			    	background-image: url(../images/radiobutton_checked_type2.png);
			    }
    		}
    	}
    }
   	input {
	    opacity: 0;
	    filter: alpha(opacity=0);
	    background: none;
	}
}
@media screen and (max-width: 800px){
	.form-row div.error, .form-row div.error-validationtype2{
		white-space: normal;
	}
}

@media screen and (max-width: 769px){
	.head .error-validationtype2{
		max-width: 70%;
	}
	.orderflowcontent {
		.form-row {
		    padding: 5px 0px 6px 0px;
		}
	}
}

@media screen and (max-width: 1366px){    
    .addtobasket {
        .amount {
            &.amount--nostock .selector span {
                color: #909090;
            }
            select { // Hide at pageload!
                opacity: 0;
                display: block;
                width: 50px;
                height: 50px;
            }            
            div:first-of-type {
                display: none;
            }
            .selector {
                display: block;
                width: 50px;
                height: 50px;
                background-image: none;
                margin: 0;
                padding: 0;
                span {
                    background-image: none;
                    border: 1px solid #edecec;
                    line-height: 50px;
                    height: 50px;
                    padding: 0;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 16px;
                }
//                select {
//                    opacity: 0;
//                    width: 50px;
//                    height: 50px;
//                }
            }
        }
    }
}



@media screen and (max-width: 480px){
    .boxondlayer{
        .addtobasket-top {
            padding: 20px 20px 0px 20px;
            .addtobasket-header {
                margin: 7px auto 5px auto;
                padding: 20px;
                font-size: 18px;
                text-align: center;
                line-height: 22px;
            }
            .buttoncontainer {
                padding: 0 0 0 5px;
                .buttonkeepshopping {
                    width: 100%;
                    max-width: none;
                    height: 40px;
                    line-height: 38px;
                }
                .buttontobasket {
                    width: 100%;
                    max-width: none;
                    height: 40px;
                    margin: 15px auto 5px auto;
                    line-height: 38px;
                }
            }
        }
    }
    
    .head .error-validationtype2{
		max-width: 100%;
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
        @include typeset(body-normal);
        // font-size: 16px;
    }
}