// Usp banner
//
// Standalone component.<br>
// A banner that takes up its full width. Has a dark text color, and therefore it demands a light background color.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/banner-usp.twig
//
// Weight: 1
//
// Style guide: components.banner-usp

.banner-usp {
    .new-font & {
        @include typeface(sans-serif);
    }
    padding: rem(24) rem(24) rem(48);
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    color: $color-white;
    
    // @include mq($from: screen-sm) {
    //     padding: rem(24);
    // }

    @include mq($from: screen-lg) {
        padding: rem(48) rem(35) rem(48) rem(72);
    }

    @include mq($from: screen-xl) {
        padding: rem(48) rem(35) rem(48) rem(124);
    }
    
    &__half {
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:first-child {
            order: 2;
        }

        &:last-child {
            order: 1;
        }
        
        @include mq($from: screen-xs) {
            padding: rem(24) 0;
            width: 50%;

            &:first-child {
                order: 1;
            }
    
            &:last-child {
                order: 2;
            }
        }

        @include mq($from: screen-sm) {
            padding: 0 rem(24);
            width: 100%;

            &:first-child {
                order: 2;
            }
    
            &:last-child {
                order: 1;
            }
        }

        @include mq($from: screen-sm-plus) {
            padding: rem(24);
            width: 50%;

            &:first-child {
                order: 1;
            }
    
            &:last-child {
                order: 2;
            }
        }
        
        @include mq($from: screen-lg) {
            padding: 0 rem(24);
        }
        
        @include mq($from: screen-xl) {
            padding: 0 rem(48);
        }
    }

    &__heading {
        .new-font & {
            @include typeface(serif);
        }
        @include typeset(display-2);

        font-weight: 400;

        margin: 0 0 rem(8) 0;
    }

    &__manchet {
        @include typeset(body-medium);

        margin-bottom: rem(36);
        
        b {
            font-weight: normal;
        }
    }
    
    &__text {
        @include typeset(body-normal);
        
        margin-bottom: rem(24);
    }

    img {
        width: 100%;
        height: auto;
    }

    hr {
        border: none;
        border-bottom: 1px solid currentColor;
        margin: rem(24) 0;
        opacity: 0.5;
        width: 90%;
    }
}