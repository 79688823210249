// Hero
//
// To be used in the top of a page, to show some media content.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/hero.twig
//
// Weight: 1
//
// examplelink: /examples/pages/hero||Hero examples
//
// Style guide: components.hero

.hero {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    
    height: rem(300);
    overflow: hidden;

    background-color: rgba(0,0,0,0.02);

    margin: 0 -6.25vw; // overwrite-maincontent-padding
    width: 100vw;
    @include mq($from: 769px) {
        margin: 0;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    &--right-align {
        .hero__image {
            background-position: right;
        }
    }

    &__buttons {
        position: absolute;
        width: 100%;
        
        text-align: center;
        
        padding: 0 rem(24);
        box-sizing: border-box;

        button {
            margin: 0 rem(7) rem(15) rem(7);
            
            @include mq($from: screen-xs) {
                margin: 0 rem(8);
            }
        }
    }
}