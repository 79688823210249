// Cookie Modal
//
// This one is a bit special.<br>
// The markup and javascript for this component is implemented inside app.cookieinformation.com,<br>
// and the css is coming from our code.<br>
// The markup is written with handlbars syntax. So it cannot be shown in the designsystem.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/modal-cookie-complience.twig
//
// Weight: 1
//
// hideDefault: false
//
// Style guide: components.cookie-modal

body.cookie-modal-open {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

.cookie-compliance {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 10000;

    overflow: hidden;

    background: rgba(0,0,0,0.5);

    &__body {
        @include typeface(sans-serif);
    
        position: relative;
        box-sizing: border-box;
    
        background: white;

        @include mq($from: screen-sm) {
            top: 45%;
            left: 50%;
            bottom: auto;
            right: auto;
            
            transform: translate(-50%, -50%);
            
            max-width: 480px;
            height: auto;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            height: inherit;

            h1 {
                @include typeface(serif);
                @include typeset(display-3);
                
                text-align: center;
                font-style: normal;
                text-transform: none;
                font-weight: normal;
                padding: rem(30) 0 rem(10);
            }

            p {
                @include typeset(body-normal);

                padding-bottom: 24px;
            }

            .cookie-page {
                // Transitions when going from 1st to 2nd page
                // Gets animated in https://app.cookieinformation.com
                transition: opacity $transition-fast, height $transition-very-slow;
                
                // Pretty hardcoded margin to take the mobile browser UI in account
                max-height: 70vh;
                // max-height: calc(100% - rem(91)); // NOTE: for testing (fullheight - button container)
                
                flex: 1 1 auto;
                background: white;
                
                &--padding {
                    padding: 0 rem(15);
                    
                    @include mq($from: screen-sm) {
                        padding: 0 rem(48);
                    }
                }
                
                &.scroll {
                    overflow: auto;
                    -webkit-overflow-scrolling: auto;

                    height: 0;
                }
                
                &.hidden {
                    display: none;
                }

                &__scroll {
                    padding: 0 rem(15);

                    @include mq($from: screen-sm) {
                        max-height: rem(500);
                        padding: 0 rem(48);
                    }
                }

                &__manchet {
                    margin-bottom: rem(24);
                    border-bottom: 1px solid $color-border;

                    p {
                        padding-bottom: rem(19);
                    }
                }

                &__byline {
                    max-width: rem(300);
                    margin: 0 auto;
                }
            }
        }

        &__buttons {
            border-top: 1px solid $color-white;
            
            width: 100%;
            padding: rem(10) 0;
            box-sizing: border-box;
            
            background: white;
            
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            
            & > * {
                margin: rem(8);
            }
            
            a {
                height: rem(24);
                margin-bottom: rem(15);
                font-size: rem(13);
                text-decoration: underline;
                color: $color-text;
            }
            
            &.active {
                transition: border $transition-very-slow;
                border-top: 1px solid $color-border;
            
                // Targets the divs with text
                button > div > * {
                    transition: transform $transition-very-slow;
                    transform: translateY(-100%);
                }

                a {
                    transition: height $transition-very-slow, margin $transition-very-slow;
                    height: 0;
                    margin: 0;
                    overflow: hidden;
                }
            }
        }
    }
}

// Checkbox item, for each category of cookie
.input-checkbox-w-text {
    display: flex;
    align-items: flex-start;

    padding-bottom: rem(24);

    input {
        display: none;

        &:checked + div:before {
            opacity: 1;
        }

        &[disabled] + div:before {
            opacity: .4;
        }
    }

    .checkbox {
        position: relative;
        padding: rem(10);
        margin-right: rem(10);
        font-size: 0;
    
        display: block;
            
        border: 1px solid $color-border;

        &:before {
            transition: opacity $transition-fast;
            opacity: 0;
        
            content: " ";
            background: center / 50% no-repeat url('../images/svg/checkmark.svg');
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        
            pointer-events: none;
                
            display: flex;
            align-items: center;
            justify-content: center;
                
            font-size: 1rem;
        }
    }

    h4 {
        margin: rem(1) 0 0 0;
    }

    p {
        font-size: rem(13);

        padding: 0;
    }
}
