// Modal
//
// Standalone component. Needs to have a trigger on the same page.<br>
// Is composed of a block of text, and a close button. Will allways be rendered on top of the rest of the page. <br><br>
// The trigger can be anything, but preferably a link. A trigger becomes a trigger if it has a ```class="js--dialog-open"```.<br>
// Will lock the rest of the page from being scrolled, when the modal is open.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/modal.twig
//
// Weight: 1
//
// hideDefault: true
//
// examplelink: /examples/examples/modal||Se example page for working example
//
// Style guide: components.modal

@keyframes hide{
    to {
        transform: translate(-50%, -45%);
        opacity: 0;
    }
}
@keyframes showWithSlide{
    from {
        transform: translate(-50%, -45%);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%);
    }
}
@keyframes showWithoutSlide{
    from {
        opacity: 0;
    }
}
@keyframes showBackdrop {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes hideBackdrop{
    to {
        opacity: 0;
    }
}

/* polyfill */
dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    border: solid;
    padding: 1em;
    background: white;
    color: black;
    display: block;

  }
  
  dialog:not([open]) {
    display: none;
  }
  
  dialog + .backdrop {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0,0,0,0.3);
  }
  
  ._dialog_overlay {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
  }
  
  dialog.fixed {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
  }
/* /polyfill */

.modal {
    @include typeface(sans-serif);

    position: fixed;

    border: none;
    box-shadow: 0 rem(2) rem(200) $color-shadow;
    box-sizing: border-box;

    z-index: 1000;

    min-height: 100vh;
    min-width: 100vw;
    max-height: 100vh;

    padding: rem(30) rem(15);

    overflow: scroll;
    
    &[open] {
        animation: showWithoutSlide $duration-fast ease normal;
    }
    
    @include mq($from: screen-sm) {
        top: 45%;
        left: 50%;
        bottom: auto;
        right: auto;
        transform: translate(-50%, -50%);
        max-width: map-get($mq-breakpoints, screen-xs);
        min-height: auto;
        min-width: auto;
        padding: rem(30) rem(48);
        &[open] {
            animation: showWithSlide $duration-fast ease normal;
        }
    }

    
    &--large {
        @include mq($from: screen-sm) {
            max-width: none;
        }
        @include mq($from: screen-md) {
            top: 47.5%;
            max-width: map-get($mq-breakpoints, screen-sm);
        }
    }

    &::backdrop {
        background: rgba(0,0,0,.3);
        animation: showBackdrop $duration-fast ease normal;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;

        padding: rem(10);
        box-sizing: border-box;

        height: rem(48);
        width: rem(48);
        display: flex;
        align-items: center;

        button {
            transition: transform $transition-very-fast;
            
            display: inline-block !important; // NOTE: Some script puts display none on button after clicked?!
            -webkit-appearance: none;
            border: none;
            background: transparent;
            cursor: pointer;

            padding: 0;

            font-size: rem(20);
            line-height: .5;

            &:hover, &:focus {
                outline: none;
                transform: scale(1.2);
            }
            &:active {
                transform: scale(1);
            }
        }
    }

    &__heading {
        h1 {
            .new-font & {
                @include typeface(serif);
            }
            @include typeset(display-3);
            
            text-transform: none;
            text-align: center;
            
            margin-bottom: rem(20); 
            padding: 0;
        }
    }
    
    &__trumpet {
        h2 {
            @include typeset(body-normal);
            
            font-weight: bold;
            margin: rem(4) 0 rem(24) 0;
            text-align: center;
        }
    }
    
    &__content {
        p {
            @include typeset(body-normal);

            margin-bottom: rem(24);
        }

        h1 {
            --modal-vertical-padding: rem(30);
            @include typeface(serif);
            @include typeset(display-3);

            text-align: center;
            margin: var(--modal-vertical-padding) 0 rem(10);
            padding: 0;
        }

        
        // &__scroll {
        //     padding: 0 rem(15);

        //     // Calculating 100vh - [Total height of button-box]
        //     // to fill the scroll container of its total accessible height
        //     // Less then ideal..
        //     max-height: calc(100vh - rem(103));
            
        //     overflow-y: auto;
            
        //     @include mq($from: screen-xs) {
        //         max-height: rem(500);
        //     }
        //     @include mq($from: screen-sm) {
        //         --modal-horizontal-padding: rem(48);
        //         padding: 0 var(--modal-horizontal-padding);
        //     }
        // }
    }

    // .cookie-page {
    //     // Transitions when going from 1st to 2nd view
    //     // Gets animated in modal.js
    //     transition: opacity $transition-very-fast, max-height $transition-very-slow;

    //     background: $color-white;
    //     overflow: auto;

        
    //     &--padding {
    //         padding: 0 rem(15);

    //         @include mq($from: screen-sm) {
    //             --modal-horizontal-padding: rem(48);
    //             padding: 0 var(--modal-horizontal-padding);
    //         }
    //     }

    //     &.hidden {
    //         display: none;
    //     }

    //     &__manchet {
    //         margin-bottom: rem(24);
    //         border-bottom: 1px solid $color-border;

    //         p {
    //             margin-bottom: rem(19);
    //         }
    //     }
    // }

    // &__input-textarea {
    //     padding-top: rem(15);
    // }

    &__buttons {
        border-top: 1px solid $color-white;

        position: absolute;
        bottom: rem(20);
        width: calc(100% - 2 * rem(15));
        box-sizing: border-box;

        background: $color-white;
        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
            @include typeset(body-small);
            
            height: rem(24);
            margin-bottom: rem(15);

            color: $color-text;
            text-decoration: underline;
        }

        &.active {
            transition: border $transition-very-slow;
            border-top: 1px solid $color-border;
            
            // Targets the divs with text
            button > div > * {
                transition: transform $transition-very-slow;
                transform: translateY(-100%);
            }

            a {
                transition: height $transition-very-slow, margin $transition-very-slow;
                height: 0;
                margin: 0;
                overflow: hidden;
            }
        }

        @include mq($from: screen-sm) {
            position: relative;
            bottom: initial;
            width: initial;
        }

        &--vertical {
            flex-direction: column;
            align-items: center;

            & > * {
                margin: rem(8);
            }
        }

        &--padding {
            padding-top: rem(10);
        }

        &--fullwidth {
            width: 100%;
            left: 0;
        }

        &__item {
            width: 100%;
            margin-top: rem(24);

            @include mq($from: screen-xs) {
                width: calc(50% - rem(12) - 1px);
                margin-top: rem(24);
            }
        }
    }

    &__byline {
        h3 {
            @include typeset(body-small);

            font-weight: bold;
            font-style: normal;
            text-transform: none;

            margin-top: rem(24);
        }
    }
}