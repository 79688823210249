.mobilemenu{
    &.menuopen {
        overflow: hidden;
        body{
            overflow: hidden;
            .menupageblur {
                display: block;
                position: absolute;
                width: 100%;
                overflow: hidden;
                background-color: rgba(0,0,0,0.6);
                z-index: 104;
            }
            .burgermenuoverlay {
                display: block;
                #burgermenucontainer {
                    overflow: hidden;
                    white-space: nowrap;
                    width: 200%;
                    #burgermenu{
                        margin-left: 0px;
                        -webkit-transition: margin-left .25s;
                        form{
                            text-align: center;
                            #logoutbutton {
                                display: inline-block;
                                height: 50px;
                                width: 220px;
                                background-color: #9FBDC3;
                                color: #ffffff;
                                font-size: 16px;
                                line-height: 50px;
                                text-align: center;
                                border: none;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.burgermenuoverlay {
    display: none;
    width: 43%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 105;
    #burgermenucontainer {
        #prevburgermenu{
            pointer-events: auto;
            float: left;
            background-color: #ffffff;
            width: 50%;
            margin-left: -50%;
            -webkit-transition: margin-left .25s;
            &.prevvisible{
                margin-left: 0px;
                -webkit-transition: margin-left .25s;
            }
        }
        #burgermenu{
            pointer-events: auto;
            float: left;
            background-color: #ffffff;
            width: 50%;
            margin-left: -50%;
            -webkit-transition: margin-left .25s;
            overflow: auto;
            &.nextvisible{
                margin-left: -50% !important;
                -webkit-transition: margin-left .25s;
            }
        }
        #nextburgermenu{
            pointer-events: auto;
            float: left;
            background-color: #ffffff;
            width: 50%;
        }
        .burgermenuheader {
            padding: 20px;
            background-color: #EDEDED;
            position: relative;
            .burgercloseicon{
                background-image: url(../images/burgermenu_closeicon.png);
                background-repeat: no-repeat;
                background-color: transparent;
                background-size: 10px 10px;
                border: none;
                height: 10px;
                width: 10px;
                margin: 4px 0px 0px 0px;
                float: right;
                cursor: pointer;
            }
            .log-in{
                float: left;
                font-size: 14px;
                color: #292929;
                padding-left: 25px;
                text-decoration: none;
                &:before {
                    content: "";
                    position: absolute;
                    background-image: url(../images/burgermenu_usericon.png);
                    background-size: 14px 16px;
                    width: 14px;
                    height: 16px;
                    left: 20px;
                }
            }
            .logged-in{
                float: left;
                font-size: 14px;
                color: #292929;
                padding-left: 25px;
                text-decoration: none;
                &:before {
                    content: "";
                    position: absolute;
                    background-image: url(../images/burgermenu_usericon.png);
                    background-size: 14px 16px;
                    width: 14px;
                    height: 16px;
                    left: 20px;
                }
            }
            #homeLink{
                float: left;
                font-size: 14px;
                color: #292929;
                padding-left: 25px;
                text-decoration: none;
                &:before {
                    content: "";
                    position: absolute;
                    background-image: url(../images/burgermenu_previcon.png);
                    background-size: 6px 10px;
                    width: 6px;
                    height: 10px;
                    margin-top: 4px;
                    left: 20px;
                }
            }
        }
        .activecategory{
            padding: 20px;
            position: relative;
            border-bottom: 1px solid #dcdcdc;
            text-align: center;
            display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    -webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;
		    flex-direction: column;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    min-height: 41px;
            .categoryname{
                font-size: 18px;
                margin-bottom: 4px;
            }
            #backLink{
                float: left;
                background-image: url(../images/burgermenu_previcon.png);
                background-size: 6px 10px;
                background-repeat: no-repeat;
    			background-position: center;
                width: 46px;
                height: 100%;
                top: 0;
                position: absolute;
                left: 0;
//                 transform: translateY(-50%);
//                 -webkit-transform: translateY(-50%);
//                 -moz-transform: translateY(-50%);
            }
            .showall{
                font-size: 14px;
                color: #292929;
                text-decoration: underline;
            }
        }
        .primary-navigation{
            ul {
                li {
                    display: block;
                    padding: 18px 20px 16px 20px;
                    position: relative;
                    border-bottom: 1px solid #dcdcdc;
                    cursor: pointer;
                    font-size: 14px;
                    img{
                    	max-height: 18px;
                    	vertical-align: top;
                    	&[src$=".svg"]{
                    		max-width: calc(2.72 * 18px);
                    		max-width: -webkit-calc(2.72 * 18px);
                    		max-width: -moz-calc(2.72 * 18px);
                    	}
                    }
                    p {
                        display: inline-block;
                    }
                    .nextarrow{
                        float: right;
                        background-image: url(../images/burgermenu_nexticon.png);
                        background-size: 6px 10px;
                        width: 6px;
                        height: 10px;
                        margin-top: 2px;
                    }
                    &.active{
                        font-weight: bold;
                    }
                    &.sidepanel-mobile-ajour-logo {
                        padding: 0;
                        a {
                            display: inline-block;
                            box-sizing: border-box;
                            width: 100%;
                            padding: 19px 20px 15px;
                            img {
                                height: 25px;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
        .secondary-navigation{
            background-color: #F6F6F6;
            ul {
                li {
                    display: block;
                    padding: 18px 20px 16px 20px;
                    position: relative;
                    border-bottom: 1px solid #dcdcdc;
                    cursor: pointer;
                    font-size: 14px;
                    .nextarrow {
                        float: right;
                        background-image: url(../images/burgermenu_nexticon.png);
                        background-size: 6px 10px;
                        width: 6px;
                        height: 10px;
                        margin-top: 2px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 769px){
    .burgermenuoverlay {
        width: 50%;
    }
}

@media screen and (max-width: 480px){
    .burgermenuoverlay {
        width: 80%;
    }
}