.pagecontent--wide .maincontent{
    padding: 0 !important;
}

.pagemargin--wide .maincontentcontainer .maincontenttop {
    display: none;
}

.mblog {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fafaf8;
    margin: 0 auto;
    a:hover{
        text-decoration: none;
    }
    div, ul, ol, p, h1, h2, h3, h4, h5, h6{
        margin: 0;
        padding: 0;
        line-height: normal;
        display: block;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: BentonModernDisp,serif;
        font-size: 1.25em;
        letter-spacing: 0.04em;
        color: #292929;
        text-transform: none;
        font-style: normal;
        font-weight: bold;
        background-color: transparent;
        font-weight: bold;
    }
    p {
        font-size: 0.875em;
        letter-spacing: 0.02em;
        color: #292929;
    }
    em {
        font-style: italic;
    }
    li a {
        padding: 0;
    }
    .dropdown, .dropdown ul, .dropdown li {
        margin: 0;
        padding: 0;
    }
    span.mblog-theme-header-title-tag {
    	padding-bottom: 0.58333em;
    	margin-bottom: 1em;
    }
    
    .mblog-header {
        position: relative;
        overflow: hidden;
        margin-bottom: rem(48);
        
        &-border {
        	&-outer {
        		max-width: 77%;
        		background-color: #fff;
        		padding: 0.875em;
        		box-sizing: border-box;
        		margin: 0 auto;
        	}
        	&-inner {
        		padding: 1.8125em 2.8125em 0.875em 2.8125em;
        		border: 1px solid #e0e0e0;
        		text-align: center;
        	}
        }
        
        .mblog-hashtag-header-container {
			margin: 0 auto 1.25em auto;
			padding: 1.5625em 1.25em 0 1.25em;
			background-color: transparent;
			max-width: 64.5em;
			
			.mblog-hashtag-header {
				background-color: #ffffff;
				padding: 0.875em;
				
				&-title {
					padding: 1.5625em 1.5625em 1.125em 1.5625em;
					border: 1px solid #e0e0e0;
					overflow: hidden;
					
					h1 {
						text-align: center;
						text-transform: uppercase;
						font-size: 2.125em;
					}
					
					img {
						display: block;
						margin: 0 auto;
						text-align: center;
						height: 1.4375em;
						
						&.mblog-logo-mobile {
							display: none;
						}
					}
				}
			}
        }
        
        .mblog-theme {
        	&-header {
      			padding-top: 3.3125em;
      			padding-bottom: 3.3125em;
      			margin: 0 1.25em 1.25em 1.25em;
      			box-sizing: border-box;
      			
      			p {
                    @include typeface(sans-serif);
      				// font-family: "Gill Sans Light", sans-serif;
      				text-align: center;
      				font-size: 1.625em;
      				margin: 0 0.96154em 1.26923em 0.96154em;
      			}
      			
      			&-title {
      				h1 {
      					text-align: center;
      					text-transform: uppercase;
      					font-size: 3.375em;
      					line-height: 1em;
      					margin-bottom: 0.2963em;
      					
      					em {
      						text-transform: none;
      						font-weight: normal;
      						display: block;
      					}
      				}
      			}
      			&-footer {
      				border-top: 1px solid #e0e0e0;
      				padding-top: 1.375em;
      				text-align: center;
      				
      				img {
      					display: inline-block;
      					height: 1.4375em;
      					margin: 0 auto;
      				}
      			}
        	}
        	
        	&-header-container {
        		position: relative;
        		width: 64.5em;
        		margin: 0 auto;
        		max-width: 100%;
        	}
        }
    }
    .mblog-category-header-container {
        position: relative;
        margin: 6.75em auto 110px auto;
        padding: 0 1.25em;
    }
    .cu-grid-item {
        width: 25%;
        text-align: center;
        background-color: transparent;
        overflow: hidden;
        padding: 0 0.75em 1.25em 0.75em;
        box-sizing: border-box;
        position: relative;
    }
    .mblog-gallery {
        display: none;
        clear: both;
        position: relative;
        max-width: 42.5em;
        margin: 3.5625em auto 0 auto;
        .bx-viewport {
            margin-bottom: 3.75em;
        }
        ul {
            margin-left: 0;
            top: 50%;
            li {
                list-style-type: none;
                padding-left: 0;
                border: 1px solid #ffffff;
            }
        }
        .jcarousel-prev {
            position: absolute;
            left: -3.75em;
            bottom: 43%;
            a {
                display: block;
                height: 4.375em;
                width: 1.875em;
            }
        }
        .jcarousel-next {
            position: absolute;
            right: -3.75em;
            bottom: 43%;
            a {
                display: block;
                height: 4.375em;
                width: 1.875em;
            }
        }
    }
    .mblog-blockquote-container {
        width: 21.875em;
        float: right;
        clear: right;
        margin: 0 0 1.5625em 1.5625em;
        position: relative;
        box-sizing: border-box;
        .mblog-blockquote-symbol {
            width: 3em;
            height: 2.625em;
            background-size: cover;
            background-image: url(../images/mblog/quote-sign_72x60-white.png);
            background-color: #767676;
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 0.4375em;
        }
        .mblog-blockquote {
            font-family: BentonModernDisp, serif;
            font-style: italic;
            font-size: 1.875em;
            padding: 0.6em 0 0 1.76667em;
            margin-bottom: 0.26667em;
            line-height: 1.4;
            color: #767676;
        }
        .mblog-blockquote-author {
            clear: right;
            float: right;
            display: block;
            font-size: 1.125em;
            font-style: italic;
            font-weight: bold;
            font-family: BentonModernDisp, serif;
            color: #767676;
            letter-spacing: .04em;
        }
    }
    .mblog-product-item {
        background-color: #ffffff;
        padding: 0.9375rem 1.125rem 1.25rem 1.125rem;
        position: relative;
        margin: 0 auto;
        box-sizing: border-box;
        width: 15em;
        height: 22.25em;
        border: 1px solid #e0e0e0;
        overflow: hidden;
        
        .productitem {
            font-size: 0.75rem;
            .productimg {
                padding: 0.625rem 0 0.4375rem 0;
                line-height: 0px;
                text-align: center;
                height: 13.75rem;
                width: 202px;
                display: block;
                margin-top: 0;
                vertical-align: middle;
                .image {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    font-size: 0;
                    box-sizing: border-box;
                    a:before {
                        content: " ";
                        display: inline-block;
                        width: 0;
                        vertical-align: middle;
                        height: 100%;
                   }
                }
            }
            .producttext .productname + div {
                white-space: normal;
            }
        }
    }
    .mblog-article-factbox-container {
        width: 20.5em;
        box-sizing: border-box;
        border: 1px solid #e0e0e0;
        float: right;
        clear: right;
        padding: 1.875em 2.1875em 1.125em 2.1875em;
        margin: 0 0 1.5625em 1.5625em;
        h3 {
            font-size: 18px;
            padding-bottom: 1em;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 1.125em;
        }
        .mblog-text {
            font-size: 1.125em;
            margin-bottom: 1.38889em;
            line-height: 1.55556em;
            letter-spacing: 0.02em;
        }
    }
    .mblog-article-shopper-container {
        border: 1px solid #e0e0e0;
        margin: 0 0 1.5625em 1.5625em;
        text-align: center;
        float: right;
        clear: right;
        box-sizing: border-box;
        width: 20.5em;
        .mblog-article-shopper {
            padding: 1.5625em 1.5625em 0 1.5625em;
            .mblog-article-shopper-topimage {
                margin-bottom: 1.5625em;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            h3 {
                font-size: 1.5em;
                font-family: BentonModernDisp, serif;
                font-weight: bold;
                margin-bottom: 0.66667em;
            }
        }
        .mblog-article-shopper-footer {
            background-color: #f6f6f6;
            padding: 1.25em 0;
        }
    }
    .mblog-article-shopper-container-left {
        float: left;
        clear: left;
        margin: 0 1.5625em 1.5625em 0;
    }
    .mblog .mblog-shop-collection {
        width: 15em;
        margin: 0.5em 0 1.25em 1.25em;
        float: right;
        clear: right;
        img {
            display: inline-block;
            width: 100%;
            height: auto;
        }
    }
    ul.mblog-article-recipe {
        float: right;
        clear: right;
        width: 15em;
        margin: 0 0 1.5625em 1.5625em;
        box-sizing: content-box;
        li {
            font-size: 0.9375em;
            padding-bottom: 0.66667em;
            margin-bottom: 0.66667em;
            border-bottom: 1px solid #e0e0e0;
            list-style: none;
            padding-left: 0;
            line-height: 1.33333em;
            &:first-child {
                font-weight: bold;
                font-size: 1em;
                padding-bottom: 0.625em;
                margin-bottom: 0.625em;
                line-height: 1.4;
            }
        }
    }
    ul.mblog-article-recipe-left {
        float: left;
        clear: left;
        margin: 0 1.5625em 1.5625em 0;
    }
    .productsplashcontainer {
        position: absolute;
        font-family: $font-serif;
        width: auto;
        right: 1.5em;
        .productsplash {
            float: right;
            padding: 0;
            font-size: 0.75rem;
            background-color: #ffffff;
            color: #a3988b;
            text-transform: uppercase;
            margin: 0 0 0 5em;
        }
    }
    .mblog-collage {
        position: relative;
        width: 100%;
        margin-bottom: 1.875em;
        .collage-image {
            width: 100%;
            height: auto;
        }
        .collage-hotspot {
            font-size: 1.5625em;
            line-height: 2em;
            margin-left: -1em;
            margin-top: -1em;
            position: absolute;
            text-align: center;
            width: 2em;
            cursor: pointer;
            /* background-color: rgba(255, 105, 65, 0.5); */
            &:before {
                color: #292929;
                content: "+";
            }
            .collage-quickview {
                position: relative;
                z-index: 100;
            }
            .mblog-product-item {
                font-size: 0.64em;
                font-weight: normal;
            }
        }
    }
    .mblog-category-header {
        margin-left: 3.4375em;
        margin-bottom: 1.25em;
        background-color: #ffffff;
        padding: 0.875em;
        width: 18.4375em;
        float: left;
        box-sizing: border-box;
        .mblog-category-header-title {
            padding: 2.5em 1.5625em 1.3125em 1.5625em;
            border: 1px solid #e0e0e0;
            h1 {
                text-align: center;
                text-transform: uppercase;
                font-size: 2.75em;
                line-height: 1em;
            }
            img {
                display: block;
                margin: 0.3125em auto 0 auto;
                text-align: center;
                height: 2em;
            }
        }
    }
    .mblog-content-width {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
    .bx-wrapper {
        position: relative;
        margin: 0 auto 4.1875em;
        padding: 0;
        img {
            max-width: 100%;
            display: block;
            left: 50%;
            transform: translate(-50%, -50%);
            position: relative;
        }
        .bx-pager {
            text-align: center;
            color: #292929;
            position: absolute;
            bottom: -2.75em;
            width: 100%;
            .bx-pager-item {
                display: inline-block;
            }
            &.bx-default-pager{
                a {
                    text-indent: -9999px;
                    display: block;
                    outline: 0;
                    width: 0.3125em;
                    height: 0.3125em;
                    margin: 0 0.3125em;
                    outline: 0;
                    border: 1px solid #a3988b;
                    -moz-border-radius: 0.21875em;
                    -webkit-border-radius: 0.21875em;
                    border-radius: 0.21875em;
                    &.active {
                        background: #292929;
                        border: 1px solid #292929;
                    }
                }
            }
        }
    }
    .mblog-frontpage-header-menu-mobile {
        display: none;
        img {
            height: 3.375em;
            margin: 2.5em 0 0 2.5em;
        }
        .mblog-top-search-comp {
            float: right;
            font-size: 1.5em;
            height: 2.5em;
            margin: 1.58333em 1.66667em 0 0;
            &:last-child {
                margin-right: 1.66667em;
            }
        }
        .mblog-top-search-arrow {
            float: right;
            height: 100%;
            background-image: url(../images/mblog/down_arrow@2x.png);
            background-repeat: no-repeat;
            background-position: center right;
            margin-top: -0.16667em;
            width: 1.16667em;
            background-size: 0.66667em 0.41667em;
        }
        .dropdown {
            float: right;
            margin-top: 0.5em;
            ul {
                display: none;
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 1;
                background-color: #fafaf8;
                opacity: .9;
                text-align: right;
                margin-right: -1.75rem;
                width: 270%;
                padding: 1.5rem 0.4375rem 1.5rem 0.875rem;
            }
            >li {
                display: inline-block;
            }
            li {
                position: relative;
                display: block;
                list-style-type: none;
                margin: 0.83333em;
                a {
                    color: #292929;
                    display: block;
                    text-decoration: none;
                    white-space: pre;
                    text-transform: uppercase;
                }
            }
            span {
                display: block;
                text-decoration: none;
                white-space: pre;
                text-transform: uppercase;
            }
        }
    }
    .dropdown {
        float: right;
        ul {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1;
            background-color: #fafaf8;
            opacity: .9;
            text-align: right;
            margin-right: -1.75rem;
            width: 270%;
            padding: 1.5rem 0.4375rem 1.5rem 0.875rem;
            li {
                margin: 0.71429em;
                text-transform: uppercase;
            }
        }
        >li {
            display: inline-block;
        }
        li {
            position: relative;
            display: block;
            list-style-type: none;
            a {
                color: #292929;
                display: block;
                text-decoration: none;
                white-space: pre;
                text-transform: uppercase;
            }
        }
        span {
            display: block;
            text-decoration: none;
            white-space: pre;
            text-transform: uppercase;
        }
    }
    .mblog-header-background-desktop {
        position: absolute;
        margin: 0 auto;
        overflow: visible;
        width: 100%;
        top: 0;
        .mblog-header-background-desktop-inner {
            position: relative;
            width: 100%;
            overflow: hidden;
            img {
                position: relative;
                width: 1440px;
                margin-left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .mblog-header-background-mobile {
        display: none;
        position: absolute;
        height: 16.875em;
        .mblog-header-background-mobile-inner {
            position: relative;
            float: left;
            width: 40em;
            
            img {
            	position: relative;
            }
        }
    }
    .mblog-frontpage-header {
        margin: 0 auto 1.25em auto;
        background-color: transparent;
        position: relative;
        padding: 0 1.25em;
        white-space: nowrap;
        .mblog-frontpage-sidebar-container {
            width: 18.9375em;
            background-color: #ffffff;
            padding: 0.875em;
            float: left;
            margin-top: 3.125em;
            margin-bottom: 0.4375em;
            box-sizing: border-box;
            .mblog-frontpage-sidebar {
                border: 1px solid #e0e0e0;
                padding: 1.1875em 1.375em 1.375em 1.375em;
                text-align: center;
                overflow: hidden;
                .mblog-frontpage-sidebar-menu{
                    li {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 0.875em;
                        line-height: 2.39286em;
                        a {
                            color: #292929;
                        }
                    }
                }
                .mblog-frontpage-titlebar-mobile {
                    display: none;
                    span {
                        margin-bottom: 0.79167em;
                        padding-bottom: 0.58333em;
                    }
                    h1 {
                        font-size: 3.75em;
                        font-weight: normal;
                        line-height: 1em;
                    }
                }
                .mblog-frontpage-sidebar-top {
                    font-size: 0.8125em;
                    letter-spacing: 0.06154em;
                    padding-bottom: 1.38462em;
                    margin-bottom: 1em;
                    border-bottom: 1px solid #e0e0e0;
                    span {
                        text-transform: uppercase;
                        font-weight: bold;
                        color: #767676;
                    }
                }
                .mblog-frontpage-sidebar-description {
                    padding-bottom: 1.375em;
                    margin-bottom: 1.0625em;
                    border-bottom: 1px solid #e0e0e0;
                    img {
                        margin-bottom: 0.0625em;
                    }
                    p {
                        font-size: 0.875em;
                        margin: 0 1.42857em;
                        line-height: 1.5em;
                        white-space: normal;
                    }
                    li {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 0.875em;
                        line-height: 2.39286em;
                        a {
                            color: #292929;
                        }
                    }
                }
            }
        }
        .mblog-frontpage-titlebar {
            display: inline-block;
            padding-top: 8em;
            padding-left: 8em;
            width: 18.75em;
            text-align: center;
            overflow: hidden;
            .mblog-frontpage-titlebar-tag {
                padding-bottom: 0.5em;
                margin-bottom: 0.33333em;
                color: white;
                border-color: white;
            }
            h1 {
                font-size: 2.5em;
                line-height: 1em;
                color: #ffffff;
                em {
                    font-weight: normal;
                }
            }
            .cu-button {
                margin-top: 1.42857em;
                font-family: $font-sans-serif;
            }
        }
    }
    .cu-tag {
        font-family: Arial,sans-serif;
        font-size: 0.75rem;
        padding-bottom: 0.25rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #292929;
        border-bottom: 1px solid #292929;
        display: inline-block;
    }
    em {
        font-style: italic;
    }
    .cu-button, .dynamiccontentshowmore {
        display: inline-block;
        border: 1px solid #e0e0e0;
        text-transform: uppercase;
        background-color: #ffffff;
        font-size: 0.875em;
        box-sizing: border-box;
        border-radius: 0;
        outline: 0;
        height: 2.78571em;
        padding: 0.71429em 2.5em 0.57143em 2.5em;
    }
    .mblog-dynamic-search {
        padding-top: 0.375em;
        margin-bottom: 1.25em;
        border-bottom: 1px solid #e0e0e0;
        .mblog-dynamic-search-heading {
            font-size: 0.875em;
            a {
                color: #a3988b;
            }
        }
        .mblog-dynamic-search-comp {
            float: right;
            position: relative;
            display: inline-block;
            font-size: 0.875em;
            height: 2.14286em;
            margin-top: 0.07143em;
            margin-bottom: 0.5em;
            width: auto;
            &:last-child {
                margin-right: 3.14286em;
            }
        }
        .mblog-dynamic-search-arrow {
            width: 1rem;
            margin-top: -0.375rem;
        }
        .dropdown {
            float: right;
            &.openselect {
                ul {
                    display: block;
                }
            }
        }
    }
    .mblog-dynamic-search-arrow {
        float: right;
        height: 100%;
        background-image: url(../images/mblog/down_arrow@2x.png);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 0.5rem 0.3125rem;
    }
    .mblog-content {
        position: relative;
        margin: 0 auto;
        p {
            margin-bottom: 1.5625em;
        }
        .mblog-content-width {
            max-width: 64.5em;
            margin-left: auto;
            margin-right: auto;
        }
        .cu-grid-item {
            width: 24.9%;
            text-align: center;
            background-color: transparent;
            overflow: hidden;
            padding: 0 0.75em 1.25em 0.75em;
            box-sizing: border-box;
            position: relative;
            
            &--width2 {
            	width: 49.8%;
            }
            
            .cu-grid-item-content {
                font-size: 0.875rem;
                background-color: #ffffff;
                padding-bottom: 1.25rem;
            }
            .cu-tag {
                font-size: 0.75rem;
                margin: 1.25rem 0.9375rem 0 0.9375rem;
            }
            .card-headline {
                font-size: 1.25rem;
                margin: 0.8125rem 0.9375rem 0 0.9375rem;
                line-height: 1.5rem;
            }
            .card-text {
                font-size: 0.875rem;
                font-family: Arial,Helvetica,sans-serif;
                margin: 0.4375rem 0.9375rem 0 0.9375rem;
                line-height: 1.25rem;
                letter-spacing: 0.02em;
            }
            .cu-teaser-image-container {
                overflow: hidden;
                position: relative;
                width: 100%;
                height: auto;
                .cu-teaser-image-window {
                    position: static;
                    &.mblog-focus-teaser-mobile {
                        display: none;
                    }
                    &.mblog-focus-related-mobile {
                        display: none;
                    }
                    &.mblog-focus-related-desktop {
                        display: none;
                    }
                    img {
                        width: 100%;
                        display: block;
                    }
                }
            }
            .cu-grid-item-content{
                .cu-grid-item-content--desktop, .cu-grid-item-content--mobile, .cu-grid-item-content--related-desktop, .cu-grid-item-content--related-mobile {
                    display: block;
                }
            }
        }
    }
    .dynamiccontentshowmore {
        margin-top: 2.64286em;
        margin: 2.5em 0 3.92857em 0;
        display: inline-block;
        border: 1px solid #e0e0e0;
        text-transform: uppercase;
        background-color: #ffffff;
        font-size: 0.875em;
        box-sizing: border-box;
        border-radius: 0;
        outline: 0;
        height: 2.78571em;
        padding: 0.71429em 2.5em 0.57143em 2.5em;
        font-family: $font-sans-serif;
    }
    .cu-grid-dynamic {
        text-align: center;
    }
    .mblog-article-container {
        background-color: #ffffff;
        .mblog-article-content {
            max-width: 42.5em;
            margin: 0 auto;
            font-family: $font-sans-serif;
            h3 {
                font-family: $font-sans-serif;
                font-weight: bold;
                &:before {
                    content: "";
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
            }
            .mblog-totop {
                position: fixed;
                bottom: 1.875em;
                right: 1.875em;
                background: transparent url(../images/mblog/to_top_arrow.png) top center/0.5625em 0.25em no-repeat;
                padding-top: 0.625em;
                z-index: 1000;
                p {
                    font-family: Arial,sans-serif;
                    font-size: 0.75em;
                    width: 3.33333em;
                    color: #767676;
                    text-align: center;
                }
            }
            li {
                font-size: 1.125rem;
                padding-left: 0.55556rem;
                margin-bottom: 1.38889rem;
                line-height: 1.55556rem;
            }

            .mblog-article-column {
                width: 50%;
                float: left;
                padding: 0 0.75em;
                box-sizing: border-box;
                &:nth-child(1) {
                    padding-left: 0;
                }
                &:nth-child(2) {
                    padding-right: 0;
                }
                .mblog-image-container-wide1032, .mblog-image-container-wide856 {
                    width: 100%;
                    margin-left: 0;
                }
            }            
            
        }
        .mblog-product-component-container {
            margin-bottom: 0em;
            width: auto;
            float: right;
            margin-left: 1.25em;
            clear: right;
            &.mblog-product-component-container-left {
                .li {
                    padding-left: 0;
                }
            }
            li {
                padding: 0;
                margin: 0;
                .mblog-product-item {
                    display: inline-block;
                    margin: 0 0.625em 1.25em 0.625em;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                       margin-right: 0;
                    }
                }
            }
        }
        .mblog-product-component-container-left {
            float: left;
            clear: left;
            margin-right: 1.25em;
            margin-left: 0;
        }
        .mblog-product-component-container-center {
            float: none;
            clear: both;
            text-align: center;
            margin-left: 0;
            li {
            	display: inline-block;
            }
        }
        .mblog-product-carousel-wrapper {
            position: relative;
            margin: 0 auto 60px;
            padding: 0;
            .mblog-product-carousel {
                margin: 0 -11em;
                position: relative;
                >h3 {
                    font-family: Arial,sans-serif;
                    font-weight: bold;
                    font-size: 1.25em;
                    margin-bottom: 1.25em;
                    display: none;
                }
                .mblog-product-carousel-wrapper {
                    width: 100%;
                    position: relative;
                    margin: 0 auto 60px;
                    padding: 0;
                }
                .bx-viewport {
                    margin-bottom: -24px;
                }
                .mblog-product-carousel-slider {
                    margin-left: 0;
                    .mblog-product-carousel-image{
                       img{
                    min-height: 355px;
                       }
                    
                    } 
                    li {
                        list-style-type: none;
                        font-size: 1rem;
                        padding-left: 0;
                        line-height: normal;
                        font-family: Arial,Helvetica,sans-serif;
                    }
                }
                .jcarousel-prev {
                    position: absolute;
                    left: -3.75em;
                    bottom: 35%;
                    a {
                        display: block;
                        height: 4.375em;
                        width: 1.875em;
                    }
                }
                .jcarousel-next {
                    position: absolute;
                    right: -3.75em;
                    bottom: 35%;
                    a {
                        display: block;
                        height: 4.375em;
                        width: 1.875em;
                    }
                }
            }
        }
    }
    .mblog-seocomponent {
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        margin: 0 auto;
        background-color: white;
        .mblog-seocomponent-container {
            margin: 0 auto;
            .mblog-seocomponent-content {
                padding-top: 3.875em;
                padding-bottom: 3.875em;
                h2 {
                    font-size: 2.125em;
                    margin-bottom: 0.35294em;
                    text-align: left;
                }
                p {
                    font-size: 0.875em;
                    margin-bottom: 1.64286em;
                    max-width: 42.85714em;
                    line-height: 1.5em;
                }
            }
        }
    }
    .mblog--article-body {
        background-color: #ffffff;
        .mblog-image-container-wide680, .mblog-image-container-wide856, .mblog-image-container-wide1032 {
            margin: 1.5625em auto 0 auto;
        }
        .mblog-image-container-wide680 {
            max-width: 42.5em;
        }
        .mblog-image-container-wide856 {
            width: 53.5em;
            margin-left: -5.5em;
        }
        .mblog-image-container-wide1032 {
            width: 64.5em;
            margin-left: -11em;
        }
        .mblog-image-container {
            margin-bottom: 1.5625em;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
            p {
                font-size: 1em;
                line-height: 1.1em;
                margin-top: 0.5em;
                letter-spacing: 0.02em;
                font-style: italic;
                color: #767676;
            }
        }
        .mblog-article-header-container {
            .mblog-image-container{
                &.mblog-image-container-wide856, &.mblog-image-container-wide1032 {
                    margin-left: auto;
                }
            }
        }
        .mblog-article-header-headlines {
            text-align: center;
            margin-top: 3.1875em;
            box-sizing: border-box;
            margin-bottom: 1.25em;
            .cu-tag {
                font-size: 0.75em;
                padding-bottom: 0.5em;
                margin-bottom: 1.83333em;
            }
            h1 {
                font-size: 3.375em;
                margin-bottom: 0.48148em;
                line-height: 1em;
                em {
                    display: block;
                    font-weight: normal;
                }
            }
            p {
                @include typeface(sans-serif);
                // font-family: "Gill Sans Light",sans-serif;
                font-size: 1.625em;
                line-height: 1.34615em;
            }
        }
        .mblog-article-width {
            max-width: 42.5em;
            margin-left: auto;
            margin-right: auto;
        }
        .mblog-article-content{
            max-width: 42.5em;
            margin: 0 auto;
            font-family: $font-sans-serif;
            .mblog-text {
                font-size: 1.125em;
                margin-bottom: 1.6050em;
                line-height: 1.55556em;
                letter-spacing: 0.02em;
                p {
                    font-size: 1.125rem;
                    margin-bottom: 0;
                    line-height: 1.75rem;
                }
                .mblog-dropcaps-letter {
                    display: block;
                    float: left;
                    font-size: 3.625rem;
                    padding-top: 0.1875rem;
                    margin: 0 0.3125rem 0 -0.0625rem;
                    line-height: 3rem;
                }
                
                &[sc-part-of="placeholder rendering"] {
                	span {
                		h3 {
                			&:before {
                				display: none;
                			}
                		}
                	}
                }
            }
            h3 {
                font-family: $font-sans-serif;
                font-weight: bold;
                &:before {
                    height: auto;
                }
            }
        }
        .mblog-article-profile-container {
            float: right;
            clear: right;
            box-sizing: border-box;
            width: 15em;
            padding: 1.375em;
            margin: 0 0 1.5625em 1.5625em;
            border: 1px solid #e0e0e0;
            text-align: center;
            .mblog-article-profile-header {
                color: #767676;
                font-size: 0.75em;
                font-weight: bold;
                font-family: Arial,Helvetica,sans-serif;
                padding-bottom: 1.25em;
                letter-spacing: .1em;
                border-bottom: 1px solid #e0e0e0;
                text-transform: uppercase;
            }
            .mblog-article-profile-content {
                margin-bottom: 0.91667em;
            }
        }
    }
    .mblog-related-carousel-container {
        display: none;
        border-bottom: 1px solid #e0e0e0;
        .mblog-related-carousel {
            position: relative;
            margin: 0 auto;
            max-width: 1032px;
            .mblog-related-carousel-wrapper {
                position: relative;
                margin: 0 auto 3.75em -1.375em;
                padding: 0;
                li {
                    border-right: 1px solid #e0e0e0;
                    .cu-grid-item {
                        margin-top: 1.625em;
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                        .cu-teaser-image-container {
                            position: absolute;
                            width: 7.0625em;
                            height: 10.4375em;
                            overflow: hidden;
                            .cu-teaser-image-window {
                                position: absolute;
                                img {
                                    height: 10.4375em;
                                    width: auto;
                                }
                            }
                        }
                        .cu-grid-item-content {
                            box-sizing: border-box;
                            height: 10.4375rem;
                            text-align: left;
                            padding-left: 8.125rem;
                            padding-bottom: 0;
                            background-color: transparent;
                            .cu-tag {
                                margin: 0.3125rem 0 0 0;
                            }
                            .card-headline {
                                font-size: 1.25rem;
                                margin: 0.5625rem 0.9375rem 0 0;
                                line-height: 1.6875rem;
                                text-align: left;

                            }
                            .card-text {
                                font-family: $font-sans-serif;
                                font-size: 0.9375rem;
                                margin: 0.625rem 0.9375rem 0 0;
                                line-height: 1.125rem;
                            }
                        }
                    }
                }
            }
            .jcarousel-prev {
                position: absolute;
                left: -3.75em;
                bottom: 37%;
                a {
                    display: block;
                    height: 4.375em;
                    width: 1.875em;
                }
            }
            .jcarousel-next {
                position: absolute;
                right: -3.75em;
                bottom: 37%;
                a {
                    display: block;
                    height: 4.375em;
                    width: 1.875em;
                }
            }
        }
        .mblog-related-carousel-mobile {
            display: none;
            .cu-grid-item {
                padding: 0 1.25em 1.25em 1.25em;
                margin-top: 1.25em;
                width: 100%;
                border-bottom: 1px solid #e0e0e0;
            }
        }
    }
    .mblog-related-carousel-wrapper .bx-viewport, .mblog-product-carousel-wrapper .bx-viewport, .bx-wrapper .bx-viewport {
        -webkit-transform: translatez(0);
        -moz-transform: translatez(0);
        -ms-transform: translatez(0);
        -o-transform: translatez(0);
        transform: translatez(0);
    }
    .mblog-footer {
        margin: 1.85em auto 3.75em;
        .mblog-mini-footer-container {
            clear: both;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            margin: 0 auto;
            background-color: #ffffff;
            .mblog-mini-footer {
                max-width: 64.5em;
                margin-top: 1.875em;
                margin-bottom: 1.875em;
                .mblog-mini-footer-date {
                    float: left;
                    font-size: 0.75em;
                    margin-right: 1.66667em;
                    font-style: italic;
                }
                .mblog-mini-footer-tag-list {
                    float: left;
                    font-size: 0.75em;
                    font-style: italic;
                    li {
                        float: left;
                        margin-right: 1.66667em;
                        a {
                            color: #292929;
                        }
                    }
                }
            }
        }
        .mblog-related-mini-footer-text {
            font-size: 1.25em;
            display: none;
            clear: both;
            padding-top: 2em;
        }
    }
    .mblog-youtube-container{
        margin-bottom: rem(48);

        iframe{
            width: 100% !important;
            height: 400px !important;
            margin-bottom: -26px;
        }
    }
}

#ajour-carousel-teaser-sitecore {
	.cu-grid-item {
		&.cu-grid-item-dynamic {
			&.cu-grid-item--width2 {
				width: 100%;
				max-width: 570px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
#ajour-megamenu-container-teaser-sitecore {
	width: 100%;
	background-color: #fff;
	color: #000;
	.submenuinner {
		max-width: 1440px;
		padding: 20px 20px 0 29px;
		margin: auto;
		box-sizing: border-box;
		border-top: 1px solid #e9e9e9;
		height: 452px;
		overflow: hidden;
		.area1 {
			width: 100%;
			.column {
				width: 25%;
				margin-right: -4px;
				display: inline-block;
				vertical-align: top;
				ul {
					padding: 0 0 18px 0;
					margin: 0;
					line-height: normal;
					display: block;
					li {
						margin: 0;
						font-family: $font-sans-serif;
						font-size: 13px;
						line-height: 19px;
						font-weight: 300;
						a {
							color: #292929;
							padding: 0;
							text-decoration: none;
						}
						&.category {
							font-size: 14px;
							font-weight: 600;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}
#ajour-megamenu-teaser-sitecore {
	width: 335px;
	height: 140px;
	.cu-grid-item {
		&.cu-grid-item-dynamic {
			width: 100%;
			.cu-teaser-image-container {
				display: inline-block;
				float: left;
				width: 90px;
				overflow: hidden;
				height: auto;
				img {
					width: auto;
					height: 135px;
					margin-left: 50%;
					transform: translateX(-50%);
				}
			}
			.cu-grid-item-content {
				display: inline-block;
				width: 180px;
				overflow: hidden;
				padding-left: 15px;
				float: left;
				box-sizing: border-box;
				height: 140px;
				text-align: left;
				.cu-tag {
					font-size: 14px;
					color: #292929;
					line-height: 16px;
					margin-bottom: 10px;
					text-transform: uppercase;
					font-family: $font-sans-serif;
					border-bottom: 2px solid;
					display: inline-block;
					padding: 0;
				}
				.card-headline {
					font-size: 12px;
					line-height: 20px;
					background-color: transparent;
					color: #292929;
					font-weight: bold;
					display: block;
					margin-bottom: 10px;
					overflow: hidden;
					position: relative;
					text-transform: none;
					font-family: $font-serif;
					text-align: left;
				}
				.card-text {
					font-size: 12px;
					color: #292929;
					line-height: 15px;
					overflow: hidden;
					position: relative;
					text-align: left;
				}
			}
		}
	}
}

@media screen and (min-width: 1367px) {
    .mblog .dropdown li:hover > ul {
        display: block;
    }
}


@media screen and (max-width: 1200px){
    .mblog{
        .mblog-related-carousel-container {
            .mblog-related-carousel {
                max-width: 682px;
            }
        }
        .mblog-content{
            .mblog-product-carousel {
                max-width: 48em;
            }
        }
        .mblog-article-container{
            .mblog-image-container-wide1032 {
                width: 53.5em;
                margin-left: -5.5em;
            }            
            .mblog-product-carousel-wrapper{
                .mblog-product-carousel {
                    margin: 0 -2.75em;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .mblog{
        .mblog-content-width {
            margin-left: 1.25em;
            margin-right: 1.25em;
        }
        .mblog-article-header-container {
            .mblog-image-container-wide856, .mblog-image-container-wide1032 {
                width: 100%;
            }
        }
        .mblog-content {
            .cu-grid-item {
                width: 33.3%;
                
	            &--width2 {
	            	width: 66.6%;
	            }
            }
            .mblog-product-carousel {
                max-width: 32em;
            }
            .mblog-article-container{
                .mblog-image-container-wide856, .mblog-image-container-wide1032 {
                    width: 100%;
                    margin-left: 0;
                }
                .mblog-product-carousel-wrapper{
                    .mblog-product-carousel {
                        margin: 0 5em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px){
    .mblog{
        .mblog-related-carousel-container {
            .mblog-related-carousel {
                max-width: 352px;
                    .mblog-related-carousel-wrapper li {
                        border-right: 1px solid #ffffff;
                    }
            }
        }
    }
}
@media screen and (max-width: 640px){
    .mblog{
		.mblog-product-carousel-slider{
    		.mblog-product-carousel-image{
    		 	img{
    			 min-height: 0px !important;			
    			}
    		}		 
    	}
//        .mblog-article-container{ 
//        	.mblog-product-carousel-wrapper{ 
//        		.mblog-product-carousel{ 
//        			.bx-viewport{
//        			    margin-bottom: 3.75em;
//        			}
//        		}		
//    		}
//    	}	
        .mblog-header {
        	&-border {
        		&-outer {
        			max-width: 90%;
        		}
        		&-inner {
        			padding: 1.8125em 1.8125em 0.875em 1.825em;
        		}
        	}
        
            .mblog-header-background-desktop {
                display: none;
            }
            .mblog-header-background-mobile {
                display: block;
                overflow: hidden;
                width: 100%;
                height: 100%;
            }
            .mblog-category-header-container {
                margin-top: 20px;
                margin-bottom: 0;
            }
            .mblog-theme-header {
            	p {
            		font-size: 0.825em;
            	}
	            &-title {
	            	h1 {
	            		font-size: 1.575em;
	            	}
	            }
			}
        }
//        .mblog-content {
//            padding: 0 5%;
//        	padding-top: 5%;
//
//        }
        .mblog-frontpage-header{
            .mblog-frontpage-titlebar {
                display: none;
            }
            .mblog-frontpage-sidebar-container {
                display: block;
                width: 100%;
                margin-top: 5.625em;
                margin-top: 12em;
                .mblog-frontpage-sidebar{
                    .mblog-frontpage-titlebar-mobile {
                        display: block;
                        left: 0;
                        top: 0;
                        .cu-button {
                            margin-top: 1em;
                        }
                        h1 {
                            font-size: 40px;
                        }
                    }
                    .mblog-frontpage-sidebar-top {
                        display: none;
                    }
                    .mblog-frontpage-sidebar-description {
                        display: none;
                    }
                    .mblog-frontpage-sidebar-menu li {
                        display: none;
                    }
                }
            }
        }
        .mblog-frontpage-header-menu-mobile {
            display: block;
            height: 4em;
            box-sizing: border-box;
            img {
                height: 27px;
                margin: 20px 0 0 20px;
            }
            .mblog-top-search-comp {
                float: right;
                font-size: 12px;
                height: 2.5em;
                margin: 1.58333em 1.66667em 0 0;
                &:last-child {
                    margin-right: 1.66667em;
                }
                .mblog-top-search-arrow {
                    float: right;
                    height: 100%;
                    background-image: url(../images/mblog/down_arrow@2x.png);
                    background-repeat: no-repeat;
                    background-position: center right;
                    margin-top: 0;
                    width: 1.16667em;
                    background-size: 0.66667em 0.41667em;
                }
                .dropdown {
                    float: right;
                    margin-top: 0.25em;
                    li {
                        position: relative;
                        display: block;
                        list-style-type: none;
                        margin: 5px;
                        span {
                            display: block;
                            text-decoration: none;
                            white-space: pre;
                            text-transform: uppercase;
                        }
                    }
                    ul {
                        display: none;
                        position: absolute;
                        top: 100%;
                        right: 0;
                        z-index: 1;
                        background-color: #fafaf8;
                        opacity: .9;
                        text-align: right;
                        margin-right: -1.75rem;
                        width: 270%;
                        padding: 1.5rem 0.4375rem 1.5rem 0.875rem;
                        li {
                            position: relative;
                            display: block;
                            list-style-type: none;
                            margin: 0.83333em;
                            a {
                                color: #292929;
                                display: block;
                                text-decoration: none;
                                white-space: pre;
                                text-transform: uppercase;
                            }
                        }
                    }
                    &.openselect{
                        ul {
                            display: block;
                        }
                    }
                }
            }
        }
        .mblog-content {
            .cu-grid-item {
                width: 49.9%;
                
	            &--width2 {
	            	width: 99.8%;
	            }
            }
        }
        .mblog-footer{
            .mblog-mini-footer-container {
                border-bottom: 0;
            }
            .mblog-related-mini-footer-text {
                display: block;
                font-family: BentonModernDisp,serif;
                padding: 0 0 0.6em 1em;
                border-bottom: 1px solid #e0e0e0;
            }
        }
        .mblog-related-carousel-container {
            .mblog-related-carousel {
                display: none;
            }
            .mblog-related-carousel-mobile {
                display: block;
                border-right: 0;
                li {
                    padding: 0;
                    .cu-grid-item {
                        padding: 0 1.25em 1.25em 1.25em;
                        margin-top: 1.25em;
                        width: 100%;
                        border-bottom: 1px solid #e0e0e0;
                        box-sizing: border-box;
                        .cu-teaser-image-container {
                            position: absolute;
                            width: 5.625em;
                            height: 8.3125em;
                            overflow: hidden;
                            .cu-teaser-image-window {
                                position: absolute;
                                img {
                                    height: 8.3125em;
                                    width: auto;
                                }
                            }
                        }
                        .cu-grid-item-content {
                            box-sizing: border-box;
                            height: 8.3125rem;
                            text-align: left;
                            padding-left: 6.625rem;
                            background-color: transparent;
                            padding-bottom: 0;
                            .cu-tag {
                                margin: 0.5625rem 0 0 0;
                            }
                            .card-headline{
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
        .mblog-content{
            .mblog-product-carousel {
                max-width: 16em;
            }
        }
        .mblog-article-container{
            .mblog-product-carousel-wrapper{
                text-align: -webkit-center;
                width: 110%;
                margin-left: -5%;
                overflow: hidden;
                .mblog-product-carousel {
                    margin: 0 0;
                    max-width: 100%;
                    .bx-viewport {
                        overflow: visible !important;
                        .mblog-product-carousel-slider {
                            white-space: nowrap;
                            li {
                                display: inline-block;
                                float: none !important;
                                vertical-align: middle;
                            }
                        }
                    }
                    
                }
                .jcarousel-prev {
                    display: none;
                }
                .jcarousel-next {
                    display: none;
                }
            }
            .mblog-gallery {
                li {
                    padding: 0;
                }
                .jcarousel-prev, .jcarousel-next {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 480px){
    .mblog{
      .mblog--article-body{ 
      
         .mblog-article-profile-container{
    		clear: right;
    		box-sizing: border-box;
   		    width: 100%;
    		padding: 1.375em;
    		margin: 0 0 1.5625em 1.5625em;
    		border: 1px solid #e0e0e0;
    		text-align: center;
    		margin-left: 41px;
         }
      }   
        .mblog-frontpage-header{
            .mblog-frontpage-sidebar-container {
                .mblog-frontpage-sidebar{
                    .mblog-frontpage-titlebar-mobile {
                        h1 {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
        .mblog-content {
            .cu-grid-item {
                width: 100%;
            }
        }
        .mblog-youtube-container{
            margin-bottom: rem(36);

            iframe{
                width: 100% !important;
                height: 200px !important;
            }
        }
        .mblog-article-column {
            width: 100%;
            float: none;
            padding: 0;
            box-sizing: border-box;
        }
        .mblog-article-container{
            .mblog-product-component-container {
                float: none;
                margin: 0;
                li {
                    text-align: center;
                }
            }
        }
        .mblog-article-factbox-container {
            width: 100%;
            float: none;
            margin: 0 0 1.5625em 0;
        }
        .mblog-article-shopper-container {
            margin: 0 0 1.5625em 0;
            float: none;
            width: 100%;
        }
        .mblog{
            .mblog-shop-collection {
                width: 100%;
                margin: 0.5em 0 1.25em 0;
                float: none;
            }
        }
        ul.mblog-article-recipe {
            width: 100%;
        }
    }
}




.cu-grid {
	.cu-grid-item-content--mobile, .cu-grid-item-content--related-desktop, .cu-grid-item-content--related-mobile {
		display: none;
	}
}
.mblog-related-carousel-wrapper {
	.cu-grid-item-content--mobile, .cu-grid-item-content--related-desktop, .cu-grid-item-content--related-mobile {
		display: none;
	}
}

@media screen and (max-width: 768px){
	.cu-grid-item-content.cu-grid-item-content--mobile,
	.cu-grid-item-content.cu-grid-item-content--related-desktop,
	.cu-grid-item-content.cu-grid-item-content--related-mobile {
		display: none;
	}
	.cu-grid {
		.cu-grid-item-content--mobile, .cu-grid-item-content--related-desktop, .cu-grid-item-content--related-mobile {
			display: none;
		}
	}
	.mblog-related-carousel-wrapper {
		.cu-grid-item-content--mobile, .cu-grid-item-content--related-desktop, .cu-grid-item-content--related-mobile {
			display: none;
		}
	}
}

.mblog {
    .mblog-product-carousel-container {
        position: relative;
        margin: 0 -176px rem(48);
        .product-carousel-header-container {
            margin-bottom: 15px;
        }
        .mblog-product-owl-carousel {
            .mblog-productitem-container {
                .mblog-productitem.productitem { //Brandstore!
                    font-size: 0;
                    line-height: 0;
                    height: 357px;
                    .mblog-product-carousel-image {
                        display: block;
                        height: 100%;
                        &:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            width: 0;
                            vertical-align: middle;
                        }
                        img {
                            display: inline-block;
                            vertical-align: middle;
                            height: auto;
                        }
                    }
                }
                li {
                    padding: 0;
                    margin: 0;
                    .mblog-product-item {
                        box-sizing: border-box;
                        position: relative;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: auto;
                        box-sizing: border-box;
                        text-align: center;
                        margin: 0;
                        padding: 15px 18px 20px 18px;
                        .productitem {
                            height: 320px;
                            .productimg {
                                position: relative;
                                display: block;
                                line-height: 0;
                                text-align: center;
                                margin: 0 auto;
                                width: auto;
                                height: auto;
                                padding: 0;
                                &:after {
                                    content: "";
                                    display: block;
                                    padding-top: 109%;
                                }
                                .image {
                                    display: flex;
                                    position: absolute;
                                    max-height: none;

                                    a {
                                        width: 100%;
                                        height: 100%;
                                        min-height: 205px;
                                        display: -webkit-box;
                                        display: -ms-flexbox;
                                        display: flex;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        align-items: center;
                                        -webkit-box-pack: center;
                                        -ms-flex-pack: center;
                                        justify-content: center;
                                        padding: 0;
                                        &:before {
                                            content: "";
                                            display: inline-block;
                                            height: 100%;
                                            width: 0;
                                            vertical-align: middle;
                                        }
                                        img {
                                            display: block;
                                            margin: auto;
                                            width: auto;
                                            height: auto;
                                            max-width: 100%;
                                            max-height: 100%;
                                            box-sizing: border-box;
                                        }
                                    }

                                }
                            }
                            .productbadge {
                                display: none;
                            }
                            .producttext {
                                margin: 14px auto 0 auto;
                                .productname {
                                    white-space: normal;
                                }
                            }
                            
                        }
                    }
                }
            }
            .owl-nav {
                .owl-prev {
                    display: block;
                    width: 30px;
                    height: 70px;
                    position: absolute;
                    top: calc(50% - 35px);
                    right: calc(100% + 30px);
                    cursor: pointer;
                    background: transparent url(../images/carousel_next.png) no-repeat 0 0;
                }
                .owl-next {
                    display: block;
                    width: 30px;
                    height: 70px;
                    position: absolute;
                    top: calc(50% - 35px);
                    left: calc(100% + 30px);
                    cursor: pointer;
                    background: transparent url(../images/carousel_next.png) no-repeat 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .mblog {
        .mblog-product-carousel-container {
            position: relative;
            margin: 0 -44px rem(36);
        }
    }
}

@media screen and (max-width: 1024px) {
    .mblog {
        .mblog-product-carousel-container {
            position: relative;
            margin: 0 80px rem(36);
        }
    }
}

@media screen and (max-width: 767px) {
    .mblog {
        .mblog-content {
            padding: 0 5%;
        	padding-top: 5%;
            
            .mblog-product-carousel-container {
                position: relative;
                margin: 0 auto rem(36);
                width: 100%;
                .mblog-product-carousel-window {
                    overflow: hidden;
                    width: 105%;
                    .mblog-product-owl-carousel {
                        width: 80%;
                        .owl-stage-outer {
                            overflow: visible;
                        }
                        .mblog-productitem-container {
                            .mblog-productitem.productitem { //Brandstore!
                                height: auto;
                            }
                            li {
                                .mblog-product-item {
                                    border-width: 0;
                                    .productitem {
                                        height: auto;
                                        .productimg {
                                            .image a {
                                                min-height: 0;
                                            }
                                        }
                                        .producttext {
                                            margin: 10px auto 0 auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}


@media screen and (max-width: 640px) {
    .mblog {
        .mblog-product-carousel-container {
            position: relative;
            margin: 0 auto rem(24);
            width: 105%;
        }
    }
}
