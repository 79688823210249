.register-container {
	@include typeset(body-small);
	// font-size: 16px;
	// line-height: normal;

	max-width: 750px;
	margin: auto;
	padding: 50px 0px 50px 0;

	.headline {
		@include typeface(serif);
		@include typeset(display-3);
		// font-family: Georgia;
		// font-size: 20px;
		// font-style: italic;
		// line-height: 35px;
		text-align: center;

		margin-top: 15px;
	}

	.goodieappbox {
		padding: 30px;
		margin-top: 25px;

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
		}

		.mobile.phone.image {
			display: inline-block;
			width: 50%;
			float: left;
			padding-right: 26px;
			box-sizing: border-box;
			text-align: center;
		}

		.AppStore.links {
			display: inline-block;
			width: 50%;
			float: left;

			.linksdescription {
				@include typeface(serif);
				// font-family: Georgia;
				text-align: center;
				font-style: italic;
				font-size: 18px;
				padding: 55px 0 30px;
				line-height: 30px;
			}

			.AppleAppStore {
				float: right;
				width: 43.38%;
				text-align: center;
			}

			.GooglePlay {
				float: left;
				width: 43.38%;
				text-align: center;
			}
		}
	}

	.creategoodieform {
		padding: 20px 20px 45px;
		background-color: #f2f2f2;
		margin-bottom: 30px;
		position: relative;

		.headline {
			text-align: left;
			margin-top: 5px;
			margin-bottom: 25px;
		}

		.form-row {
			display: inline-block;
			width: 37%;
			box-sizing: border-box;
			padding-right: 20px;

			.leftfloat {
				float: none;

				input {
					width: 100%;
					height: 42px;
					border-radius: 3px;
					background-color: #ffffff;
					border: 1px solid #797979;
					margin: 0;
					box-sizing: border-box;
					padding: 13px;
				}
			}

			.label {
				@include typeset(body-small);
				// line-height: 16px;
				// font-size: 13px;
				font-weight: bold;
				float: none;
				width: auto;
				display: inline-block;
				padding-bottom: 8px;
			}
		}

		.continuebutton {
			float: none;
			display: inline-block;
			margin: 0;
			width: 24%;

			.button {
				margin: 0;

				.large.lockimg {
					background-image: none;
					margin: 0;

					button {
						@include typeset(body-normal);
						// font-size: 17px;
						// font-weight: normal;
						background-image: none;
						padding: 0;
						width: 100%;
						height: 42px;
						margin: 0;
						background-color: #65ba9c;
						border: none;
					}
				}
			}
		}

		.secondarylink {
			// font-family: Arial, Helvetica, sans-serif;
			// font-style: normal;
			// font-weight: normal;
			// text-align: right;
			display: block;
			padding-top: 10px;
			width: 26%;
			margin: 0 8px 0 auto;
			text-align: center;
			position: absolute;
		}
	}

	.register {
		#profile-create-login {
			padding: 0;
			margin: 0 -10px;
			width: 85%;

			&.googie-content {
				width: 100%;
				margin: 0;
				padding: 0;
			}
		}

		.button .large button {
			padding: 0;
		}

		.orderflowcontent {
			padding: 0;
			margin-right: 0;
			width: 100%;

			.subheader {

				//                 font-family: Arial,Helvetica,sans-serif;
				//                 font-size: 13px;
				//                 font-weight: bold;
				//                 font-style: normal;
				//                 text-decoration: none;
				//                 padding-left: 0;
				h2 {
					background-color: transparent;
					color: #292929;
					text-transform: inherit;
					padding: 0;
				}
			}

			>.subheader {
				text-align: center;
				text-transform: uppercase;
				padding-top: 28px;
			}

			.infotext {
				padding: 0 20px;
				font-style: normal;

				&.first {
					font-size: 13px;
					padding: 0 30px 0 20px;
					text-align: center;
					margin-bottom: 35px;
				}
			}

			.error-message {
				display: none;
				background-image: url(../images/errormessage_bg.gif);
				background-repeat: no-repeat;
				background-color: transparent;
				width: 303px;
				padding: 11px 10px 10px 42px;
				margin: 0px 0px 20px 146px;
				color: #ffffff;
				font-weight: bold;
				-moz-box-shadow: 0px 0px 12px -5px #000000;
				-webkit-box-shadow: 0px 0px 12px -5px #000000;
				box-shadow: 0px 0px 12px -5px #000000;
			}

			fieldset {
				border: none;
				padding: 0px;
				margin: 0px;

				.form-row {
					width: 50%;
					padding: 10px 10px;
					box-sizing: border-box;
					position: relative;
					display: inline-block;

					&.form-row--phone {
						.intl-tel-input {
							display: block;

							.flag-container {
								.selected-flag {
									width: 110px;
									background-color: #ffffff;

									.selected-dial-code {
										@include typeset(body-small);
										// font-size: 12px;
										// font-family: Arial;
										// font-weight: bold;
										// color: #292929;
									}

									.iti-arrow {
										width: 0;
										height: 0;
										border-left: 5px solid transparent;
										border-right: 5px solid transparent;
										border-top: 5px solid #333333;
										position: absolute;
										right: 14px;
										top: 50%;
										margin-top: -3px;
									}

									&:after {
										content: "";
										display: inline-block;
										position: absolute;
										right: 0;
										top: 0;
										box-sizing: border-box;
										height: 100%;
										width: 39px;
										border: 1px solid #797979;
										border-width: 0 1px;
									}
								}

								.country-list {
									width: 110px;
									overflow-y: auto;

									.active {
										background-color: #ffffff;
									}

									.divider {
										margin: 0;
										padding: 0;
										height: 7px;
									}

									.dial-code {
										@include typeset(body-small);
										color: $color-text;
										// font-size: 12px;
										// font-family: Arial;
										// font-weight: bold;
										// color: #292929;
									}
								}
							}

							.input-text {
								padding-left: 117px;
							}
						}

					}

					&.address {
						width: 100%;
					}

					&.birthday {
						width: 60%;
						float: left;
					}

					&.gender {
						width: 40%;
						float: left;
					}

					&.goodiesignup {
						width: 100%;

						// 					    background-color: transparent;
						// 					    padding: 0;
						// 					    margin-bottom: 32px;
						// 					    font-size: 13px;
						// 					    line-height: 17px;
						.goodielabelpadding {
							width: 90%;
						}
					}

					.required-indicator {
						float: none;
						margin-left: 0;
						color: #292929;
					}

					.input-text {
						width: 100%;
						height: 42px;
						background-color: #ffffff;
						border: 1px solid #797979;
						margin: 0;
						box-sizing: border-box;
					}

					.infolink {
						display: none;
					}

					div.error-validationtype2 {
						background-color: transparent;
						color: #fe3e64;
						float: left;
						margin: 0;
					}
				}

				&.permission {
					border-top: 1px solid #292929;
					border-bottom: 1px solid #292929;
					padding: 38px 0 31px 0;
					margin: 0 20px;

					.label {
						display: block;
						margin-bottom: 14px;
					}

					.form-row {
						width: 100%;
						position: static;
						padding: 0;

						label {
							>div {
								padding-left: 34px;
								font-size: 13px;
							}
						}
					}
				}
			}

			.label {
				//                 font-family: Arial,Helvetica,sans-serif;
				font-size: 13px;
				font-weight: bold;
				font-style: normal;
				text-decoration: none;
				padding-left: 0;
			}

			.infolink {
				@include typeface(serif);
				// font-family: Georgia, serif;
				// font-style: italic;
				// font-weight: bold;
				
				float: left;
				padding: 0px 0px 5px 10px;

				a {
					background-color: transparent;
					color: #292929;
					text-decoration: underline;
				}
			}
		}

		.advantages-container {
			padding: 0 34px 0 20px;

			.advantages {
				width: 50%;
				margin-bottom: 20px;
				float: left;

				.headingadvantages {
					font-weight: bold;
					font-size: 13px;
					padding: 37px 0 12px 0;
				}

				.advantage {
					line-height: 31px;
					padding-left: 23px;
					background: url(../images/sb_checkmark.png) left 7px/ 12px 15px no-repeat;
				}

				a {
					text-decoration: underline;
					display: inline-block;
					padding-top: 5px;
				}
			}

			.image-container {
				float: right;
				margin-top: 22px;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.existingcard {
			border-width: 0;
			background-color: #f2f2f2;
			padding: 5px 15px;
			margin-bottom: 14px;

			.subheader {
				padding-left: 10px;
			}
		}

		h1 {
			font-size: 24px;
			text-transform: none;
			padding-bottom: 17px;
			margin-top: 24px;
			border-bottom: 1px solid #292929;
			padding-left: 0;
		}
	}

	.crossprofileprompt & {
		// .register-container
		max-width: 780px;
		padding-top: 35px;
		text-align: left;

		.register {
			.crossprofileprompt-header {
				border-bottom: 1px solid #f4f4f4;
				text-align: center;
				padding-bottom: 40px;
				margin-bottom: 15px;

				h1 {
					font-family: $font-serif;
					font-weight: 500;
					font-size: 32px;
					font-style: normal;
					border-width: 0;
					margin: 0 0 27px;
					padding: 0;
				}

				.header-substext {
					max-width: 620px;
					margin: auto;
				}
			}

			.orderflowcontent {
				max-width: 620px;
				margin: 0 auto 42px;

				.form-row {
					.selector:after {
						z-index: 1;

						select {
							width: calc(100% + 1px);
						}
					}

					&.form-row--country-calling-code {
						display: none;
					}

					div.error-validationtype2 {
						font-size: 12px;
						line-height: 17px;
					}
				}

				#profile-create-login {
					width: 100%;
					margin: 0;
				}
			}

			.crossprofileprompt-bottom {
				text-align: center;

				.button-style:active {
					color: #ffffff;
				}
			}

		}
	}

	.crossprofileprompt.crossprofileprompt--registered & {

		// .register-container
		.register {
			.crossprofileprompt-header {
				border-width: 0;
				margin: 0;
			}

			.crossprofileprompt-bottom {
				.button-style {
					text-transform: uppercase;
					width: 285px;

					@media screen and (max-width: 640px) {
						width: 100%;
					}
				}
			}
		}
	}
}

body.iti-mobile .intl-tel-input.iti-container {
	.country-list {
		.country {
			.dial-code {
				color: #292929;
			}

			&.highlight {
				background-color: #ffffff;
			}
		}
	}
}

@media screen and (max-width: 640px) {
	.register-container .register #profile-create-login {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.crossprofileprompt {
		.register-container {
			.register {
				.crossprofileprompt-header {
					h1 {}

					.header-substext {}
				}

				.orderflowcontent {
					.form-row {}
				}

				.crossprofileprompt-bottom {}
			}
		}
	}
}

@media screen and (max-width: 570px) {
	.register-container {
		.goodieappbox {
			.mobile.phone.image {
				display: block;
				width: 100%;
				float: none;
				padding-right: 0;
				text-align: center;
				margin-bottom: 60px;
			}

			.AppStore.links {
				display: block;
				width: 100%;
				float: none;

				.linksdescription {
					display: none;
				}

				.AppleAppStore {
					float: right;
					width: 43.38%;
				}

				.GooglePlay {
					float: left;
					width: 43.38%;
				}
			}
		}

		.creategoodieform {
			.headline {
				margin-bottom: 0;
			}

			.form-row {
				display: block;
				width: 100%;
				padding-right: 0;

				.label {
					padding-top: 25px;
				}
			}

			.continuebutton {
				display: block;
				width: 100%;
				margin-top: 25px;
			}

			.secondarylink {
				text-align: center;
				width: 100%;
				margin: 0;
				text-align: center;
				position: absolute;
				bottom: 10px;
				left: 3px;
			}
		}

		.register {
			#profile-create-login {

				//				width: 100%;
				//			    margin: 0;
				//				padding: 0;
				&.googie-content {
					padding: 0;
				}
			}

			.advantages-container {
				padding: 0;

				.advantages {
					width: 100%;
					float: none;
				}

				.image-container {
					float: none;
				}
			}

			.orderflowcontent {
				fieldset {
					.form-row {
						width: 100%;
						padding: 10px 0;
						display: block;

						&.birthday,
						&.gender {
							width: 100%;
						}

						&.goodiesignup {
							padding: 10px;
						}
					}

					&.permission {
						margin: 0;
					}
				}
			}
		}
	}
}