// Textarea input
//
// Element to put in container.<br>
// A form element that lets the user enter a longer text. Will fill up it's full container's size.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/elements/input-textarea.twig
//
// Weight: 1
//
// Style guide: elements.input-textarea

.input-textarea {
    .new-font & {
        @include typeface(sans-serif);
    }
    @include typeset(body-normal);
    
    position: relative;
    height: 100%;
    margin-top: rem(24);
    
    label {
        transition: transform $transition-fast;
        transform-origin: left;

        position: absolute;
        top: rem(6);
        left: rem(12);

        color: $color-shadow;
    }
    
    textarea {
        resize: none;
        overflow: auto;

        width: 100%;
        // height: inherit;

        padding: rem(6) rem(12);
        
        border: 1px solid $color-border;
        box-sizing: border-box;

        &:focus {
            outline: none;
            border: 1px solid $color-border-hover;

            & + label {
                transform: scale(0.8) translate(-1em, -2.6em);
            }
        }
    }
}