.brandshead {
    max-width: 780px;
    h1 {
        font-family: $font-serif;
        padding: 0 30px 12px 30px;
        text-align: center;
        font-size: 36px;
        line-height: 40px;
        font-style: normal;
        font-weight: normal;
        white-space: normal;
        text-transform: none;
        &.filter {
            margin-bottom: 32px;
            &:before {
                content: "\201C";
            }
            &::after {
                content: "\201d"
            }
        }
    }
    .filter-text {
        display: block;
        font-family: $font-serif;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 34px;
    }
    p {
        font-size: 16px;
        text-align: center;
        padding-bottom: 31px;
    }
}

.brandsearch-container {
    text-align: center;
    max-width: 780px;
    margin: 0 auto 39px 0;
    .brandsearch-field {
        border: 1px solid #E7E7E7;
        display: block;
        margin: auto;
        max-width: 600px;
        width: 100%;
        line-height: 8px;
        input {
            // untested
            @include typeface(sans-serif);
            // font-family: "Gill Sans Light", sans-serif;
            display: block;
            float: left;
            width: calc(100% - 43px);
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            border: none;
            padding: 12px 20px;
            background-color: transparent;
            &::-webkit-input-placeholder {
              color: #909090;
            }
            &::-moz-placeholder {
              color: #909090;
            }
            &:-ms-input-placeholder {
              color: #909090;
            }
            &:-moz-placeholder {
              color: #909090;
            }
           	&::-ms-clear{
           		display: none;
           	}
        }
        #brandsearch-button {
            background-image: url(../images/search_icon.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 18px auto;
            border: none;
            outline: 0;
            background-color: transparent;
            width: 43px;
            height: 43px;
            padding: 0;
            margin: 0;
            cursor: default;
            &.hasText {
                background-image: none;
                cursor: pointer;
                span {
                    position: relative;
                    display: inline-block;
                    height: 10px;
                    width: 10px;
                    &:before, &:after {
                        content: "";
                        display: inline-block;
                        height: 1px;
                        width: 13px;
                        background-color: #292929;
                        transform-origin: center center;
                        position: absolute;
                        top: 5px;
                        right: -1px;
                        transform: rotate(45deg);

                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

// OLD
//.startletterlist{
//    padding: 20px 31px 10px 25px;
//    .startletter {
//        font-family: Georgia,serif;
//        font-size: 14px;
//        font-weight: bold;
//        text-transform: uppercase;
//        padding-bottom: 5px;
//        span {
//            background-color: transparent;
//            font-weight: bold;
//            color: #292929;
//            text-decoration: none;
//            margin: 0px 0px 0px 5px;
//        }
//        a {
//            background-color: transparent;
//            font-weight: normal;
//            color: #292929;
//            text-decoration: underline;
//            margin: 0px 0px 0px 5px;
//        }
//        .inactive {
//            background-color: transparent;
//            font-weight: normal;
//            color: #999999;
//            text-decoration: none;
//            margin: 0px 0px 0px 5px;
//        }
//    }
//    .lettergroup {
//        float: left;
//        width: 33.33%;
//        border-top: 1px solid #d0cac4;
//        margin-top: 10px;
//        padding-top: 8px;
//        .startletter {
//            background-image: url(../images/letter_bg.png);
//            background-repeat: no-repeat;
//            background-color: transparent;
//            width: 29px;
//            height: 23px;
//            color: #ffffff;
//            text-transform: uppercase;
//            font-family: Arial;
//            padding-top: 6px;
//            margin-left: -3px;
//            text-align: center;
//        }
//        a {
//            background-color: transparent;
//            color: #292929;
//        }
//    }
//}

.brandrefinement-spinner {
    display: block;
    margin: 12px auto;
    height: auto;
}

.startletterlist {
    max-width: 780px;
    padding: 0;
    .startletter {
        font-family: $font-sans-serif;
        text-align: justify !important;
        font-size: 0 !important;
        line-height: normal;
        text-justify: distribute-all-lines;
        padding-bottom: 18px;
        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            line-height: 0;
        }
        span {
            margin: 0;
            font-size: 14px;
            display: inline-block;
            font-weight: 300;
        }
        a {
            text-decoration: none;
            margin: 0;
            font-size: 14px;
            color: #292929;
            &.empty {
                color: #c5c5c5;
                cursor: default;
                &.active {
                    font-weight: 300;
                }
            }
            &.active {
                font-weight: 600;
            }
        }
    }
}

.brand-list, .didumean-list {
    max-width: 780px;
    list-style: none inside none;
    .letter {

        border-top: 1px solid #e9e9e9;
        padding: 45px 0 50px 0;
        margin-bottom: 0;
        .character {
            display: inline-block;
            margin-bottom: 22px;
            font-size: 28px;
            line-height: 34px;
            font-family: $font-serif;
            color: #292929;
        }
        ul {
            margin-left: -2px;
            list-style: none inside none;
            -moz-column-count: 4;
            -moz-column-gap: 20px;
            -webkit-column-count: 4;
            -webkit-column-gap: 20px;
            column-count: 4;
            column-gap: 20px;
        }
    }
}

.brand-list {
    .letter {
        ul {
            .brand-link {
                padding: 0;
                margin-bottom: 6px;
                a {
                    // untested
                    @include typeface(sans-serif);
                    // font-family: "Gill Sans Light", sans-serif;
                    font-size: 14px;
                    color: #292929;
                    &:hover {
                        color: #909090;
                    }
                }
            }
        }
    }
}


.didumean {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-top: 21px;
    padding-bottom: 19px;
}
.didumean-list {
    .letter {
        ul {
            .didumean-link {
                padding: 0;
                margin-bottom: 6px;
                a {
                    // untested
                    @include typeface(sans-serif);
                    // font-family: "Gill Sans Light", sans-serif;
                    font-size: 14px;
                    color: #292929;
                    &:hover {
                        color: #909090;
                    }
                }
            }
        }
    }
}

.notinsearchcontext {
    max-width: 780px;
    text-align: center;
    border-top: 1px solid #e9e9e9;
    padding-top: 46px;
    margin-bottom: 40px;
    .notonlinebutinstore-container {
        margin-bottom: 34px;
        .notonlinebutinstore {
            display: inline;
            font-family: $font-serif;
            font-size: 28px;
            line-height: 34px;
        }
    }
}

.onlinebrand {
    background-color: #f5f5f5;
    padding-top: 32px;
    margin-bottom: 40px;
    max-width: 780px;
    text-align: center;
    .onlinebrand-inner {
        .onlinebrand-header {
            font-family: $font-serif;
            font-size: 22px;
            line-height: 26px;
            .brand-query {
                display: inline-block;
            }
        }
        .button-style {
            margin: 17px 0 35px 0;
        }
    }
}

.stores-container {
    max-width: 780px;
    .nohitsstore {
        font-size: 0px;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 18px;
        margin-bottom: 20px;
        text-align: justify;
        text-justify: distribute-all-lines;
        * {
            text-justify: auto;
        }
        &:last-child {
            border-width: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0;
        }
        .storeinfo {
//            width: 260px;
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            margin-top: -3px;
            .storename {
                font-family: $font-serif;
                font-style: normal;
                text-transform: none;
                margin-bottom: 17px;
            }
            .brandcategories {
                padding: 12px 10px 12px 10px;
                margin-bottom: 16px;
                background-color: #f5f5f5;
                span, div {
                    // untested
                    @include typeface(sans-serif);
                    // font-family: "Gill Sans Light", sans-serif;
                    font-size: 14px;
                }
            }
            .storeaddress {
                // untested
                @include typeface(sans-serif);
                // font-family: "Gill Sans Light", sans-serif;
                font-size: 14px;
                margin-bottom: 13px;
            }
            .storecontact {
                font-size: 10px;
            }
        }
        .storeimage_small {
            display: inline-block;
//            width: 260px;
            width: 33.33%;
            vertical-align: top;
            &.mobile-only {
                display: none;
            }
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
    
    .openingcarousel-container {
        position: relative;
    //            width: 220px;
        width: 28.20%;
        max-width: 220px;
    //            margin: 0 20px;
        display: inline-block;
        vertical-align: top;
        margin-top: -3px;
        padding: 0 25px;
        box-sizing: border-box;
        .openining-owl-carousel {
            width: 100%;
            position: relative;
            .item {
                .openinghours {
                    // untested
                    @include typeface(sans-serif);
                    // font-family: "Gill Sans Light", sans-serif;
                    width: 100%;
    //                box-sizing: border-box;
    //                padding: 0 25px;
                    height: auto;
                    font-size: 14px;
                    .ohhead {
                        font-family: $font-serif;
                        font-style: normal;
                        text-transform: none;
                        margin-bottom: 17px;
                        font-size: 20px;
                        text-align: center;
                        line-height: 25px;
                        padding: 0;
                        white-space: nowrap;
                    }
                    .ohtabel {
                        display: table;
                        width: 100%;
                        border-collapse: collapse;
                        .ohrow {
                            display: table-row;
                            .ohday {
                                display: table-cell;
                                padding: 0 10px 0 0;
                                width: 100%;
                                float: none;
                            }
                            .ohhours {
                                display: table-cell;
                                width: 1px;
                                white-space: nowrap;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .owl-nav {
                position: absolute;
                width: 100%;
                height: 0;
                bottom: 0;
                left: 0;
                .owl-prev {
                    display: inline-block;
                    width: 10px;
                    height: 18px;
                    position: absolute;
                    bottom: 53px;
                    left: -25px;
                }
                .owl-next {
                    display: inline-block;
                    width: 10px;
                    height: 18px;
                    position: absolute;
                    bottom: 53px;
                    right: -25px;
                }
                .disabled {
                    opacity: .2;
                    cursor: default;
                }
            }
        }
    }
}

.brandsearch-buttonwrapper {
    text-align: center;
    max-width: 780px;
    .button-style {
        box-sizing: border-box;
        width: 200px;
        text-transform: none;
        line-height: 48px;
        background-color: #ffffff;
        border: 1px solid #292929;
        color: #292929;
        &:hover {
            color: #909090;
        }
    }
}

.brandnotfound {
    max-width: 780px;
    border-top: 1px solid #e9e9e9;
    padding-top: 44px;
    h3 {
        font-family: $font-serif;
        font-size: 30px;
        line-height: 36px;
        font-style: normal;
        font-weight: 400;
        white-space: normal;
        text-transform: none;
        text-align: center;
        margin-bottom: 73px;
    }
    .category-tallspot {
        .categoryheader {
            display: none;
        }
        > div {
            width: 100%;
            display: block;
            font-size: 0;
            .item {
                display: none;
                .brandcarousel-imagecontainer {
                    width: 100%;
                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
                .brandcarousel-textcontainer {
                    width: 100%;
                    text-align: center;
                    p {
                        font-size: 16px;
                        line-height: 24px;
                        opacity: .6;
                        margin-top: 15px;
                    }
                }
                &:nth-child(1), &:nth-child(2) {
                    display: inline-block;
                    width: 50%;
                    .brandcarousel-imagecontainer, 
                    .brandcarousel-textcontainer {
                        width: calc(100% - 20px);
                    }
                }
                &:nth-child(2) {
                    .brandcarousel-imagecontainer, 
                    .brandcarousel-textcontainer {
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
        
    }
}


//.pagecontent .maincontent .rightcontent.rightcontent--brandsearch {
//    float: none;
//    width: 100%;
//    max-width: 780px;
//    margin: auto;
//}

$sideMenuWidthTablet: 130px;

// TEMP

@media screen and (min-width: 641px)  and (max-width: 1366px) {
    .pagecontent .maincontentcontainer .maincontent.brandsearch-maincontent {
//         padding: 0 20px;
        .leftcoll {
//            width: $sideMenuWidthTablet;
            padding-right: 20px;
//            display: block;
//            padding-top: 27px;
        }
        .rightcontent {
//            float: right;
//            width: calc(100% - #{$sideMenuWidthTablet});
            .fullhead {
                h1 {
                    text-align: left;
                    padding-left: 0;
                }
                p {
                    text-align: left;
                }
            }
            .brandsearch-container .brandsearch-field {
                max-width: none;
            }
        }
        
    }
}

@media screen and (min-width: 768px) and (max-width: 1366px){
    .pagecontent .maincontentcontainer .maincontent.brandsearch-maincontent {
        padding: 0 20px;
        .leftcoll {
            width: $sideMenuWidthTablet;
            padding-right: 20px;
            display: block;
            padding-top: 27px;
       }
        .rightcontent {
            float: right;
            width: calc(100% - #{$sideMenuWidthTablet});
//            .fullhead {
//                h1 {
//                    text-align: left;
//                    padding-left: 0;
//                }
//                p {
//                    text-align: left;
//                }
//            }
        }
        
    }

}

@media screen and (min-width: 640px) and (max-width: 769px){
    .pagecontent .maincontentcontainer .maincontent.brandsearch-maincontent {
        .leftcoll {
            display: none;
        }
        .rightcontent {
            float: none;
		    width: 100%;
		    max-width: 780px;
		    margin: auto;
        }
    }
}


@media screen and (max-width: 1024px) {
    .brand-list, .didumean {
        .letter {
            ul {
                list-style: none inside none;
                -moz-column-count: 3;
                -moz-column-gap: 20px;
                -webkit-column-count: 3;
                -webkit-column-gap: 20px;
                column-count: 3;
                column-gap: 20px;

            }
        }
    }
    
//    .stores-container {
//        .nohitsstore {
//            .storeinfo {
//                width: 100%;
//                margin-bottom: 18px;
//            }
//            .storeimage_small {
//                width: 48%;
//            }
//        }
//        .openingcarousel-container {
//            width: 48%;
//            .openining-owl-carousel {
//                width: 100%;
//    //            max-width: 220px;
//    //            margin: auto auto auto 0;
//            }
//        }
//    }

}



@media screen and (max-width: 768px) {
    .brandshead {
        padding-top: 27px;
    }
    
}

$pageSideMarginMobile: 10px;



@media screen and (max-width: 640px) {
	.pagecontent .maincontentcontainer .maincontent.brandsearch-maincontent{
		padding: 0;
	}
    .brandshead {
        h1 {
            text-align: center;
            font-size: 30px;
            line-height: 32px;
            padding: 0 10px 5px 10px;
        }
        p {
            font-size: 14px;
            padding-bottom: 19px;
            line-height: 14px;
            margin: 0 20px;
        }
    }
    .brandsearch-container {
        margin-bottom: 21px;
        padding: 0 6.25%;
        .brandsearch-field {
            input {
                font-size: 14px;
                padding: 8px 10px;
                line-height: 17px;
                width: calc(100% - 37px);
            }
            #brandsearch-button {
                height: 37px;
                width: 37px;
                background-size: 15px auto;
                position: relative;
                right: -1px;
            }
        }
    }
    .startletterlist {
        display: none;
    }
    
    .brand-list, .didumean-list {
        margin: 0;
        .letter {
            border-width: 0;
            padding: 0;
            margin-bottom: 0;
            .character {
                display: block;
                font-size: 22px;
                margin: 0;
                padding: 10px 6.25% 9px 6.25%;
                border-bottom: 1px solid #e9e9e9;
            }
            ul {
                column-count: 1;
            }
        }
    }
    
    .brand-list {
        margin-top: -10px;
        .letter {
            ul {
                .brand-link {
                    margin: 0;
                    padding: 0;
                    margin-bottom: 0;
                    a {
                        display: block;
                        padding: 16px 6.25% 19px 6.25%;
                        border-bottom: 1px solid #e9e9e9;
                    }
                }
            }
        }
    }
    
    .didumean {
        margin: 0;
        padding: 10px 10px 9px 7px;
        border-bottom: 1px solid #e9e9e9;
        margin: 15px -10px 0 -10px;        
    }
    
    .didumean-list {
        .letter {
            ul {
                .didumean-link {
                    margin: 0;
                    padding: 0;
                    margin-bottom: 0;
                    a {
                        display: block;
                        padding: 16px $pageSideMarginMobile 19px $pageSideMarginMobile;
                        border-bottom: 1px solid #e9e9e9;
                    }
                }
            }
        }
    }
    
    .brandnotfound {
            border-width: 0;
            padding-top: 0;
        h3 {
            margin-bottom: 39px;
            font-size: 26px;
            line-height: 28px;
        }
        .category-tallspot {
            > div {
                .item {
                    width: 100%;
                    display: none;
                    &:nth-child(1) {
                        display: inline-block;
                        width: 100%;
                        .brandcarousel-imagecontainer, 
                        .brandcarousel-textcontainer {
                            width: 100%;
                        }                        
                    }
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }

        }
    }
    .stores-container {
        .nohitsstore {
            margin: 0 -10px 20px -10px;
            padding: 0 10px;
            .storeinfo {
                width: 100%;
                margin-bottom: 19px;
                padding-bottom: 16px;
                border-bottom: 1px solid #e9e9e9;
            }
            .storeimage_small {
                width: 100%;
                &.mobile-only {
                    display: block;
                    margin-bottom: 20px;
                }
                &.desktop-only {
                    display: none;
                }
            }
        }
        .openingcarousel-container {
            width: 100%;
            max-width: none;
            .openining-owl-carousel {
                position: static;
                width: 100%;
                max-width: 180px;
                margin: auto;
                .owl-nav {
                    .owl-prev {
                        left: 0;
                    }
                    .owl-next {
                        right: 0;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
.pagecontent .maincontentcontainer .maincontent.brandsearch-maincontent{
		padding: 0 6.25%;
	}
}	
	