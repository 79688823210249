// Note
//
// A note is a full width simple banner.<br>
// Can be used to highlight a message.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/note.twig
//
// .note--small         - Small text
// .note--collapse      - No margin
// .note--center        - Text centered on mobile
// .note--left          - Text left aligned on desktop
// .note--tall          - More vertical padding
// .note--inverted      - Inverts the order of the content
//
// Weight: 1
//
// Style guide: components.note

.note, .servicemessage {
    @include typeset(body-normal);
    line-height: 1.4;
    
    display: flex;
    justify-content: flex-start;
    
    max-width: 1440px;
    box-sizing: border-box;
    margin: rem(16) auto;
    padding: rem(12) rem(24);
    
    color: $color-white;
    text-align: left;
        
    // If no bg-modifier is present, make banner green
    &:not([class*="c--"]) {
        background-color: $color-dark-green-cyan;
    }
    
    @include mq($from: screen-xs) {
        padding: rem(15) rem(24);
        justify-content: center;
        text-align: center;
    }

    @include mq($from: 640px) {
        margin: rem(30) auto;
    }
    
    b {
        font-weight: bold;
    }
    
    a {
        text-decoration: underline;
        color: currentColor;
        
        &:hover, &:active, &:visited {
            text-decoration: underline;
            color: currentColor !important;
        }
    }
    
    p, span {
        max-width: 35em;
    }
    
    img, svg {
        min-width: 2em;
        height: 2em;
        margin-right: rem(15);
        
        @include mq($from: screen-xs) {
            align-self: center;
        }
        
        + p {
            max-width: calc(100% - rem(15) - 2em); // = full width - paddings - icon width
            text-align: left;
        }
    }
    
    &--small {
        @include typeset(body-small);
        padding: rem(8) rem(24);

        img, svg {
            margin-bottom: 0;
        }
    }
    
    &--collapse {
        margin-top: 0;
        margin-bottom: 0;
    }

    &--center {
        justify-content: center;
        text-align: center;
    }
    
    &--left {
        justify-content: flex-start;
        text-align: left;
    }
    
    &--tall {
        padding: rem(24);
    }
    
    &--inverted {
        flex-direction: row-reverse;
        
        img, svg {
            margin-left: rem(15);
            margin-right: 0;
        }
    }

    &--light {
        color: $color-text;
    }
}