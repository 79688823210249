// Link block
//
// A link tile.<br>
// Is preferably used within a grid of many Link blocks.<br>
// A crossover between a button and a normal link, but takes less attention.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/elements/link-block.twig
//
//
// Weight: 1
//
// Style guide: elements.link-block

.link-block {
    .new-font & {
        @include typeface(sans-serif);
    }
    @include typeset(body-normal);

    position: relative;

    border-left: 1px solid $color-border;
    border-right: 1px solid $color-border;

    padding: rem(15) rem(15);
    margin: rem(15) -1px rem(15) 0px;

    color: $color-text;
    text-align: center;

    &:hover, &:focus {
        border-color: $color-border-hover;
        text-decoration: none;
        z-index: 1;
    }
}