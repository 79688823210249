.owl-nav {
    .owl-next {
        background-image: url(../images/svg/frem_pil_ikon.svg);
        background-size: 200% 100%;
        background-repeat: no-repeat;
        width: 14px;
        height: 24px;
        background-position: left -5px center;
    }
    .owl-prev {
        background-image: url(../images/svg/frem_pil_ikon.svg);
        background-size: 200% 100%;
        background-repeat: no-repeat;
        width: 14px;
        height: 24px;
        background-position: left -5px center;
        transform: rotate(180deg);
    }
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel--white-nav .owl-nav .owl-next, .owl-carousel--white-nav .owl-nav .owl-prev {
    background-image: none;
//    background-color: #d5d5d5;
    background-color: rgba(0,0,0,.08);
    border-radius: 30px;
    width: 60px;
    height: 60px;
}

.owl-nav .owl-prev {
    transform: rotate(180deg);
}

//.owl-nav .owl-next, .owl-nav .owl-prev {
//    background-image: url(../images/svg/frem_pil_ikon.svg);
//    background-size: 130% 50%;
//    background-repeat: no-repeat;
//    width: 14px;
//    height: 24px;
//    background-position: left -5px center;
//}

.owl-carousel{
    .productitem {
        .productsplashcontainer {
            top: 32px;
        }
        .productimg {
        //    display: block;
         //   margin-bottom: 30px;
            .image{
                a {
    //    			width: 100%;
    //                height: 100%;
    //                min-height: 205px;
    //                display: -webkit-box;
    //                display: -ms-flexbox;
    //                display: flex;
    //                -webkit-box-align: center;
    //                -ms-flex-align: center;
    //                align-items: center;
    //                -webkit-box-pack: center;
    //                -ms-flex-pack: center;
    //                justify-content: center;
                    img {
                        width: auto;
                    }
                }
            }
            h3 {
                width: 100%;
                height: 100%;
            }
        }
    }
    .owl-item{
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.topbanner-carousel-container{
    margin: 0 -30px 100px; // Added by sorthvid

    .topbanner-owl-carousel{
    	display: block;
    	.item{
    		&:not(:first-child){
    			display: none;
    		}
    	}
    }
    .topbanner-text {
        text-align: center;
        line-height: normal;
        font-size: 12px;
        margin: -5px auto -4px auto;
        padding: 20px 20px 0 20px;
    }
    .owl-nav{
        display: none;
        .owl-prev {
            background-image: none;
            position: absolute;
            height: 60px;
            width: 60px;
//            opacity: .4;
//            background-color: #292929;
            border-radius: 50%;
            background-color: rgba(0,0,0,.08);
            z-index: 10;
            top: calc(50% - 30px);
            right: calc(100% - 90px);
        }
        .owl-next {
            background-image: none;
            position: absolute;
            height: 60px;
            width: 60px;
//            opacity: .4;
//            background-color: #292929;
            background-color: rgba(0,0,0,.08);
            border-radius: 50%;
            z-index: 10;
            top: calc(50% - 30px);
            left: calc(100% - 90px);
            
        }
        .owl-next-inner, .owl-prev-inner {
            position: absolute;
            top: calc(50% - 12px);
            left: calc(100% - 36px);
            z-index: 20;
            background-image: url(../images/svg/frem_pil_ikon_hvid.svg);
            background-size: 200% 100%;
            background-repeat: no-repeat;
            width: 14px;
            height: 24px;
            background-position: left -5px center;
        }
        .owl-next-inner {
            background-image: url(../images/svg/frem_pil_ikon_hvid.svg);
            background-size: 200% 100%;
            background-repeat: no-repeat;
            width: 14px;
            height: 24px;
            background-position: left -5px center;
        }
        .owl-prev-inner {
            left: auto;
            right: calc(100% - 39px);
        }
    }
    &:hover{
        .owl-nav{
            display: block;
        }
    }
}


.owl-carousel--white-nav .owl-nav .owl-next-inner, .owl-carousel--white-nav .owl-nav .owl-prev-inner {
    position: absolute;
    left: 24px;
    top: 18px;
    background-image: url(../images/svg/frem_pil_ikon_hvid.svg);
    background-size: 200% 100%;
    background-repeat: no-repeat;
    width: 14px;
    height: 24px;
    background-position: left -5px center;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display: none;
}


.frontpageslider {
    padding-bottom: 2%;
}

.brandcarousel-container{
    clear: both;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 2% 100px 2%;
    .brand-owl-carousel {
        max-width: 1170px;
        margin: auto;
        .owl-stage-outer {
            .owl-item {
//                padding: 0.25%;
//                box-sizing: border-box;
                .item {
                    position: relative;
                    .brandcarousel-imagecontainer {
                        width: 100%;
                        .brandcarousel-image-sec {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                    .brandcarousel-textcontainer {
                        width: 100%;
                        text-align: center;
                        p {
                            font-size: 16px;
                            line-height: 24px;
                            opacity: .6;
//                            margin-top: 13px;
                            margin: 13px auto -6px auto;
                            &:empty {
                                display: none; // TODO: Remove when BE are done removing empty tags.
                            }
                        }
                        .tilelink-style {
                            margin-top: 17px;
                        }
                    }
                }
            }
        }
        .owl-nav {
            .owl-prev, .owl-next {
                position: absolute;
                top: calc(50% - 63px);
            }
            .owl-prev {
                right: calc(100% + 48px);
            }
            .owl-next {
                left: calc(100% + 48px);
            }
        }
    }
}

.product-carousel-container{
    box-sizing: border-box;
    margin: 0 0 0 auto;
    .product-carousel-innercontainer {
        width: 100%;
        max-width: 1170px;
        margin: auto;
        position: relative;
        margin-bottom: -9px;
        .product-carousel-header-container {
            width: 240px;
            float: left;
            padding-top: 11%;
            .categoryheader {
                text-align: left;
                margin-left: auto;
                margin-right: auto;
                padding: 0;
            }
        }
        .product-carousel-window {
            width: calc(100% - 300px);
            margin: 0 0 0 auto;
            font-size: 0;
            line-height: 0;
            .product-owl-carousel{
                &.owl-loaded {
                    display: inline-block;
                }
                &.grey-overlay .productitem .productimg .image:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 10;
                    background-color: #d8d8d8;
                    opacity: .2;
                }
                .productitem {
                    margin: 0;
                    box-sizing: border-box;
                    cursor: pointer;
                    .productsplashcontainer {
//                        width: 100%;
//                        margin-top: 10px;
//                        z-index: 1;
                        display: none;
                    }
                    .productbadge {
                        margin-top: 14px;
                    }
                    .productimg {
                        width: 100%;
                        margin: 0;
                        display: inline-block;
                        height: auto;
                        position: relative;
                        &:after {
                            content: "";
                            display: block;
                            padding-top: 100%;
                            padding-top: 144.45%;
                        }
                        .image {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            font-size: 0;
                            max-height: none;
                            a {
                                min-height: auto;
                                img {
                                    vertical-align: middle;
                                    display: inline-block;
                                    max-width: 80%;
                                    max-height: 80%;
                                    height: auto;
                                    width: auto;
                                }
                            }
                        }
                    }
//                    .producttext, .productprice {
//                        display: none;
//                    }
                    .producttext-truncated{
                        overflow: hidden;
                    }
                }
                .owl-nav {
                    .owl-prev, .owl-next {
                        position: absolute;
                        top: calc(50% - 10px);
                        left: calc(100% + 27px);
                    }                    
                    .owl-prev {
                        position: absolute;
                        left: auto;
                        right: calc(100% + 27px);
                    }
                }
            }
        }
    }
    /**
     * sorthvid quick fix.
     * code is copied from live site.
     * seems to have gotten lost somewhere between sprint 45-46
     */
    &.product-carousel--custom {
        margin: auto auto 100px;
        padding: 0;
        line-height: 0;
        font-size: 0;

        .product-carousel-innercontainer {
            box-sizing: border-box;
            margin: auto;
            padding: 0 70px;

            .product-carousel-header-container {
                width: 100%;
                float: none;
                padding: 0 0 25px;

                &:before {
                    display: none;
                }

                .moduleheader {
                    margin: -3px auto -8px;
                    text-align: center;
                }
            }

            .product-carousel-window {
                width: 100%;

                .product-owl-carousel {
                    .owl-stage-outer .productitem {
                        .productimg {
                            margin-bottom: 15px;
        
                            &:after {
                                padding-top: 100%;
                            }
                            
                            .image {
                                a img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                                
                                &:after {
                                    display: none;
                                }
                                
                            }
    
                            + .producttext {
                                padding-top: 15px;
                            }
                        }
    
                        .productbadge {
                            margin: 0 auto 15px;
                        }
    
                        .producttext {
                            margin-top: -3px;
                        }
    
                        .productprice {
                            margin-bottom: -4px;
                        }
                    }

                    .owl-nav .owl-prev,
                    .owl-nav .owl-next {
                        top: 91px;
                    }
                    .owl-dots {
                        display: inline-block;
                        width: 100%;
                        margin-top: 30px;
                        text-align: center;

                        .owl-dot {
                            display: inline-block;
                            font-size: 0;
                            line-height: 0;
                            margin: 5px;
                            vertical-align: middle;
                            
                            span {
                                background-color: #e8e8e8;
                                height: 9px;
                                width: 9px;
                                display: inline-block;
                                border-radius: 50%;
                                display: inline-block;
                                box-sizing: border-box
                            }

                            &.active span {
                                background-color: #292929
                            }
                        }
                    }
                }
            }
        }
    }
    
    &.product-carousel-container--shopthelook {
        clear: both;
        padding-bottom: 100px;
        .product-carousel-innercontainer {
            .product-carousel-header-container {
                width: 100%;
                padding-top: 0;
                &:before {
                    display: none;
                }
                .categoryheader {
                    text-align: center;
                }
                .tilelink-style {
                    display: none;
                }
            }
            .product-carousel-shopthelook-img-container {
                width: calc(100% - 74.35%);
                float: left;
                img {
                    height: 100%;
                    width: auto;
                    display: none;
                }
            }
            .product-carousel-window {
                width: 74.35%;
                .product-owl-carousel {
                    .productitem {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

.matchescarousel-container {
    text-align: center;
    width: 100%;
    height: 100%;
    .matchescarousel {
        max-width: 1275px;
        margin: 50px auto 0;
        padding: 40px 63px;
        box-sizing: border-box;
        position: relative;
        text-align: center;
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            background-color: #d8d8d8;
            opacity: .2;
            pointer-events: none;
        }
        h1 {
		    margin-bottom: 10px;
            text-transform: none;
            font-style: normal;
            font-family: $font-serif;
            font-size: 37px;
            padding: 0;
        }
        .matches-owl-carousel {
            width: calc(100% - 40px);
            width: -webkit-calc(100% - 40px);
            width: -moz-calc(100% - 40px);
            margin: 0 20px;
            .owl-stage {
                margin: auto;
                .item {
                    position: static;
                    padding: 0 36px;
                    .productbadge {
                        margin-top: 14px;
                    }
                    .productimg {
                        // Eposition: static;
                        // margin: 0 auto 0 auto;
                        // width: auto;
                        // height: auto;                            
                        .image {
                          //  display: -webkit-box;
                          //  display: -ms-flexbox;
                          //  display: flex;
                          //  position: static;
                          //  max-height: none;
                            img {
                              //  display: block;
                            }
                        }
                    }
                    .productoverlay {
                        display: none !important;
                    }
                }
            }
            .owl-nav {
                position: absolute;
                top: 150px;
                right: -20px;
                left: -20px;
                z-index: -1;
                .owl-prev {
                    float: left;
                }
                .owl-next {
                    float: right;
                }
            }
            .owl-dots {
                width: 100%;
                margin-top: 25px;
                text-align: center;
            }
        }
    }
}


.rrplaceholder{
    text-align: center;
    width: 100%;
    margin-top: 20px;
    
}
.popular-owl-carousel {
    max-width: 1275px;                
    margin: 0 auto 0;
    padding: 0 63px 102px 63px;
    box-sizing: border-box;
    text-align: center;
    h1 {
        text-transform: none;
        font-style: normal;
        font-family: $font-serif;
        font-size: 37px;
        padding: 0;
    }
    .owl-carousel {
        width: calc(100% - 40px);
        width: -webkit-calc(100% - 40px);
        width: -moz-calc(100% - 40px);
        margin: 0 20px -21px;
        
        .owl-stage {
            margin: auto;
            .item {
                position: static;
                padding: 0 26px;
                li {
                    padding: 0;
                    .productbadge {
                        margin-top: 16px;
                    }
                    .productimg {
                       /* position: static;
                        margin: 0 auto 0 auto;
                        width: auto;
                        height: auto;   */                         
                        .image {
                         /*   display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            position: static;
                            max-height: none; */
                            a {
                                display: block;
                                width: 100%;
                                height: 100%;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    position: static;
                                    height: 100%;
                                    width: 0;
                                    vertical-align: middle;
                                }
                                img {
                                 //   display: block;
                                }
                                .productimgmissing {
                                    max-width: 205px;
                                }

                            }
                            
                        }
                    }
                    .productoverlay {
                        display: none !important;
                    }
                }
            }
        }
        .owl-nav {
            position: absolute;
            top: 150px;
            right: -20px;
            left: -20px;
            z-index: -1;
            .owl-prev {
                float: left;
            }
            .owl-next {
                float: right;
            }
        }
        .owl-dots {
            margin-top: 27px;
            text-align: center;
        }
    }
}

.productcarousel {
    .jcarousel-clip-horizontal {
        margin-left: 50px;
        width: 840px;
        height: 330px;
    }
    &.livecarousel {
        .jcarousel-clip-horizontal {
            width: 830px;
            height: 335px;
        }
    }
    .jcarousel-clip {
        overflow: hidden;
    }
    .jcarousel-item {
        width: 208px;
        height: 335px;
        margin: 0px;
        padding: 0px;
        background-image: none;
        .productitem {
            position: relative;
            float: left;
            background-image: url(../images/product_divider_258.png);
            background-repeat: no-repeat;
            width: 209px;
            text-align: center;
            .productsplashcontainer {
                width: 210px;
            }
        }
    }
    .jcarousel-item-horizontal {
        margin-left: 0;
        margin-right: 0;
    }
}

.frontpageslider{
    .menulink{
        &>a{
            display: block;
            img{
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}

.product-owl-carousel .productitem .productimg {
    .image a:before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;        
    }
    &:after {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.pdpGallery-owl-carousel, .pdp-owl-carousel {
    .image-fixed-aspect {
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 100%;
        }
        .pdp-item-link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            &:before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 0;
            }
            img {
                display: inline-block;
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: auto;
                vertical-align: middle;
                flex: 0 0 auto;
                object-fit: scale-down;
            }
            .productimgmissing {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                font-size: 0px;
                white-space: nowrap;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 0;
                    vertical-align: middle;
                    height: 100%;
                }
                .image-missing {
                    max-width: 500px !important;
                    height: auto;
                    object-fit: contain;
                    margin: 30px;
                }
            }
        }
    }
    .item-video {
        white-space: nowrap;
        font-size: 0;
        line-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .owl-video-wrapper {
            width: 100%;
            iframe {
                height: 100%;
            }
            .owl-video-play-icon {
                background-image: url(../images/svg/video_player.svg);
                background-size: 150px 150px;
                background-position: center center;
                height: 124px;
                width: 124px;
                margin: -62px 0 0 -62px;
                &:hover {
                    transform: none;
                }
            }
            .owl-video-tn {
                background-size: cover;
                -moz-background-size: cover;
            }
        }
    }
    .owl-nav {
        .owl-next, .owl-prev  {
            position: absolute;
            top: calc(50% - 50px);
            left: 21px;
            opacity: 0;
            
        }
        .owl-next {
            left: auto;
            right: 21px;
        }
    }
    &:hover .owl-nav {
        .owl-next, .owl-prev  {
//            opacity: .8;
            opacity: 1;
        }
    }
    .owl-dots {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 2px;
        .owl-dot {
            display: inline-block;
            font-size: 0px;
            line-height: 0;
            margin: 5px;
            vertical-align: middle;
            span {
                background-color: #E8E8E8;
                height: 9px;
                width: 9px;
                display: inline-block;
                border-radius: 17px;
                display: inline-block;
                box-sizing: border-box;
            }
            &.active span {
                background-color: #292929;
            }
            &.owl-dot-video span {
                background-image: url(../images/svg/video_dot.svg);
                background-size: 23px 23px;
                background-position: center center;
                width: 17px;
                height: 17px;
                background-color: transparent;
                opacity: .2;
            }
            &.owl-dot-video.active span {
                background-color: transparent;
                opacity: 1;
            }
        }
    }
    &.disabled {
        display: none;
    }
}

.pdpGallery-owl-carousel {
    .item-video, .image-fixed-aspect {
        max-width: 830px;
        margin: auto;
    }
}

.popular-owl-carousel, .matchescarousel {
    .owl-carousel .owl-dot {
        background-color: #e7e7e7;
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin: 0 4.5px;
        &.active {
            background-color: #292929;
        }
    }
//    #pdcarousel01 {    // Contact BSD, if these rules are to be used!
//        display: block;
//    }
}

.jcarousel-clip {
    overflow: hidden;
}

.jcarousel-item-horizontal {
    margin-left: 0;
    margin-right: 0;
}

.jcarousel-item {
    margin: 0px;
    padding: 0px;
    background-image: none;
}

.jcarousel-prev-horizontal {
    position: absolute;
    cursor: pointer;
    background: transparent url(../images/carousel_prev.png) no-repeat 0 0;
}

.jcarousel-next-horizontal {
    position: absolute;
    cursor: pointer;
    background: transparent url(../images/carousel_next.png) no-repeat 0 0;
}

// .openingcarousel {
// 	.jcarousel-container-horizontal {
// 	    width: 278px;
// 	    padding: 20px 30px 20px 30px;
// 	    .jcarousel-clip-horizontal {
// 		    width: 278px;
// 		    height: 200px;
// 		}
// 	}
// 	.jcarousel-item {
// 	    width: 278px;
// 	    height: 200px;
// 	}
// 	.jcarousel-prev-horizontal {
// 	    width: 30px;
// 	    height: 70px;
// 	    top: 80px;
//     	left: 0px;
// 	}
// 	.jcarousel-next-horizontal {
// 	    width: 30px;
// 	    height: 70px;
//     	top: 80px;
//     	right: 0px;
// 	}
// }

// #pdcarousel01{
//     &.jcarousel-list{
//         display: block;
//         li{
//             &:before{
//                 display: none;
//             }
//         }
//     }
// }

	

@media screen and (max-width: 1031px){
    .product-carousel-container {
        .product-carousel-innercontainer {
            .product-carousel-header-container {
                width: 200px;
            }
            .product-carousel-window {
                width: calc(100% - 220px);
                .product-owl-carousel {
                    .owl-dots {
                        text-align: center;
                        margin-top: 10px;
                        .owl-dot {
                            display: inline-block;
                            font-size: 0px;
                            line-height: 0;
                            margin: 5px;
                            vertical-align: middle;
                            span {
                                background-color: #E8E8E8;
                                height: 9px;
                                width: 9px;
                                display: inline-block;
                                border-radius: 50%;
                                display: inline-block;
                                box-sizing: border-box;
                            }
                            &.active span {
                                background-color: #292929;
                            }
                        }

                    }
                }
            }
        }
        /**
         * sorthvid quick fix.
         * code is copied from live site.
         * seems to have gotten lost somewhere between sprint 45-46
         */
        &.product-carousel--custom {
            margin: auto auto 60px;

            .product-carousel-innercontainer {
                box-sizing: border-box;
                margin: auto;
                padding: 0;

                .product-carousel-header-container {
                    width: 100%;
                    float: none;

                    &:before {
                        display: none;
                    }

                    .moduleheader {
                        text-align: center;
                    }
                }

                .product-carousel-window {
                    width: 99.0384615385%;
                    margin-right: -2.6041666667%;
                    overflow: hidden;
                    padding: 0;

                    .product-owl-carousel {
                        width: 81.4147018031%;

                        .owl-stage-outer {
                            overflow: visible;

                            .productitem {
                                .productimg {
                                    margin-bottom: 15px;

                                    &:after {
                                        padding-top: 100%;
                                    }
                                }

                                .productbadge {
                                    margin: 0 auto 15px;
                                }

                                .producttext {
                                    margin-top: -3px;
                                }

                                .productprice {
                                    margin-bottom: -3px;

                                    .beforeprice {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .owl-nav {
                            display: none;
                        }

                        .owl-dots {
                            position: relative;
                            left: 7.4957410562%;
                            margin: 15px auto -5px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        &.product-carousel-container--shopthelook {
            .product-carousel-innercontainer {
                .product-carousel-shopthelook-img-container {
                    width: calc(100% - 76.35%);
                    float: left;
                }
                .product-carousel-window {
                    width: 76.35%;
                }
            }
        }

    }
    .owl-nav {
        .owl-next, .owl-prev {
            width: 11px;
            height: 18px;
        }
    }
}

@media screen and (min-width: 1032px){
    .product-carousel-container {
        margin: auto;
        padding: 0 100px 100px 100px;
        box-sizing: border-box;
        .product-carousel-innercontainer {
            .product-carousel-header-container {
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    //height: calc(10vw - 18px);
                }
//                .categoryheader {
//                    margin-left: auto;
//                    margin-right: auto;
//                    line-height: 44px;
//                }
            }
            .product-carousel-window {
                .product-owl-carousel {
                    .productitem {
                        .producttext, productprice {
                            display: block;
                        }
                        .producttext {
                            margin-top: 17px;
                        }
                    }
                    .owl-nav {
                        .owl-prev, .owl-next {
                            top: calc(50% - 54px);
                            left: calc(100% + 37px)                        }
                        .owl-prev {
                            left: auto;
                            right: calc(100% + 37px);
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 769px){

    .brandcarousel-container h2{
        padding-top: 9px;
    }

    .topbanner-carousel-container .owl-next{
        Display: none;
    }

    .topbanner-carousel-container .owl-prev{
        Display: none;
    }
    .maincontent  .owl-carousel{
        position: static;
//        padding-bottom: 35px;
        .owl-dots {
            position: absolute;
            width: 100%;
            left: 0;
        }
    }
    .topbanner-carousel-container{
        margin: 0;
        padding-bottom: 40px;
    }
   	.tbbanner{
   		.topbanner-carousel-container{
	    	display: none;
	    }
   	}
    .brand-herms, .brand-dior, .brand-chanel{
    	.tbbanner{
	    	.topbanner-carousel-container{
		    	display: block;
		    }
	   	}
    }
    .brandcarousel-container{
        .brand-owl-carousel {
            .owl-nav {
                display: none;
            }
        }
    }
    .matchescarousel-container {
        .matchescarousel {
                    padding: 40px 6.25% 80px;
   				    overflow: hidden;
    				margin: 50px -6.25% 0;
            h1 {
                padding: 0 20px;
      			font-size: 24px;
            	line-height: 24px;
                margin-bottom: 21px;
            }
            .owl-carousel {
                margin: 0 20px 0 0;
                .owl-stage-outer {
                    overflow: visible !important;
                    .owl-stage {
                        .item {
                            padding: 0 10px;
                        }
                    }
                }
            }
            .owl-nav {
                display: none;
            }
        }
    }
    .livecarouselcontainer {
        padding-bottom: 40px;
        margin-top: -10px;
    }
    .popular-owl-carousel {
        padding: 10px 12.2% 20px 0;
        overflow: hidden;
        margin-right: -6.25%;
       .fulpageheaddivider {
        	padding: 0;
        	h2{
        		padding: 0 20px;
        	}
        }
        h1, h2 {
            padding: 11px 20px 0;
            margin-right: -22px;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 21px;
        }
        .owl-carousel {
            margin: 0 20px 0 0;
         //   padding: 0 0 20px 0;
            width: 100%;
            .owl-stage-outer {
                overflow: visible !important;
                .owl-stage {
                    .item {
                        padding: 0 10px;
                    }
                }
            }
             .owl-dots {
            margin-top: 18px;
           }
        }
        .owl-nav {
            display: none;
        }
    }
    
    .product-carousel-container{
        .product-carousel-innercontainer {
            .product-carousel-header-container {
                position: static;
                width: 100%;
                transform: none;
                padding-top: 27px;
                .categoryheader {
                    text-align: center;
                    padding: 0;
                    margin-bottom: 16px;
                    margin-top: 10px;
                }
                .tilelink-style {
                    display: none;
                }
            }
            .product-carousel-window {
                width: 100%;
                margin: auto;
                padding-bottom: 60px;
            }
        }
        
        &.product-carousel-container--shopthelook {
            padding: 0 20px 17px 20px;
            .product-carousel-innercontainer {
                .product-carousel-header-container {
                    width: 100%;
                    position: static;
                    padding-top: 5px;
                }
                .product-carousel-shopthelook-img-container {
                    width: calc(100% - 68.5%);
                    img {
                    }
                }
                .product-carousel-window {
                    width: 68.5%;
                    margin: 0 0 0px auto;
                    .product-owl-carousel {
                        .productitem {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
        
    }
    
    .product-carousel-container {
        .product-carousel-innercontainer {
            .product-carousel-window {
                .product-owl-carousel {
                    .owl-dots {
                        position: absolute;
                        margin-top: 21px;
                    }
                }
            }
        }
    }
    .brandcarousel-container{
    	h2{
    	margin-bottom: 16px;
    	}
    	padding: 0 2% 6% 2%;
    }
    .boxondlayer .rrplaceholder .popular-owl-carousel {
   	   margin-right: 0;
    }
    
    .basketpage .popular-owl-carousel {
        margin: auto;
        padding: 10px 0 20px 0;
    }
    
    .matchescarousel-container .matchescarousel .matches-owl-carousel .owl-dots{
    	margin-bottom: 20px;
    }
    
}

@media screen and (max-width: 640px){
    .topbanner-carousel-container {
        width: 114.2857%;  // 320/280px
        margin-left: -7.143%;  // 20/280px
    }
    
    .basketpage .popular-owl-carousel {
        padding: 10px 0 20px 0;
        margin-right: -10px;
        h2 {
            width: 100%;
            margin: 0 0 0 -5px;
            box-sizing: border-box;
        }
        .owl-carousel {
            width: 80%;
            .owl-dots {
                width: 125%;
                margin-left: -5px;
            }
        }
	}
    
    .brandcarousel-container{
        width: 114.2857%;  // 320/280px
        margin-left: -7.143%;  // 20/280px
		padding: 0 15% 40px 0;  
		overflow: hidden;
        box-sizing: border-box;
		.categoryheader {
            padding: 0;
            margin: -3px auto 16px auto;
            width: 115.1%;   // 320/278
        }
	    .brand-owl-carousel {
	        .owl-stage-outer {
                overflow: visible;
                margin-bottom: 0;
	        	.owl-item{
	        		text-align: center;
	        		padding-right: 5px;
	        		box-sizing: border-box;
                    .item {
                        .brandcarousel-textcontainer {
                            width: 100%;
                            position: relative;
                            left: calc(7.69% + 2.5px);
                            margin: 0 0 0 auto;
                        }
                    }
	        	}
            }
	        .owl-dots {
	        	position: static;
	        	display: block;
                width: 115.1%; // 320/278px
	        	text-align: center;
                margin: -5px auto -10px auto;
                padding-top: 25px;
	        	.owl-dot {
	        		background: none repeat scroll 0 0 #DCDCDC;
					border-radius: 50%;
					display: inline-block;
					height: 9px;
					margin: 5px;
					opacity: 0.5;
					width: 9px;
					&.active {
						background-color: #292929;
					}
	        	}
	        }
        }
        
    }
    
    .product-carousel-container {
        .product-carousel-innercontainer {
            margin-bottom: 0;
            .product-carousel-header-container {
                padding: 0;
                .categoryheader {
                    margin: -3px auto 16px auto;
                }
            }
            .product-carousel-window {
                padding-bottom: 40px;
                .product-owl-carousel {
                    .owl-stage-outer {
                        .owl-stage {
                            margin-bottom: -3px;
                        }
                    }
                    .owl-dots {
                        position: static;
                        margin: -6px auto -5px auto;
                        padding-top: 25px;
                    }
                }
            }
        }
        &.product-carousel--custom {
            margin: auto auto 40px;

            .product-carousel-innercontainer {
                box-sizing: border-box;
                margin: auto;
                padding: 0;

                .product-carousel-header-container {
                    width: 100%;
                    float: none;

                    &:before {
                        display: none;
                    }

                    .moduleheader {
                        text-align: center;
                    }
                }

                .product-carousel-window {
                    width: 106.0714285714%;
                    float: none;
                    margin: 0 -6.734006734% 0 auto;

                    .product-owl-carousel {
                        width: 86.531986532%;

                        .owl-stage-outer {
                            overflow: visible;

                            .productitem {
                                .productimg {
                                    margin-bottom: 15px;
                                }

                                .productbadge {
                                    margin: 0 auto 15px;
                                }

                                .producttext {
                                    margin-top: -3px;
                                }
                            }
                        }

                        .owl-nav {
                            display: none;
                        }

                        .owl-dots {
                            position: relative;
                            left: 3.8910505837%;
                            margin: 20px auto -5px;
                            padding: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    
        &.product-carousel-container--shopthelook {
            padding: 0;
            .product-carousel-innercontainer .product-carousel-header-container {
                padding: 0;
            }
        }
    }
    
}



//@media screen and (max-width: 480px){
//	.brandcarousel-container{
//		h2{
//            margin: 35px -15% 15px 0;
//            padding-top: 30px;
//		}
//	    .brand-owl-carousel {
//	        .owl-stage-outer {
//	        	overflow: visible;
//	        	.owl-item{
//	        		text-align: center;
//	        		padding-right: 5px;
//	        		box-sizing: border-box;
//	        	}
//	        }
//	    }
//	}
//}


@media screen and (max-width: 479px){
	.matchescarousel-container{
	   .matchescarousel{
	   		padding-bottom: 59px;	
	   		padding-top: 20px;	
	    h1 {
	        margin-top: 9px;
	     }
	     .matches-owl-carousel{
	       .owl-stage{ 
	         .item{ 
	           .productimg{
	              padding: 0 10px;
	     
	           }
	         }
	       }
	     }
	   }    
	}
//	.brandcarousel-container{
//	    .brand-owl-carousel {
//	        .owl-stage-outer {
//	        	.owl-item{
//	        		.item {
//	        			.brandcarousel-textcontainer {
//	        				position: relative;
//	        				left: calc((87vw - 100%)/2);
//	        			}
//	        		}
//	        	}
//	        }
	        
//	        .owl-dots {
//	        	position: relative;
//	        	bottom: 100px;
//	        	display: inline-block;
//	        	width: 100%;
//	        	text-align: center;
//	        	left: calc((100vw - 100%)/2);
//	        	.owl-dot {
//	        		background: none repeat scroll 0 0 #DCDCDC;
//					border-radius: 20px;
//					display: inline-block;
//					height: 12px;
//					margin: 5px 7px;
//					opacity: 0.5;
//					width: 12px;
//					&.active {
//						background-color: #292929;
//					}
//	        	}
//	        }
//	    }
//	}
    .product-carousel-container.product-carousel-container--shopthelook {
        .product-carousel-innercontainer {
            .product-carousel-header-container {
                width: 100%;
                position: static;
                &:before {
                    display: none;
                }
                .categoryheader {
                    text-align: center;
                    margin-bottom: 16px;
                }
                .tilelink-style {
                    display: none;
                }
            }
            .product-carousel-shopthelook-img-container {
                width: 72.34%;
                height: auto !important;
                margin: auto;
                float: none;
                margin-bottom: 40px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .product-carousel-window {
                width: 100%;
                margin: 0 0 0px auto;
                .product-owl-carousel {
                    .productitem {
                        padding-top: 0;
                    }
                }
            }
        }
    }
    .popular-owl-carousel{
    	padding-bottom: 40px;
    }
}


.productitem .productimg img.image-missing {
	width: 100%;
	display: inline-block;
	vertical-align: middle;
}

.pdpGallery-owl-carousel .image-fixed-aspect .pdp-item-link:before, .pdpGallery-owl-carousel .video-fixed-aspect .pdp-item-link:before, .pdp-owl-carousel .image-fixed-aspect .pdp-item-link:before, .pdp-owl-carousel .video-fixed-aspect .pdp-item-link:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
}

.pdpGallery-owl-carousel .productimgmissing .image-missing {
	width: 100%;
	height: 100%;
}

.pdp-owl-carousel .item .pdp-item-link img:not(.image-missing) {
    cursor: crosshair;
}

	.rrplaceholder .popular-owl-carousel h2{
			margin-bottom: 8px;
	}

.productitem.showoverlay .productimg {
    .image.matchproduct a:before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle; 
        position: absolute;       
    }
    &:after {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.productitem .productimg {
    .image.matchproduct a:before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;
        position: absolute;        
    }
    &:after {
        content: "";
        display: block;
        padding-top: 100%;
    }
}