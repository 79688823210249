// Deck
//
// Container component. <br>
// Can not be used on its own, but only act as a containing parent to any other block of content that takes up its full parenting width.<br>
// A Deck acts as a horizontal block to have content in. Every section on each page should be placed inside a Deck.<br><br>
// As default it has a little margin in the bottom, other than that it's unstyled. <br>
//
// DOD: wip
//
// Markup:
// <section class="deck {{ modifier_class }}">
//     <div class="designsystem-box"></div>
// </section>
// <section class="deck {{ modifier_class }}">
//     <div class="designsystem-box tall-box"></div>
// </section>
// <section class="deck {{ modifier_class }}">
//     <div class="designsystem-box"></div>
// </section>
//
// Weight: 1
//
// Style guide: core.deck

@keyframes pageLoad {
    from {
        transform: translateY(rem(10));
        opacity: 0;
    }
    
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.deck {
    animation: pageLoad 1s forwards ease;
    position: relative;
    margin-bottom: rem(30);

    @include mq($from: screen-md) {
        margin-bottom: rem(60);
    }
    
    &--double-margin-bottom {
        margin-bottom: rem(60);
    
        @include mq($from: screen-md) {
            margin-bottom: rem(120);
        }
    }

    &--trippel-margin-bottom {
        margin-bottom: rem(90);
    
        @include mq($from: screen-md) {
            margin-bottom: rem(180);
        }
    }

    &--padding {
        padding: 0 rem(12);
        
        @include mq($from: screen-xs) {
            padding: 0 rem(24);
        }
    }
    
    &--border {
        @include mq($from: screen-xs) {
            border: 1px solid $color-border;
        }
    }
}