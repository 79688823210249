.giftcartbanner-container {
    background-color: #FAFAFA;
    padding: 16px 0 20px 0;
    text-align: center;
    margin-top: 31px;
    h2 {
        font-family: $font-serif;
        background-color: transparent;
        color: #292929;
        text-transform: none;
        font-size: 25px;
        font-weight: normal;
        text-align: center;
        line-height: normal;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    .giftcart-description {
        @include typeface(serif);
        //font-family: "Gill Sans Light";
        text-align: center;
        font-size: 19px;
        line-height: normal;
        margin: -1px auto 14px auto;
    }
    .button-style {
        width: 200px;
    }
}