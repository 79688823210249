// Footer banner
//
// Standalone component.<br>
// A banner that takes up its full width. Has a light text color, and therefore it demands a dark background color.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/banner-footer.twig
// 
// Weight: 1
//
// Style guide: components.banner-footer

.banner-footer {
    position: relative;

    text-align: center;
    color: $color-white;

    padding: rem(72) rem(48);
    
    @include mq($from: screen-sm) {
        padding: rem(96);
    }

    button {
        margin-top: rem(24);
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__background-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        opacity: .04;

        background-repeat: no-repeat;
        background-size: cover;
    }
}