.input-checkbox-w-text {
    display: flex;
    align-items: flex-start;

    input {
        display: none;

        &[type=checkbox]:checked {
            & + div:before {
                opacity: 1;
            }

            &[disabled] {
                & + div:before {
                    opacity: .4;
                }
            }
        }
    }

    .checkbox {
        position: relative;
        padding: rem(10);
        margin-right: rem(10);
        font-size: 0;

        display: block;
        
        border: 1px solid $color-border;

        &:before {
            transition: opacity $transition-very-fast;
            opacity: 0;

            content: " ";
            background: center / 50% no-repeat url('../images/svg/checkmark.svg');
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            pointer-events: none;
            
            display: flex;
            align-items: center;
            justify-content: center;
            
            font-size: 1rem;
        }
    }

    &__text {
        h4 {
            @include typeset(display-5);
            
            margin: rem(1) 0 0 0;
        }

        p small {
            @include typeset(body-small);
        }
    }
}