.align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    &--center {
        text-align: center;
        
        * {
            text-align: center;
        }
    }
}

// .card-padding {
//     padding: rem(0) rem(24) rem(24);
//     margin-top: rem(-48);
    
//     @include mq($from: screen-xs) {
//         padding: rem(0) rem(60) rem(60);
//     }
// }

// .border-bottom {
//     border-bottom: 1px solid $color-border;
// }