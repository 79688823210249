// Logo wall
//
// Standalone component.<br>
// Is composed of a heading, a list of icon-links, and a button.<br><br>
// The icons scale on hover.<br>
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/logo-wall.twig
//
// examplelink: /examples/store-pages/stores-single||Can be seen in use here
//
// Weight: 1
//
// Style guide: components.logo-wall

.logo-wall {
    text-align: center;
    border: 1px solid $color-border;

    padding: rem(35) rem(25);
    @include mq($from: screen-xs) {
        padding: rem(35) rem(0);
    }
    @include mq($from: screen-sm) {
        padding: rem(50) rem(25);
    }

    &__heading {
        h2 {
            @include typeface(serif);
            @include typeset(display-2);
            // font-family: $font-serif;
            // font-size: rem(35);
            // font-style: normal;
            // text-transform: none;
            // line-height: rem(45);
            
            margin: 0;
            padding: 0;
        }
    }

    &__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        padding: rem(15) 0;
        
        &__item {
            padding: rem(10) 0;

            display: flex;
            width: 50%;

            align-items: center;
            justify-content: center;

            @include mq($from: screen-xs) {
                width: calc(100% / 3 - 1px); // -1px = IE11
            }
            @include mq($from: screen-md) {
                width: calc(100% / 4 - 1px);
                padding: rem(15) 0;
            }
            @include mq($from: screen-lg) {
                width: calc(100% / 5 - 1px);
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;
                height: 100%;
                
                &:hover, &:focus {
                    svg, img {
                        transform: scale(1.05)
                    }
                }
            }
            
            svg, img {
                transition: transform $transition-very-fast;
                transform: translateZ(0);
                max-width: rem(110);
                max-height: rem(50);
            }
        }
    }

    &__cta {
        button {
            width: 100%;

            @include mq($from: screen-xs) {
                width: auto;
            }
        }
    }
}