.selectmenu {
    .ui-selectmenu-button {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        outline: 0;

        .ui-selectmenu-icon {
            position: absolute;
            right: 0;
            left: auto;
            bottom: calc(50% - 3px);
            top: auto;
            background-image: url(../images/sb_downarrow.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 9px;
            height: 5px;
        }

        .ui-selectmenu-text {
            @include typeset(body-normal);
            // font-size: 16px;
            // line-height: 1.4;
            
            text-align: left;
            padding: 14px 25px 14px 0;
            margin-left: 34px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ui-selectmenu-menu {
        @include typeset(body-small);
        // font-size: 14px;
        // line-height: normal;
        
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        text-align: left;
        z-index: 11;

        &.ui-selectmenu-open {
            display: block;
        }

        .ui-menu {
            background-color: #ffffff;
            overflow: auto;
            overflow-x: hidden;
            padding: 15px 20px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);

            .ui-menu-item {
                line-height: 32px;
                cursor: pointer;

                .ui-state-active {
                    background-image: none;
                    color: #909090;
                }

                &.ui-menu-item-selected,
                &.ui-menu-item-selected .ui-state-active {
                    font-weight: 600;
                    cursor: default;
                    color: #292929;
                }
            }
        }
    }
}

.variant-selectmenu {
    &.ui-selectmenu-open {
        display: block;
    }

    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 3;

    .ui-menu {
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
        max-height: 270px;
        overflow-y: auto;
        background-color: #ffffff;

        .ui-menu-item {
            @include typeset(body-normal);
            // font-size: 16px;
            // font-family: "Gill Sans light", sans-serif;
            // font-weight: bold;
            
            line-height: 48px;
            position: relative;
            padding: 0 20px;
            border: 1px solid #edecec;
            margin-top: -1px;
            cursor: pointer;

            &.ui-state-selected {
                font-weight: bold;
                // font-family: $font-sans-serif;
            }

            &.noSpan .ui-menu-item-wrapper {
                padding-left: 0;
            }

            &.nostock {
                // MAG-45532  Produktside - På lager notifikation
                padding-right: 100px;

                .ui-menu-item-wrapper {
                    color: #a3988b;

                    //                    &:after {
                    ////                        content: " - Ikke p\uE5\uA lager";
                    //                        content: " - TEST!";
                    //                        text-transform: uppercase;
                    //                    }
                    //                        &:after {
                    //                            content: " - TEST!";
                    //                            text-transform: uppercase;
                    //                        }                        
                    .nostocklink {
                        position: absolute;
                        bottom: 11px;
                        right: 10px;
                        text-decoration: underline;
                        padding: 2px;
                        width: auto;
                    }

                    .ui-icon {
                        padding-left: 0;
                        margin-top: 0;
                    }
                }
            }

            .ui-menu-item-wrapper {
                // font-weight: 300;
                padding-left: 41px;
                display: block;
                line-height: 21px;
                margin-top: 13px;
                margin-bottom: 13px;
                white-space: normal;

                &.ui-state-active {
                    background-image: none;
                }

                &:hover {
                    color: #919191;
                }

                .innerWrapper {
                    //                        width: calc(100% - 92px);
                    width: 100%;
                    max-height: 42px;
                    display: block;
                }

                .ui-icon {
                    position: absolute;
                    padding-left: 0;
                    margin-top: 0;
                    display: inline-block;
                    height: 26px;
                    width: 26px;
                    display: inline-block;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
            }
        }
    }
}


@media screen and (max-width: 1032px) {
    .selectmenu {
        .ui-selectmenu-button span {
            &.ui-selectmenu-text {
                // font-size: 14px;
                padding: 0 25px 11px 0;
                margin-left: 34px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .productsearch & .ui-selectmenu-button span.ui-icon {
            bottom: calc(50% + 4px);
        }
    }
}

#grid-sort-header {
    visibility: hidden;
    width: 156.09px;
}