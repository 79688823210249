.giftpromo {
    border: 1px solid;
    text-align: center;
    padding: 18px;
    margin-top: 40px;
    margin-bottom: 30px;
    .giftpromomsg {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .giftpromolink {
        @include typeface(sans-serif);
        // font-family: "Gill Sans light";
        font-size: 17px;
        a {
            color: #292929;
            text-decoration: underline;
        }
        img {
            height: 14px;
            margin-left: 2px;
            vertical-align: middle;
        }
    }
}

// Overlay
.ui-dialog {
    .gwpdetail-container {
        .overlay-header {
            margin-bottom: 29px;
        }
        .overlay-content {
            border: none;
            overflow: hidden;
            .gwpdetail-imagecontainer {
                width: 100%;
                font-size: 0;
                line-height: 0;
                margin-bottom: 30px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .gwpdetail-desc {
                margin-bottom: 30px;
            }
            .overlay-footer {
                margin-top: 33px;
                .button-style.campaign-button {
                    width: 100%;
                }
                .button-style.button-style--light {
                    margin-top: 20px;
                }
            }
        }
    }
}