.searchresults {
    .navigation {
        border-top: 1px solid #e7e7e7;
        div:first-of-type {
            border-width: 0;
        }
        .navgroup {
            &.Produkter{
                .groupname {
                    // sorthvid
                    // padding: 31px 0 24px 0;
                }
            }
        }
    }
}