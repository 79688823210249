.categorybanner-container {
    //	h2.categoryheader{
    //        padding-top: 0px;
    //        margin-bottom: 16px;
    //    }
    padding-bottom: 50px;
    clear: both;

    .categorybanners {
        max-width: 1170px;
        margin: auto;
        font-size: 0px;
        line-height: 0px;
        padding: 0 2%;

        .categorybanner-element {
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            width: 33.333%;
            padding: 0 1%;

            &:first-child {
                margin-left: 0;
            }

            .categorybanner-imagecontainer {
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .categorybanner-text {
                @include typeset(edge-1);
                text-shadow: $shadow-1px;
                // font-size: 40px;
                // line-height: 43px;
                // color: #292929;
                // font-weight: 600;

                position: absolute;
                text-align: center;
                word-spacing: 100vw;
                top: calc(50% - 6px);
                left: 50%;
                transform: translate(-50%, -50%);

                &.categorybanner-text--white {
                    color: #ffffff;
                }
            }
        }
    }
}

.categorybannermenucontainer {
    margin: -12px auto 0 auto;
    padding-bottom: 89px;
    max-width: 1170px;

    .categorybannermenu {
        padding: 0 1%;
        font-size: 0px;
        line-height: 0px;

        li {
            display: inline-block;
            width: 25%;
            padding: 5px 1%;
            box-sizing: border-box;
        }

        a {
            @include typeset(body-large);
            // color: #292929;
            // font-size: 18px;
            // line-height: normal;
            
            padding: 0;
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.showmorecategories {
    display: none;
}

.topbanner-carousel-container {
    .item {
        .topbanner-imagecontainer {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}

.gtm-banner-element {
    img {
        width: 100%;
        height: auto;
        //padding-bottom: 1%;
    }
}

.herobanner-container {
    margin: 0 -30px;
    padding-bottom: 97px;

    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }

    .herobanner-owl-carousel {
        display: block;

        .item {
            .singleherobanner {
                img {
                    width: 100%;
                }
            }

            .doubleherobanner {
                img {
                    width: 50%;
                    float: left;
                }
            }

            .tribleherobanner {
                img {
                    width: 33.33%;
                    float: left;
                }
            }

            &:not(:first-child) {
                display: none;
            }
        }

        .owl-nav {
            display: none;

            .owl-prev {
                background-image: none;
                position: absolute;
                height: 60px;
                width: 60px;
                opacity: .4;
                background-color: #ffffff;
                border-radius: 50%;
                z-index: 10;
                top: calc(50% - 30px);
                right: calc(100% - 90px);
            }

            .owl-next {
                background-image: none;
                position: absolute;
                height: 60px;
                width: 60px;
                //	            opacity: .4;
                //	            background-color: #ffffff;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, .08);
                border-radius: 50%;
                z-index: 10;
                top: calc(50% - 30px);
                left: calc(100% - 90px);

            }

            .owl-next-inner,
            .owl-prev-inner {
                position: absolute;
                top: calc(50% - 12px);
                left: calc(100% - 35px);
                z-index: 20;
                background-image: url(../images/svg/frem_pil_ikon.svg);
                background-size: 200% 100%;
                background-repeat: no-repeat;
                width: 14px;
                height: 24px;
                background-position: left -5px center;
            }

            .owl-next-inner {
                background-image: url(../images/svg/frem_pil_ikon.svg);
                background-size: 200% 100%;
                background-repeat: no-repeat;
                width: 14px;
                height: 24px;
                background-position: left -5px center;
            }

            .owl-prev-inner {
                left: auto;
                right: calc(100% - 39px);
            }
        }

        &:hover {
            .owl-nav {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .categorybannermenucontainer {
        .categorybannermenu {
            height: 130px;
            overflow: hidden;

            li {
                width: 50%;
            }
        }

        .showmorecategories {
            display: block;
            text-align: center;
            padding-top: 20px;

            .showmorebutton {
                @include typeset(body-normal);
                // font-size: 18px;

                display: inline-block;
                cursor: pointer;
                border: 1px solid #292929;
                padding: 10px 30px;
            }

            .showlessbutton {
                @include typeset(body-normal);
                // font-size: 18px;
                
                display: none;
                cursor: pointer;
                border: 1px solid #292929;
                padding: 10px 30px;
            }
        }

        &.showall {
            .categorybannermenu {
                height: auto;
            }

            .showmorecategories {
                .showmorebutton {
                    display: none;
                }

                .showlessbutton {
                    display: inline-block;
                }
            }
        }
    }

    //     .singleherobanner{
    //         margin: 0 -20px;
    //     }
    //     .doubleherobanner{
    //         margin: 0 -20px;
    //     }
    .herobanner-container {
        margin: 0 -20px;
        padding-bottom: 40px;

        .herobanner-owl-carousel {
            .owl-nav {
                display: block;
            }
        }
    }

    .categorybanner-container {
        padding-bottom: 4%;

        .categorybanners {
            .categorybanner-element {
                width: 50%;
                margin: 0;
                box-sizing: border-box;
                padding: 0.5%;

                // .categorybanner-text {
                //     font-size: 25px;
                //     line-height: 21px;
                // }
            }
        }
    }

    .categorybannermenucontainer {
        margin: -13px auto 0 auto;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 640px) {
    .categorybanner-container {
        padding-bottom: 25px;

        .categorybanners {
            //            margin-bottom: 0;
            width: calc(100% + 5px);
            margin-bottom: -5px;
            padding: 0;

            .categorybanner-element {
                //                padding: 0.5%;
                padding: 0 0 5px 0;
                width: calc(50% - 5px);
                margin-right: 5px;

                .categorybanner-text {
                    font-size: 22px;
                }
            }
        }
    }

    .categorybannermenucontainer {
        padding-bottom: 40px;
        margin: -12px auto 0 auto;

        .categorybannermenu {
            height: 135px;
            padding: 0;

            li {
                width: 50%;
                padding: 5px 0 5px 21px;
            }
        }

        .showmorecategories {
            padding-top: 15px;

            .showmorebutton {}

            .showlessbutton {}
        }

        &.showall {
            .categorybannermenu {}

            .showmorecategories {
                .showmorebutton {
                    display: none;
                }

                .showlessbutton {
                    display: inline-block;
                }
            }
        }
    }
}

//@media screen and (max-width: 480px){
//    .categorybanner-container{
//         .categorybanners {
//             margin-bottom: 13px;
//        }
//    }
//}

@media screen and (max-width: 480px) {
    .herobanner-container {
        margin: 0px -10px;
        overflow: hidden;
    }
}