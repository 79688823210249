.shoppingbasketcontainer {
	height: auto;
	width: auto;
	display: inline-block;
	float: left;
	position: relative;
	padding-right: 19px;

	.shoppingbasket {
		cursor: pointer;
		z-index: 3;
		height: 75px;
		box-sizing: border-box;

		.price {
			float: right;
			padding: 5px 0px 0px 0px;
			font-weight: bold;
		}
	}

	.topbg {
		position: absolute;
		top: -16px;
		left: 0;
		display: none;
	}

	.overlay {
		position: absolute;

		img {
			width: 100%;
		}
	}

	.icon {
		float: right;
		background-image: url(../images/basket_icon.svg);
		background-repeat: no-repeat;
		background-size: 20px;
		background-position: center 34px;
		width: 50px;
		padding: 33px 10px 20px 10px;
		position: relative;
		height: 75px;
		box-sizing: border-box;

		.amountinbasket {
			display: block;
			position: absolute;
			right: 9px;
			top: 25px;
			width: 20px;
			line-height: 20px;
			text-align: center;
			background-color: #9FBDC3;
			border-radius: 50%;
			color: #ffffff;
			font-size: 12px;

			&.empty {
				display: none;
			}
		}
	}

	.arrow {
		display: none;
		float: right;
		background-image: url(../images/sb_downarrow.png);
		background-repeat: no-repeat;
		height: 7px;
		width: 10px;
		margin: 25px 10px 0 0;
	}

	.content {
		display: none;

		.emptycart {
			padding: 20px 20px 0;
		}

		.sbbuttons {
			padding: 20px;

			.paybutton {
				width: 100%;

				.button {
					margin: 0;

					.primary {
						background-image: none;
						background-color: #9FBDC3;
						margin: 0;

						fieldset {
							border: none;
							margin: 0;
							padding: 0;

							button {
								@include typeset(body-normal);
								// font-size: 16px;
								// font-family: $font-sans-serif;
								text-transform: none;
								
								background-image: none;
								margin: 0;
								width: 100%;
								height: 50px;
							}
						}
					}
				}
			}
		}
	}
}

.basket__no-collide .shoppingbasketcontainer .shoppingbasket:hover .content .sbbuttons .paybutton .button {
	width: 100%;
}

@media screen and (min-width: 769px) {
	.shoppingbasketcontainer {
		.shoppingbasket {
			&:hover {
				border-bottom: 2px solid #292929;

				.content {
					display: block;
					width: 340px;
					position: absolute;
					right: 0;
					border: 1px solid #E7E7E7;
					background-color: #FFFFFF;
					box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.17);
					top: 100%;
					z-index: 99;

					.item {
						border-bottom: 1px solid #e7e7e7;
						position: relative;

						&:last-of-type {
							border-width: 0;
						}

						.image {
							position: absolute;
							margin: 15px 10px 0px 10px;
							line-height: 0px;
							min-width: 73px;
							text-align: center;
							height: 73px;

							a {
								display: inline-block;
								text-align: center;
								font-size: 0px;
								line-height: 0px;
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;

								&:before {
									content: "";
									display: inline-block;
									vertical-align: middle;
									width: 0px;
									height: 100%;
								}

								.productimgmissing {
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
								}
							}

							img {
								max-height: 100%;
								max-width: 100%;
								width: auto;
								height: auto;
								margin: auto;
								vertical-align: middle;
								display: inline-block;
							}
						}

						.product {
							width: 145px;
							padding: 15px 5px 10px 0px;
							text-align: left;
							margin-left: 93px;

							.name {
								font-weight: bold;
								text-transform: uppercase;

								a {
									@include typeset(body-normal);
									// background-color: transparent;
									// color: #292929;
									// font-weight: 300;
									// line-height: 16px;
									// font-family: "gill sans light", sans-serif;
									margin: 0;
									padding: 0;
								}
							}
						}

						.numbers {
							float: left;
							width: 100%;
							padding: 0 20px 18px;
							box-sizing: border-box;

							.amount {
								width: 54px;
								height: 24px;
								display: inline-block;
								margin-left: 73px;
								position: relative;

								.input-number {
									width: 100%;
									height: 24px;
									text-align: center;
									box-sizing: border-box;

									&:disabled {
										background-color: #ffffff;
										border-width: 1px;
										border-style: solid;
										border-radius: 3px;
										border-color: #E7E7E7;
									}
								}

								.btn-number {
									&.disabled {
										color: #C4C4C4;
									}
								}

								.btn-number[data-type="minus"] {
									position: absolute;
									left: 0;
									top: 0;
									width: 33%;
									height: 100%;
									-webkit-appearance: none;
									background: transparent;
									border: none;
									cursor: pointer;

									span {
										&:before {
											content: "-";
											line-height: 22px;
											height: 22px;
											display: inline-block;
										}
									}
								}

								.btn-number[data-type="plus"] {
									position: absolute;
									right: 0;
									top: 0;
									width: 33%;
									height: 100%;
									-webkit-appearance: none;
									background: transparent;
									border: none;
									cursor: pointer;

									span {
										&:before {
											content: "+";
											line-height: 22px;
											height: 22px;
											display: inline-block;
										}
									}
								}
							}

							.price {
								@include typeset(body-normal);
								// font-size: 16px;
								
								span {
									@include typeset(body-normal);
									// font-size: 16px;
								}
							}

							.stockErrorMessage {
								@include typeset(body-small);
								// font-style: italic;
								// font-size: 13px;
								// font-family: "gill sans light", sans-serif;
								display: none;
								margin-left: 75px;
								margin-top: 7px;
								color: #D90000;
							}
						}
					}

					.emptycart {
						padding: 20px 20px 0;
					}

					.removeitem {
						position: absolute;
						right: 20px;
						top: 17px;
					}

					.sumline {
						// font-family: "Gill Sans Light", sans-serif;
						border: 1px solid #e7e7e7;
						border-width: 1px 0px;
						padding: 19px 20px 18px 20px;

						.price {
							@include typeset(body-normal);
							// font-size: 16px;
							float: right;
							padding: 0px;
							margin-bottom: 6px;
						}
						
						.productsQty {
							@include typeset(body-normal);
							// font-size: 16px;
							float: left;
							padding: 0px;
							margin-bottom: 6px;
						}
						
						.delivery {
							@include typeset(body-normal);
							// font-size: 16px;
							float: left;
						}
						
						.deliveryprice {
							@include typeset(body-normal);
							// font-size: 16px;
							float: right;
							font-weight: bold;
						}

						.total {
							float: left;
							font-size: 23px;
							line-height: 26px;
							margin-top: 14px;
							font-family: $font-sans-serif;
						}

						.totalprice {
							float: right;
							font-size: 23px;
							line-height: 26px;
							margin-top: 14px;
							font-family: $font-sans-serif;
							font-weight: bold;
						}
					}

					.sbbuttons {
						padding: 20px;

						.paybutton {
							width: 100%;

							.button {
								margin: 0;

								.primary {
									background-image: none;
									background-color: #9FBDC3;
									margin: 0;

									button {
										@include typeset(body-normal);
										// font-family: $font-sans-serif;
										// background-image: none;
										// text-transform: none;
										// font-size: 16px;
										margin: 0;
										width: 100%;
										height: 50px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.shoppingbasketcontainer {
		.icon {
			width: 20px;
			padding: 33px 0px 20px 0px;
		}
	}
}