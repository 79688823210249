.producttop {
    margin-top: 47px;
    margin-bottom: 108px;

    .productpage {
        padding: 0 30px;
        margin: auto;

        .pictureside {
            box-sizing: border-box;
            padding: 0;
            width: 64.3642%;
            float: left;

            .pictureside-inner {
                position: relative;
                width: calc(100% - 128px);
                box-sizing: border-box;
                margin-left: 128px;

                .pdp-owl-carousel {
                    width: 97.6%;
                    overflow: hidden;

                    .image-fixed-aspect {
                        position: relative;

                        &:before {
                            display: block;
                            content: "";
                            width: 100%;
                            padding-top: 100%;
                        }

                        .pdp-item-link {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            text-align: center;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;

                            &:before {
                                content: "";
                                display: inline-block;
                                vertical-align: middle;
                                width: 0;
                            }

                            img {
                                display: inline-block;
                                max-height: 100%;
                                max-width: 100%;
                                width: auto;
                                height: auto;
                                vertical-align: middle;
                            }
                        }
                    }

                    .item-video {
                        white-space: nowrap;
                        font-size: 0;
                        line-height: 0;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;

                        .owl-video-wrapper {
                            width: 100%;

                            .owl-video-play-icon {
                                background-image: url(../images/svg/video_player.svg);
                                background-size: 150px 150px;
                                background-position: center center;
                                height: 124px;
                                width: 124px;
                                margin: -62px 0 0 -62px;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                cursor: pointer;
                                z-index: 1;
                                -webkit-backface-visibility: hidden;
                                transition: transform 100ms ease;
                            }
                        }
                    }

                    .owl-nav {
                        .owl-prev {
                            position: absolute;
                            top: calc(50% - 50px);
                            left: 21px;
                            opacity: 0;
                        }

                        .owl-next {
                            position: absolute;
                            top: calc(50% - 50px);
                            right: 21px;
                            opacity: 0;
                        }
                    }

                    &:hover {
                        .owl-nav {

                            .owl-next,
                            .owl-prev {
                                //                                opacity: 0.8;
                                opacity: 1;
                            }
                        }
                    }

                    //                    @media screen and (min-width: 1276px){ // Need this block? Contact BSD!
                    //                        .owl-dots {
                    //                            display: none;
                    //                        }
                    //                        .owl-nav .owl-prev, .owl-nav .owl-next {
                    //                            top: calc(50% - 30px);
                    //                        }
                    //                    }
                }

                .pdp-nav-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform-origin: top left;
                    transform: rotate(90deg);
                    padding-top: 28px;

                    .pdp-nav-owl-carousel {
                        .owl-nav {
                            .owl-prev {
                                width: 12px;
                                height: 22px;
                                position: absolute;
                                top: calc(50% - 10px);
                                right: calc(100% + 17px);
                            }

                            .owl-next {
                                width: 12px;
                                height: 22px;
                                position: absolute;
                                top: calc(50% - 10px);
                                right: auto;
                                left: calc(100% + 17px);
                            }
                        }
                    }

                    .pdp-nav-owl-carousel--with-nav {
                        margin: 0 29px;

                        .owl-nav {
                            display: block;

                            .owl-prev.disabled {
                                opacity: .2;
                                cursor: default;
                            }

                            .owl-next.disabled {
                                opacity: .2;
                                cursor: default;
                            }
                        }
                    }

                    .owl-item {
                        a {
                            width: 100px;
                            height: 100px;
                            box-sizing: border-box;
                            border: 1px solid #ffffff;
                            display: inline-block;
                            text-align: center;
                            font-size: 0px;
                            line-height: 0px;
                            transform: rotate(-90deg);

                            &:before {
                                content: "";
                                display: inline-block;
                                vertical-align: middle;
                                width: 0px;
                                height: 100%;
                            }

                            img {
                                max-height: 100%;
                                max-width: 100%;
                                width: auto;
                                height: auto;
                                margin: auto;
                                vertical-align: middle;
                                display: inline-block;
                            }

                            .productimgmissing {
                                margin: auto;
                                display: inline-block;

                                img {
                                    width: 100%;
                                }
                            }
                        }

                        &.selected {
                            a {
                                border-color: #D9D6D6;
                            }
                        }
                    }
                }

                .product-video-container {
                    width: 100px;
                    position: absolute;
                    left: -100px;
                    margin-left: -28px;

                    .videolink {
                        display: inline-block;
                        margin-top: 24px;

                        &.selected .pdpNav-item-link {
                            border-color: #D9D6D6;
                        }
                    }

                    .pdpNav-item-link {
                        position: relative;
                        display: inline-block;
                        border: 1px solid #ffffff;
                        width: 98px;
                        height: 98px;
                        font-size: 0px;
                        line-height: 0px;

                        &:before {
                            content: "";
                            display: inline-block;
                            height: 100px;
                            vertical-align: middle;
                        }

                        div {
                            position: absolute;
                            height: 80px;
                            width: 80px;
                            left: 50%;
                            top: 50%;
                            margin-left: -40px;
                            margin-top: -40px;
                            background: url(../images/owl.video.play.png) no-repeat;
                            cursor: pointer;
                            z-index: 1;
                        }

                        .owl-video-play-icon {
                            background-image: url(../images/svg/video_player.svg);
                            background-size: 50px 50px;
                            background-position: center center;
                            height: 42px;
                            width: 42px;
                            left: 50%;
                            top: 50%;
                            margin-left: -21px;
                            margin-top: -21px;
                        }

                        img {
                            width: 100%;
                            vertical-align: middle;
                        }
                    }
                }
            }

            /*
            .largeproductcarousel{
                .jcarousel-container-horizontal {
                    width: 270px;
                    padding: 12px 26px 0px 26px;
                    .jcarousel-clip-horizontal {
                        width: 270px;
                        height: 330px;
                    }
                }
            }
*/
        }

        .textside {
            width: 34.55%;
            float: right;

            .productarea {
                margin-bottom: 0px;

                .contenttop {
                    .headline {
                        h1 {
                            @include typeset(body-large);
                            // font-family: "Gill Sans light", sans-serif;
                            // font-weight: bold;
                            // font-size: 26px;
                            // line-height: 34px;
                            // width: auto;
                            // padding: 0;

                            a {
                                color: #292929;
                                // font-style: normal;
                            }
                        }
                    }

                    h3 {
                        @include typeset(display-3);
                        // font-family: "Gill Sans light", sans-serif;
                        // text-transform: none;
                        // font-size: 26px;
                        // line-height: 34px;
                        // color: #292929;
                        // font-style: normal;
                        overflow: hidden;
                    }

                    .productpricecontainer {
                        line-height: 34px;

                        .productprice {
                            @include typeset(body-large);
                            // font-weight: bold;
                            // font-size: 26px;
                            margin-bottom: 50px;

                            span {
                                font-weight: bold;
                            }
                            
                            .beforeprice {
                                // font-family: "Gill Sans light", sans-serif;
                                // font-weight: bold;
                                // background-color: transparent;
                                // font-size: 26px;
                                // line-height: 34px;
                                
                                float: left;
                                text-decoration: line-through;
                                color: #808080;
                                clear: right;
                                margin: 0 11px -10px 0;
        
                                b {
                                    font-weight: normal;
                                }
        
                                span {
                                    // font-size: 26px;
                                    font-weight: normal;
                                }
                            }
                        }

                        .badgegoodieprice {
                            display: inline-block;
                            position: relative;
                            color: #7FCFD2;

                            .badgegoodieprice-dialogbox-pointer {
                                display: inline-block;
                                width: 13px;
                                height: 13px;
                                position: absolute;
                                transform-origin: 0 0px;
                                transform: rotate(-45deg);
                                background-color: #7FCFD2;
                                z-index: -1;
                                left: calc(50% - 6.5px);
                                bottom: -47px;
                            }
                        }

                        .badgegoodieprice-dialogbox {
                            background-color: #7FCFD2;
                            text-align: center;
                            color: #ffffff;
                            margin-top: -16px;
                            padding: 13px 20px 12px 20px;
                            text-align: center;
                            line-height: 25px;
                            margin-bottom: 30px;

                            p {
                                @include typeset(body-normal);
                                // font-size: 16px;
                                // font-weight: 300;
                                
                                margin: 0;

                                em {
                                    font-style: normal;
                                    font-weight: 600;
                                }

                            }

                            a {
                                color: #ffffff;
                                font-weight: 600;
                                text-decoration: underline;

                                &:hover {
                                    color: #ffffff;
                                    text-decoration: underline;
                                }
                            }

                        }
                    }
                }
            }

            .productbadge {
                display: inline-block;
                font-size: 0;
                //           		line-height: 25px;
                padding: 6px 25px;
                margin-bottom: 30px;
                height: 40px;
                box-sizing: border-box;
                margin-top: -20px;

                &.fdpbadge {
                    margin-bottom: 30px;
                    display: block;
                    text-align: center;
                    white-space: nowrap;
                    padding: 6px 0;
                    margin-top: -20px;

                    img {
                        width: 31px;
                        margin: 0;
                    }
                }

                &:before {
                    content: "";
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                }

                span {
                    @include typeset(trumpet);
                    // font-size: 16px;
                    
                    display: inline-block;
                    vertical-align: middle;
                }

                img {
                    width: 17px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 4px;
                }
            }

            .variants {
                margin-top: 19px;
                margin-left: 0;

                &.variants-appendto {
                    margin-bottom: 20px;
                    position: relative;

                    .ui-selectmenu-open {
                        width: 100%;

                        .ui-menu {
                            width: 100% !important;
                        }
                    }
                }

                &.sizepicker {
                    margin-bottom: 20px;
                    display: block;
                }

                .variant-dropdown {
                    height: 50px;
                    box-sizing: border-box;
                    opacity: 0;

                    +.ui-selectmenu-button {
                        @include typeset(body-normal);
                        // font-size: 16px;
                        // font-family: "Gill Sans light", sans-serif;
                        // font-weight: bold;
                        
                        line-height: 48px;
                        display: block;
                        padding: 0 42px 0 20px;
                        border: 1px solid #edecec;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        position: relative;
                        margin-bottom: 30px;
                        margin-top: 20px;

                        .ui-icon {
                            background-image: url(../images/svg/frem_pil_ikon.svg);
                            background-repeat: no-repeat;
                            float: right;
                            background-size: 13px;
                            background-position: center;
                            display: inline-block;
                            height: 50px;
                            width: 13px;
                            transform: rotate(90deg);
                            -wekbkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            position: absolute;
                            right: 20px;
                        }

                        &-open {
                            -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.15);
                            -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.15);
                            box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.15);

                            .ui-icon {
                                transform: rotate(-90deg);
                                -wekbkit-transform: rotate(-90deg);
                                -moz-transform: rotate(-90deg);
                            }
                        }

                        .ui-selectmenu-text {
                            position: relative;
                            padding-left: 41px;

                            &.noSpan {
                                padding-left: 0;
                            }

                            .ui-color {
                                position: absolute;
                                display: inline-block;
                                height: 26px;
                                width: 26px;
                                display: inline-block;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                                -moz-transform: translateY(-50%);
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                            }
                        }
                    }
                }
            }

            .sizepicker {
                margin-bottom: 0;

            }

            .errorMessage {
                color: #ff0000;
                font-size: 11px;
                line-height: 12px;
                margin-top: -5px;
            }

            .sizepicker~.errorMessage {
                margin: 0;
            }

            #sizeGuideContainer {
                @include typeset(body-normal);
                // font-family: "Gill Sans Light", sans-serif;
                // font-size: 16px;
                text-align: left;
                margin: -23px auto 20px 0;

                a {
                    text-decoration: underline;
                }
            }

            table.addtobasket {
                position: relative;
                border-spacing: 0;

                td {
                    vertical-align: top;

                    &:nth-child(2) {
                        width: 100%;
                    }

                    &:nth-child(3) {
                        vertical-align: middle;
                    }
                }

                .amount {
                    vertical-align: top;
                    display: inline-block;
                    text-align: left;
                    width: 50px;
                    padding-right: 16px;

                    input {
                        @include typeset(body-normal);
                        // font-size: 16px;
                        // font-weight: 600;
                        // font-family: "Gill Sans light", sans-serif;
                        
                        border: 1px solid #EDECEC;
                        width: 50px;
                        height: 50px;
                        box-sizing: border-box;
                        text-align: center;
                        padding: 11px 0;
                    }

                    &.amount--nostock input {
                        background-color: #FFFFFF;
                        color: #909090;
                    }

                    &.amount--overflow input {
                        border-color: red;
                    }
                }

                .basketbutton {
                    .button-style {
                        // font-family: $font-sans-serif;
                        width: 100%;
                    }
                }

                .specialdelivery {
                    @include typeset(body-normal);
                    // font-family: "Gill Sans Light", sans-serif;
                    // font-size: 15px;
                    // line-height: 16px;
                    
                    padding-top: 7px;
                    text-decoration: underline;
                }

                .nobutton {
                    .notInStockButton {
                        @include typeset(body-normal);
                        // font-family: "Gill Sans light", sans-serif;
                        // font-weight: bold;
                        // font-size: 16px;
                        
                        line-height: 50px;
                        background-image: none;
                        height: 50px;
                        background-color: #d0d0d0;
                        letter-spacing: 0.040em;
                        width: 100%;
                        color: #ffffff;
                        text-align: center;
                        min-width: auto;
                        text-transform: none;
                        margin-top: 0;
                    }
                }

                .wishlist-wishbutton-container {
                    width: 55px;
                    display: inline-block !important;
                    text-align: center;

                    &.wishlist-wishbutton-container--wish {
                        .wishlist-wishbutton-listmenu .symbol-heart {
                            background-image: url(../images/wishlist/hjerte_solid.svg);
                        }
                    }

                    .wishlist-wishbutton-listmenu {
                        //                        position: relative;
                        //                        left: 15px;
                        //                        top: 10px;
                        //                        right: -35px;

                        .symbol-heart {
                            background-image: url(../images/wishlist/hjerte_stroke.svg);
                            background-size: 100%;
                            background-position: center center;
                            background-repeat: no-repeat;
                            width: 33px;
                            height: 33px;
                            position: absolute;
                            font-size: 0px;
                            line-height: 0px;
                            position: static;
                            cursor: pointer;
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .wishlist-dialog-container {
                            right: -136px;

                            p {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        .facttabbox {
            margin-top: 13px;
            border-bottom: 1px solid #e0e0e0;
            padding-bottom: 17px;
            float: right;
            width: 34.55%;

            .ui-state-default {
                @include typeset(body-normal);
                // font-family: "Gill Sans Light", sans-serif;
                // font-size: 18px;
                // letter-spacing: .8px;
                // color: #292929;
                // font-weight: 300;
                // line-height: 30px;
                
                background-image: none;
                margin-top: 17px;

                .accordionheader-icon {
                    position: relative;
                    float: right;
                    width: 13px;
                    height: 13px;
                    padding-top: 14px;

                    .outerdash {
                        position: absolute;
                        height: 1px;
                        width: 13px;
                        background-color: #292929;
                        transform-origin: center center;

                        .dash {
                            position: absolute;
                            height: 1px;
                            width: 13px;
                            background-color: #292929;
                        }
                    }
                }

                &.ui-accordion-header-collapsed {
                    .accordionheader-icon .outerdash .dash {
                        transform: rotate(90deg);
                    }
                }
            }

            .ui-state-active {
                font-weight: 600;
            }

            .ui-accordion-content {
                @include typeset(body-small);
                // font-family: "Gill Sans Light", sans-serif;
                // font-size: 16px;
                // line-height: 24px;
                
                padding: 0 0 8px 0;
                margin: 0;
                max-width: 415px;
                background-color: transparent;

                .infotext {
                    overflow: hidden;
                    // line-height: 24px;
                    // font-family: "Gill Sans Light", sans-serif;
                    // font-size: 16px;
                }

                .infolink {
                    text-align: left;

                    .expand-toggle {
                        // font-size: 16px;
                        // font-family: "Gill Sans Light", sans-serif;
                        // line-height: normal;
                        // line-height: 24px;
                        // font-weight: normal;
                        // font-style: normal;
                        
                        margin-top: 1px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .materialtable {
                    display: table;

                    .materialrow {
                        display: table-row;

                        .materialcell {
                            display: table-cell;
                            padding-right: 15px;
                            font-style: normal;
                        }
                    }
                }
            }

            h3 {
                cursor: pointer;
                border-top: 1px solid #e0e0e0;
                outline: none;
                font-style: normal;
                padding: 22px 0px 5px 0px;
                width: auto;
                text-transform: none;
                overflow: hidden;
            }
        }
    }
}

.producttop .productpage .textside .productbadge.fdpbadge {
    display: block;
}

.producttop .productpage .textside .productbadge.fdpbadge span {
    display: inline-block;
}

body>.ui-dialog .quickview-container .pdp-nav-container {
    display: none;
}

span#ui-id-5-button {
    margin-top: -10px;
    margin-bottom: 30px;
}

.categorybuttons-container {
    padding: 0 0 74px 0;
    margin-top: 15px;

    .button-style {
        width: 450px;
        display: block;
        margin: 0 auto 26px auto;
        font-style: normal;

        &:hover {
            background-color: #9fbdc3;
            color: #ffffff;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 769px) {
    .categorybuttons-container {
        padding: 0 0 15px 0;
        margin: 31px 15px 10px;
    }

}

@media screen and (max-width: 1650px) {
    .producttop {
        .productpage {
            .textside {
                table.addtobasket .wishlist-wishbutton-container .wishlist-wishbutton-listmenu .wishlist-dialog-container {
                    //                    right: 14px;
                    //                    top: 46px;
                    right: 1px;
                    top: 52px;

                    &:before {
                        transform: rotate(45deg);
                        top: -8px;
                        left: 106px;
                    }
                }

            }

        }
    }
}

//@media screen and (max-width: 1280px){
//    .producttop {
//        margin: 0 auto 60px auto;
//        .productpage {
//            .pdp-nav-container, .product-video-container {
//                display: none;
//            }   
//        }
//    }
//}

//@media screen and (max-width: 1376px){
@media screen and (max-width: 1024px) {
    .producttop {
        margin: 0 auto 60px auto;

        .productpage {
            padding: 0;

            .pictureside {
                width: 61.45%;

                .pictureside-inner {
                    width: calc(100% - 40px);
                    box-sizing: border-box;
                    margin-left: 0px;

                    .pdp-nav-container,
                    .product-video-container {
                        display: none;
                    }
                }
            }

            .textside {
                width: 38.55%;

                .productarea {
                    .contenttop {
                        .headline {

                            h1,
                            h3 {
                                // font-size: 20px;
                                // line-height: 28px;
                            }
                        }

                        .productpricecontainer {
                            .productprice {
                                // font-size: 21px;
                                // line-height: 26px;
                            }
                        }
                    }
                }
            }

            .facttabbox {
                margin-top: 0;
                margin-bottom: -12px;
                border-bottom: 1px solid #e0e0e0;
                padding-bottom: 17px;
                float: none;
                clear: both;
                width: 100%;
                padding-top: 18px;

                h3.ui-state-default:first-of-type {
                    margin-top: 0;
                }

                .ui-accordion-content {
                    max-width: none;
                }
            }

            .pdp-nav-container,
            .product-video-container {
                display: none;
            }

            .pdp-owl-carousel {
                .owl-dots {
                    display: block;
                    margin-top: 13px;
                    position: static;

                    &.disabled {
                        display: none;
                    }

                    .owl-dot {
                        display: inline-block;
                        font-size: 0px;
                        line-height: 0;
                        margin: 5px;
                        vertical-align: middle;

                        span {
                            background-color: #E8E8E8;
                            height: 9px;
                            width: 9px;
                            display: inline-block;
                            border-radius: 17px;
                            display: inline-block;
                            box-sizing: border-box;
                        }

                        &.active {
                            span {
                                background-color: #292929;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {

    .pdpGallery-owl-carousel,
    .pdp-owl-carousel {
        .owl-nav {
            display: none;
        }
    }
}

// New!
@media screen and (max-width: 767px) {
    .producttop {
        .productpage {
            padding: 0;

            .pictureside {
                width: 100%;

                .pictureside-inner {
                    width: 100%;

                    .pdp-owl-carousel {
                        width: 100%;
                        overflow: hidden;

                        .owl-nav {
                            display: none;
                        }

                        .owl-dots {
                            position: static;
                            margin-bottom: -3px;
                        }
                    }
                }
            }

            .textside {
                width: 100%;
                text-align: -webkit-center;

                .productarea {
                    text-align: center;

                    .contenttop {
                        margin-top: 20px;

                        // sorthvid
                        // .beforeprice {
                        //     // Generel rule!
                        //     display: inline-block;
                        //     float: none;

                        //     +.productpricecontainer {
                        //         display: inline-block;
                        //     }
                        // }

                        .productpricecontainer {
                            display: block;
                            
                            // sorthvid
                            .productprice {
                                .beforeprice {
                                    display: inline-block;
                                    float: none;
                                }
                            }

                            .badgegoodieprice {
                                .badgegoodieprice-dialogbox-pointer {
                                    bottom: -34px;
                                }
                            }

                            .badgegoodieprice-dialogbox {
                                line-height: 21px;
                                margin-top: -29px;
                                padding: 13px 20px 12px 20px;

                                p {
                                    a {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }

                .productbadge {
                    line-height: 20px;
                    padding: 4px 20px;

                    &.fdpbadge {
                        padding: 4px 0;
                        margin: -18px 14px 23px;
                    }
                }

                .variants {
                    text-align: left;
                    margin-bottom: 23px;
                }

                table.addtobasket {
                    .wishlist-wishbutton-container {
                        .wishlist-wishbutton-listmenu {
                            .wishlist-dialog-container {
                                p {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .categorybuttons-container {
        .button-style {
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    span#ui-id-5-button {
        margin-top: -17px;
    }

    .producttop {
        .productpage {
            .textside {
                .productarea {
                    margin-bottom: -20px;

                    .contenttop {
                        .productpricecontainer {
                            .productprice {
                                margin-bottom: 42px;

                                .badgegoodieprice .badgegoodieprice-dialogbox-pointer {
                                    bottom: -26px;
                                }
                            }
                        }
                    }
                }

                .variants .variant-dropdown+.ui-selectmenu-button {
                    margin-top: 23px;
                }

                .variants.sizepicker {
                    margin-bottom: -14px;
                }

                .variants.variants-appendto {
                    margin-bottom: -14px;
                }

                .productbadge.fdpbadge {
                    margin: -12px 2px 23px;
                }

                .productbadge {
                    margin-bottom: 24px;
                }
            }

            .facttabbox {
                padding-top: 23px;
            }
        }
    }

}

.pdpGallery-owl-carousel .owl-dot.owl-dot-video span,
.producttop .productpage .pdp-owl-carousel .owl-dot.owl-dot-video span {
    background-image: url(../images/svg/video_dot.svg);
    background-size: 23px 23px;
    background-position: center center;
    width: 17px;
    height: 17px;
    background-color: transparent;
    opacity: .2;
}

.producttop .pdpGallery-owl-carousel .owl-dot.owl-dot-video.active span,
.producttop .productpage .pdp-owl-carousel .owl-dot.owl-dot-video.active span {
    background-color: transparent;
    opacity: 1;
}