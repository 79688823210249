.navigationbar {
    //    padding: 7px 30px;
    display: none;
    margin-right: 16px;

    .topbanner+& {
        margin-top: 0px;
    }

    .breadcrumb {
        // sorthvid
        @include typeset(micro);
        // font-family: "Gill Sans Light", sans-serif;
        // font-size: 13px;
        
        display: none;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 25px 0 12px 0;

        a {
            // color: #292929;
            background-color: transparent;
        }

        .bcitemarrow {
            padding: 0 9px;
            background-color: transparent;
            cursor: default;
            vertical-align: 2px;
            font-size: 10px;
        }

        .lastbcitemtext {
            cursor: default;
        }
    }
}