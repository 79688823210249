.page404 {
	@include typeset(body-normal);
	// font-size: 16px;
	// line-height: 24px;
	max-width: 750px;
	margin: auto;
	padding-bottom: 30px;
	padding-top: 40px;

	.headline {
		padding: 20px 0px 20px 0px;

		h3 {
			@include typeset(display-1);
			@include typeface(serif);
			// font-style: normal;
			// font-size: 36px;
			// text-transform: none;
			// font-family: $font-serif;
		}
	}

	.bold {
		@include typeset(body-large);
		// font-size: 19px;
		// line-height: 35px;
	}

	.page404padding {
		padding: 10px 0px 15px 0px;

		ul {
			padding: 0px 0px 0px 17px;
			margin: 0px;

			li {
				padding: 0;
				position: relative;

				&:before {
					content: "\2022";
					font-size: 12px;
					vertical-align: middle;
					line-height: 23px;
					display: inline-block;
					width: 17px;
					text-align: center;
					position: absolute;
					left: -17px;
					top: 0;
				}
			}
		}

		.tryto {
			.button {
				display: inline;
				margin-left: 0px;

				.large {
					background-image: none;
					margin: 0;
					display: inline;

					input {
						background-image: none;
						margin: 0;
						padding: 0;
						height: auto;
						color: inherit;
						font-size: inherit;
						text-decoration: underline;
						text-transform: none;
					}
				}
			}
		}

		.primarylink {
			@include typeset(body-normal);
			// font-family: $font-sans-serif;
			// font-weight: normal;
			// font-size: inherit;

			a {
				// color: inherit;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.page404 {
		// font-size: 13px;
		// line-height: 19px;
		// padding-top: 20px;

		.headline {
			h3 {
				// font-size: 27px;
			}
		}

		.bold {
			// font-size: 15px;
		}

		.page404padding {
			padding: 0px 0px 15px 0px;
		}
	}
}

@media screen and (max-width: 640px) {
	.page404 {
		padding-top: 5px;

		.headline {
			padding: 10px 0px 10px 0px;

			h3 {
				// font-size: 19px;
			}
		}

		.bold {
			// line-height: 30px;
		}
	}
}