// Top Teaser
//
// Standalone component.<br>
// A simple accordion with text it it. When it's closed, the component only shows a 'read more' button.<br>
// On opening, the text fades in, in a staggering motion.<br><br>
// Uses <a href="https://greensock.com/docs/Utilities/SplitText/SplitText()">GSAP - Split Text</a> (Disabled until fix for IE11 is done)
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/top-teaser.twig
//
// Weight: 1
//
// Style guide: components.top-teaser

.top-teaser {
    position: relative;
    font-size: rem(15);
    text-align: center;

    margin: 0 rem(12);
    padding-bottom: rem(36);
    @include mq($from: screen-lg) {
        padding-bottom: rem(24);
    }

    
    input {
        position: absolute;
        top: -9999px;

        &:focus ~ label span {
            color: $color-dull-orange;
        }
    }
    
    &__content {
        transition: height $transition-very-fast;
        height: 0;
        overflow: hidden;

        & > * { // Original content
            font-family: $font-sans-serif;
            line-height: 1.6;
        }

        & > div { // "lines" outputted by gsap
            overflow: hidden;
            line-height: 1;
        }
    }

    .read-more-button {
        position: absolute;
        width: 100%;
        left: 50%;
        box-sizing: border-box;

        font-family: $font-sans-serif;
        
        span {
            position: absolute;
            transform: translateX(-50%);
            left: 0;
            
            text-decoration: underline;
            white-space: nowrap;
            line-height: 1.6;

            cursor: pointer;
        }

        .read-less-text {
            opacity: 0;
        }
    }
}