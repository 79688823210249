html {
    font-size: 100%;
}

body {
    @include typeface(sans-serif);
    font-size: 12px;
    line-height: 1.6;
    // font-family: $font-sans-serif;
    // margin: 0px;
    background-color: #ffffff;

    color: #292929;

    -webkit-overflow-scrolling: touch;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

div#instocklabel {
    color: #ff0000;
}

a {
    transition: color .2s;
    color: currentColor;
    text-decoration: none;
    // background-color: transparent;
    border: none;
    outline: none;

    &:visited {
        color: currentColor;
    }

    &:hover, &:focus {
        color: #909090;
        // text-decoration: underline;
        // background-color: transparent;
    }
}

img {
    border: none;
}

// h1 {
//     font-family: Georgia, serif;
//     font-size: 30px;
//     font-weight: normal;
//     text-transform: uppercase;
//     line-height: 32px;
//     font-style: italic;
//     margin: 0px;
//     padding: 0px 0px 0px 30px;
// }

// h2 {
//     font-family: $font-serif;
//     font-size: 36px;
//     line-height: 40px;
//     text-align: center;
//     display: block;
//     background-color: transparent;
//     color: #292929;
//     text-transform: none;
//     padding: 0;
//     margin: -10px 0px 36px 0px;
//     font-weight: normal;
//     -webkit-margin-after-collapse: separate;
// }

h2.categoryheader {
    @include typeface(serif);
    @include typeset(display-1);
    // font-family: $font-serif;
    // font-size: 38px;
    // line-height: 44px;
    // color: #292929;
    
    text-align: center;
    display: block;
    background-color: transparent;
    text-transform: none;
    margin: -5px auto 38px auto;
    margin-right: auto;
    padding: 0 2% 0 2%;
}

h2.categoryheader:empty {
    display: none; // TODO: Remove when BE are done removing empty tags.
}

@media screen and (max-width: 1031px) {
    h2.categoryheader {
        // font-size: 30px;
        // line-height: 34px;
        padding-top: 69px;
        margin-bottom: 0;
    }
}


// h3 {
//     font-family: Georgia, serif;
//     font-size: 20px;
//     font-weight: normal;
//     color: #292929;
//     text-transform: uppercase;
//     background-color: transparent;
//     font-style: italic;
//     padding: 0px;
//     margin: 0px;
//     line-height: 25px;
// }

li {
    list-style: none;
    background-repeat: no-repeat;
    background-position: left 7px;
    padding: 0px 0px 0px 10px;
    margin: 0px;

    a {
        // sorthvid
        // padding: 2px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        &:hover {
            text-decoration: none;
            color: #909090;
        }
    }
}

.headlinebar {
    @include typeface(serif);
    @include typeset(body-normal);
    // font-family: Georgia, serif;
    // font-size: 15px;
    // text-transform: uppercase;
    
    color: #ffffff;
    background-color: #292929;
    padding: 2px 5px 1px 5px;
    margin-bottom: 10px;
}

.rpinfobox {
    background-color: #FFFFE0;
    padding: 50px 0;
    text-align: center;

    .headline {
        @include typeface(serif);
        @include typeset(body-large);
        // font-family: $font-serif;
        // font-style: italic;
        // font-size: 21px;
        // text-transform: uppercase;
        
        text-align: center;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 769px) {

    .tallspot .overlay .content h2 {
        min-height: 45px;
    }

    // h2 {
    //     margin: -10px 0px 16px 0px;
    // }
}

@media screen and (max-width: 640px) {
    h2.categoryheader {
        // font-size: 24px;
        // line-height: 28px;
        
        padding: 0;
        margin: -3px auto 17px auto;
    }
}


@media screen and (max-width: 480px) {

    .tallspot .overlay .content h2 {
        padding-top: 5px;
        min-height: 55px;
    }

}