.boxondlayer {
    .addbasketproduct {
        padding: 0 15px 0 10px;
        .productcontainer {
            display: table;
            width: 100%;
            border-bottom: 1px solid #a3988b;
            .productimg {
                display: table-cell;
                vertical-align: middle;
                height: 110px;
                width: 120px;
                padding: 30px 10px;
                text-align: center;
                img {
                    max-height: 100%;
                    max-width: 100%;
                    height: auto;
                    width: auto;
                }
            }
            .productinfo {
                display: table-cell;
                vertical-align: top;
                padding: 24px 20px 0 10px;
                .iteminfo {
                    display: inline-block;
                    vertical-align: top;
                    width: 280px;
                    .itemname {
                        // untested
                        @include typeface(serif);
                        // font-family: Georgia;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 24px;
                    }
                    .itembrand {
                        // untested
                        @include typeface(serif);
                        // font-family: Georgia;
                        // font-style: italic;
                        font-size: 15px;
                        color: #a3988b;
                        line-height: 20px;
                    }
                    .itemsize {
                        font-size: 13px;
                        padding: 10px 0 15px 0;
                    }
                }
                .itemprice {
                    float: right;
                    width: 140px;
                    text-align: right;
                    font-size: 18px;
                    b {
                        font-weight: normal;
                    }
                }
                .itemamount {
                    display: inline-block;
                    vertical-align: top;
                    width: 80px;
                    text-align: right;
                    font-size: 13px;
                    float: right;
                }
                .itemdelivery {
                    border-top: 1px solid #e7e3df;
                    padding: 20px 0;
                    margin-right: -20px;
                }
            }
        }
        .amountline {
            border-bottom: 1px solid #e7e3df;
            padding: 20px 20px 20px 10px;
            .campaigncode {
                float: left;
                a {
                    font-size: 13px;
                    font-weight: bold;
                    color: #292929;
                    text-decoration: underline;
                }
            }
            .itemvalue {
                clear: right;
                float: right;
                width: 140px;
            }
            .itemlabel {
                float: right;
            }
            .subtotal {
                float: right;
                text-align: right;
                font-size: 18px;
                max-width: 400px;
                line-height: 24px;
                .itemvalue {
                    b {
                        font-weight: normal;
                    }
                }
            }
            .totalprice {
                float: right;
                text-align: right;
                font-size: 20px;
                font-weight: bold;
                .itemvalue {
                    b {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}