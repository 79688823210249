// Info banner
//
// Standalone component.<br>
// A banner that takes up its full width. Has a light text color, and therefore it demands a dark background color.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/banner-info.twig
//
// Weight: 1
//
// Style guide: components.banner-info

.banner-info {
    .new-font & {
        @include typeface(sans-serif);
    }
    
    position: relative;
    padding-bottom: rem(50);
    color: $color-white;

    @include mq($from: screen-sm) {
        padding-bottom: rem(80);
    }

    &__attentive {
        width: 100%;
        padding: rem(15) rem(24);
        box-sizing: border-box;

        background: $color-text;
        
        text-align: center;

        strong {
            @include typeset(body-small);

            font-weight: 600;
            letter-spacing: 1px;
        }
    }
    
    &__heading {
        .new-font & {
            @include typeface(serif);
        }
        @include typeset(display-2);
        
        text-align: center;
        color: $color-white;
    
        margin: 0 0 rem(20) 0;
        padding: rem(50) rem(24) 0;

        @include mq($from: screen-md) {
            margin: 0 0 rem(40) 0;
        }
    }

    &__content {
        text-align: center;
        margin: 0 auto;
        padding: 0 rem(8);
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: rem(24) rem(48);
        width: 100%;
        box-sizing: border-box;
        
        &:nth-of-type(2) {
            border: none;
        }

        @include mq($from: screen-xs) {
            flex-direction: row;
        }
        
        @include mq($from: screen-sm) {
            flex-direction: column;
            padding: 0 rem(12);
            
            &:nth-of-type(2) {
                border-left: 1px solid rgba(255,255,255,0.2);
                border-right: 1px solid rgba(255,255,255,0.2);
            }
        }

        img {
            height: rem(60);
            width: rem(60);

            margin-bottom: rem(20);

            @include mq($from: screen-xs) {
                margin-bottom: 0;
                margin-right: rem(20);
            }

            @include mq($from: screen-sm) {
                margin-bottom: rem(20);
                margin-right: 0;
            }
        }

        &__text {
            @include mq($from: screen-xs) {
                text-align: left;
            }
            @include mq($from: screen-sm) {
                text-align: center;
            }
        }

        &__trumpet {
            @include typeset(trumpet);
        }
        
        &__info {
            @include typeset(blow-up);
            
            color: $color-white;
    
            &:hover, &focus {
                color: currentColor;
            }
        }
    }
}