// Table
//
// Standalone component.<br>
// A minimal table to show larger amounds of data.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/table.twig
//
// Weight: 1
//
// Style guide: components.table

.table {
    width: 100%;
    overflow-x: auto;

    padding-bottom: rem(72);

    h3 {
        padding-top: rem(50);
    }

    footer {
        opacity: .4;
    }
}