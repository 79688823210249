%product-img-h3 {
    display: inline-block;
    width: 75%;
    height: 75%;
    vertical-align: middle;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
}

%product-img-h3-before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

%product-productprice-beforeprice {
    @include typeset(body-normal);
    // font-family: "Gill Sans Light",sans-serif;
    // font-size: 16px;
    background-color: transparent;
    text-decoration: line-through;
    padding-right: 5px;
    color: #a3988b;
}

// Generel styling for desktop/mobile
.productitemcontainer {
    .wishlist-wishbutton-container {
        position: static;
        display: inline-block;
        clear: right;
        float: right;
        margin: 5px 11px 0 0;

        .wishlist-wishbutton-listmenu {
            position: relative;

            .wishlist-wishbutton-backlight {
                background-color: #ffffff;
                border-radius: 15px;
                opacity: .7;
                height: 30px;
                width: 30px;
                position: absolute;
                top: -5px;
                right: -4px;
            }

            .wishlist-overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 190;
            }

            .symbol-heart {
                position: relative;
                width: 22px;
                height: 22px;
            }

            .wishlist-dialog-container {
                text-align: left;
                width: 143px;
            }

            .wishlist-menuselect-create-list-message {
                &:before {
                    transform: rotate(-45deg);
                    left: -8px;
                    top: 8px;
                    right: initial;
                }

                p {
                    font-size: 12px;
                    line-height: 21px;
                    text-align: left;
                }

                a {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #292929;
                }
            }
        }
    }
}

.productitem {
    box-sizing: border-box;
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
    padding: 0;

    .productimg {
        line-height: 0px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        margin: 32px auto 15px auto;
        width: 80%;
        height: auto;
        position: relative;

        .image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 0;
            // max-height: 200px;
            box-sizing: border-box;

            img {
                vertical-align: middle;
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                box-sizing: border-box;

                &.image-missing {
                    display: inline-block;
                    width: 100%;
                    vertical-align: middle;
                }
            }
        }

        h3 {
            @extend %product-img-h3;

            &:before {
                @extend %product-img-h3-before;
            }
        }
    }

    .producttext {
        @include typeset(body-normal);
        // font-size: 16px;

        text-align: center;
        margin: 14px auto 0 auto;

        &.producttext-truncated {
            overflow: hidden;
        }

        a {
            // background-color: transparent;
            // padding: 0;
            // color: #292929;
        }

        .productoverlay {
            position: absolute;
            display: none;

            .productsplashcontainer {
                right: -1px;
            }

            .productimg h3 {
                @extend %product-img-h3;

                &:before {
                    @extend %product-img-h3-before;
                }
            }

            .stock {
                // sorthvid NOTE: defined in _searchresult.scss
                // color: #292929;
                // font-size: 11px;
                // text-transform: uppercase;
                // font-weight: bold;
                margin-top: 17px;
            }

            .sizes {
                @include typeset(body-normal);
                // font-family: "Gill Sans Light",sans-serif;
                // font-size: 16px;
                // line-height: 15px;
                // color: #292929;
                // text-transform: uppercase;

                margin-top: -3px;
                text-align: center;

                ul {
                    padding: 9px 0 0 0;

                    li {
                        display: inline-block;

                        &:first-child {
                            background-image: none;
                            padding: 0;
                        }

                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }

                >a {
                    // special case!
                    display: block;
                    padding-top: 9px;
                }
            }

            .productprice {
                margin-top: 4px;

                .beforeprice {
                    @extend %product-productprice-beforeprice;
                }
            }

            .variants4wide {
                ul {
                    text-align: center;
                    padding-top: 10px;
                    margin-left: -10px;

                    li {
                        display: inline-flex;
                        background-image: none;
                        width: 44px;
                        height: 44px;
                        text-align: center;
                        padding: 0;

                        .naoverlay {
                            position: absolute;
                            display: none;
                            margin: -2px 0 0 2px;
                        }

                        .image {
                            width: 100%;

                            a {
                                border-bottom: 1px solid transparent;
                                display: block;
                                height: 100%;
                                box-sizing: border-box;

                                &:hover {
                                    border-bottom: 1px solid #292929;
                                }

                                img {
                                    width: auto;
                                    height: auto;
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }

                .jcarousel-container-horizontal {
                    width: 206px;
                    margin: auto;
                    padding-left: 3px;

                    .jcarousel-clip {
                        overflow: hidden;

                        &-horizontal {
                            width: 176px;
                            height: 44px;
                            margin: 5px 15px;

                            .jcarousel-item {
                                width: 44px;
                                height: 44px;
                                text-align: center;
                                padding: 0;
                            }
                        }
                    }

                    .jcarousel-prev-horizontal {
                        top: 0;
                        left: 0;
                        width: 17px;
                        height: 39px;
                        position: absolute;
                        cursor: pointer;
                        background: transparent url(../images/variantscroller_prev.png ) no-repeat 0 0;
                    }

                    .jcarousel-next-horizontal {
                        top: 0;
                        right: 0;
                        width: 17px;
                        height: 39px;
                        position: absolute;
                        cursor: pointer;
                        background: transparent url(../images/variantscroller_next.png ) no-repeat 0 0;
                    }

                    .jcarousel-prev-disabled-horizontal,
                    .jcarousel-next-disabled-horizontal {

                        &,
                        &:hover,
                        &:focus,
                        &:active {
                            cursor: default;
                            background: none;
                            background-position: 0px top;
                        }
                    }
                }
            }
        }

        .productname {
            @include typeset(body-small);
            // font-family: "Gill Sans Light",sans-serif;
            // font-weight: 600;
            // font-size: 16px;
            // line-height: 19px;
            
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;

            &+div {
                // font-family: "Gill Sans Light",sans-serif;
                // line-height: 19px;
                
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 5px 10px 4px 10px;
            }
        }
    }

    .productprice {
        @include typeset(body-normal);
        // font-size: 16px;
        // line-height: 19px;
        text-align: center;

        .beforeprice {
            @extend %product-productprice-beforeprice;
        }

        span {
            font-weight: 600;
        }
    }
}

.has_badge .productitem {
    .productimg {
        margin: 2px auto 15px auto;
    }
}

.productitem .productbadge {
    @include typeset(trumpet);
    // sorthvid
    // font-size: 14px;
    // line-height: normal;
    // font-size: 0;
    
    margin-top: rem(10);
    padding: 0 18px;
    display: inline-block;
    box-sizing: border-box;
    height: 30px;
    white-space: nowrap;

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
        font-size: 0;
    }

    &.fdpbadge {
        img {
            width: 28px;
            margin: 0;
        }
    }

    span {
        vertical-align: middle;
        // 8font-size: 14px;
    }

    img {
        width: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        //	    max-height: 100%;
        //	    width: auto;
        //	    vertical-align: top;
        //	    padding-top: 1px;
        //	    padding-left: 3px;
    }
}

@media screen and (min-width: 769px) {

    // special case!
    .overlaybox780--addtobasket .productitem .productbadge {
        paddign: 0 12px;

        img {
            display: none;
        }
    }


}


// Badge compensation/changes to tiles for search pages
.searchcontainer .searchresult .productitemcontainer {
    float: none;

    .productitem {
        min-height: auto;
        margin-bottom: 46px;

        .productimg {
            margin: 2px auto 15px auto;
        }
    }
}

.searchcontainer .searchresult .productitemcontainer.has_badge .productitem {
    .productimg {
        margin: 2px auto 15px auto;
    }
}

@media screen and (max-width: 769px) {
    .searchcontainer .searchresult .productitemcontainer {
        .productitem {
            margin-bottom: 11px;
        }
    }
}

// Badge compensation/changes to tiles for search pages /end!



@media screen and (min-width: 1025px) {
    .productitem {
        &.overlay-loaded:hover .productoverlay {
            display: block;
        }
    }
}

@media screen and (max-width: 1032px) {
    .productitem {

        .producttext .productname,
        .productprice {
            // font-size: 14px;
            // line-height: 16px;
        }
    }
}

.productimgmissing {
    text-align: center;
    background-color: #ffffff;
    margin-left: 1px;
    margin-right: 1px;
    font-style: normal;
    color: #A3988B;
}

.productitem .productimg img.image-missing {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.productitemcontainer .productitem.productitem--show-wishlist .productoverlay {
    display: block;
}

.productsplashcontainer {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    //    top: 12px;
    right: 0;
    z-index: 2;

    .productsplash {
        float: right;
        padding: 0px 6px 0px 6px;
        font-size: 14px;
        background-color: #ffffff;
        color: #a3988b;
        text-transform: uppercase;
        margin: 0px 5px 0px 0px;
        margin-left: 60px;
        text-align: right;
    }
}

@media screen and (max-width: 768px) {
    .productitem {
        .producttext {
            margin: 3px auto 0px auto;
        }

        .productbadge {
            // sorthvid
            // font-size: 12px;
            height: 27px;
            line-height: normal;
            padding: 0 15px;

            &.fdpbadge {
                img {
                    width: 24px;
                }
            }

            // sorthvid
            // span {
            //     font-size: 12px;
            // }
        }
    }

    .searchresult {
        .productitemcontainer {
            .productitem .producttext {
                .wishlist-wishbutton-listmenu {
                    .wishlist-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 190;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .productitem .productbadge {
        padding: 0 10px;

        img {
            display: none;
        }
    }
}