.fulpageheaddivider{
    clear: both;
    max-width: 1170px;
    margin: 15px auto 15px auto;
    
    h2 {
        // sorthvid
        @include typeset(display-1);
        @include typeface(serif);
        // font-family: $font-serif;
        // font-size: 36px;
        // line-height: 40px;
        // background-color: transparent;
        // color: #292929;
        // text-transform: none;
        text-align: center;
        display: block;
        padding: 0;
        margin: 0;
    }
}

