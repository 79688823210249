.boxmodul{
    padding: 0;
    cursor: pointer;
    margin: auto;
    width: 100%;
    clear: both;
    max-width: 1170px;
    box-sizing: border-box;
    margin-bottom: 85px;
    &.follow{
    	text-align: center;
    }
    a {
        text-decoration: none;
        color: #292929;
    }
    .box1x1{
        width: 33.333%;
        float: left;
        padding: 0 1%;
        box-sizing: border-box;
        .tallspot{
            a {
                background-repeat: no-repeat;
                >img {
                    width: 100%;
                    height: auto;
                }
            }
            .spotimg{
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .overlay {
                .content {
                    height: 82px;
                    background-image: none;
                    background-color: rgba(255,255,255,.85);
                    h1 {
                        font-family: $font-sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        text-transform: none;
                        font-style: normal;
                        line-height: 17px;
                        margin-top: 3px;
                        padding: 0;
                    }
                    h2 {
                        font-family: $font-sans-serif;
                        font-size: 12px;
                        line-height: 17px;
                        color: #292929;
                        text-transform: none;
                        background-color: transparent;
                    }
                }
            }
        }
        .overlay {
            .content {
                background-image: url(../images/focusbox_overlay_bg.png);
                text-align: center;
                padding: 0px 14px 10px;
                &.middle {
                    text-align: center;
                }
                .text {
                    font-weight: bold;
                    font-size: 11px;
                    text-transform: uppercase;
                    padding: 5px;
                }
            }
        }
    }
    .box1x2{
        width: 33.333%;
        float: left;
        padding: 0 1%;
        box-sizing: border-box;
        .tallspot{
            a {
                background-repeat: no-repeat;
                >img {
                    width: 100%;
                    height: auto;
                }
            }
            .spotimg{
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .overlay {
                .content {
                    height: 82px;
                    background-image: none;
                    background-color: rgba(255,255,255,.85);
                    h1 {
                        font-family: $font-sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        text-transform: none;
                        font-style: normal;
                        line-height: 17px;
                        margin-top: 3px;
                        padding: 0;
                     }
                    h2 {
                        font-family: $font-sans-serif;
                        font-size: 12px;
                        line-height: 17px;
                        color: #292929;
                        text-transform: none;
                        background-color: transparent;
                        margin: 0;
                    }
                   	.button {
					    background-color: transparent;
					    color: #292929;
					    border: 1px solid #989898;
					}
                }
            }
        }
        .overlay {
            .content {
                background-image: url(../images/focusbox_overlay_bg.png);
                text-align: center;
                padding: 0px 14px 10px;
                &.middle {
                    text-align: center;
                }
                .text {
                    font-weight: bold;
                    font-size: 11px;
                    text-transform: uppercase;
                    padding: 5px;
                }
               	.button {
				    color: #ffffff;
				    display: inline-block;
				    font-size: 12px;
				    text-transform: uppercase;
				    background-color: #292929;
				    border: 1px solid #ffffff;
				    font-weight: normal;
				    padding: 5px 10px;
				}
            }
        }
        &:nth-of-type(4){
            display: none;
        }
    }
    .box1x05{
        width: 221px;
        height: 94px;
        display: inline-block;
//         float: left;
//         &:first-child{
//             margin: 15px 0 0 0;
//         }
        a{
            width: 221px;
            height: 94px;
        }
    }
    .tallspot{
        position: relative;
        background-repeat: no-repeat;
        a {
            display: block;
        }
    }
    .overlay{
        .content{
            .text {
                font-weight: bold;
                font-size: 11px;
                text-transform: uppercase;
                padding: 5px;
            }
           	.button {
			    color: #ffffff;
			    display: inline-block;
			    font-size: 12px;
			    text-transform: uppercase;
			    background-color: #292929;
			    border: 1px solid #ffffff;
			    font-weight: normal;
			    padding: 5px 10px;
			    margin: 0px;
			}
        }
    }
}

@media screen and (max-width: 769px){
    .boxmodul{
        margin-bottom: 40px;
        .box1x2{
            width: 50%;
            &:nth-of-type(4){
            	margin-bottom: 50px;
                display: block;
            }
            .overlay {
                .content {
                    padding: 0 14px 25px 14px;
                }
            }
        }
        .box1x1{
            width: 50%;
            &:nth-of-type(4){
                display: block;
            }
        }
    }
        .tallspot .overlay .content h1{
    	min-height: 25px;
    }
    		
}

@media screen and (max-width: 480px){
    .boxmodul{
        .box1x2{
            .overlay {
                .content {
                    padding: 0 14px 35px 14px;
                }
            }
        }
    }
       .tallspot .overlay .content h1{
    	min-height: 34px;
    }
}
