.link-list {
    h3 {
        @include typeface(sans-serif);
        @include typeset(display-4);

        margin-bottom: rem(15);
    }
    
    li {
        padding: 0;

        a {
            @include typeface(sans-serif);
            
            font-size: rem(15);
            line-height: 2;
            color: $color-text;

            padding: 0;
        }
    }
}