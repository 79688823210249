.brandspot-container {
    max-width: 1170px;
    margin: auto;
    padding-bottom: 100px;
    .moduleheader {
//        margin: -3px auto 39px auto;
    }
    &.brandspot-container--not-ready {
        display: none;
    }
    &.brandspot-container--non-carousel {
        .brandspotbanners-window {
            .brandspotbanners {
                .brandspot-element {
                    width: calc(33.3333% - 20px);
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .brandspotbanners-window {
        .brandspotbanners { //generel rule
            font-size: 0;
            line-height: 0;
        }
        .scrolling-wrapper {
        	.brandspot-element {
        		vertical-align: top;
        		position: relative;
                width: calc(33.3333% - 20px);
                &:last-child {
                    margin-right: 0;
                }
                
                
                width: 100%;
                font-size: 0px;
                line-height: 0;
                display: inline-block;
                position: relative;
                margin-right: 30px;
                vertical-align: top;
                .brandspot-imagecontainer {
//                    padding-bottom: 20px; // topspacing!
                    .brandspot-imagelink {
                        display: block;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .brandspot-contentcontainer {
                    text-align: center;
                    .brandspot-content-imglink {
                        display: block;
                        height: 50px;
//                        margin-bottom: 13px;
                        margin: 20px 0 0 0; // top spacing!
                        &:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            width: 0;
                            vertical-align: middle;
                        }                        
                        img {
                            display: inline-block;
                            max-width: perc(250, 370);
                            max-height: 100%;
                            width: auto;
                            height: auto;
                            vertical-align: middle;
                        }
                    }
                    p {
                        // sorthvid
                        @include typeset(body-normal);
                        // font-size: 16px;
                        // line-height: 24px;
//                        padding-bottom: 11px;
                        margin: 13px 0 -6px 0; // Top spacing!
                    }
                    .brandspot-content-link {
                        margin: 17px 0 0 0; // top spacing!
                    }
                }
                
        	}
        }
        .brandspotbanners { // includes carousel mode
            .owl-nav {
                display: block;
                position: absolute;
                height: 600px;
                top: 0;
                right: 0;
                left: 0;
                pointer-events: none;
                .owl-prev, .owl-next {
                    position: absolute;
                    top: calc(50% - 12px);
                    right: calc(100% + 37px);
                    pointer-events: all;
                }
                .owl-next {
                    position: absolute;
                    top: calc(50% - 12px);
                    right: auto;
                    left: calc(100% + 37px);
                }
            }
            .owl-dots {
                display: none;
                text-align: center;
                position: static;
                margin: 15px auto -5px auto;
                width: 100%;
                .owl-dot {
                    display: inline-block;
                    font-size: 0;
                    line-height: 0;
                    margin: 5px;
                    vertical-align: middle;
                    span {
                        background-color: #e8e8e8;
                        height: 9px;
                        width: 9px;
                        display: inline-block;
                        border-radius: 50%;
                        box-sizing: border-box;
                    }
                    &.active span {
                        background-color: #292929;
                    }
                }
            }
            .brandspot-element {
                width: 100%;
                font-size: 0px;
                line-height: 0;
                display: inline-block;
                position: relative;
                margin-right: 30px;
                vertical-align: top;
                .brandspot-imagecontainer {
//                    padding-bottom: 20px; // topspacing!
                    .brandspot-imagelink {
                        display: block;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .brandspot-contentcontainer {
                    text-align: center;
                    .brandspot-content-imglink {
                        display: block;
                        height: 50px;
//                        margin-bottom: 13px;
                        margin: 20px 0 0 0; // top spacing!
                        &:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            width: 0;
                            vertical-align: middle;
                        }                        
                        img {
                            display: inline-block;
                            max-width: perc(250, 370);
                            max-height: 100%;
                            width: auto;
                            height: auto;
                            vertical-align: middle;
                        }
                    }
                    p {
                        // sorthvid
                        @include typeset(body-normal);
                        // font-size: 16px;
                        // line-height: 24px;
//                        padding-bottom: 11px;
                        margin: 13px 0 -6px 0; // Top spacing!
                    }
                    .brandspot-content-link {
                        margin: 17px 0 0 0; // top spacing!
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1319px) { // BP used to hide carousel nav.
    .brandspot-container {
        .brandspotbanners-window {
            .brandspotbanners {
                .owl-nav {
                    display: none;
                }
                .owl-dots {
                    display: block;
                }
            }
        }
    }

}

@media screen and (max-width: $tablet-bp-max-width) {
    .brandspot-container {
        padding: 0 0 60px 0;
        .moduleheader {
            margin-bottom: 16px;
        }    
        &.brandspot-container--non-carousel {
            .brandspotbanners-window {
                .brandspotbanners {
                    .brandspot-element {
                        width: calc(33.3333% - 13.33px);
                    }
                }
            }
        }
        .brandspotbanners-window {
            .brandspotbanners {
                .brandspot-element {
                    margin-right: 20px;
                    .brandspot-imagecontainer {
//                        padding-bottom: 14px;
                    }
                    .brandspot-contentcontainer {
                        .brandspot-content-imglink {
                            height: 32px;
//                            margin-bottom: 9px;
                            margin: 16px 0 0 0;
                        }
                        p {
                            line-height: 18px;
//                            padding-bottom: 2px;
                            margin: 12px 0 -2px 0;
                        }
                        .brandspot-content-link {
                            margin: 6px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-bp-max-width) {
    .brandspot-container {
        width: 100%;
//        overflow: hidden;
        padding: 0 0 40px 0;
        .moduleheader {
            // sorthvid
            @include typeset(display-3);
            // font-size: 24px;
            // line-height: 30px;
            margin-bottom: 17px;
        }
        &.brandspot-container--non-carousel {
            .brandspotbanners-window {
                .brandspotbanners {
                    .brandspot-element {
                        width: 100%;
                    }
                }
            }
        }
        .brandspotbanners-window {
//            width: calc(76.26% - 5px);
            width: perc(320, 280);
            margin: 0 0 0 perc(-20, 280);
            overflow: hidden;
            .brandspotbanners {
                width: perc(240, 320);
                .owl-stage-outer {
                    overflow: visible;
                }
                .brandspot-element {
//                    margin-right: 20px;
                    margin: 0;
                    padding-right: 5px;
                    .brandspot-imagecontainer {
//                        padding-bottom: 22px;
                    }
                    .brandspot-contentcontainer {
                        .brandspot-content-imglink {
                            height: 47px;
//                            margin-bottom: 12px;
                            margin-top: 22px;
                        }
                        p {
                            // sorthvid
                            @include typeset(body-normal);
                            // font-size: 14px;
                            // line-height: 16px;
                            padding: 0 10px;
                            margin: 12px 0 -3px 0;
                        }
                        .brandspot-content-link {
                            margin: 5px 0 0 0;
                        }
                    }
                }
                .owl-dots {
//                    text-align: center;
//                    position: static;
//                    margin: 18px auto -5px auto;
                    width: 133.9%;
//                    .owl-dot {
//                        display: inline-block;
//                        font-size: 0;
//                        line-height: 0;
//                        margin: 5px;
//                        vertical-align: middle;
//                        span {
//                            background-color: #e8e8e8;
//                            height: 9px;
//                            width: 9px;
//                            display: inline-block;
//                            border-radius: 50%;
//                            box-sizing: border-box;
//                        }
//                        &.active span {
//                            background-color: #292929;
//                        }
//                    }
                }
            }
        }
    }
}