.generallogin{
	.headline {
	    // font-style: italic;
	    // text-transform: uppercase;
	    background-color: transparent;
	    color: #292929;
	    font-family: $font-serif;
	    font-size: 20px;
	    line-height: 25px;
	    padding: 2px 0 15px;
	}
	.twocolls {
	    .leftside {
		    float: left;
		    width: 50%;
		    padding-right: 20px;
		    border-right: 1px solid #e7e3df;
    		box-sizing: border-box;
    		#loginfieldset{
	    		.form-row{
	    			float: none;
	    			.leftfloat{
	    				float: none;
	    			}
	    		}
	    		.password-reset{
	    			padding-left: 100px;
	    		}
	    	}
		}
		.rightside {
		    float: left;
    		padding: 0px 20px 0px 25px;
		    width: 50%;
		    box-sizing: border-box;
		}
		.bottom {
		    padding-top: 40px;
		}
	}
	.advlist{
		ul{
			list-style-image: none;
		    margin: 0;
		    padding: 0 0 9px;
	    	li {
			    background-image: url(../images/sb_checkmark.png);
			    padding: 7px 0 5px 18px;
			}
		}
	}
}

@media screen and (max-width: 769px) {
	.generallogin{
		.twocolls {
		    .leftside {
			    float: none;
			    width: 100%;
			    padding-right: 0;
			    border-right: none;
			}
			.rightside {
			    float: none;
	    		padding: 0;
			    width: 100%;
			}
		}
	}
}

@media screen and (max-width: 520px) {
	.generallogin{
		.twocolls {
		    .leftside {
		    	#loginfieldset{
			    	.form-row{
			    		float: none;
			    		.leftfloat{
			    			float: none;
			    		}
			    		.label{
			    			width: auto;
			    		}
			    		.input-text{
			    			width: 100%;
                            box-sizing: border-box;
			    		}
			    	}
			    	.password-reset{
		    			padding-left: 0px;
		    		}
		    	}
			}
		}
	}
}