@mixin screen-reader-only() {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}


// Typography mixins
//
//
// require $typefaces, $font-sizes and $font-types maps to be filled
//
// $font-sizes needs to be a nested map with font-size and line-height*
// *line-height are automatically calculated if it's defined with px unit,
// if it's defined with a raw number without units it not calculated and just used as a static number.
// ex.
// $font-sizes: (
//     large: (
//         font-size: 12px,
//         line-height: 24px (=> 2)
//     ),
//     small: (
//         font-size: 11px,
//         line-height: 1.5 (=> 1.5)
//         )
//     )
// );
// ex. with media queries. Remember to point to a breakpoint for a base-font size to calculate line-height and letter-spacing
// $font-sizes: (
//     large: (
//      base-font-size: screen-sm,
//         screen: (
//             font-size: 12px,
//             line-height: 24px
//         ),
//         screen-sm:(
//             font-size: 11px,
//             line-height: 15px
//         )
//     )
// );
//
// $font-types is where you define your fonts
// Ex.
// $font-types: (
//    display-1: (
//     font-size: xl,
//     font-weight: bold,
//     font-style: normal,
//     text-transform: uppercase,
//     letter-spacing: 6px,
// ));
//
// $typefaces
// $typefaces: (
//     sans-serif: (
//         font-family: $font-primary,
//         color: $color-black
//     ),
//     serif: $font-secondary
// );
//
// Mixins
// Ex.
// typeface(sans-serif)
// typeset(display-1)
//

@function getFontSize($value) {
    $result: '';
    @if(type-of($value) == number) {
        @if(unit($value) == "" or unit($value) == "px") {
            $result: rem($value);
        }
        @else {
            $result: $value;
        }
    }
    @return $result;
}

@function getLetterSpace($value, $fs) {
    $result: null;
    @if(type-of($value) == number and type-of($fs) == number) {
        @if(unit($value) == "px" and unit($fs) == "px" ) {
            $result: $value/$fs * 1em;
        }
        @if(unit($value) == "em" or unit($value) == "rem") {
            $result: $value;
        }
        @if(unit($value) == "") {
            $result: $value * 1em;
        }
    }
    @return $result;
}

// $lh = line-height
// $fs = font-size
@function getLineHeight($lh, $fs) {
    $result: null;
    @if(type-of($lh) == number and type-of($fs) == number) {
        @if(unit($lh) == "px" and unit($fs) == "px" ) {
            $result: $lh/$fs;
        }
        @if(unit($lh) == "px" and unit($fs) == "vw" or unit($fs) == "vh") {
            $result: $lh/$base-font-size;
        }
        @if(unit($lh) == "") {
            $result: $lh;
        }
    }
    @return $result;
}

@mixin typeset( $typestyle: body-copy ) {
    $type: map-get($font-types, $typestyle);
    $responsive: false;
    $font-size: null;
    $fontMap: null;
    $base-fs: null;
    $allowed-rules: (
        font-weight: true,
        font-style: true,
        text-transform: true,
        color: true
    ) !default;
    // if screen is there it's responsive

    @if($type) {
        @if(map-get(map-get($font-sizes, map-get($type, font-size)), screen)) {
           $responsive: true;
           $fontMap: map-get(map-get($font-sizes, map-get($type, font-size)), screen);
           $base-fs-map: map-get(map-get($font-sizes, map-get($type, font-size)), base-font-size);
           $base-fs: map-get(map-get(map-get($font-sizes, map-get($type, font-size)), $base-fs-map), font-size);
        }
        @else {
            $fontMap: map-get($font-sizes, map-get($type, font-size));
            $base-fs: map-get(map-get($font-sizes, map-get($type, font-size)), font-size);
        }
        font-size: getFontSize(map-get($fontMap, font-size));
        @if map-has-key($fontMap, line-height) {
            line-height: getLineHeight(map-get($fontMap, line-height), $base-fs);
        }
        @if map-has-key($type, letter-spacing) {
            letter-spacing: getLetterSpace(map-get($type, letter-spacing), $base-fs);
        }
        @each $rule, $val in $type {
            @if map-has-key($allowed-rules, $rule) {
                #{$rule}: #{$val};
            }
        }
    }
    @else {
        @warn 'You have entered a typestyle: `#{$typestyle}`. It looks like it doesn\'t exist. Please check your $type-styles map';
    }


    @if($responsive) {
        @each $break-point, $map in map-get($font-sizes, map-get($type, font-size)) {
            @if($break-point != screen and $break-point != base-font-size) {
                @include mq($from: #{$break-point}){
                    font-size: getFontSize(map-get($map, font-size));
                    @if map-has-key($map, line-height) {
                        line-height: getLineHeight(map-get($map, line-height), map-get($map, font-size));
                    }
                }
            }
        }
    }
}
@mixin typeface($face: sans-serif) {
    $type: map-get($typefaces, $face);

    @if(type-of($type) == map) {
        font-family: map-get($type, font-family);
        @if map-has-key($type, color) {
            color: map-get($type, color);
        }
    }
    @if(type-of($type) == list) {
        font-family: $type;
    }
}

//SH: COOMENT - Only good for up to 5 cols
@mixin grid($cols: 2, $col-gap: 40, $row-gap: 40){
    .no-cssgrid & {
        display: block;
        font-size: 0;
        & > * {
            display: inline-block;
            font-size: rem(16);
            vertical-align: bottom;

            @if $cols > 1 {
                $colWidth: (100% / #{$cols});
                width: calc(#{$colWidth} - rem(#{$col-gap - (($col-gap - 1) / $cols)})); // NOTE: Less accurate but robust
                // width: calc(#{$colWidth} - rem(#{$col-gap - ($col-gap / ($cols - 2) / $cols)})); // NOTE: More accurate, but breaks w/ f.ex. 2cols

                // Examples from above
                // 33,3 - 30 - ((30 - 1) / 3)     = -6,3666666667
                // 33,3 - 30 - (30 / (3 - 2) / 3) = -6,7
                
                // #{$gap - ($gap / ($cols - 1) / 5)
                    // 40 / (5-1) /1 2
                    // 40 - (40 / (5 - 1) / 5)
                    
                    &:nth-child(1n+2) {
                        margin-left: rem($col-gap);
                    }
                    &:nth-child(#{$cols}n+1) {
                        margin-left: 0;
                    }
                    &:nth-child(1n+#{$cols+1}) {
                        margin-top: rem($col-gap);
                }
            } @else {
                width: 100%;
            }
        }
    }

    @supports (display: grid) {
        & {
            /* autoprefixer: off */
            grid-template-columns: repeat($cols, 1fr);
        }
    }
}
