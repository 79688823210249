.wishlist-list-container {

    .button--action-style,
    .button--teritary-style {
        @include typeset(body-normal);
        // font-size: 15px;
        display: inline-block;
        vertical-align: middle;
        width: 77px;
        height: 36px;
        line-height: 36px;
        text-transform: none;
        cursor: pointer;
        box-sizing: border-box;
    }
}


.wishlist-page-container {
    margin: 1px auto 25px auto;
    background-color: #ffffff;

    &.wishlist-page-container--input-active .wishlist-list-overlay-keyblock {
        display: block;
    }

    &.wishlist-page-container--input-active {

        .wishlist-add-list-button,
        .wishlist-list-container .wishlist-list-header>* {
            opacity: .4;
        }
    }

    h1 {
        padding: 0;
    }

    .wishlist-login-container {
        background-color: #f5f5f5;
        text-align: center;
        font-size: 0;
        padding: 24px 0 33px 0;
        margin: 1px 0 25px -2px;

        h1 {
            @include typeface(serif);
            @include typeset(display-3);
            // font-size: 29px;
            // text-transform: none;
        }

        p {
            @include typeset(body-normal);
            // font-size: 15px;
            margin: 4px 0 19px 0;
        }

        a:first-of-type {
            margin-right: 10px;
        }

        ~.wishlist-list-top h1 {
            margin-top: 2px;
        }

    }

    .wishlist-list-overlay-keyblock {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
        display: none;
    }

    .wishlist-list-top {
        font-size: 0 !important;
        line-height: 0px;
        text-align: justify;
        -moz-text-align-last: justify;
        text-align-last: justify;
        text-justify: distribute-all-lines;

        h1 {
            @include typeface(serif);
            @include typeset(display-2);
            // font-size: 27px;
            // text-transform: none;
            display: inline-block;
            margin: 28px 0 18px 0;
        }

        .wishlist-add-list-button {
            @include typeset(body-normal);
            // font-size: 15px;
            // color: #292929;
            display: inline-block;
            cursor: pointer;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            font-size: 0px;
        }

        .wishlist-list-container {
            // ! wishlist-list-top branch
            display: none;
            width: 100%;
            margin-bottom: 5px;

            &.wishlist-list-container--addlist {
                display: block;

                .wishlist-list-input-container {
                    display: inline-block;
                    text-align: left;
                    -moz-text-align-last: auto;
                    text-align-last: auto;
                    z-index: 110;
                    opacity: 1;
                    position: absolute;
                    left: 73px;
                    top: 10px;

                    input {
                        @include typeset(body-normal);
                        // font-size: 16px;
                        width: 413px;
                        height: 36px;
                        border: 1px solid #e0e0e0;
                        padding-left: 11px;
                        box-sizing: border-box;
                        vertical-align: middle;
                    }

                    .button--action-style {
                        margin: 0 9px 0 15px;
                    }
                }
            }
        }
    }

    .single-list {
        // specific rules (not logged in)
        margin-bottom: -25px;

        .wishlist-list-container {
            .wishlist-list-header {
                .wishlist-list-expand-toggle {
                    display: none;
                }

                .wishlist-list-name-container {
                    left: 10px;
                }
            }

            .wishlist-list-products-container {
                .wishlist-producthittile-container {
                    .productlistHitTile {
                        margin-top: 0 !important;

                        .productitem .productimg {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .wishlist-list-container {
        margin-top: -2px;

        &.wishlist-list-container--expanded {
            .wishlist-list-header .wishlist-list-expand-toggle .wishlist-arrow {
                transform: rotate(90deg);
            }
        }

        &.wishlist-list-container--edit-name {
            .wishlist-list-header .wishlist-list-input-container {
                display: inline-block;
                text-align: left;
                -moz-text-align-last: auto;
                text-align-last: auto;
                z-index: 110;
                opacity: 1;
            }
        }

        &.wishlist-list-container--share-open .wishlist-list-header .wishlist-list-dropdown-share,
        &.wishlist-list-container--verify-delete .wishlist-list-header .wishlist-dialog-container.wishlist-dialog-container--delete-verification {
            display: block;
        }

        .wishlist-list-header {
            display: inline-block;
            width: 100%;
            height: 56px;
            background-color: #f2f2f2;
            font-size: 0;
            margin-bottom: 12px;
            position: relative;

            .wishlist-list-expand-toggle {
                display: inline-block;
                position: absolute;
                top: 14px;
                left: 20px;
                line-height: 30px;
                padding-right: 19px;
                border-right: 1px solid #bdbdbd;
                cursor: pointer;

                .wishlist-arrow {
                    transform: rotate(0deg);
                    vertical-align: middle;
                }
            }

            .wishlist-list-item-count {
                font-size: 15px;
                line-height: 30px;
                display: inline-block;
                position: absolute;
                right: 228px;
                top: 13px;
                padding-right: 36px;
                border-right: 1px solid #bdbdbd;
            }

            .wishlist-list-name-container {
                position: absolute;
                top: 16px;
                left: 74px;
                display: flex;
                align-items: center;

                .wishlist-list-name {
                    @include typeset(body-normal);
                    // font-size: 16px;
                    
                    display: inline-block;
                    padding-left: 11px;
                    max-width: 450px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                }

                .wishlist-list-name-edit-button {
                    @include typeset(micro);
                    // font-size: 11px;
                    // vertical-align: middle;
                    display: inline-block;
                    text-decoration: underline;
                    margin-left: 14px;
                    margin-top: 1px;
                    cursor: pointer;
                }
            }

            .wishlist-edit-name-container {
                position: absolute;
                top: 10px;
                left: 73px;

                input {
                    @include typeset(body-normal);
                    // font-size: 16px;
                    width: 413px;
                    height: 36px;
                    border: 1px solid #e0e0e0;
                    padding-left: 11px;
                    box-sizing: border-box;
                    vertical-align: middle;
                }

                .button--action-style {
                    margin: 0 9px 0 15px;
                }
            }

            .wishlist-list-input-container {
                display: none;
            }

            .wishlist-list-share-button {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                position: absolute;
                right: 150px;
                top: 11px;

                .symbol-share {
                    background-image: url(../images/wishlist/del.svg);
                    background-size: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: 25px;
                    height: 25px;
                    // vertical-align: middle;
                    // margin-top: -2px;
                    display: inline-block;
                }

                .button-text {
                    @include typeset(body-normal);
                    // font-size: 15px;
                    // color: #292929;
                    // line-height: 100%;
                    
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: 5px;
                }
            }

            .wishlist-list-dropdown-share {
                display: none;
                height: 51px;
                width: 194px;
                background-color: #f2f2f2;
                font-size: 0px;
                position: absolute;
                right: 74px;
                top: 100%;
                z-index: 70;

                &:before {
                    content: "";
                    display: inline-block;
                    height: 100%;
                    font-size: 0;
                    vertical-align: middle;
                }

                &.wishlist-list-dropdown-share--dialog-open .wishlist-dialog-container--link-message {
                    display: block;
                }

                .wishlist-list-dropdown-item {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 0px;
                    line-height: 0;

                    .symbol-base {
                        cursor: pointer;
                    }
                }

                .wishlist-list-dropdown-item {
                    &:nth-child(1) {
                        margin-left: 21px;
                    }

                    &:nth-child(2) {
                        margin: 0 20px 0 21px;
                    }
                }
            }

            .wishlist-list-delete-button {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                position: absolute;
                right: 21px;
                top: 16px;

                .symbol-clear {
                    background-image: url(../images/wishlist/ryd.svg);
                    background-size: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                    vertical-align: middle;
                    display: inline-block;
                    margin-right: 4px;
                }

                .button-text {
                    @include typeset(body-normal);
                    // font-size: 15px;
                    // color: #292929;
                    // line-height: 100%;
                    
                    display: inline-block;
                    vertical-align: middle;
                }

                .symbol-delete {
                    background-image: url(../images/wishlist/slet.svg);
                    background-size: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                    vertical-align: middle;
                    margin: 0 2px 4px 0;
                    display: inline-block;
                }
            }

            .wishlist-dialog-container.wishlist-dialog-container--delete-verification {
                right: 25px;
                top: 105%;
                height: 86px;
                width: 202px;
                text-align: justify;
                padding: 15px 19px 0 19px;
                z-index: 50;

                p {
                    margin-bottom: 11px;
                }

                &:after {
                    content: "";
                    width: 100%;
                    display: inline-block;
                    font-size: 0;
                    line-height: 0;
                }
            }
        }

        .wishlist-list-products-container {
            font-size: 0;
            overflow: hidden;
            width: calc(100% + 16px);

            .wishlist-list-empty {
                display: block;
                margin: 0 0 28px 0;
                text-align: center;

                .wishlist-list-heart {
                    background-image: url(../images/wishlist/hjerte_stroke.svg);
                    background-size: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: 22px;
                    height: 22px;
                    display: inline-block;
                    margin-top: -3px;
                }

                p {
                    font-size: 15px;
                }
            }

            .wishlist-producthittile-container {
                width: 20%;
                display: inline-block;
                position: relative;
                vertical-align: top;
                background-image: url(../images/product_divider_258.png);
                background-repeat: no-repeat;
                box-sizing: border-box;
                background-position: left 8px bottom 112px;
                background-size: auto 77%;
                padding-left: 16px;

                .wishlist-producthittile-counter {
                    @include typeset(micro);
                    // font-size: 12px;
                    
                    line-height: 23px;
                    width: 100%;
                    height: 23px;
                    color: #ffffff;
                    background-color: #b4b4b4;
                    text-align: center;
                    margin: 0;
                    box-sizing: border-box;
                }

                .productlistHitTile {
                    margin: 0;

                    .productitem {
                        height: 390px;

                        .productsplashcontainer {
                            margin-top: 3px;

                            .productsplash {
                                margin: 0 10px 0 0;
                            }

                            .wishlist-wishbutton-container {
                                position: static;
                                display: inline-block;
                                clear: right;
                                float: right;
                                margin: 2px 28px 0 0;

                                .wishlist-wishbutton-listmenu {
                                    position: relative;

                                    .wishlist-wishbutton-backlight {
                                        background-color: #ffffff;
                                        border-radius: 15px;
                                        opacity: .7;
                                        height: 30px;
                                        width: 30px;
                                        position: absolute;
                                        top: -5px;
                                        right: -4px;
                                    }

                                    .symbol-heart {
                                        position: relative;
                                        width: 22px;
                                        height: 22px;
                                    }
                                }
                            }
                        }

                        .productimg {
                            line-height: 0px;
                            text-align: center;
                            height: 227px;
                            width: 230px;
                            width: 100%;
                            display: block;
                            margin-top: 0;

                            &:after {
                                display: none;
                            }

                            .image {
                                position: static;
                                max-height: none;

                                &:before {
                                    content: "";
                                    display: inline-block;
                                    vertical-align: middle;
                                    height: 227px;
                                }
                            }
                        }
                    }

                    .variants {
                        font-size: 0;
                        line-height: 0;

                        p {
                            display: none;
                        }

                        .variant--not-chosen .selector {
                            border: 1px solid #f14040;
                        }
                    }

                    .newselect {
                        .selector {
                            width: 100%;
                            box-sizing: border-box;
                            padding: 0;
                            margin: 0 0 8px 0;

                            span {
                                background-image: url(../images/newselect_right.gif);
                                background-repeat: no-repeat;
                                background-position: right top;
                                height: 39px;
                                padding: 1px 59px 0px 15px;
                                cursor: pointer;
                                display: block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                line-height: 37px;
                            }

                            select {
                                width: 100%;
                                top: 0px;
                                left: 0px;
                                position: absolute;
                                opacity: 0;
                                filter: alpha(opacity=0);
                                height: 39px;
                                border: none;
                                background: none;
                                cursor: pointer;
                                z-index: 2;
                            }
                        }

                        .isactive.disabled {
                            pointer-events: none;

                            .selector,
                            span {
                                opacity: .7;
                            }
                        }
                    }

                    .basketbutton {

                        &.missingSize,
                        &.missingColor {
                            opacity: .7;
                        }

                        .button {
                            margin: 0;

                            .large {
                                width: 100%;
                                background-image: none;
                                margin: 0;

                                input {
                                    @include typeset(body-normal);
                                    // font-size: 16px;
                                    // font-weight: normal;
                                    
                                    text-transform: uppercase;
                                    background-image: none;
                                    height: 39px;
                                    background-color: #292929;
                                    padding: 2px 0 0 0;
                                    display: block;
                                    width: 100%;
                                    margin: 0;
                                    border: none;
                                    -webkit-appearance: none;
                                    border-radius: 0;
                                }
                            }
                        }
                    }

                    .nobutton {
                        .notInStockButton {
                            margin: 0;
                            min-width: 0;
                        }

                        .notinstocklabel {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                        }
                    }

                    .missingStock {
                        @include typeset(body-small);
                        // font-size: 12px;
                        // line-height: 12px;
                        
                        margin-top: 3px;
                        display: block;
                        color: #ff0000;
                        text-align: center;
                        display: none;

                        &.missingStock--show {
                            display: block;
                            position: relative;
                            top: -40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1225px) {
    .wishlist-page-container .wishlist-list-container .wishlist-list-products-container .wishlist-producthittile-container {
        width: 20%;

        &:nth-of-type(5n+1) {
            margin-left: -16px;
        }
    }
}

@media screen and (min-width: 997px) and (max-width: 1224px) {
    .wishlist-page-container .wishlist-list-container .wishlist-list-products-container .wishlist-producthittile-container {
        width: 25%;

        &:nth-of-type(4n+1) {
            margin-left: -16px;
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 996px) {
    .wishlist-page-container .wishlist-list-container .wishlist-list-products-container .wishlist-producthittile-container {
        width: 33.33333%;

        &:nth-of-type(3n+1) {
            margin-left: -16px;
        }
    }
}


@media screen and (max-width: 640px) {
    .wishlist-page-container .wishlist-list-container .wishlist-list-products-container .wishlist-producthittile-container {
        width: 50%;

        &:nth-of-type(2n+1) {
            margin-left: -16px;
        }
    }
}

@media screen and (max-width: 769px) {
    .wishlist-page-container {
        .wishlist-login-container {
            padding: 20px;

            a {
                // font-size: 14px;
                width: calc(50% - 5px);
                max-width: 170px;
            }
        }

        .wishlist-list-top {
            line-height: normal;

            h1 {
                margin-right: 30px;
            }

            .wishlist-add-list-button {
                margin: -10px 0 20px 0;
                padding: 5px 0;
            }
        }

        .wishlist-list-container {
            .wishlist-list-header {
                .wishlist-list-item-count {
                    display: none;
                }

                .wishlist-list-share-button {
                    right: 70px;

                    .button-text {
                        display: none;
                    }
                }

                .wishlist-list-dropdown-share {
                    right: 0;
                    width: 162px;

                    .wishlist-list-dropdown-item {
                        width: 33.3333%;
                        margin: 0 !important;
                        text-align: center;
                    }

                    .wishlist-dialog-container--link-message {
                        right: 0;
                        width: 100%;
                    }
                }

                .wishlist-list-delete-button {
                    .button-text {
                        display: none;
                    }
                }

                .wishlist-dialog-container {
                    &.wishlist-dialog-container--delete-verification {
                        right: 8px;
                    }
                }

                .wishlist-edit-name-container {
                    width: 100%;

                    input {
                        width: calc(100% - 266px);
                    }
                }
            }
        }

        .wishlist-list-top .wishlist-list-container.wishlist-list-container--addlist .wishlist-list-input-container {
            width: 100%;

            input {
                width: calc(100% - 266px);

            }
        }

    }
}

@media screen and (max-width: 768px) {
    .wishlist-page-container .wishlist-list-container .wishlist-list-products-container .wishlist-producthittile-container .productlistHitTile .productitem {
        height: 380px;

    }
}

@media screen and (max-width: 480px) {
    .wishlist-page-container .wishlist-list-container {
        .wishlist-list-header {
            .wishlist-list-expand-toggle {
                display: none;
            }

            .wishlist-list-name-container {
                left: 10px;
            }
        }

        .wishlist-list-products-container {
            display: block !important;
        }
    }

    .wishlist-page-container .wishlist-list-container .wishlist-list-header .wishlist-edit-name-container,
    .wishlist-page-container .wishlist-list-top .wishlist-list-container.wishlist-list-container--addlist .wishlist-list-input-container {
        width: calc(100% - 10px);
        left: 5px;

        input {
            width: calc(100% - 20px);
            margin-left: 10px;
        }

        .button--action-style {
            width: calc(50% - 15px);
            margin: 10px 0 10px 10px;
        }

        .button--teritary-style {
            width: calc(50% - 15px);
            margin: 10px 10px 10px 0;
            float: right;
        }

    }
}


// sorthvid
// .wishlist-description {
//     font-family: Arial, Verdana, sans-serif;
//     font-size: 28px;
//     line-height: 34px;
// }

.productlistHitTile {
    @include typeset(body-small);
    // font-size: 12px;
    // line-height: 18px;
    width: 100%;
    position: relative;

    form {
        padding-bottom: 40px;
    }
}