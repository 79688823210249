.infobox {
    @include typeset(body-normal);
    // font-family: $font-sans-serif;
    // font-size: rem(15);
    // line-height: 1.6;

    padding: rem(40) 0 rem(30);

    h3 {
        @include typeface(serif);
        @include typeset(display-3);
        // font-style: normal;
        // font-family: $font-serif;
        // text-transform: capitalize;
        // font-size: rem(25);
    }

    a {
        // transition: color $transition-very-fast;
        // color: $color-text;
        text-decoration: underline;

        // &:hover, &:focus {
        //     color: $color-dull-orange;
        // }
    }
}