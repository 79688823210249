@function perc($width, $container-width: $max-width) {
   @return percentage($width / $container-width);
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// Globale break points
$tablet-bp-max-width: 1024px;
$mobile-bp-max-width: 640px;

.clear{
    clear: both;
    height: 0;
    line-height: 0;
}

.leftclear {
    clear: left;
    height: 0;
    line-height: 0;
}

.leftfloat {
    float: left;
}

.nofloat {
    float: none!important;
}

.bold {
    font-weight: bold!important;
}

.underlined{
	text-decoration: underline;
	display: inline;
	cursor: pointer;
}

.responsive-img{
	width: 100%;
	height: auto;
}

.centertext {
    text-align: center;
}

.topPadding {
    padding-top: 5px;
    float: left;
}

.divider {
    clear: left;
    border-bottom: 1px solid #a19589;
    height: 14px;
    margin: 0px 14px 0px 0px;
}

.dividershadow {
    clear: both;
    height: 32px;
    margin-bottom: -17px;
}

.backlink {
    margin-top: 0px;
    height: 30px;
    .bcbacklinkarrow {
	    float: left;
	    margin: -1px 0px 0px -4px;
	}
}

.bcbacklinknofloat {
    padding: 3px 8px 0px 8px;
    display: inline-block;
    color: #292929;
    a {
	    color: #292929;
	}
}

.primarylink, .secondarylink {
    // sorthvid
    // font-family: "Gill Sans Light",sans-serif;
    // font-size: 15px;
}

.primarylink {
    font-weight: 600;
    text-decoration: underline;
}

.secondarylink {
//    font-family: Georgia,serif;
//    font-style: italic;
//    font-weight: bold;
    a {
        // background-color: transparent;
        // color: #292929;
        text-decoration: underline;
        // padding: 2px;
    }
}

.nobutton {
    .notInStockButton {
        @include typeset(body-normal);
        // font-size: 16px;
        background-image: none;
        height: 39px;
        background-color: #b4b4b4;
        width: 100%;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        line-height: 39px;
        min-width: 289px;
        margin-top: 12px;
        a {
            @include typeset(body-normal);
            // font-family: "Gill Sans light",sans-serif;
            // font-size: 15px;
            // line-height: 16px;
            
            color: #8c8c8c;
            cursor: pointer;
            display: block;
            text-decoration: underline;
            padding-top: 7px;
            white-space: nowrap;
            width: 50px;
        }
    }
    .stocknotifybutton {
        float: none;
        position: relative;
    }
    .notinstocklabel {
        @include typeset(body-small);
        // font-size: 15px;
        // line-height: 16px;
        // font-family: "Gill Sans light",sans-serif;
        
        color: #8c8c8c;
        cursor: pointer;
        display: block;
        text-decoration: underline;
        padding-top: 7px;
        white-space: nowrap;
        width: 50px;
    }
}

.justify-end {
    width: 100%;
    font-size: 0;
    line-height: 0;
    display: inline;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.pagecontent, .overlaybox960{ 
	.waitanim {
    	display: none;
    }
   	.pleasewait{
   		.waitanim{
			display: block;
			position: fixed;
			text-align: center;
			top: 50%;
			left: 50%;
		}
		.maincontentcontainer{
			opacity: 0.2;
			filter: alpha(opacity=20);
			pointer-events: none;
		}
   	}
}

.moduleheader {
    // sorthvid
    @include typeset(display-1);
    @include typeface(serif);
    // font-family: $font-serif;
    // font-size: 36px;
    // line-height: 40px;
    // background-color: transparent;
    // color: #292929;
    // text-transform: none;
    
    text-align: center;
    display: block;
    margin: -3px auto 39px auto;
    padding: 0 2%;
    @media screen and (max-width: $tablet-bp-max-width) {
        // font-size: 30px;
        // line-height: 34px;
        margin: -2px auto 20px auto;
    }
    @media screen and (max-width: $mobile-bp-max-width) {
        font-size: 24px;
        // line-height: 28px;
        margin: -3px auto 20px auto;
    }    
}


.tilelink-style {
    // sorthvid
    @include typeset(body-medium);
    // font-size: 16px;
    // color: #292929;
    // line-height: 36px;
    font-weight: 600;
    display: inline-block;
    border-bottom: 2px solid #292929;
    &:hover {
        text-decoration: none;
        color: #292929;
    }
    @media screen and (max-width: $tablet-bp-max-width) {
        // font-size: 14px;
        // line-height: 30px;
    }
    @media screen and (max-width: $mobile-bp-max-width) {
        border-width: 1px;
    }
}

#pagesloadedforscroll{
    display: none;
}

@media screen and (min-width: $mobile-bp-max-width + 1px){ // contact BSD if this needs to be changed!
	.hide-on-desktop{ 
		display: none !important;
	}
}

@media screen and (max-width: $mobile-bp-max-width){ // contact BSD if this needs to be changed!
	.hide-on-mobile{
		display: none !important;
	}
}


.disable-bottom-spacing {
	padding-bottom: 0px !important;
}
