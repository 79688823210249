// Rich text
//
// Text elements.<br>
// There are a lot of pre-styled text ready for Magasin. Put ```class="rich-text"``` on the parent element and it's content will get styled.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/elements/rich-text.twig
//
// Weight: 1
//
// Style guide: tools.rich-text

.rich-text {
    @include typeface(sans-serif);
    @include typeset(body-normal);
    
    h1 {
        @include typeface(serif);
        @include typeset(display-1);
        margin: rem(0) 0 rem(8);
    }
    h2 {
        @include typeface(serif);
        @include typeset(display-3);
        margin: rem(0) 0 rem(8);
    }
    
    h3 {
        @include typeset(display-4);
        
        margin: rem(0) 0 rem(8);
        
        font-family: inherit;
        text-transform: none;
        font-style: initial;
    }

    h4 {
        @include typeset(display-5);
        margin: rem(0) 0 rem(8);
    }
    h5 {
        @include typeset(trumpet);
        margin: rem(0) 0 rem(8);
    }

    ul {
        padding-bottom: rem(24);
        
        li {
            font-family: inherit;

            list-style-type: disc;
            margin: 0 0 0 rem(30);
            padding: 0;
        }
    }
    
    ol {
        padding-bottom: rem(24);
        
        li {
            font-family: inherit;

            list-style-type: decimal;
            margin: 0 0 0 rem(30);
            padding: 0;
        }
    }

    p {
        margin: rem(0) 0 rem(24);
    }

    strong {
        font-weight: 600;
    }
    
    small {
        @include typeset(body-small);
    }

    i {
        font-style: italic;
    }

    a {
        transition: color $transition-very-fast;
        color: $color-text;
        text-decoration: underline;
        
        &:hover, &:focus {
            color: $color-border-hover;
            text-decoration: none;
        }
    }

    table {
        @include typeset(body-small);
        border-collapse: collapse;

        overflow-x: auto;
        max-width: 100%;
        display: block;

        text-align: left;

        margin-bottom: rem(24);

        thead tr {
            @include typeset(display-5);

            th {
                padding: rem(24) rem(24) rem(15);
            }
        }

        tr {
            border-bottom: 1px solid $color-border;
        }

        td,
        th {
            padding: rem(24) rem(24);

            &:first-of-type {
                padding-left: rem(6);
            }

            &:last-of-type {
                padding-right: rem(6);
            }
        }
    }

    blockquote {
        margin-bottom: rem(24);

        p {
            margin-bottom: rem(12);

            &:before,
            &:after {
                content: '"';
            }
        }

        span {
            font-style: italic;
            margin-left: rem(24);
        }
    }

    .bodytext-sm {
        @include typeset(body-small);
    }

    .bodytext-lg {
        @include typeset(body-medium);
    }
}