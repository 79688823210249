// Responsive breakpoints
//
// There are a set of predefined media queries available. They are set after different sizes of the most used screensizes of different devices.<br><br>
// Below you can see a box that have a different background color, depending on what breakpoint is active. Try and resize your browser window to see it in action. 
//
// Weight: 1
//
// Style guide: tools.breakpoints


// Types
//
// DOD: done
//
// The following breakpoints are implemented. Mobile first applies, meaning all styling is done from a benchmark mobile screen width of 320px portrait mode, and then gets enhanced as screen size increases.<br/>– screen-xs: 567px (mobile landscape)<br/>– screen-sm: 768px (tablet portrait)<br/>– screen-sm-plus: 950px (large tablet/very small desktop)<br/>– screen-md: 1000px (tablet landscape/small desktop)<br/>– screen-lg: 1150px (desktop+)<br/>– screen-xl: 1280px<br/>– screen-xxl: 1440px<br/>– screen-xxxl: 1680px<br/><br/>When these breakpoints are used in media queries, values are transformed to "em".
//
// Markup: <div class="designsystem-responsive-div"></div>
//
// Style guide: tools.breakpoints.types

// ----------
// SH MQ
//
// * Modified sass-mq version for consistent and easy media query usage.
// @link https://github.com/sass-mq/sass-mq
// ----------
@import '../core/mq';


// ----------
// Media queries
// ----------
$mq-breakpoints: (
	screen-xs: 567px,	// mobile landscape (phablet aka. "iPhone 7 plus")
	screen-sm: 768px,	// up to tablet portrait
	screen-sm-plus: 950px,	// bridge between tablet portrait and desktop
	screen-md: 1024px,	// tablet landscape + small desktop
	screen-lg: 1150px,	// wrapper min-width
	screen-xl: 1280px,	// medium desktop
	screen-xxl: 1440px,	// large desktop
	screen-xxxl: 1680px	// extra large desktop
);


// ----------
// Tiny schema of pixel resolutions for devices
//
// * iPhone SE 			up to screen-xs(567px)			1136-by-640-pixel resolution at 326 ppi =~ 	350px
// * iPhone 7			up to screen-xs 				1334-by-750-pixel resolution at 326 ppi =~ 	409px
// * iPhone 7 plus		up to screen-md					1920-by-1080-pixel resolution at 401 ppi =~ 480px
// ----------

// DESIGNSYSTEM DEMO ONLY
.designsystem-responsive-div {
	height: rem(300);
	display: flex;
	align-items: center;
	justify-content: center;

	--responsive-demo-size: "Default";
	--responsive-demo-color: rgb(200,255,10);

	background-color: var(--responsive-demo-color);
	
	&:after {
		content: var(--responsive-demo-size);
		color: red;
		font-size: rem(60);
	}

	@include mq($from: screen-xxxl) {
		--responsive-demo-size: "screen-xxxl";
		--responsive-demo-color: rgb(200,255,255);
	}
	@include mq($from: screen-xxl) {
		--responsive-demo-size: "screen-xxl";
		--responsive-demo-color: rgb(200,255,230);
	}
	@include mq($from: screen-xl) {
		--responsive-demo-size: "screen-xl";
		--responsive-demo-color: rgb(200,255,200);
	}
	@include mq($from: screen-lg) {
		--responsive-demo-size: "screen-lg";
		--responsive-demo-color: rgb(200,255,170);
	}
	@include mq($from: screen-md) {
		--responsive-demo-size: "screen-md";
		--responsive-demo-color: rgb(200,255,140);
	}
	@include mq($from: screen-sm-plus) {
		--responsive-demo-size: "screen-sm-plus";
		--responsive-demo-color: rgb(200,255,100);
	}
	@include mq($from: screen-sm) {
		--responsive-demo-size: "screen-sm";
		--responsive-demo-color: rgb(200,255,70);
	}
	@include mq($from: screen-xs) {
		--responsive-demo-size: "screen-xs";
		--responsive-demo-color: rgb(200,255,40);
	}
}