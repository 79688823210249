.quickview-container {
    .producttop {
        margin: 0;
        .productpage {
            padding: 30px 0 0 20px;
            .pictureside {
                width: 60.2%;
                .pictureside-inner {
                    width: 100%;
                    margin-left: 0;
                    .pdp-owl-carousel {
                        .item .pdp-item-link {
                            cursor: default;
                            img {
                                cursor: default;
                            }
                        }                        
                        .owl-dots {
                            display: block;
                            &.disabled {
                                display: none;
                            }
                        }
                    }
                    .product-video-container {
                        display: none;
                    }
                }
            }
            .textside {
                width: 38.3%;
            }
            .facttabbox {
                width: 38.3%;
            }
        }
    }
}


@media screen and (max-width: 1280px) {
    .quickview-container {
        .producttop {
            .productpage {
                padding: 30px 0 0 0;
                .pictureside {
                    width: 55.2%;
                }
                .textside {
                    width: 43.3%;
                }
                .facttabbox {
                    width: 100%;
                }

            }
        }
    }
}

@media screen and (max-width: 769px) {
    .quickview-container {
        .producttop {
            .productpage {
                .pictureside {
                    width: 100%;
                }
                .textside {
                    width: 100%;
                }
            }
        }
    }
    
    .ui-dialog[aria-describedby="quickviewDialog"] {
        top: 0px !important;
    }   
    
}




