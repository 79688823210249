// Default/base image splash, Herobanner
.imagesplash-container {
    // font-family: "Gill Sans", "sans-serif";
    // color: #292929;
    // line-height: 0;
    /*font-size: 0;*/
    
    position: absolute;
    top: 40px;
    right: 40px;
    height: 130px;
    width: 130px;
    background-color: #ffffff;
    border-radius: 50%;
    white-space: nowrap;
    transform-origin: top right;

    &.imagesplash-container--left {
        left: 40px;
        right: auto;
        transform-origin: top left;
    }

    &.imagesplash-container--center {
        left: calc(50% - 65px);
        right: auto;
        transform-origin: top left;
    }

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
        /*font-size: 0;*/
        line-height: 0;
    }

    .imagesplash-inner {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        text-align: center;

        //      Percentage styling
        &.imagesplash-percentage {
            margin-top: -11px; // only for percentage

            .imagesplash-introtext {
                @include typeset(body-large);
                // font-size: 20px;
                // line-height: normal;
                // font-weight: 300;
                
                max-width: 75%;
                margin: auto;

                +.imagesplash-percentage {
                    padding-top: 0px;
                }
            }

            .imagesplash-percentage {
                max-width: 95%;
                padding-top: 10px;
                margin: auto;

                .imagesplash-number {
                    display: inline-block;
                    font-size: 60px;
                    font-weight: 600;
                    vertical-align: middle;
                    line-height: 58px;
                    margin-top: -3px;
                }

                .imagesplash-percent {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 5px;
                    font-size: 34px;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }

        //      X for Y styling
        &.imagesplash-xfory-price {
            .imagesplash-for-top {
                max-width: 80%;
                margin: auto;
                line-height: normal;

                .imagesplash-qualifying {
                    display: inline-block;
                    vertical-align: bottom;
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 37px;
                }

                .imagesplash-qualifyingtext {
                    display: inline-block;
                    vertical-align: bottom;
                    font-size: 26px;
                    font-weight: 300;
                    line-height: 30px;
                    padding: 0 0 2px 6px;
                }
            }

            .imagesplash-discountnumber {
                margin: auto;
                font-size: 40px;
                line-height: 52px;
                font-weight: 600;
            }

            .imagesplash-discountprice {
                font-size: 40px;
                line-height: 52px;
                font-weight: 600;
            }
        }

        &.imagesplash-xfory-quantity {
            .imagesplash-for-top {
                max-width: 80%;
                margin: auto;
                line-height: normal;

                .imagesplash-qualifying {
                    display: inline-block;
                    vertical-align: bottom;
                    font-size: 50px;
                    font-weight: 600;
                    line-height: 37px;
                }

                .imagesplash-qualifyingtext {
                    display: inline-block;
                    vertical-align: bottom;
                    font-size: 26px;
                    font-weight: 300;
                    line-height: 30px;
                    padding: 0 0 2px 6px;
                }
            }

            .imagesplash-quantitynumber {
                margin: auto;
                font-size: 50px;
                line-height: 52px;
                font-weight: 600;
            }
        }

        //      Freetext
        &.imagesplash-freetext {
            margin-top: -2px;

            .imagesplash-boldtext {
                display: inline-block;
                font-size: 22px;
                line-height: 24px;
                font-weight: 600;
                max-width: 80%;
                white-space: normal;
                margin: auto;
            }

            .imagesplash-regulartext {
                display: block;
                font-size: 22px;
                line-height: 24px;
                font-weight: 300;
                white-space: normal;
                max-width: 80%;
                margin: auto;
            }
        }
    }
}

.imagesplash-container {

    .squarespots-container &,
    .brandspot-container & {
        top: 10px;
        right: 10px;
        transform: scale(.6923);

        &.imagesplash-container--left {
            left: 10px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .imagesplash-container {
        top: 20px;
        right: 20px;
        transform: scale(.6923);

        &.imagesplash-container--left {
            left: 20px;
            right: auto;
        }

        &.imagesplash-container--center {
            left: calc(50% - 45px);
            right: auto;
        }
    }

    .imagesplash-container {

        .squarespots-container &,
        .brandspot-container & {
            top: 10px;
            right: 10px;
            transform: scale(.5);

            &.imagesplash-container--left {
                left: 10px;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .imagesplash-container {

        &,
        .brandspot-container & {
            top: 15px;
            right: 15px;
            height: 140px;
            width: 140px;
            transform: scale(.53845);

            &.imagesplash-container--left {
                left: 15px;
                right: auto;
            }
        }

        .brandspot-container .owl-carousel & {
            right: 20px;

            &.imagesplash-container--left {
                left: 15px;
                right: auto;
            }
        }
    }

    .imagesplash-container {

        &,
        .brandspot-container & {
            top: 15px;
            right: 15px;
            height: 140px;
            width: 140px;
            transform: scale(.53845);
        }

        .squarespots-container & {
            top: 5px;
            right: 5px;
            transform: scale(.42);

            &.imagesplash-container--left {
                left: 5px;
                right: auto;
            }
        }
    }

    // Special case
    //    .branspot-container .owl-carousel .imagesplash-container {
    //        right: 20px;
    //        &.imagesplash-container--left {
    //            left: 15px;
    //            right: auto;
    //        }
    //    }
}