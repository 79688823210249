// Link block, SVG
//
// A link tile, will scale the SVG when hovered.<br>
// Will simply fill up all available space, so place this component in a confined element. Preferably a grid.<br>
// Here seen inside a parenting ```<div>``` with ```display: grid; width: 300px;``` 
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/elements/link-block-svg.twig
//
// wrapperstyles: display:grid; width: 300px;
//
// Weight: 1
//
// Style guide: elements.link-block-svg

.link-block-svg {
    transition: box-shadow $transition-fast, z-index $transition-fast;
    box-shadow: 0 0 0 1px $color-border;
    z-index: 0;
    
    background-color: $color-white;
    padding: rem(25) rem(15);
    
    display: inline-block;
    width: auto;
    height: auto;

    overflow: hidden;
    
    @include mq($from: screen-sm) {
        padding: rem(30) rem(15);
    }

    &:hover, &:focus {
        transition: box-shadow $transition-fast, z-index 0s;
        box-shadow: 0 0 0 1px $color-border-hover;
        z-index: 1;
        
        text-decoration: none;
        
        background-color: $color-white;

        img {
            transform: scale(1.05);
        }
    }

    &__media {
        max-width: rem(60);
        height: rem(60);
        margin: 0 auto rem(10);

        display: flex;
        justify-content: center;

        svg,
        img {
            transition: transform $transition-fast;
            max-width: inherit;
            height: inherit;
        }
    }

    &__text {
        .new-font & {
            @include typeface(sans-serif);
        }
        @include typeset(body-normal);
        
        color: $color-text;
        text-align: center;
    }
}