// Colors
//
// All the colors used in the components in the UI Library.<br>
// All colors can be accessed as background-color, using the class name provided with each color.<br>
//
// Style guide tools.colors


// Palette colors
//
// wrapperstyles: display:block; color:grey;
//
// Markup: <div style="height:100px;" class="{{modifier_class}}">{{modifier_class}}</div>
//
// .c--casper                  - HEX: 9FBDC3
// .c--botticelli              - HEX: 8FAAAE
// .c--dull-orange             - HEX: A3988B
// .c--dark-green-cyan         - HEX: 517F70
// .c--sisal                   - HEX: D3CBBA
//
// hidedefault: true
//
// Style guide tools.colors.palette



// Greyscale colors
//
// wrapperstyles: display:block; color:grey;
//
// Markup: <div style="height:100px;" class="{{modifier_class}}">{{modifier_class}}</div>
//
//	.c--white              - HSL: 0,0,100
//	.c--white-smoke        - HSL: 0,0,96
//	.c--light-grey         - HSL: 0,0,91
//	.c--dark-grey          - HSL: 0,0,58
//	.c--very-dark-grey     - HSL: 0,0,16
//	.c--grey-transparent   - RGBA: 0, 0, 0, 0.1
//
// hidedefault: true
//
// Style guide tools.colors.greyscale


// Semantic shorthands
//
// Defined semantic color shorthands for common element types.
//
// wrapperstyles: display:block; color:grey;
//
// Markup: <div style="height:100px;" class="{{modifier_class}}">{{modifier_class}}</div>
//
// .c--text                    - HSL: 0,0,16
// .c--border                  - HSL: 0,0,91
// .c--border-hover            - HSL: 0,0,58
// .c--background-grey         - HSL: 0,0,96
// .c--shadow                  - RGBA: 0, 0, 0, 0.1
// .c--button                  - HEX: 9FBDC3
// .c--button-focus            - HEX: 8FAAAE
// .c--link                    - HEX: A3988B
//
// hidedefault: true
//
// Style guide tools.colors.semantic-shorthands

$color-white: hsl(0,0,100%);
$color-grey-1: hsl(0,0,99%);
$color-white-smoke: hsl(0,0,96%);
$color-light-grey: hsl(0,0,91%);
$color-88-grey: hsl(0,0,88%);
$color-middle-grey: hsl(0,0,75%);
$color-dark-grey: hsl(0,0,58%);
$color-very-dark-grey: hsl(0,0,16%);

$color-grey-transparent: rgba(0,0,0,0.1);
$color-black: #292929;

$color-casper: #9FBDC3;
$color-botticelli: #87ACB4;
$color-dull-orange: #A3988B;
$color-dark-green-cyan: #517F70;
$color-sisal: #D3CBBA;


$color-text: $color-very-dark-grey;
$color-border: $color-light-grey;
$color-border-hover: $color-dark-grey;
$color-background-grey: $color-white-smoke;
$color-shadow: $color-grey-transparent;

$color-button: $color-casper;
$color-button-focus: $color-botticelli;
$color-link: $color-dull-orange;

.c {
    &--white            { background-color: $color-white; }
    &--grey-1      { background-color: $color-grey-1; }
    &--white-smoke      { background-color: $color-white-smoke; }
    &--light-grey       { background-color: $color-light-grey; }
    &--dark-grey       { background-color: $color-dark-grey; }
    &--very-dark-grey   { background-color: $color-very-dark-grey; }

    &--grey-transparent { background-color: $color-grey-transparent; }

    &--casper      { background-color: $color-casper; }
    &--botticelli  { background-color: $color-botticelli; }
    &--dull-orange { background-color: $color-dull-orange; }
    &--dark-green-cyan { background-color: $color-dark-green-cyan }
    &--sisal { background-color: $color-sisal }


    &--text            { background-color: $color-very-dark-grey; }
    &--border          { background-color: $color-light-grey; }
    &--border-hover    { background-color: $color-dark-grey; }
    &--background-grey { background-color: $color-white-smoke; }
    &--shadow          { background-color: $color-grey-transparent; }

    &--button          { background-color: $color-button; }
    &--button-focus    { background-color: $color-button-focus; }
    &--link            { background-color: $color-link; }
}
