.contentpagehero {
    position: relative;
    padding-bottom: 45px;
    max-width: 992px;
    height: 270px;
    overflow: hidden;

    img {
        position: absolute;
        left: 50%;
        width: 992px;
        height: 270px;
        margin-left: -496px;
    }
}

.contentpagecontent {
    max-width: 750px;
    // sorthvid
    @include typeset(body-normal);
    // font-size: 16px;
    // line-height: 24px;
    //    line-height: normal;

    &.contentpagecontent--magasin-card {
        max-width: 780px;

        h2 {
            margin: 0;
        }

        p {
            padding-bottom: 23px;

            a {
                color: #292929;
                text-decoration: underline;
            }
        }

        ol {
            list-style-position: inside;
            margin: 0 0 0 20px;
            padding-bottom: 23px;

            li {
                list-style: decimal inside;

                a {
                    color: #292929;
                    text-decoration: underline;
                }
            }
        }

        ul {
            margin: 0 0 0 20px;
            padding-bottom: 23px;

            li {
                a {
                    color: #292929;
                    text-decoration: underline;
                }
            }
        }

        a.button-style {
            display: block;
            padding: 0 30px;
            margin-bottom: 41px;
        }

        .infotable {
            display: table;
            padding-bottom: 23px;
            margin-top: 21px;

            .infotable-row {
                display: table-row;

                .infotable-cell {
                    display: table-cell;
                    width: 50%;

                    &:first-child {
                        padding-right: 18px;
                    }

                    &:last-child {
                        padding-left: 18px;
                    }

                    .infotable-cell-inner {
                        display: block;
                        padding-top: 23px;
                        border-top: 1px solid #E7E7E7;
                        color: #292929;
                        text-decoration: none;

                        &.infotable-cell-inner--bottom {
                            padding: 0;
                        }

                        h4 {
                            padding-right: 30px;
                            margin-bottom: 10px;
                            position: relative;

                            .arrowlink-icon-container {
                                position: absolute;
                                right: -8px;
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                font-size: 0px;
                                top: 9px;

                                .arrowlink-icon-top {
                                    display: inline-block;
                                    height: 1px;
                                    width: 11px;
                                    background-color: #292929;
                                    transform-origin: right top 3px;
                                    transform: translate(0, 1px) rotate(20deg);
                                }

                                .arrowlink-icon-bottom {
                                    display: inline-block;
                                    height: 1px;
                                    width: 11px;
                                    background-color: #292929;
                                    transform-origin: right center;
                                    transform: rotate(-20deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fullpageimg {
        width: 100%;
        height: auto;

        img {
            width: 100%;
        }
    }

    .halfpageimg {
        vertical-align: top;
        display: inline-block;
        width: 50%;
        margin-right: -4px;

        img {
            width: 100%;
        }
    }

    .halfpagecontent {
        clear: left;
        float: left;
        width: 50%;
        padding-right: 1%;
        box-sizing: border-box;
        padding-bottom: 30px;

        img {
            width: 100%;
            height: auto;
            padding-bottom: 20px;
        }
    }

    .halfpagecontent:nth-of-type(2n) {
        clear: none;
        padding-right: 0;
        padding-left: 1%;
    }

    h1 {
        // sorthvid
        @include typeset(display-1);
        @include typeface(serif);
        // font-family: $font-serif;
        // font-size: 36px;
        // font-style: normal;
        // text-transform: none;
        // line-height: 40px;
        padding: 0 0 20px 0;
    }

    h2 {
        // sorthvid
        @include typeset(display-3);
        @include typeface(serif);
        // font-family: $font-serif;
        // font-size: 27px;
        // font-style: normal;
        // text-transform: none;
        // line-height: 30px;
        // text-align: left;
        padding: 0 0 10px 0;
    }

    h3 {
        @include typeset(display-4);
        // font-family: $font-sans-serif;
        // font-weight: bold;
        // font-size: 16px;
        // text-transform: none;
        // font-style: normal;
        // line-height: 24px;
    }

    ul {
        li {
            padding-left: 26px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                background-color: #292929;
                height: 4px;
                width: 4px;
                border-radius: 3px;
                top: 10px;
                left: 10px;
            }
        }
    }

    ol {
        display: table;

        //        border-spacing: 0 5px;  // 5px between the list-elements and 5px on top/bottom
        li {
            counter-increment: listcount;
            padding: 0;
            display: table-row;
            line-height: 24px;

            &:before {
                content: counter(listcount) ". ";
                font-size: 16px;
                width: 26px;
                display: table-cell;
                line-height: 24px;
            }
        }
    }

    table {
        td {
            text-align: center;
        }
    }

    embed {
        height: 244px;
        width: 490px;
    }

    // sorthvid
    a {
        text-decoration: underline;
    }
}

@media screen and (max-width: 769px) {
    .contentpagecontent {
        .hideonmobile {
            display: none;
        }
    }

    .contentpagehero {
        padding-bottom: 35px;
        max-width: 100%;
        height: 192px;

        img {
            width: 708px;
            height: 192px;
            margin-left: -354px;
        }
    }
}

@media screen and (max-width: 480px) {
    .contentpagehero {
        padding-bottom: 25px;
        height: 190px;

        img {
            width: 700px;
            height: 190px;
            margin-left: -350px;
        }
    }

    .contentpagecontent {
        .halfpageimg {
            display: block;
            width: 100%;
            margin-right: 0;
        }

        .halfpagecontent {
            float: none;
            display: block;
            width: 100%;
            padding-right: 0;
        }

        .halfpagecontent:nth-of-type(2n) {
            float: none;
            padding-right: 0;
            padding-left: 0;
        }

        embed {
            height: auto;
            width: auto;
        }
    }
}