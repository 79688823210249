.brand-chanel{
    font-family: Arial;
	.brandbanner {
		overflow: hidden;
        margin: 0 -30px;
		.topbanner {
			img {
	 		    min-width: 100%;
			    height: auto;
                margin-bottom: 30px;
			    vertical-align: middle;
			}
		}
	}
    .product-page {
        .topbanner {
            margin: 0 -30px;
            a {
                display: block;
                width: 100%;
                height: auto;
                overflow: hidden;
            }
        }
        .producttop {
            .textside {
                .contenttop {
                     .headline h1 {
                        font-family: Arial;
                        font-weight: bold;
                        text-transform: uppercase;
                        line-height: 34px;
                        padding-top: 5px;
                        a {
                            color: #000000;
                        }
                     }
                    .franchise {
                        font-family: Arial, sans-serif;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 26px;
                        line-height: 34px;
                        width: auto;
                        padding-bottom: 0;
                    }
                    h3 {
                        font-family: Arial;
                    }
                }
            }
            .facttabbox {
                .ui-state-active, .ui-state-default {
                    font-family: Arial;
                    color: #000000;
                }
                .ui-accordion-content {
                    font-family: Arial;
                    color: #000000;
                    .infotext {
                        font-family: Arial;
                    }
                    .infolink .expand-toggle {
                        font-family: Arial;
                    }
                }
            }
        }
        .categorybuttons-container {
            .button-style {
                &:hover {
                    background-color: #000000;
                }
            }
        }
    }
    .button-style {
        background-color: #000000;
        font-family: Arial;
        font-weight: bold;
    }
	.topproductbannerdesktop{
		width: 100%;
		overflow: hidden;
		img{
 			min-width: 100%;
		    height: auto;
		    vertical-align: middle;
		}
	}
    .popular-owl-carousel, .matchescarousel {
        h1 {
            font-family: Arial;
        }
    }
    .productitem {
        .productoverlay {
            .stock {
                font-family: Arial;
            }
        }
        .producttext {
            .productname {
                text-transform: uppercase;
                font-family: Arial;
                font-style: normal;
            }
            .franchise {
                font-family: Arial;
                text-transform: none;
                margin: 4px 0 6px 0;
                font-style: normal;
                font-weight: 600;
            }
        }
        .productprice {
            font-family: Arial;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            font-style: normal;
            a{
                color: #000000;
            }
        }
    }
    .searchresults .navigation .navgroup{
        .groupname {
            font-family: Arial;
        }
    }
}

.brand-dior{
	.brandbanner {
		overflow: hidden;
        margin: 0 -30px;
		.topbanner {
			img {
	 		    min-width: 100%;
			    height: auto;
			    margin: 0 0 30px 50%;
			    transform: translateX(-50%);
			    -webkit-transform: translateX(-50%);
			    -moz-transform: translateX(-50%);
			    -ms-transform: translateX(-50%);
			    vertical-align: middle;
			}
		}
	}
    .topbanner{
        .topbanner-carousel-container {
//             margin: auto;
        }
    }
    .product-page {
        .topbanner {
            overflow: hidden;
            margin: 0 -30px;
            a img {
                min-width: 100%;
                height: auto;
                margin-left: 50%;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                vertical-align: middle;
            }
        }
        .textside .contenttop .franchise {
            font-family: $font-sans-serif;
            // sorthvid
            // font-weight: bold;
            font-size: 26px;
            line-height: 34px;
            width: auto;
            padding-bottom: 0;
            text-transform: none;
        }
    }
    .productitem .producttext {
        a {
            font-family: Arial;
            font-size: 12px;
            .productname {
                font-family: Arial;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: bold;
            }
            .franchise {
                font-family: Arial;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                line-height: normal;
                + div {
                    max-height: none !important;
                    line-height: normal;
                }
            }
        }
    }
	.topproductbannerdesktop{
		width: 100%;
		overflow: hidden;
		img{
 			min-width: 100%;
		    height: auto;
		    margin-left: 50%;
		    transform: translateX(-50%);
		    -webkit-transform: translateX(-50%);
		    -moz-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		    vertical-align: middle;
		}
	}
    .button-style {
        background-color: #000000;
        &:hover {
            background-color: #000000;
        }
    }
    .categorybuttons-container .button-style:hover {
        background-color: #000000;
    }
}


.brand-chanel {
    .searchresult {
        .productitem {
            .producttext {
                .productname {
                    text-transform: uppercase;
                    font-family: Arial;
                    + div {
                        font-family: Arial;
                    }
                }
                .franchise {
                    text-transform: none;
                    margin: 4px 0 6px 0;
                    font-weight: 600;
                    + div {
                        font-size: 16px;
                        max-height: none!important;
                        padding-bottom: 5px;
                    }
                }
                .productoverlay {
                    border-color: #ffffff;
                    padding: 0 0 49px 0;
                    height: 400px;
                    .stock {
                        font-family: Arial;
                    }
                }
            }
        }
    }
}


.brand-dior {
    .searchresult {
        .productitemcontainer {
            .productitem {
                background-image: none;
                .producttext {
                    a {
                        font-family: Arial;
                        font-size: 12px;
                        .productname {
                            font-family: Arial;
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: bold;
                        }
                        .franchise {
                            font-family: Arial;
                            font-size: 12px;
                            text-transform: uppercase;
                            font-weight: bold;
                            line-height: normal;
                             + div {
                                max-height: none !important;
                                 line-height: normal;
                            }
                        }
                    }
                    .productoverlay {
                        border-color: #ffffff;
                        padding: 0 0 49px 0;
                        height: 400px;
                    }

                }
            }
        }
    }
}

.brand-chanel .searchcontainer .searchresult .productitemcontainer .productitem .producttext .productoverlay .maincontent .stock {
    font-family: Arial;
}

//.brand-chanel .searchcontainer .searchresult .productitemcontainer {
//    min-height: 420px;
//}

.brand-chanel .productitem .producttext.producttext-truncated {
    height: auto !important;
}

//.brand-dior .searchcontainer .searchresult .productitemcontainer {
//    min-height: 400px;
//}

.brand-dior .searchresults .navigation .navgroup .groupname {
    font-size: 13px;
}

.brandbanner-container {  // Brandbanner V1 component
    max-width: 970px;
    margin: 0 auto 100px auto;
    padding: 0 82px;
    font-size: 0px;
    line-height: 0px;
    clear: both;
    .brandbanner-content {
        width: 30.93%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding-right: 43px;
        box-sizing: border-box;
//        margin-right: -13px;
//            .brandbanner-contentimage img {
//                max-width: 177px;
//            }
        .brandbanner-contentimage {
            padding-bottom: 17px;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .brandbanner-contentlink {
            font-size: 16px;
            font-weight: 600;
            color: #292929;
            line-height: 36px;
            display: inline-block;
            border-bottom: 2px solid #292929;
//            margin-top: 15px;
            text-decoration: none;
        }
    }
    .brandbanner-image {
        width: 69.07%;
        display: inline-block;
        vertical-align: middle;
        img {
            width: 100%;
            height: auto;
        }
    }
}

.brandbanner-container .brandbanner-content .brandbanner-contentlink,
.logobanner .logobanner-content .logobanner-contentinner .logobanner-contentlink{
    &, &.large {
//        font-size: 14px;
        text-transform: uppercase;
    }
    &.small {
       text-transform: none; 
    }
}

.logobanner { //  logobanner (Brandbanner V2)  component
    position: relative;
    max-width: 970px;
    margin: 0 auto 100px auto;
    padding: 0;
    font-size: 0px;
    line-height: 0px;
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    .logobanner-header {
        & ~ .logobanner-content {
            height: calc(100% - 79px); // 79px = header height.
        }
    }
    .logobanner-content {
        position: absolute;
        width: perc(300, 970);   //30.93% = 300/970px

        height: 100%;
        display: inline-block;
        text-align: center;
        padding: 0 perc(55, 970) 0 0;
        box-sizing: border-box;
        white-space: nowrap;
        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 0;
            vertical-align: middle;
        }
        .logobanner-contentinner {
            display: inline-block;
            text-align: center;
            white-space: normal;
            vertical-align: middle;
            .logobanner-contentimage {
                padding: 0;
                img {
                    height: auto;
                    width: 100%;
                }
                & ~ .logobanner-contentlink { // without contentimage
                    margin-top: 17px;
                }
                .brandbanner-contentimage {
                    img {
                        width: auto; 
                        max-height: 90px; 
                        max-width: 240px;
                    }
                }
            }
            .logobanner-contentlink {  // with contentimage
                line-height: 36px;
                display: inline-block;
                border-bottom: 2px solid #292929;
                text-decoration: none;
            }
        }
    }
    .logobanner-image {
        width: perc(670,970); //69.07% = 670/970px
        display: inline-block;
        float: right;
        img {
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 1536px) {
    .brand-dior, .brand-chanel {
        .brandbanner {
            .topbanner {
                img {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
	.startletterlist{
		padding: 20px 0;
		.lettergroup {
	    	float: none;
	    	width: 100%;
    	}
	}

    .brand-chanel{
	    .brandbanner {
            margin: 0;
        }
        .product-page {
            .topbanner {
                margin: 0;
            }
        }
    }
    .brand-dior{
	    .brandbanner {
            margin: auto;
//            margin: 0 -20px;
        }
        .product-page {
            .topbanner {
                margin: 0 -20px;
            }
        }
    }
}

@media screen and (max-width: $tablet-bp-max-width) {
    .brandbanner-container {
        padding: 0 83px;
        margin: 0 auto 60px auto;
        .brandbanner-content {
            margin: 0;
            padding-bottom: 0;
            .brandbanner-contentimage {
                width: 100%;
                img {
//                    width: 100%;
                    height: auto;
                }
            }
        }
        .brandbanner-image {
            a {
                display: block;
                img {
                    width: 100%;
                    height: auto;
                }
                
            }
        }
    }
    
    // content-width: 728px
    $component-width: 768 - 2*83; // = 596px
    .logobanner {
        max-width: none;
        width: perc($component-width, 728); //  component-width/content-width
        margin: 0 auto 60px auto;
            &, & > div {
                margin: auto;
                -webkit-transform: none;
                transform: none;
            }
        & {
            margin: 0 auto 60px auto;
        }
        &:after {
        }
        .logobanner-header {
            & ~ .logobanner-content {
                height: calc(100% - 54px); // 54px = header height.
            }
        }
        .logobanner-content {
            width: perc(300, 970);   //30.93% = 300/970px
            height: 100%;
            padding-right: 43px;
            &:before {
            }
            .logobanner-contentinner {
                .logobanner-contentimage {
                    img {
                    }
                    & ~ .logobanner-contentlink { // without contentimage
                            margin-top: 7px;
                    }
                }
                .logobanner-contentlink {  // with contentimage
                    margin-top: 7px;
                }
            }
        }
        .logobanner-image {
            width: perc(670,970); //69.07% = 670/970px
            img {
            }
        }
    }    
    
}

@media screen and (max-width: $mobile-bp-max-width) {
    .brandbanner-container { // Brandbanner V1
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        padding: 40px 0 0 0; // Notice! container is rotated!
        margin: 0;
        .brandbanner-content {
            display: block;
            width: auto;
            padding: 0;
            margin: 0;
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
            .brandbanner-contentlink {
//                line-height: 32px;
            }
            .brandbanner-contentimage {
                padding-bottom: 5px;
            }
        }
        .brandbanner-image {
            width: 100%;
            margin: 0;
            padding-bottom: 20px;
        }
        > div {
            display: block;
            margin: 0 auto 44px;
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
        }
    }

    .logobanner {
        max-width: none;
        width: 100%;
        margin: 0 auto 40px auto;
        &:after {
        }
        .logobanner-header {
            & ~ .logobanner-content {
                height: auto;
            }
        }
        .logobanner-content {
            display: block;
            position: static;
            width: 100%;
            height: auto;
            padding: 0;
            &:before {
            }
            .logobanner-contentinner {
                width: 100%;
                .logobanner-contentimage {
                    padding-top: 20px;
                    max-width: perc(136, 280);
                    height: auto;
                    margin: auto;
                    img {
                        max-height: 100px;
                        height: auto;
                    }
                    & ~ .logobanner-contentlink { // without contentimage
                        margin: 5px 0 0 0;
                    }
                }
                .logobanner-contentlink {  // with contentimage
                    margin: 5px 0 0 0;
                    line-height: 30px;
                    padding: 0;
                    border-width: 1px;
                }
            }
        }
        .logobanner-image {
            display: block;
            float: none;
            width: 100%;
            padding: 0;
            img {
            }
        }
    }    
    
}



@media screen and (max-width: 480px) {
    .brand-chanel, .brand-dior {
        .product-page {
            .topbanner {
                margin: 0 -10px;
            }
        }
    }
}
