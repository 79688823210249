.pagecontent {
  background-position: center 170px !important;
}

.mobilemenu.searchopen .basket__no-collide .pagecontent {
  height: auto;
}

.w--normal {
  max-width: none;
  padding-bottom: 330px;
}

.fulpageheaddivider {
  margin-right: 0;
}

.inpagefooter .newsletter {
  margin: 0 69px 0 69x;
}

.inpagefooter .newsletter .text {
  margin-bottom: 0;
}

.inpagefooter .trustpilot {
  margin: 15px 69px 0 69x;
}

.inpagefooter .contact {
  margin: 15px 69px 0 69x;
}

.basket--no-show {
  display: none;
}

.basket--is-empty {
  display: block;
}

.basket__no-collide .paybutton .button {
  margin: 0px -4px 0px -4px;
  padding: 0;
  min-width: 0;
}

.basket__no-collide .colorThemeWrapTop {
  top: 141px !important;
}

.basket__no-collide .topproductbannerdesktop {
  z-index: 0;
}

.basket__no-collide .header .mainmenu {}

.basket__no-collide .header .mainmenu table {
  width: auto;
}

.basket__no-collide .mainmenu .menuitem.menuitem-ajour .menulink a {
  padding: 7px 4px 8px 4px;
}

.basket__no-collide .basket .product__row__item--usp {
  width: 50%;
}

.basket__no-collide .basket .product__row__item--tools {
  width: 50%;
}

.basket__no-collide .basket .product__row__item--title,
.basket__no-collide .basket .product__row__item--price {
  width: 50%;
}

.basket__no-collide .basket .product__row.product__row--heading::after,
.basket__no-collide .basket .product__row.product__row--sub::after {
  width: 0;
}

.basket__no-collide .basket .priceview {
  line-height: normal;
}

.basket__no-collide .basket .flexrow {
  font-size: 1rem;
}

.basket__no-collide .utilitybar .icon {
  border: 0;
  padding: 5px 1px 5px 5px;
}

.basket__no-collide .utilitybar .icon:before {
  display: none;
}

.basket__no-collide .utilitybar a {
  text-decoration: none;
}

.basket__no-collide .utilitybar a,
.basket__no-collide .utilitybar a:hover,
.basket__no-collide .utilitybar a:visited,
.basket__no-collide .utilitybar a:hover:visited {
  color: #292929;
}

.basket__no-collide .utilitybar .uspline a,
.basket__no-collide .utilitybar .uspline a:hover,
.basket__no-collide .utilitybar .uspline a:visited,
.basket__no-collide .utilitybar .uspline a:hover:visited {
  color: #8b8277;
}

.basket__no-collide .utilitybar a:hover {
  /* text-decoration: underline; */
}

.basket__no-collide .utilitybar a .icon {
  width: auto;
  border: 0;
}

.basket__no-collide .shoppingbasket .icon {
  border: 0;
  border-radius: 0;
}

.basket__no-collide .shoppingbasket .text {
  font-size: 12px;
}

.basket__no-collide .shoppingbasket .item .name a {
  text-decoration: none;
}

.basket__no-collide .shoppingbasket .item .name a:hover {
  text-decoration: underline;
}

.basket__no-collide .header {
  border-bottom: 0;
  margin: 0;
  height: auto;
}

.basket__no-collide .header .rightcontainer .shoppingbasket .topbg {
  top: -18px;
  width: 335px;
}

.basket__no-collide .maincontenttop {
  text-align: center;
  padding-bottom: 1px;
}

.basket__no-collide .uspline {
  display: block;
  padding: 0;
}

.basket__no-collide .uspline .icon {
  border: 0;
}

.basket__no-collide .uspline .icon img {
  vertical-align: top;
  max-width: none;
}

.basket__no-collide .uspline .icon:before {
  display: none;
}

.basket__no-collide .uspline a:nth-child(2) {
  color: #a3988b;
  text-decoration: none;
  border: 0;
  outline: 0;
}

.basket__no-collide .uspline a:nth-child(2):hover {
  text-decoration: underline;
}

.basket__no-collide .heading {
  font-size: 16px;
}

.basket__no-collide .heading h2 {
  color: #292929;
  background-color: transparent;
  text-transform: none;
}

.basket__no-collide .tooltip a {
  padding: 0;
}

.basket__no-collide .tooltip a.tooltip__close {
  padding: 0.8125em;
}

.basket__no-collide .button {
  font-family: "Gill Sans", sans-serif;
  margin: 0;
}

.basket__no-collide .modal {
  font-size: 1rem;
}

.basket__no-collide .modal .modal__header h3,
.basket__no-collide .modal .modal__header .rich-text>.highlight {
  text-transform: none;
}

.basket__no-collide .modal li h3,
.basket__no-collide .modal li .rich-text>.highlight {
  text-transform: none;
  font-style: normal;
}

.basket__no-collide .modal li a {
  padding: 0;
}

.basket__no-collide .modal .button {
  font-family: "Gill Sans", sans-serif;
  margin: 0;
}

.basket__no-collide .uspline a {
  text-decoration: none;
}

.basket__no-collide a:active:not(.button-style):not(.button) {
  color: #292929;
}

.basket__no-collide a:visited:not(.button-style):not(.button) {
  color: #292929;
}

.basket__no-collide a.highlight {
  font: bold 0.875em/3.21 georgia, times, times new roman, serif;
}

.basket__no-collide .product__image a,
.basket__no-collide .product__title a,
.basket__no-collide a.product__tool,
.basket__no-collide a.product__promotioncode,
.basket__no-collide a.tooltip__indicator,
.basket__no-collide a.tooltip__close {
  background-color: transparent;
}

.basket__no-collide a.image-holder__link {
  text-decoration: none;
}

.basket__no-collide .error-validationtype2 {
  color: #fe3e64;

  a,
  a:visited,
  a:hover,
  a:active {
    color: #fe3e64;
    text-decoration: underline;
  }
}



.basket__no-collide .productcarousel .heading h2 {
  float: left;
  text-transform: none;
}

.basket__no-collide .productcarousel a {
  background-color: transparent;
}

.basket__no-collide a.product__tool {
  text-decoration: underline;
}

.basket__no-collide .product__title a {
  text-transform: none;
}

.basket__no-collide a.product__promotioncode,
.basket__no-collide a.product__promotioncode:active {
  color: #292929;
  text-decoration: underline;
}

.basket__no-collide a.product__promotioncode:hover {
  color: #8f8f8f;
}

.basket__no-collide .product.product--total {
  padding: rem(20) 0;
  border-bottom: 0;
}

.basket__no-collide .product.product--total .grid::after {
  display: none;
}

.basket__no-collide nav.flexrow {
  font-size: 1rem;
  border-color: #e7e3df;
}

.basket__no-collide a.tooltip__indicator {
  text-decoration: none;
}

.basket__no-collide .basket__goodiecard {
  font-size: 1rem;
  margin-top: 0px;
}

.basket__no-collide .basket__goodiecard~div {
  font-size: 1rem;
}

.basket__no-collide a.button--primary,
.basket__no-collide a.button--secondary {
  // font-family: $font-sans-serif;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
}

.basket__no-collide .productcarousel .productitem .producttext a {
  text-decoration: none;
}

.basket__no-collide .productcarousel .productitem .producttext a:hover {
  text-decoration: underline;
}

.basket__no-collide .productcarousel .productitem .productprice b {
  font-weight: bold;
}

.basket__no-collide .productcarousel .jcarousel-prev-horizontal {
  background-position: left center;
}

.basket__no-collide .productcarousel .jcarousel-prev-horizontal:hover,
.basket__no-collide .productcarousel .jcarousel-prev-horizontal:focus {
  background-position: right center;
}

.basket__no-collide .inpagefooter a.unsubscribe,
.basket__no-collide .inpagefooter a.unsubscribe:visited,
.basket__no-collide .inpagefooter a.unsubscribe:hover,
.basket__no-collide .inpagefooter a.unsubscribe:active {
  color: #292929;
}


.basket__no-collide .inpagefooter .menuitem .menulink a,
.basket__no-collide .inpagefooter .menuitem .menulink a:visited,
.basket__no-collide .inpagefooter .menuitem .menulink a:hover,
.basket__no-collide .inpagefooter .menuitem .menulink a:active {
  color: #292929;
  text-decoration: none;
}

.basket__no-collide .inpagefooter .contact span a,
.basket__no-collide .inpagefooter .contact span a:visited,
.basket__no-collide .inpagefooter .contact span a:hover,
.basket__no-collide .inpagefooter .contact span a:active {
  color: #292929;
  text-decoration: underline;
}

.basket__no-collide .favbutton .icon {
  border: 0;
}

.basket__no-collide #recommendations_cart_page_horizontal1 .productcarousel .jcarousel-clip-horizontal {
  width: 832px;
}

.basket__no-collide #recommendations_cart_page_horizontal1 .productcarousel .hideborder {
  margin-left: 854px;
}

.basket__no-collide .productsplashcontainer {
  width: 210px;
}

.basket__no-collide .overlaybox550 .button,
.basket__no-collide .overlaybox750 .button,
.basket__no-collide .overlaybox780 .button {
  margin: 0 -4px 0 -4px;
  width: auto;
  padding: 0;
  min-width: 0;
}

.basket__no-collide .overlaybox780 .button,
.basket__no-collide .overlaybox550 .button {
  width: 100%;
  text-align: left;
  margin: 0;
}

.basket__no-collide .overlaybox550 .closebutton,
.basket__no-collide .overlaybox750 .closebutton,
.basket__no-collide .overlaybox780 .closebutton {
  display: block;
  margin: 0;
  line-height: 1.125em;
  width: auto;
}

.basket__no-collide .overlaybox550 .closebutton .small,
.basket__no-collide .overlaybox750 .closebutton .small,
.basket__no-collide .overlaybox780 .closebutton .small {
  width: auto;
}

.basket__no-collide .overlaybox550 .closebutton .small button,
.basket__no-collide .overlaybox750 .closebutton .small button,
.basket__no-collide .overlaybox780 .closebutton .small button {
  height: 26px;
  padding: 1px 10px 0 10px;
  margin: 0 0 0 -8px;
}

.basket__no-collide .overlaybox780 .icon {
  border: 0;
}

.basket__no-collide .overlaybox780 .icon::before {
  display: none;
}

.basket__no-collide .overlaybox780 .icon img {
  vertical-align: top;
}

.basket__no-collide .overlaybox780 form::before,
.basket__no-collide .overlaybox780 form::after {
  display: none;
}

.basket__no-collide .page--main {
  box-shadow: none;
}

.no-flexbox .basket__goodiecard>figure,
.no-flexbox .basket__goodiecard>div {
  width: auto;
}

.no-flexbox .basket__goodiecard h4.flexrow__item {
  text-align: left;
  margin-left: 1em;
}

.inpagefooter .contact span a {
  color: #292929 !important;
  text-decoration: underline;
}

/* Wish list basket page */
.wishlist-basket-modify-container {
  display: inline-block;
  position: relative;
}

.wishlist-basket-modify-link {
  cursor: pointer;
}

.wishlist-basket-modify-link .wishlist-basket-text {
  font-size: 13px;
  line-height: 18px;
  text-decoration: underline;
}

.wishlist-basket-modify-container .wishlist-wishbutton-container {
  display: inline-block;
}

.wishlist-basket-modify-container .wishlist-wishbutton-container .symbol-heart {
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  margin: 0 0 -6px 0;
}


.wishlist-basket-modify-container .wishlist-menuselect-container,
.wishlist-menuselect-create-list-message {
  top: -5px;
  right: -161px;
}

.wishlist-basket-modify-container .wishlist-dialog-container.wishlist-menuselect-create-list-message a {
  display: block;
  padding: 0;
  text-decoration: none;
  text-align: left;
}

.wishlist-basket-modify-container .wishlist-dialog-container.wishlist-menuselect-create-list-message a:hover {
  text-decoration: underline;
  background-color: transparent;
}

.basketpage .page--main.w--normal {
  max-width: 1440px;
  padding-bottom: 0;
}

@media screen and (max-width: 769px) {
  .basket__no-collide {
    .basket .product__row__item--tools {
      text-align: right;

      .product__attributes.product__quantity--max {
        margin-left: 0;
      }
    }
  }
}



/*	
.wishlist-basket-modify-link .symbol-heart {
	display: inline-block;
	width: 22px;
	height: 22px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center center;
	margin: 0 8px -6px 0;
}
*/


/*
.basket{
    &.collapse--bottom {
        margin-bottom: 0;
    }
    &:before{
        content: "";
        display: table;
    }
    .product {
        padding: 1.375rem 0;
        position: relative;
        border-bottom: 1px solid #a3988b;
    }
    .product__image {
        width: 20%;
        display: block;
        text-align: center;
        float: left;
        &>.image-holder {
            display: inline-block;
            max-width: 6.25rem;
            img {
                max-height: 8.75em;
            }
        }
    }
    .product__row {
        text-align: justify!important;
        text-justify: distribute-all-lines;
        font-size: 0!important;
        padding-bottom: 1px;
        margin-left: 0%;
        text-justify: auto;
        &>* {
            text-align: left;
            font-size: 16px;
            vertical-align: top;
            width: 100%;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
    }
    .product__row--heading {
        margin-bottom: 0.9375rem;
        margin-left: 25%;
    }
    .product__row__item--title {
        width: 100%;
    }
     .product__title {
        font: bold 1em/1.4 georgia,times,times new roman,serif;
        a {
            text-transform: none;
        }
    }
    .product__subtitle {
        display: block;
        font: italic 0.875em/1.2 georgia,times,times new roman,serif;
        color: #a3988b;
    }
    .product__row__item--usp {
        width: 50%;
    }
    .list.list--check>li, .list.list--check .list__item {
        padding-left: 1.15385em;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…Q9InBhdGgtMSIgY2xhc3M9ImNscy0yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4NCjwvc3ZnPg0K) no-repeat 0 0.46154em transparent;
        font-size: 0.8125em;
        line-height: 1.7;
    }
}

.basket__no-collide{
    a {
        .image-holder__link {
            text-decoration: none;
        }
    }
    .maincontenttop {
        text-align: center;
        padding-bottom: 1px;
    }
}

 @media screen and (max-width: 480px) {
 
 
	.sold-out.product__row--sub{
	padding: 1.5rem 1.5rem 5rem 4.5rem;
	}
	.product__row.product__row--sub.sold-out .product__row__item--tools{
    width: 100%;
    margin: 1px 0 0 -45px;
    text-align: left;
	}
	.product__row.product__row--sub.sold-out .product__row__item--tools .product__tool.js--remove-product.ng-scope{
	
	}
	.product__tool.v--mobile-only.js--display-detailes{
	display: none;
	}
}
@media screen and (min-width: 48em) {
    .basket{
        &:not(.basket--va-top){
            .product__image {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
        .product__image {
            width: 18%;
        }
        .product__row {
            margin-left: 18%;
        }
        .product__row--heading {
            margin-left: 18%;
        }
        .product__row__item--title {
            width: 48.64865%;
        }
        .product__title {
            font-size: 1.125em;
        }
        .product__subtitle {
            font-size: 1em;
        }
        .product__row__item--usp {
            display: inline-block;
            width: 48.64865%;
        }
    }
}
*/