// Transitions
//
// There are five different transition durations to work with.<br>
// A good rule of thumb is to use an as short duration as possible, and longer durations on bigger element/movements.<br><br>
// Most elements in the UI Library already has a suitable transition added to themselfs. So this page can mostly be seen as a general guide of behaviour.<br><br> 
// For those wanting to deep dive in the subject:<br>
// <a href="https://uxdesign.cc/the-ultimate-guide-to-proper-use-of-animation-in-ux-10bd98614fa9">The ultimate guide to proper use of animation in UX</a>
//
// Markup: <div class="{{modifier_class}}"></div>
//
// .designsystem-box.designsystem-box--very-fast  - Very fast
// .designsystem-box.designsystem-box--fast       - Fast
// .designsystem-box.designsystem-box--normal     - Normal
// .designsystem-box.designsystem-box--slow       - Slow
// .designsystem-box.designsystem-box--very-slow  - Very slow
//
// hidedefault: true
//
// Style guide tools.transitions

///////////////////
// TEXT STYLES
///////////////////
$shadow-1px: 1px 1px 0 rgba(0,0,0,0.5);

///////////////////
// TRANSITIONS
///////////////////
$ease-out: cubic-bezier(.25, 0.1, .25, 1);
$duration-very-fast:   .1s;
$duration-fast:        .2s;
$duration-normal:      .3s;
$duration-slow:        .4s;
$duration-very-slow:   .5s;
$transition-very-fast: $duration-very-fast ease-in-out;
$transition-fast:      $duration-fast ease-in-out;
$transition-normal:    $duration-normal ease-in-out;
$transition-slow:      $duration-slow ease-in-out;
$transition-very-slow: $duration-very-slow ease-in-out;

$transition-page-load: 1s $ease-out;


.designsystem-box {
    border: 1px solid grey;
    height: 100px;
    background-color: $color-white-smoke;
    &:hover {
        background-color: $color-text;
    }

    &.tall-box {
        height: 300px;
    }

    &--very-fast {
        transition: background-color $transition-very-fast;
    }
    &--fast {
        transition: background-color $transition-fast;
    }
    &--normal {
        transition: background-color $transition-normal;
    }
    &--slow {
        transition: background-color $transition-slow;
    }
    &--very-slow {
        transition: background-color $transition-very-slow;
    }
}
