.squarespots-container {
    max-width: 1170px;
    margin: auto;
    padding-bottom: 100px;

    .squarespots {
        font-size: 0;
        line-height: 0;
        margin: 0 -15px;

        &.squarespots--desktop4w {
            .squarespot-element {
                @media screen and (min-width: 1024px) {
                    width: calc(25% - 30px);

                    .squarespot-imagecontainer {
                        .squarespot-imglink {
                            .squarespot-overlaytext {
                                // line-height: 21px;
                                font-size: 22px;
                                top: calc(50% - 3px);
                            }
                        }
                    }
                }

                @media screen and (min-width: 641px) and (max-width: 1024px) {
                    width: calc(25% - 20px);

                    .squarespot-imagecontainer {
                        .squarespot-imglink {
                            .squarespot-overlaytext {
                                // line-height: 21px;
                                font-size: 22px;
                                top: calc(50% - 3px);
                            }
                        }
                    }
                }

                &.hide-on-tablet {
                    @media screen and (min-width: 641px) and (max-width: 1024px) {
                        display: none;
                    }
                }
            }
        }

        .squarespot-element {
            display: inline-block;
            vertical-align: top;
            width: calc(33.333% - 30px);
            margin: 0 15px;

            .squarespot-imagecontainer {
                .squarespot-imglink {
                    display: block;
                    position: relative;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .squarespot-overlaytext {
                        @include typeset(edge-1);
                        text-shadow: $shadow-1px;
                        // font-size: 40px;
                        // line-height: 43px;
                        // color: #292929;
                        // font-weight: 600;
                        
                        position: absolute;
                        text-align: center;
                        word-spacing: 100vw;
                        top: calc(50% - 5px);
                        left: 50%;
                        transform: translate(-50%, -50%);

                        &.squarespot-overlaytext--white {
                            color: #ffffff;
                        }
                    }

                    .splash-container {
                        display: none; // TODO: Temp.!
                    }
                }
            }

            .squarespot-content {
                text-align: center;
                padding-top: 13px;

                .squarespot-contentheader,
                .squarespot-contentsubtext {
                    // sorthvid
                    @include typeset(body-normal);
                    // font-size: 16px;
                    // line-height: 24px;
                    padding-bottom: 0;
                }

                .squarespot-contentheader {
                    font-weight: 600;
                }

                .squarespot-contentlink {
                    margin-top: 11px;

                }
            }
        }
    }

    .squarespots-disclaimer {
        // sorthvid
        @include typeset(body-small);
        // font-size: 13px;
        // line-height: normal;
        text-align: center;
        padding-top: 15px;
        margin-bottom: -4px;
    }
}

@media screen and (max-width: $tablet-bp-max-width) {
    .squarespots-container {
        padding: 0 0 60px 0;

        .squarespots {
            margin: 0;
            width: 100%;
            
            .squarespot-element {
                margin: 0 10px;
                width: calc(33.333% - 20px);

                .squarespot-imagecontainer {
                    .squarespot-imglink {
                        .squarespot-overlaytext {
                            // font-size: 22px;
                            // line-height: 21px;
                            top: calc(50% - 3px);
                        }
                    }
                }

                .squarespot-content {
                    padding-top: 10px;

                    .squarespot-contentheader,
                    .squarespot-contentsubtext {
                        // font-size: 14px;
                        // line-height: 18px;
                    }

                    .squarespot-contentlink {
                        margin-top: 6px;
                    }
                }
            }
        }

        .squarespots-disclaimer {
            // sorthvid
            // font-size: 12px;
            padding-top: 10px;
        }
    }
}

@media screen and (max-width: $mobile-bp-max-width) {
    .squarespots-container {
        padding: 0 0 15px 0;

        .moduleheader {
            margin-bottom: 17px;
        }

        .squarespots {
            margin: 0 -2.5px;

            .squarespot-element {
                width: calc(50% - 5px);
                padding-bottom: 25px;
                margin: 0 2.5px;

                .squarespot-content {
                    padding-top: 11px;

                    .squarespot-contentheader,
                    .squarespot-contentsubtext {
                        // font-size: 14px;
                        // line-height: 18px;
                        padding: 0 5px;
                    }

                    .squarespot-contentlink {
                        margin-top: 1px;
                    }
                }
            }
        }

        .squarespots-disclaimer {
            margin-top: -30px;
            padding: 15px 0 25px 0;
        }
    }
}