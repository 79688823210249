// Page header
//
// Standalone component.<br>
// A minimal heading, with a little vertical padding.<br>
// Ca be used with or without an accompanying leading paragraph.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/page-header.twig
//
// Weight: 1
//
// Style guide: components.page-header

.page-header {
    padding-top: rem(30);
    margin-bottom: rem(24);
    
    text-align: center;
    
    @include mq($from: screen-sm) {
        padding-top: rem(50);
    }
    
    h1, h2, h3, h4, h5, h6 {
        @include typeface(serif);
        @include typeset(display-1);
        
        padding: 0;
        margin: 0;
        
        @include mq($from: screen-sm) {
        }
    }
    
    &__manchet {
        p {
            @include typeface(sans-serif);
            @include typeset(body-medium);
            
            padding-top: rem(15);
            text-align: center;
        }
    }
    
    &--align-left {
        h1, h2, h3, h4, h5, h6 {
            text-align: left;
        }

        p {
            text-align: left;
        }
    }

    &--extra-padding-top {
        padding-top: rem(72);
    }
    &--no-padding-top {
        padding-top: 0;
        
        &--desktop {
            padding-top: rem(30);
            
            @include mq($from: screen-sm) {
                padding-top: 0;
            }
        }
    }
    &--no-margin-bottom {
        margin-bottom: 0;
    }

    &--small {
        h1, h2, h3, h4, h5, h6 {
            @include typeset(display-2);
        }
    }
}