.header {
    background-color: #ffffff;
    color: inherit;
    
    .pagemargin {
        max-width: 1440px;
        margin: auto;

        .utilitybar {
            float: right;
            width: auto;
            font-size: 14px;
            padding-top: 0px;

            .utilitybarcontent {
                .servicelinks {
                    display: inline-block;

                    li {
                        padding: 10px 0 0 0;
                        margin: 0px;
                        display: inline-block;
                        height: 75px;
                        box-sizing: border-box;
                        margin-right: 10px;

                        &:hover,
                        &.active {
                            border-bottom: 2px solid #292929;
                        }

                        a {
                            // font-weight: normal;
                            color: #292929;
                            display: inline-block;
                            padding: 32px 10px 20px 12px;
                            border-right: 1px solid #292929;
                            //                             margin: 10px 0 8px 0;
                            line-height: 11px;

                            &:last-child {
                                border-right: none;
                            }
                        }
                    }
                }

                .menuitem {
                    display: inline-block;
                    height: 75px;
                    box-sizing: border-box;
                    position: relative;
                    padding: 10px 0px 0px 0px;
                    margin-right: 25px;

                    .mitmagasinmenu {
                        display: none;
                        position: absolute;
                        width: 240px;
                        border: 1px solid #E7E7E7;
                        background-color: #FFFFFF;
                        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.17);
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 99;
                        top: calc(100% + 2px);

                        .navgroup {
                            padding: 20px 30px;

                            li {
                                padding: 0;
                                line-height: 30px;

                                a {
                                    // font-family: "Gill Sans Light", sans-serif;
                                    margin: 0;
                                    padding: 0;
                                    line-height: inherit;

                                    &:hover {
                                        // text-decoration: none;
                                        // color: #909090;
                                    }
                                }
                            }
                        }

                        .logoutbutton {
                            padding: 20px 30px;
                            border-top: 1px solid #E7E7E7;
                            line-height: 14px;

                            #logoutbutton {
                                @include typeset(display-5);
                                // line-height: 14px;
                                // font-size: 14px;
                                // font-weight: 600;
                                -webkit-appearance: none;
                                box-shadow: none;
                                background: none;
                                border: none;
                                cursor: pointer;
                                padding: 0;

                                &:hover {
                                    // text-decoration: none;
                                    // color: #909090;
                                }
                            }
                        }
                    }

                    &:hover {
                        border-bottom: 2px solid #292929;
                        cursor: pointer;

                        .mitmagasinmenu {
                            display: inline-block;
                        }
                    }

                    a {
                        color: #292929;
                        display: inline-block;
                        font-weight: normal;
                        padding: 32px 10px 20px 12px;
                        line-height: 11px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .rightcontainer {
                    float: right;

                    .wishlist-top-container {
                        display: inline-block;
                        float: left;
                        height: 74px;
                        box-sizing: border-box;
                        margin-right: 10px;

                        &:hover {
                            border-bottom: 2px solid #292929;
                            cursor: pointer;
                        }

                        &.wishlist-top-container--show {
                            display: block;
                        }

                        .wishlist-link {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                        }

                        .wishlist-header {
                            background-image: url(../images/wishlist/heart_icon.svg);
                            background-size: 24px;
                            background-position: center 36px;
                            background-repeat: no-repeat;
                            width: 50px;
                            position: relative;
                            padding: 35px 10px 20px 10px;
                            height: 75px;
                            box-sizing: border-box;

                            .wishlist-item-count {
                                @include typeset(body-small);
                                // font-size: 12px;
                                line-height: 20px;
                                position: absolute;
                                top: 25px;
                                right: 5px;
                                color: #ffffff;
                                height: 20px;
                                width: 20px;
                                border-radius: 10px;
                                text-align: center;
                                transition: opacity .3s ease;
                            }

                            .wishlist-header-container--nonempty {
                                background-color: #a3bcc4;
                                opacity: 1;
                            }

                            .wishlist-header-container--empty {
                                display: none;
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .logo {
        float: left;
        padding: 29px 25px 10px 30px;
        line-height: 0px;

        img {
            width: 125px;
            height: auto;
        }
    }

    .uspline {
        position: relative;
        clear: both;

        .usp-carousel-container {
            position: absolute;
            right: -6px;
            top: 12px;
            padding-right: 74px;
            margin-right: 10px;

            .usp-owl-carousel {
                width: 250px;

                .usp-item {
                    display: block;
                    text-align: right;
                    white-space: nowrap;

                    .usp-item-icon {
                        max-width: 33px;
                        max-height: 23px;
                        width: 100%;
                        margin-top: -3px;
                        display: inline-block;
                        vertical-align: middle;

                        img {
                            display: block;
                            width: 100%;
                        }
                    }

                    .usp-item-text {
                        @include typeset(display-5);
                        // font-size: 14px;
                        // font-weight: 600;
                        // color: #292929;
                        // text-transform: uppercase;
                        margin-left: -3px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }

            .usp-owl-carousel {
                .owl-nav {
                    .owl-next {
                        position: absolute;
                        width: 9px;
                        height: 14px;
                        left: calc(100% + 36px);
                        top: calc(50% - 7px);
                        background-image: url(../images/svg/frem_pil_ikon.svg);
                        background-size: 200% 100%;
                        background-repeat: no-repeat;
                        background-position: left -5px center;
                    }

                    .owl-prev {
                        display: none;
                        position: absolute;
                        width: 9px;
                        height: 14px;
                        right: calc(100% + 36px);
                        top: calc(50% - 7px);
                        background-image: url(../images/svg/frem_pil_ikon.svg);
                        background-size: 200% 100%;
                        background-repeat: no-repeat;
                        background-position: left -5px center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1375px) {
    .header {
        .pagemargin {
            .uspline {
                clear: none;
            }

            .usp-carousel-container {
                position: static;
                float: right;
                margin: 11px 4px 20px 0;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .header {
        .pagemargin {
            .mainmenucontainer {
                .mainmenu {
                    .menuitem {
                        padding: 0 3px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .header {
        .pagemargin {
            .mainmenucontainer {
                .mainmenu {
                    .menuitem {
                        padding: 0 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 857px) {
    .header {
        .pagemargin {
            .mainmenucontainer {
                .mainmenu {
                    .menuitem {
                        .menuitemlink {
                            padding: 0 7px 18px 7px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .header {
        .pagemargin {
            .mainmenucontainer {
                .mainmenu {
                    .menuitem {
                        .menuitemlink {
                            padding: 0 7px 18px 7px;
                        }
                    }
                }
            }
        }
    }
}


//@media screen and (max-width: 769px){
@media screen and (max-width: 1024px) {
    .header {
        // overflow: hidden;
        position: relative;
        
        .pagemargin {
            .logo {
                float: none;
                width: 100%;
                text-align: center;
                padding: 21px 0 20px 0;
            }

            .utilitybar {
                position: absolute;
                right: 0;

                .utilitybarcontent {
                    .servicelinks {
                        display: none;
                    }

                    .menuitem {
                        display: none;
                    }
                }
            }
        }
    }
}

// @media screen and (max-width: 768px){
//     .header {
//         overflow: initial;
//     }
// }

@media screen and (max-width: 640px){
	.header {
        .pagemargin {
            .uspline {
                background-color: #F5F5F5;
                clear: both;

                .usp-carousel-container {
                    float: none;
                    position: relative;
                    right: auto;
                    top: auto;
                    border-top: 1px solid #dcdcdc;
                    margin: 0;
                    padding: 7px 0;

                    .usp-owl-carousel {
                        width: calc(100% - 80px);
                        margin: auto;

                        .usp-item {
                            text-decoration: none;

                            .usp-item-text {
                                text-transform: none;
                                font-weight: 400;
                            }

                            display: block;
                            text-align: center;
                        }

                        .owl-nav {
                            .owl-prev {
                                display: block;
                                position: absolute;
                                top: 2px;
                                left: -20px;
                                right: auto;
                                background-image: url(../images/svg/frem_pil_ikon.svg);
                            }

                            .owl-next {
                                top: 1px;
                                right: -20px;
                                left: auto;
                            }
                        }

                    }
                }
            }

            .frontpage-usp-container {
                // display: none;
            }
        }
    }

}