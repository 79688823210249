.navigation {
    padding: 0 0 20px 0;

    .navgroup {
        position: relative;
        padding-bottom: 12px;
        clear: left;

        .groupname {
            // sorthvid
            @include typeset(display-5);
            // font-size: 15px;
            // font-family: "Gill Sans Light", sans-serif;
            // font-weight: 600;
            // text-transform: uppercase;
            
            padding: 0 0 20px 0;
            float: left;
            cursor: pointer;
            width: 100%;

            &.groupname--brandsearch:hover {
                cursor: default;
            }

            &.groupname--accountmenu {
                float: none;

                +ul {
                    margin-top: -36px;
                }
            }
        }

        .resetlink {
            display: inline-block;

            .secondarylink {
                // sorthvid
                @include typeset(body-small);
                // font-weight: 300;
                // font-style: normal;

                padding: 0;
                margin: 0 0 0 22px;

                a {
                    text-transform: none;
                }
            }
        }

        ul {
            clear: left;
            // sorthvid
            // padding: 0px 0px 0px 0px;
            // margin: 0px 0px 0px -2px;

            li {
                padding: 0;

                &.active {
                    >a {
                        text-decoration: none;
                        font-weight: 600;
                    }
                }

                a {
                    // sorthvid
                    @include typeset(body-normal);
                    // font-size: 15px;
                    // background-color: transparent;
                    // color: #292929;
                    // padding: 0 0 0 2px;
                    
                    line-height: 32px;

                    &:hover {
                        // sorthvid
                        // color: #909090;
                        // cursor: pointer;
                    }

                    &.no-values {
                        color: #b5b5b5;

                        &:hover {
                            color: #b5b5b5;
                            cursor: auto;
                        }
                    }
                }

                .submenu {
                    margin-left: 15px;
                }
            }
        }

        .navcollapse {
            float: right;
            position: absolute;
            width: 11px;
            height: 13px;
            padding: 2px;
            cursor: pointer;
            top: 32px;
            right: 6px;
            z-index: 1;

            .outerdash {
                transform-origin: center center;
                transform: translateY(5px);
                height: 1.1px;

                .dash,
                & {
                    position: absolute;
                    height: 1.1px;
                    width: 11px;
                    background-color: #292929
                }
            }

            &.navcollapse--collapsed {
                .outerdash .dash {
                    transform: rotate(90deg);
                }
            }
        }

        .navcontainer {
            margin-bottom: 12px;
            clear: left;

            .ui-slider {
                margin: 0 20px 10px 18px;
                position: relative;
                background-color: #ffffff;
                height: 1px;

                .ui-slider-range {
                    position: absolute;
                    z-index: 1;
                    display: block;
                    border: 0;
                    background-color: #292929;
                    top: 0;
                    height: 100%;
                }

                .ui-slider-handle {
                    position: absolute;
                    z-index: 2;
                    cursor: default;
                    background-color: #ffffff;
                    background-image: none;
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    top: -11px;
                    margin-left: -15px;
                    outline: none;

                    &:before {
                        content: "";
                        position: absolute;
                        background-color: transparent;
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        border: 2px solid #292929;
                        box-sizing: border-box;
                        top: 2px;
                        left: 2px;
                    }
                }
            }

            .minamount, .maxamount {
                float: left;
                padding: 12px 0 0 12px;

                input {
                    @include typeface(sans-serif);
                    
                    padding: 0px;
                    margin: 0px 8px 0px 0px;
                    width: 40px;
                    text-align: right;
                    font-size: 12px;
                    background-color: transparent;
                    color: inherit;
                }
            }

            .maxamount {
                // float: left;
                padding: 12px 20px 4px 0;

                input {
                    margin: 0px;
                //     padding: 0px;
                //     width: 40px;
                //     text-align: right;
                //     font-size: 12px;
                //     background-color: transparent;
                //     color: inherit;
                }
            }

            .navoverlay {
                display: none;
                position: fixed;
                z-index: 2000;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                width: calc(100% - 40px);
                max-width: 1220px;
                padding: 10px 20px 0 40px;

                .navoverlaycontent {
                    background-color: #ffffff;
                    padding: 10px 20px 40px 40px;
                    -moz-box-shadow: 0px 0px 22px -5px #000000;
                    -webkit-box-shadow: 0px 0px 22px -5px #000000;
                    box-shadow: 0px 0px 22px -5px #000000;
                    color: inherit;
                    font-size: 0;
                    line-height: 0;
                    position: relative;

                    .groupname {
                        font-family: $font-serif;
                        font-size: 30px;
                        line-height: 37px;
                        text-align: center;
                        float: none;
                        text-transform: none;
                        font-weight: 300;
                    }

                    .buttontd {
                        padding: 0px 0px 0px 0px;
                        width: 0px;
                        margin: 0;
                        position: absolute;
                        bottom: calc(100% - 63px);
                        left: calc(100% - 92px);

                        .button {
                            margin: 0;
                            padding: 0;
                            position: relative;
                            line-height: normal;
                            float: none;
                            display: inline-block;
                            background-color: #ffffff;
                            transform-origin: center right;

                            .small {
                                margin: 0;
                                background-image: none;

                                button {
                                    // untested
                                    @include typeface(sans-serif);
                                    // font-family: "Gill Sans Light", sans-serif;
                                    background-image: none;
                                    color: #292929;
                                    padding-right: 20px;
                                    background-color: transparent;
                                    position: relative;
                                    margin: 0;
                                    padding: 2px 27px 2px 2px;
                                    height: auto;
                                    font-size: 16px;
                                    text-transform: none;

                                    // sorthvid
                                    // font-weight: 600;
                                    &:before,
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        height: 1px;
                                        width: 16px;
                                        top: 50%;
                                        right: 0;
                                        background-color: #292929;
                                    }

                                    &:before {
                                        -webkit-transform: rotate(45deg);
                                        -moz-transform: rotate(45deg);
                                        -ms-transform: rotate(45deg);
                                        -o-transform: rotate(45deg);
                                        transform: rotate(45deg);
                                    }

                                    &:after {
                                        -webkit-transform: rotate(-45deg);
                                        -moz-transform: rotate(-45deg);
                                        -ms-transform: rotate(-45deg);
                                        -o-transform: rotate(-45deg);
                                        transform: rotate(-45deg);
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        margin: 22px 0 12px 0;
                        overflow: auto;
                        height: 400px;

                        li {
                            display: inline-block;
                            width: 225px;
                            white-space: nowrap;

                            .checkboxlabel {
                                display: inline-block;
                                margin: 0 20px 0 0;
                                width: calc(100% - 50px);
                                overflow: hidden;

                                label {
                                    display: inline-block;
                                    text-overflow: ellipsis;
                                    max-width: 100%;
                                    overflow: hidden;
                                }
                            }
                        }
                    }

                    .navoverlaybottom {
                        padding-top: 33px;
                        border-width: 0;
                        text-align: center;

                        .button {
                            float: none;
                            margin: 0;

                            .large {
                                margin: 0;
                                background-image: none;

                                button {
                                    display: inline-block;
                                    height: 50px;
                                    width: 220px;
                                    background-color: #9FBDC3;
                                    color: #ffffff;
                                    font-size: 16px;
                                    line-height: 50px;
                                    text-align: center;
                                    border-width: 0;
                                    padding: 0;
                                    cursor: pointer;
                                    -webkit-appearance: none;
                                    border-radius: 0;
                                    background-image: none;
                                    text-transform: none;
                                }
                            }
                        }
                    }
                }
            }

            .checkboxinput {
                float: left;

                .checker {
                    padding: 9px 13px 0 0;

                    span {
                        background-image: none;
                        border: 1px solid #292929;
                        box-sizing: border-box;
                        width: 15px;
                        height: 15px;
                        float: left;
                        line-height: 0;
                        background-repeat: no-repeat;

                        &.checked {
                            width: 15px;
                            height: 15px;
                            background-image: url(../images/svg/checkmark_black.svg);
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 11px 9px;
                        }

                        input {
                            opacity: 0;
                            filter: alpha(opacity=0);
                            background: none;
                        }
                    }
                }
            }

            .checkboxlabel {
                margin-left: 20px;

                label {
                    @include typeset(body-normal);
                    
                    line-height: 32px;
                    // background-color: transparent;
                    // font-size: 15px;
                    // padding: 0 0 0 2px;
                    // color: #292929;
                }
            }
        }

        &.Pris .navcontainer {
            padding-top: 29px;
        }

        .primarylink {
            padding: 14px 0px 12px 0px;

            a {
                padding: 0;
                color: #292929;
            }
        }
    }
}

.navcollapse.navcollapse--collapsed .outerdash {
    display: block;
}

// Search page specific styling
.searchresults .navigation {
    .navgroup {
        border-top: 1px solid #e7e7e7;

        .groupname {
            padding: 31px 0 19px 0;
            // line-height: 19px;

            .resetlink {
                // sorthvid
                // vertical-align: text-top;

                .secondarylink {
                    margin-left: 18px;
                }
            }
        }

        &:last-of-type {
            border-bottom: 1px solid #e7e7e7;
        }
    }

}

@media screen and (max-width: 769px) {
    .searchresults .navigation {
        .navgroup {
            .groupname {
                width: calc(100% - 25px);
                // line-height: 20px;
            }
        }
    }
}