.event {
    padding-top: 10px;
    .eventdescription {
	    float: left;
	    width: 60%;
	    padding-right: 40px;
	    height: 100%;
	    box-sizing: border-box;
	}
    .eventinfo {
	    float: left;
	    background-image: url(../images/eventbgshadow.png);
	    background-repeat: no-repeat;
	    background-position: left bottom;
	    width: 40%;
	    padding: 35px 0 50px 20px;
	    box-sizing: border-box;
	    &.eventdesc {
		    padding: 20px 0 50px 20px;
			.eventplace {
			    padding-top: 2px;
			    .old {
				    font-family: $font-serif;
				    font-size: 16px;
				    text-transform: uppercase;
				    padding-bottom: 12px;
				}
			}
			.datetime {
			    padding-bottom: 10px;
			}
		}
		.subhead {
		    color: #A3988B;
		    font-weight: bold;
		}
		.addevent {
		    padding-top: 15px;
		    a {
			    color: #292929;
			    span {
				    font-family: $font-serif;
				    // font-style: italic;
				    // font-weight: bold;
				    // color: #292929;
				}
			}
		}
	}
	.eventbottom {
	    padding-bottom: 30px;
	    .eventtags {
		    color: #A3988B;
		    padding-top: 30px;
		    font-weight: bold;
		    a {
			    font-family: $font-serif;
			    // font-style: italic;
			    // color: #292929;
			    font-weight: normal;
			    padding: 0 0 0 10px;
			    text-decoration: underline;
			}
		}
		.fb_iframe_widget{
			width: 100%;
			overflow: hidden;
			span{
				width: 100% !important;
				overflow: hidden;
				iframe{
					width: 100% !important;
				}	
			}
		}
	}
}

.orderflowcontent{
	.spots223px {
	    padding: 0 15% 20px 15%;
	    margin-right: -20px;
	    .spot {
		    float: left;
		    padding: 14px 14px 10px 0px;
		    box-sizing: border-box;
		    width: 50%;
		    &:nth-child(2n+1) {
			    clear: left;
			}
			.spotpic{ 
				a{ 
					img {
					    width: 100%;
					} 
				}
			}
			.spothead {
			    padding: 15px 0px 5px 0px;
			}
		}
	}
	.spot{
		a {
		    background-color: transparent;
		    color: #292929;
		}
		.spotsubhead {
		    padding: 5px 0px 0px 0px;
		    color: #a0988b;
		    font-weight: bold;
		    .title {
			    color: #292929;
			}
		}
		.readmorelink {
		    padding: 0px;
		    font-family: $font-serif;
		    // font-style: italic;
		    // font-weight: bold;
		    text-decoration: underline;
		}
	}
}

.spots223px.spots223px--stores {
    .spot {
        .spothead {
            h2 {
                background-color: transparent;
                padding: 0;
                a {
                    font-size: 27px;
                    color: #292929;
                    text-transform: none;
                }
            }
        }
        
    }
}

.registrationreceipt {
	.headline {
	    text-transform: none;
	}
	.subheader {
		@include typeset(body-normal);
	    //line-height: 25px;
	    font-weight: bold;
	}
	a{
		&.viewprofile {
			@include typeset(body-normal);
		    // font-size: 17px;
		    line-height: 43px;
		    text-transform: uppercase;
		    color: #ffffff;
		    text-align: center;
		    background-color: #292929;
		    width: 180px;
		    height: 43px;
		    display: inline-block;
		    margin-top: 30px;
		}
	}
	.standardcontent{
		&.centertext {
    		padding-bottom: 45px;
		}
	}
	.goodieappbox {
	    background-color: #ececec;
	    padding: 30px;
	    img {
		    width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
		}
	    .mobile.phone.image {
		    display: inline-block;
		    float: left;
		    width: 50%;
		    text-align: center;
		}
		.AppStore.links {
		    display: inline-block;
		    width: 50%;
		    text-align: center;
		    float: left;
		    .linksdescription {
				@include typeset(body-normal);
				@include typeface(serif);
				// font-family: $font-serif;
			    // font-size: 18px;
			    // line-height: 30px;
			    text-align: center;
				font-style: italic;
			    padding: 40px 0 30px;
			}
			.AppleAppStore {
			    float: right;
			    width: 45%;
			}
			.GooglePlay {
			    float: left;
			    width: 45%;
			}
		}
	}
	.divider {
	    text-align: center;
	    margin: 45px 0;
	    h3 {
		    color: #a19589;
		    text-transform: none;
		    background-color: #ffffff;
		    display: inline-block;
		    padding: 0 10px;
		    font-size: 15px;
		}
	}
	.advantages {
	    padding: 0 35px;
	    .advantage {
		    width: 20%;
		    float: left;
		    display: inline-block;
		    text-align: center;
		    color: #292929;
		    padding: 0 30px;
		    box-sizing: border-box;
		    a {
			    color: #292929;
			    font-size: 13px;
			    font-weight: bold;
			    text-transform: uppercase;
			}
		    img {
			    width: 75%;
			    display: inline-block;
			    margin-bottom: 20px;
			    vertical-align: middle;
			}
			.description {
			    display: none;
			}
		}
	}
}
	
.unsubnewsletteremail-container, .unsubnewsletteremail-confirm-container{
	.standardcontent {
	    font-size: 12px;
	    text-align: center;
	    div{
    	    font-size: 16px;
		    line-height: normal;
		    margin: 24px auto 39px auto;
	    }
	}
	h1 {
	    margin-top: 75px;
	    font-family: $font-serif;
	    font-size: 36px;
	    line-height: 40px;
	    font-style: normal;
	    font-weight: normal;
	    white-space: normal;
	    text-transform: none;
	}
	.unsuscribe-infotext {
	    font-size: 16px;
	    line-height: normal;
	    margin: 24px auto 39px auto;
	}
	input {
	    padding: 0 24px;
	    width: 390px;
	    font-size: 16px;
	    height: 50px;
	    line-height: 50px;
	    margin: 0;
	    border: 1px solid #e3e3e3;
	    background-color: #ffffff;
	    box-sizing: border-box;
	    border-radius: 0;
	}
	.button-style {
	    margin-left: 7px;
	    width: 160px;
	}
	.error-text {
	    display: block;
	    color: #fe3e64;
	    max-width: 560px;
	    margin: 0px auto 3px auto;
	    text-align: left;
	    font-size: 12px;
	}
}
.unsubscribeGC{
	font-size: 16px;
	.headline {
	    font-family: $font-serif;
	    font-size: 27px;
	    padding: 0 0 10px 0;
	    font-style: normal;
	    text-transform: none;
	    line-height: 30px;
	}
	#unsubscribe_body{
		padding: 0 75px;
	}
	.newsletterunsub {
	    text-align: center;
	    font-size: 0;
	    line-height: 0;
	    input {
		    padding: 0 24px;
		    width: 390px;
		    font-size: 16px;
		    height: 50px;
		    line-height: 50px;
		    margin: 0;
		    border: 1px solid #e3e3e3;
		    background-color: #ffffff;
		    box-sizing: border-box;
		    border-radius: 0;
		    margin-bottom: 10px;
		}
		.button-style {
		    margin-left: 10px;
		    width: 160px;
		    margin-bottom: 10px;
		}
		.error-text {
		    display: block;
		    color: #fe3e64;
		    max-width: 560px;
		    margin: 9px auto 14px auto;
		    text-align: left;
		    font-size: 12px;
		}
	}
	#gotoHomeLink {
	    height: 43px;
	    width: 160px;
	    display: inline-block;
	    background-color: #292929;
	    text-align: center;
	    line-height: 43px;
	    color: #ffffff;
	    text-decoration: none;
	    font-size: 14px;
	    text-transform: uppercase;
	    margin-top: 30px;
	}
	.unsuscribe-infotext {
	    max-width: 560px;
	    text-align: left;
	    margin: 4px auto;
	}
	.advantages {
	    padding: 0 35px;
	    .advantage {
		    display: inline-block;
		    width: 20%;
		    float: left;
		    padding: 35px 25px;
		    box-sizing: border-box;
		    text-align: center;
		    img {
			    width: 60px;
			}
			.title {
			    text-transform: uppercase;
			    color: #292929;
			    font-weight: bold;
			    font-size: 13px;
			    margin-top: 15px;
			}
			.description {
			    display: none;
			}
		}
	}
}
	
.goodielabelpadding {
    padding-left: 40px;
    padding-right: 170px;
}
.pagecontent {
	.maincontent {
		.creategoodie{
			.rightcontent{
				max-width: 750px;
				float: left;
			    font-size: 16px;
			    line-height: normal;
			}
		}
	}
}
.creategoodie{
    h2{
    	&.headline{
	    	width: 100%;
		    display: block;
		    background-color: transparent;
		    color: inherit;
		    text-transform: none;
		    // font-style: italic;
		    font-size: 30px;
		    line-height: 48px;
		    border-bottom: 1px solid #a19589;
		    text-align: left;
	    }
    }
	.headline {
	    font-family: $font-serif;
	    font-size: 20px;
	    // font-style: italic;
	    text-align: center;
	    line-height: 35px;
	    margin-top: 15px;
	}
	.goodieappbox {
	    padding: 30px;
	    margin-top: 25px;
	    img{
	    	width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
	    }
	    .mobile.phone.image{
	    	display: inline-block;
		    width: 50%;
		    float: left;
		    padding-right: 26px;
		    box-sizing: border-box;
		    text-align: center;
	    }
    	.AppStore.links{
	    	display: inline-block;
		    width: 50%;
		    float: left;
		    .linksdescription {
			    text-align: center;
			    font-family: $font-serif;
			    // font-style: italic;
			    font-size: 18px;
			    padding: 55px 0 30px;
			    line-height: 30px;
			}
			.AppleAppStore {
			    float: right;
			    width: 43.38%;
			    text-align: center;
			}
			.GooglePlay {
			    float: left;
			    width: 43.38%;
			    text-align: center;
			}
	    }
	}
	.creategoodieform {
	    padding: 20px;
	    background-color: #f2f2f2;
	    margin-bottom: 30px;
	    .headline {
		    text-align: left;
		    margin-top: 5px;
		    margin-bottom: 25px;
		}
		.form-row {
		    display: inline-block;
		    width: 37%;
		    box-sizing: border-box;
		    padding-right: 20px;
            vertical-align: top;
		    .leftfloat {
			    float: none;
			    input {
				    width: 100%;
				    height: 42px;
				    border-radius: 3px;
				    background-color: #ffffff;
				    border: 1px solid #797979;
				    margin: 0;
				    box-sizing: border-box;
				    padding: 13px;
				}
			}
			.label {
			    float: none;
			    width: auto;
			    display: inline-block;
			    font-weight: bold;
			    line-height: 16px;
			    font-size: 13px;
			    padding-bottom: 8px;
			}
		}
		.continuebutton {
		    float: none;
		    display: inline-block;
		    margin: 0;
		    width: 24%;
            padding-top: 28px;
		    .button {
			    margin: 0;
			    .large.lockimg {
				    background-image: none;
				    margin: 0;
				    button {
					    font-size: 17px;
					    background-image: none;
					    padding: 0;
					    width: 100%;
					    height: 42px;
					    margin: 0;
					    background-color: #65ba9c;
					    border: none;
					    font-weight: normal;
					}
				}
			}
		}
		.secondarylink {
			@include typeface(sans-serif);
		    // font-family: Arial,Helvetica,sans-serif;
		    font-style: normal;
		    font-weight: normal;
		    text-align: right;
		    display: block;
		    padding-top: 10px;
//		    width: 26%;
            width: 100%;  // Account-SignupContentPage
		    margin: 0 8px 0 auto;
		    text-align: right;
		}
	}
	.register{ 
		.orderflowcontent {
		    padding: 0;
		    margin-right: 0;
		    width: 100%;
		}
		#profile-create-login {
		    padding: 0;
		    margin: 0 -10px;
		    width: 85%;
		    
		    &.googie-content{
	    		width: 100%;
			    margin: 0;
			    padding: 0;
	    	}
		}
		.button .large button {
		    padding: 0;
		}
	}
}
.register{
	#profile-create-login {
	    position: relative;
    	padding: 0 10px;
    	fieldset{
    		.infotext {
			    padding: 0 10px;
			    display: inline-block;
			    width: auto;
			    float: left;
			    margin-top: -20px;
			}
    	}
    	&.googie-content{
    		padding: 0 20px 0 20px;
    	}
    	.existingcard{
    		.goodiesignupcard {
			    top: 130px;
			    right: 20px;
			}
    	}
    	.goodiesignupcard {
		    top: 40px;
		    right: 52px;
		}
	}
	.existingcard{
	    position: relative;
	    padding: 20px 10px;
	    background-color: #F2F2F2;
	    margin-top: 20px;
	    margin-bottom: 10px;
	}
	.orderflowcontent {
	    padding: 4px 10px 0 190px;
	    .subheader {
		    font-family: $font-serif;
		    font-size: 22px;
		    // font-style: italic;
		    padding: 10px 20px;
		}
	    .form-row {
		    width: 50%;
		    float: left;
		    padding: 10px 10px;
		    box-sizing: border-box;
		    position: relative;
		    display: inline-block;
		    &.goodiesignup {
			    width: 100%;
			    background-color: #F2F2F2;
			    .goodielabelpadding {
				    width: 90%;
				    padding-right: 10%;
				}
			    .label {
				    display: none;
				}
			}
		    &.subheader {
				@include typeface(serif);
				@include typeset(body-large);
			    // font-family: $font-serif;
			    // font-size: 20px;
			    // font-weight: normal;
			    // font-style: italic;
			    padding: 20px 10px;
			    margin: 0;
			    width: 80%;
			}
		    .leftfloat {
			    float: none;
			}
			.label {
			    float: none;
			    width: auto;
			    display: inline-block;
			    font-weight: bold;
			    line-height: 16px;
			    font-size: 13px;
			    padding-bottom: 8px;
			}
			.required-indicator {
			    float: none;
			    margin-left: 0;
			    color: #292929;
			}
            .intl-tel-input {
                width: 100%;
            }
			.input-text {
			    width: 100%;
			    height: 42px;
			    background-color: #ffffff;
			    border: 1px solid #797979;
			    margin: 0;
			    box-sizing: border-box;
			}
			.infolink {
			    display: none;
			}
			&.address {
			    width: 100%;
			}
			&.birthday{
				width: 60%;
			    float: left;
			    display: inline-block;
			}
			&.gender{
				width: 40%;
			    float: left;
			    display: inline-block;
			}
			.selector{
				background-image: none;
			    height: 39px;
    			box-sizing: border-box;
			    width: 100%;
			    border: 1px solid #797979;
			    position: relative;
			    overflow: initial;
			    margin-left: 0;
			    padding: 0;
			    span {
				    background-image: none;
				    height: 37px;
				    position: relative;
				    line-height: 37px;
				    padding: 0 47px 0px 10px;
				    overflow: initial;
				    &:after {
					    content: "";
					    width: 37px;
					    height: 37px;
					    display: inline-block;
					    position: absolute;
					    right: 0;
					    border-left: 1px solid #797979;
					}
				}
				select {
				    height: 37px;
				    width: 100%;
				}
				&:after {
				    content: "";
				    display: inline-block;
				    width: 0;
				    height: 0;
				    border-left: 5px solid transparent;
				    border-right: 5px solid transparent;
				    border-top: 5px solid #333333;
				    position: absolute;
				    right: 13px;
				    z-index: -1;
				    top: 16px;
				}
			}
			.dateselects {
			    width: 100%;
			    .selector {
				    width: 31.66%;
				    margin-right: 2.5%;
				    box-sizing: border-box;
				    margin-left: 0;
				    border-radius: 2px;
				    &:last-child {
					    margin-right: 0;
					}
				}
			}
			.radios {
			    width: 100%;
			    padding-top: 10px;
			    font-size: 13px;
			    .radio {
				    float: left;
				    height: auto;
				    width: auto;
			    	&.type2 {
				    	padding: 0px 25px 0px 0px;
				    	&:last-of-type {
						    padding: 0;
						}
						span {
						    background-size: 17px;
						    width: 19px;
						    height: 19px;
						}
					}
				}
			}
		}
		.ofbottom {
		    border: none;
		    margin: 0;
		    padding: 16px 0px 16px 0px;
		    .continuebutton {
			    margin: 0;
			    float: right;
			}
		}
	}
	.button {
	    margin: 0;
	    .large {
		    background-image: none;
		    button {
				@include typeset(body-normal);
			    // font-size: 17px;
			    // font-weight: normal;
			    background-image: none;
			    background-color: #292929;
			    height: 43px;
			    width: 180px;
			    padding-right: 20px;
			    margin-right: 20px;
			    border: none;
			}
		}
	}
	.permission {
	    padding: 20px;
	    margin: 0 20px;
	    border-bottom: 1px solid #bbbbbb;
	    position: relative;
	}
}
.rightcontent{
	.goodiefaq {
	    padding: 10px;
	
		.goodiefaqimg {
		    width: 12%;
		    float: left;
		    padding-right: 10px;
			box-sizing: border-box;
			img {
			    width: 100%;
			    height: auto;
			}
		}
		
		.goodiefaqquestion {
		    width: 88%;
		    float: left;
		}
		
		&:after {
		    content:  "";
		    clear:  both;
		    display:  table;
		}
	}
	.goodieservices {
	    margin: 0px -5px;
		.goodieservice {
		    width:  33.33%;
		    float: left;
		    padding: 0 5px;
		    box-sizing:  border-box;
		
			img {
			    width: 100%;
			    height: auto;
			}
		}
	}
	
	.continuebutton {
	    float: right;
	    margin: 0px -5px 0px 0px;
	}
	.goodiebenefits{
		.top{
		    margin-top: 18px;
			img{
				width: 100%;
				height: auto;
			}
		}
		.btncontainer {
		    text-align: center;
		    input[type="button"] {
			    font-size: 15px;
			    text-transform: uppercase;
			    width: 200px;
			    height: 43px;
			    -webkit-appearance: none;
			    border: none;
			    background-color: #292929;
			    color: #ffffff;
			    cursor: pointer;
			    font-family: $font-sans-serif;
			}
		}
		.fulpageheaddivider {
		    margin-top: 65px;
		}
		.advantages {
		    margin: 45px 0;
	    	.advantage {
			    margin-bottom: 45px;
			    .image {
				    display: inline-block;
				    float: left;
				    padding-right: 28px;
				    width: 15%;
				    box-sizing: border-box;
				    img{
				    	width: 100%;
				    	height: auto;
				    }
				}
				.title {
				    display: inline-block;
				    width: 85%;
				    font-size: 15px;
				    font-weight: bold;
				    float: left;
				    line-height: 25px;
				    text-transform: uppercase;
				}
				.description {
				    display: inline-block;
				    float: left;
				    width: 85%;
				}
			}
		}
		.infotext {
		    margin-bottom: 25px;
		}
	}
	.easycruitlogin{
		> div:not(.clear){
			float:left;
			label{
				width: 93px;
				display: inline-block;
			}
		}
	}
	.easycruitbtn{
		width:60px;
	}
	.campaign {
	    margin: 14px 0px 0px 0px;
	}
	.easyVacancyTable {
	    width: 98%;
	    padding: 10px 0 40px 0;
	    &.easyVacancyTableLinks{
	    	.easyVacancyTableHead {
	    		> div:not(.clear) {
				    padding-bottom: 14px;
				    text-align: left;
				    width: 9%;
				    float: left;
				    &.first{
				    	width: 50%;
				    }
				   	&.second {
					    width: 18%;
					}
					&.third {
					    width: 23%;
					}
					h3{
						a{
							a {
							    font-weight: normal;
							}
						}
					}
				}
			}
			.easyVacancyTableRow{
				> div:not(.clear){
					padding-bottom: 10px;
					text-align: left;
					width: 9%;
				    float: left;
				    &.first{
				    	width: 50%;
				    }
				   	&.second {
					    width: 18%;
					}
					&.third {
					    width: 23%;
					}
				}
			}
		}
	}
	.cardpages{
		margin: 10px 0 100px 0;
		.top{
			text-align: center;
			padding: 5px 15px 35px 0;
			img{
				width: 100%;
				height: auto;
			}
		}
		.entrypoints {
		    padding: 35px 0 0 0;
		    .item {
		    	width: 50%;
		    	box-sizing: border-box;
		    	float: left;
				display: inline-block;
				padding: 0 10px 10px 0;
				img{
					width: 100%;
					height: auto;
				}
			}
			&:after {
			    content:  "";
			    display:  table;
			    clear:  both;
			}
		}
		.leftside{
			float: left;
		    width: 75%;
		    box-sizing: border-box;
		    padding: 0 26px 30px 0;
		    min-height: 300px;
		    ul{
		    	li{
		    		&:before{
		    			content: none;
		    		}
		    		&.checkmark{
		    			background-image: url(../images/cardpages/listcheckmark.png);
						background-position: left 22px;
						padding: 20px 0 0 44px;
		    		}
		    		&.question{
		    			background-image: url(../images/cardpages/questionmark.png);
					    background-position: 7px 17px;
					    padding: 20px 0 0 44px;
		    		}
		    		h3{
		    			text-transform: none;
		    		}
		    	}
		    }
		}
		.rightside{
			float: left;
		    width: 25%;
		    padding: 0 0 30px 0;
		    > img{
		    	width: 100%;
		    	height: auto;
		    }
		    .createbutton {
			    text-align: center;
			    .button {
				    display: inline-block;
				    button {
					    padding-left: 25px;
					    padding-right: 25px;
					}
				}
			}
		}
		.spot{ 
			.readmore{ 
				a {
				    font-family: $font-serif;
				    // font-weight: bold;
				    // font-style: italic;
				    font-size: 12px;
				    color: #292929;
			    }
		    }
		}
	}
	.giftcardsaldo{
		.loginrequired {
		    padding: 0px 0px 18px 30px;
		}
		.required-indicator{
			text-align: left;
		}
		.getsaldo {
			.form-row {
			    padding: 5px 0px 6px 0;
			}
			input {
				@include typeface(sans-serif);
			    // font-family: "Gill Sans Light", sans-serif;
			    background-color: #ffffff;
			    height: 50px;
			    width: 300px;
			    padding: 5px 25px;
			    box-sizing: border-box;
			    margin: 0;
			    letter-spacing: 0.005em;
			    font-size: 16px;
			}
			.saldobutton {
			    float: right;
			    padding-top: 12px;
			    margin-right: 8px;
			}
		}
	}
	.eventcal{
		margin: 10px 0 10px 2px;
		.detskertop{
			img{
				width: 100%;
				height: auto;
			}
		}
		.storemonth{
			padding-bottom: 10px;
			.selector {
			    float: left;
			    margin-left: 0;
			}
			.choosemonth {
			    float: left;
			    margin-left: 20px;
			    .month {
					@include typeface(serif);
					// font-family: $font-serif;
				    float: left;
				    font-size: 16px;
				    font-weight: normal;
				    color: #292929;
				    text-transform: uppercase;
				}
				.prevnext {
					@include typeface(serif);
				    // font-family: $font-serif;
				    float: left;
				    font-size: 20px;
				    padding: 0 5px;
				}
			}
		}
		.day {
		    float: left;
		    text-align: center;
		    text-transform: uppercase;
		    width: 13.82%;
		    border-bottom: 1px solid #d1cac4;
		    background-image: url(../images/utilitybar_bg.gif);
		    background-repeat: repeat-x;
		    color: #ffffff;
		    font-weight: bold;
		    border-left: 1px solid #ffffff;
		    padding: 2px 0 1px 0;
		    &:first-child {
			    width: 13.96%;
			    border-left: none;
			}
			&:last-child {
			    width: 13.96%;
			}
		}
		.dateitem {
		    float: left;
		    width: 13.96%;
    		box-sizing: border-box;
		    border-left: 1px solid #d1cac4;
		    border-bottom: 1px solid #d1cac4;
		    padding: 8px;
		    height: 110px;
		    position: relative;
		    &:nth-child(7n +3) {
			    border-right: 1px solid #d1cac4;
			}
		    &.noday {
			    background-color: #fafaf8;
			}
			.date {
			    font-family: $font-serif;
			    font-size: 23px;
			    color: #292929;
			}
			.head {
			    color: #a0988b;
			    padding-top: 10px;
			    font-weight: bold;
			}
			.text {
			    font-weight: normal;
			    line-height: 16px;
			    a {
				    color: #292929;
				}
			}
			.readmore {
			    position: absolute;
			    font-size: 12px;
			    font-weight: bold;
			    text-decoration: underline;
			    bottom: 8px;
    			right: 8px;
			}
		}
	}
	.event {
	    padding-top: 10px;
	    font-size: 16px;
	    line-height: normal;
	    .eventimg{
	    	> img{
	    		width: 100%;
	    		height: auto;
	    	}
	    }
	    .spot{
	    	.spotsubhead {
	    		.title {
				    color: #292929;
				}
	    	}
	    }
	   	.eventlist {
		    padding-top: 10px;
		    .eventitem {
			    padding: 10px 0px 0px;
			    .eventimg {
				    float: left;
				    padding-right: 15px;
				    width: 35%;
				    box-sizing: border-box;
				    img{
				    	width: 100%;
				    	height: auto;
				    	vertical-align: middle;
				    }
				}
				.eventdesc {
				    float: right;
				    width: 65%;
				    h2{
				    	text-align: left;
					    font-size: 16px;
					    line-height: normal;
				    }
				    .subhead {
					    color: rgb(163, 152, 139);
					    font-weight: bold;
					    padding: 15px 0px 11px;
					}
				}
			}
		}
		.eventdesc {
			.eventplace {
			    padding-top: 2px;
			    table {
				    text-align: left;
				    border-spacing: 0px;
				    span {
					    text-align: left;
					}
				}
				span {
				    display: inline-block;
				    color: rgb(163, 152, 139);
				    font-weight: bold;
				    padding-right: 2px;	
				}
			}
			.eventtext {
			    padding-top: 18px;
			}
			.readmore {
			    font-family: $font-serif;
			    // font-style: italic;
			    // font-weight: bold;
			    text-decoration: underline;
			    a {
				    color: rgb(0, 0, 0);
				}
			}
		}
		.headline {
		    font-family: $font-serif;
		    font-size: 36px;
		    padding: 0 0 20px 0;
		    font-style: normal;
		    text-transform: none;
		    line-height: 40px;
		}
	}
	.spots342px{
        max-width: 960px;
		.spot{
			width: 50%;
            div a img{
                width: 100%;
            }
            &:nth-child(2n+1){
                clear: both;
            }
		}
        &.spots342px--magazines {
            .spot {
                width: 100%;
                padding: 0;
                font-size: 0px;
                float: none;
                div {
                    div {
                        width: 50%;
                        display: inline-block;
                        box-sizing: border-box;
                        vertical-align: top;
                        padding-top: 0;
                        font-size: 0px;
                    }
                    .spothead {
                        width: calc(50% - 20px);
                        margin-left: 20px;
                        box-sizing: border-box;
                        font-size: 16px;
                        font-size: 0px;
                        h2 {
                            margin-bottom: 5px;
                            display: inline-block;
                            font-size: 20px;
                        }
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 640px) {
            &.spots342px--magazines {
                .spot {
                    div {
                        div, .spothead {
                            width: 100%;
                            margin: 0;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
	}
	.spot {
	    padding: 14px 14px 0px 0px;
	    width: 33.33%;
	    box-sizing: border-box;
	    float: left;
	    .spotpic {
		    line-height: 0px;
		    img{
		    	width: 100%;
		    	height: auto;
		    }
		}
		.spothead {
		    padding: 15px 0px 5px 0px;
		    h2 {
		        font-family: $font-serif;
    			font-size: 20px;
    			font-weight: normal;
    			color: #ffffff;
    			text-transform: uppercase;
    			background-color: #292929;
    			padding: 1px 3px 0px 3px;
    			margin: 0px 0px 0px 0px;
    			display: inline;
    			line-height: 25px;
    			a {
    				background-color: transparent;
    				color: #fff;
    			}
		    }
		}
		.spotsubhead {
		    padding: 5px 0px 0px 0px;
		    color: #a0988b;
		    font-weight: bold;
		}
		a {
		    background-color: transparent;
		    color: #292929;
		    font-style: normal;
		    font-weight: normal;
            img{
                width: 100%;
            }
		}
		.readmorelink{ 
			a {
			    padding: 0px;
			    font-family: $font-serif;
			    // font-style: italic;
			    // font-weight: bold;
			    text-decoration: underline;
			}
		}
	}
    
    .openlocationmap {
        float: left;
        width: 50%;
        padding: 14px 0px 35px 0px;
        box-sizing: border-box;
        img{
            width: 100%;
        }
        .primarylink {
            float: right;
            margin-top: 9px;
        }
    }
    .storecampaign{
        img{
            width: 100%;
        }
    }  
}

@media screen and (max-width: 1024px) {
    .rightcontent{
        .openingcarousel {
            width: 100%;
            float: none;
        }
        .openlocationmap {
            width: 100%;
            float: none;
        }
    }
}

@media screen and (max-width: 992px){
	.registrationreceipt{
		.advantages{
			.advantage{
				width: 33.33%;
				margin-bottom: 20px;
			}
		}
	}
	.creategoodie {
		.register {
			#profile-create-login {
			    width: 100%;
		    }
		}
	}
	.rightcontent{
		.giftcardsaldo{
			.form-row{
				padding-left: 0;
			}
		}
	}
}

@media screen and (max-width: 769px){
	.unsubscribeGC{
		#unsubscribe_body{
			padding: 0;
		}
	}
	.rightcontent{
		.event{
			.eventlist{ 
				.eventitem{ 
					.eventimg{
						width: 100%;
						padding-right: 0;
					}
					.eventdesc{
						width: 100%;
					}
				}
			}
		}
	}
}

//@media screen and (max-width: 520px) {
@media screen and (max-width: 640px) {
	.event{
		.eventdescription {
		    float: none;
		    width: 100%;
		    padding-right: 0;
		    height: auto;
		}
		.eventinfo {
		    float: none;
		    background-image: none;
		    width: 100%;
		    padding: 35px 0 50px 0;
		    &.eventdesc {
			    padding: 20px 0 50px 0;
			}
		}
	}
	.orderflowcontent{
		.spots223px {
		    .spot {
			    float: none;
			    width: 100%;
			}
		}
	}
	.registrationreceipt{
		.goodieappbox{
			.mobile.phone.image {
			    float: none;
			    width: 100%;
			}
			.AppStore.links {
			    display: block;
			    width: 100%;
			    float: none;
			}
		}
		.advantages{
			padding: 0;
			.advantage{
				width: 50%;
				.title {
				    min-height: 36px;
				}
			}
		}
	}
	.unsubnewsletteremail-container {
		input {
		    width: 100%;
		    margin-bottom: 10px;
		}
	}
	.unsubscribeGC{
		.newsletterunsub {
		    input {
			    width: 100%;
			}
		}
		#gotoHomeLink {
		    width: 100%;
		}
		.advantages {
		    padding: 0;
		    .advantage {
			    width: 50%;
			    padding: 10px;
			    img {
				    width: 60px;
				}
			}
		}
	}
    .creategoodie {
    	.goodieappbox {
		    .mobile.phone.image{
		    	display: block;
			    width: 100%;
			    float: none;
			    padding-right: 0;
			    text-align: center;
    			margin-bottom: 60px;
		    }
	    	.AppStore.links{
		    	display: block;
			    width: 100%;
			    float: none;
			    .linksdescription {
				    display: none;
				}
				.AppleAppStore {
				    float: right;
				    width: 43.38%;
				}
				.GooglePlay {
				    float: left;
				    width: 43.38%;
				}
		    }
		}
		.creategoodieform {
			.headline {
			    margin-bottom: 0;
			}
			.form-row {
			    display: block;
			    width: 100%;
			    padding-right: 0;
			    .label{
			    	padding-top: 25px;
			    }
			}
			.continuebutton {
			    display: block;
			    width: 100%;
			}
			.secondarylink {
			    text-align: center;
			    width: 100%;
			    margin: 0;
			    text-align: center;
			}
		}
		.register {
			#profile-create-login {
			    margin: 0;
			}
		}
	}
	.register {
		.button {
			.large {
				margin: 0;
				button {
					width: 100%;
					margin: 0;
				}
			}
		}
		.orderflowcontent {
			.ofbottom{
				.continuebutton {
				    float: none;
				}
			}
			.form-row {
			    width: 100%;
			    float: none;
			    padding: 10px 0;
			    display: block;
			    &.birthday, &.gender{
			    	width: 100%;
			    }
			   	&.subheader{
					width: 100%;
				}
				&.goodiesignup {
					padding: 10px 10px;
					.checkbox.type2 .checker span {
					    width: 19px;
					    height: 19px;
					    background-size: 19px;
					}
					.goodielabelpadding{
						padding-right: 0;
					}
				}
			}
		}
		#profile-create-login{
			.existingcard {
				.form-row{
					&.subdescription{
						display: none;
					}
					&.subheader{
	    				padding-bottom: 190px;
					}
				}
				.goodiesignupcard {
					width: 80%;
				    top: 130px;
				    right: 50%;
				    transform: translateX(50%);
				    -webkit-transform: translateX(50%);
				    -moz-transform: translateX(50%);
				    -ms-transform: translateX(50%);
				    img{
				    	width: 100%;
				    	height: auto;
				    }
				}
			}
		}
	}
	.rightcontent{
		.goodiefaq {
		    padding: 10px 0;
		
			.goodiefaqimg {
			    display: none;
			}
			
			.goodiefaqquestion {
			    width: 100%;
			    float: none;
			}
		}
		.goodieservices {
		    margin: 0;
			.goodieservice {
			    width: 100%;
			    float: left;
			    padding: 10px 0;
			}
		}
    	.goodiebenefits{
			.advantages {
				display: -webkit-box!important;
			    display: -ms-flexbox!important;
			    display: flex!important;
			    -webkit-box-pack: center!important;
			    -ms-flex-pack: center!important;
			    justify-content: center!important;
			    -ms-flex-wrap: wrap!important;
	    		flex-wrap: wrap!important;
			    margin: 0 -34px;
		    	.advantage {
		    		width: 49%;
				    display: inline-block;
				    text-align: center;
				    padding: 0 34px;
				    box-sizing: border-box;
				    .image {
					    display: block;
					    float: none;
					    padding-right: 0;
					    width: 100%;
					    img{
					    	width: 41.66%;
					    }
					}
					.title {
					    display: block;
					    width: 100%;
					    float: none;
					    margin: 20px 0 15px;
					}
					.description {
					    display: block;
					    float: none;
					    width: 100%;
					}
				}
			}
			.infotext {
			    margin-bottom: 25px;
			}
		}
    	.easycruitlogin{
    		> div:not(.clear){
				float:none;
	    		input{
	    			width: 100%;
	    		}
			}
    	}
    	.easyVacancyTable {
		    width: 100%;
		    border-top: 1px solid #EEEEEE;
		    padding: 0;
		    &.easyVacancyTableLinks{
		    	.easyVacancyTableHead {
		    		display: none;
				}
				.easyVacancyTableRow{
					border-bottom: 1px solid #EEEEEE;
					padding: 20px 0 10px;
					> div:not(.clear){
						padding-bottom: 10px;
						text-align: left;
						width: 100%;
					    float: left;
					    &.first{
					    	width: 100%;
					    	font-weight: bold;
					    }
					   	&.second {
						   width: 100%;
						}
						&.third {
						   width: 100%;
						}
					}
				}
			}
		}
    	.cardpages{
			.entrypoints {
			    .item {
			    	width: 100%;
			    	float: none;
			    	padding-right: 0;
				}
			}
			.leftside{
				width: 100%;
				padding-left: 0;
			}
			.rightside{
				width: 100%;
			}
		}
    	.eventcal{
    		.day{
    			display: none;
    		}
    		.dateitem{
    			width: 100%;
    			height: auto;
    			border-left: none;
    			&:nth-child(7n +3) {
				    border-right: none;
				}
    			&.noday{
    				display: none;
    			}
    			.date{
				    width: 14%;
    				float: left;
    			}
    			.head{
    				padding-top: 0;
    				width: 86%;
    				float: right;
    			}
    			.text{
    				width: 86%;
    				float: right;
    			}
    		}
    	}
		.spots342px .spot, .spot{
			width: 100%;
            padding-right: 0;
		}
        .infobox {
            float: none;
            width: 100%;
        }
       	.giftcardsaldo{
       		.required-indicator {
			    text-align: left;
			}
       		.loginrequired {
			    padding-left: 0;
			}
			.form-row{
				.leftfloat{
					float: none;
					.input-text{
						width: 95%;
					}
				}
				.infolink{
					padding-left: 0;
				}
			}
		}
    }
    .spots223px.spots223px--stores {
        .divider {
            display: none;
        }
        .spot {
            .spothead {
                padding: 5px 0px 15px 0px;
                text-align: center;
                border-bottom: 1px solid #a19589;
                margin: auto -7.142857%;
                h2 {
                    background-color: transparent;
                    padding: 0;
                    a {
                        font-size: 20px;
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 480px) {
    .rightcontent .openingcarousel .jcarousel-container-horizontal {
        width: 278px;
        padding: 20px 0;
    }    
}
