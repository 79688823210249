// Main search
//
// The search bar in the header.<br>
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/search-main.twig
//
// Weight: 1
//
// Style guide: components.search-main

$mobile-menu-height: rem(89); // Aprox height of header

.sitesearch {
    * {
        @include typeface(sans-serif);
        
        -webkit-tap-highlight-color: transparent;
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;

    pointer-events: none;
    z-index: 103;

    @include mq($from: screen-xs) {
        margin-top: 0;
        margin-bottom: rem(24);
    }
    
    @include mq($from: screen-sm) {
        // NOTE: a bit messy, but the header is float so until header gets a redesign it's like this
        position: relative;
        width: 50%;
        float: left;
        margin-left: 3%;
        pointer-events: auto;
    }

    @include mq($from: screen-md) {
        // NOTE: a bit messy, but the header is float so until header gets a redesign it's like this
        width: 30%;
        margin-top: rem(30);
    }

    a {
        transition: color $transition-fast;
        color: $color-text;

        &:focus {
            text-decoration: underline;
        }
        
        &:hover {
            color: $color-dark-grey !important;
            text-decoration: none;
        }
        
        &:active {
            text-decoration: none;
        }
    }

    //
    //
    // THE SEARCH INPUT FORM
    //
    //
    &__formarea {
        display: none;
        align-items: center;
        justify-content: space-between;
        
        position: relative;
        z-index: 101;
        
        width: 100%;
        height: $mobile-menu-height; // = roughly height of the header
        
        box-sizing: border-box;
        
        background: white;
        border-bottom: 1px solid $color-border;
        
        @include mq($from: screen-sm) {
            transition: border $transition-fast;
            display: flex;
            height: auto;
            border: 1px solid $color-border;
            padding: 0;
            box-sizing: content-box;
        }
    }
    
    &__form {
        transition: border-color $transition-fast;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        width: calc(100% - #{ $mobile-menu-height }); // 100% - the close button
        height: rem(50);
        padding: 0 rem(24) 0 rem(9);
        box-sizing: border-box;
        
        background-color: $color-white;
        
        @include mq($from: screen-sm) {
            width: inherit;
            max-width: rem(500);
            height: rem(42);
            margin: 0;
            padding: 0;
        }

        input[type="text"] {
            padding: 0;
            border: none;
            background: none;
            font-size: rem(18);
            
            width: 100%;
            height: 100%;
            padding: 0 rem(15);

            @include mq($from: screen-sm) {
                font-size: rem(15);
            }

            &::-ms-clear { display: none; } // some IE browsers renders an 'X' to clear the input
        }
        
        button[type="submit"] {
            transition: opacity $transition-fast;
            background: none;
            border: none;
            outline: none;
            font-size: 0;

            position: relative;
            display: none !important; // NOTE: som random js removes button on click..
            align-items: center;
            justify-content: center;
            
            padding: 0;
            width: rem(58);
            height: 100%;

            cursor: pointer;
            
            &[disabled] {
                cursor: auto;
                pointer-events: none;
            }
            
            &:before { // Used as :focus indicator
                transition: transform $transition-very-fast;
                transform: translate(-50%,-50%) scale(0);
                content: "";
                position: absolute;
                
                background: $color-light-grey;
                
                width: rem(20);
                height: rem(20);
                top: 50%;
                left: 50%;
                border-radius: 50%;
            }
            
            @include mq($from: screen-sm) {
                display: inline-flex !important; // NOTE: som random js removes button on click..
                
                &:focus {
                    &:before {
                        transform: translate(-50%,-50%) scale(1);
                    }
                }
            }
    
            svg {
                position: relative;
                width: rem(20);
                height: rem(20);
                
                @include mq($from: screen-sm) {
                    width: rem(15);
                    height: rem(15);
                }
            }
        }
    }

    &__clear-search {
        background: none;
        border: none;

        color: $color-middle-grey;
        font-size: rem(13);
        height: 100%;
        padding-top: rem(2); // Align it with input field

        @include mq($from: screen-sm) {
            display: none;
        }
    }

    &__mobile-open {
        display: block !important; // NOTE: som random js removes button on click..
        width: 43px;
        height: 43px;
        background-color: transparent;
        background-image: url(../images/search_icon.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 23px auto;
        position: absolute;
        top: 25px;
        left: 55px;

        border: none;
        -webkit-appearance: none;

        @include mq($from: screen-sm) {
            display: none !important; // NOTE: som random js removes button on click..
        }
    }

    &__mobile-close {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        font-size: 0;
        
        width: $mobile-menu-height;
        height: $mobile-menu-height;

        border-left: 1px solid $color-border;

        display: inline-block !important; // NOTE: som random js removes button on click..

        @include mq($from: screen-sm) {
            display: none !important; // NOTE: som random js removes button on click..
        }
    }
    
    //
    //
    // RESULT BOX
    //
    //
    &__dropdown {
        transition: opacity $transition-fast,
            border-width $transition-normal,
            height $transition-normal,
            width $transition-fast; // Height and width gets set in search.js
        opacity: 1;
        
        position: absolute;
        z-index: 101;
        min-width: 100%;
        
        border-bottom: 1px solid $color-border;
        background-color: $color-background-grey;
        
        pointer-events: none;
        overflow: hidden;
        
        @include mq($from: screen-sm) {
            opacity: 0;
            border: 1px solid $color-border;
        }

        @include mq($from: screen-sm) {
            max-width: rem(700);
        }
        
        @include mq($from: screen-md) {
            max-width: rem(800);
        }

        &:focus {
            outline: none;
        }

        &--no-border { // Removed when dropdown is open
            transition: opacity $transition-fast,
                border-width 0s $transition-normal,
                height $transition-normal,
                width $transition-fast; // Height and width gets set in search.js
            border-width: 0px;
        }
        
        &[aria-hidden="true"] {
            visibility: visible;
            opacity: 0;
            pointer-events: none;
        }

        &__view {
            transition: opacity $transition-fast;//, height $transition-normal; 
            opacity: 0;
            
            position: absolute;
            top: 0;
            width: 100%;
    
            box-sizing: border-box;
            background-color: $color-background-grey;
    
            pointer-events: none;
            
            &[aria-hidden="true"] {
                visibility: visible;
                opacity: 0;
                pointer-events: none;
            }
            
            &[aria-hidden="false"] {
                opacity: 1;
                pointer-events: auto;
                
                @include mq($from: screen-sm) {
                    pointer-events: none;
                }
            }

            &:empty {
                border: none;
            }
            
            .category,
            .category--history {
                color: $color-border-hover;
                text-transform: uppercase;
                letter-spacing: 1px;
                
                width: 100%;
            }
        }
    }

    .search-suggestion-wrapper,
    .search-suggestion-wrapper-full {
        width: 100%;

        &.two-cols {
            width: 100vw;
        }

        @include mq($from: screen-sm) {
            max-width: rem(700);
        }
        
        @include mq($from: screen-md) {
            max-width: rem(800);
        }
    }

    .search-suggestion-contents {
        display: flex;
        flex-wrap: wrap;

        @include mq($from: screen-sm) {
            flex-wrap: nowrap;
        }

        &.history {
            flex-direction: column;
            padding-bottom: rem(20);
        }

        * {
            margin: 0;
            padding: 0;

            line-height: 1.6;
            text-align: left;
            font-size: rem(15);
            
            @include mq($from: screen-sm) {
                font-size: rem(13);
            }
        }

        > div:nth-child(2) {
            border-top: 1px solid $color-border;
            
            @include mq($from: screen-sm) {
                border-top: none;
                border-left: 1px solid $color-border;
            }
        }

        .suggestions {
            position: relative;
            width: 100%;

            @include mq($from: screen-sm) {
                max-width: rem(300);
            }
            
            @include mq($from: screen-md) {
                max-width: rem(325);
            }
        }
        
        .search-list {
            padding: rem(0) rem(24) rem(20);
            width: 100%;
            box-sizing: border-box;
            
            background-color: $color-background-grey;
            
            &:first-of-type {
                padding: rem(20) rem(24);
            }
            
            &__title {
                font-style: normal;
                font-size: rem(14);
                padding-bottom: rem(8);
                
                @include mq($from: screen-sm) {
                    font-size: rem(12);
                    padding-bottom: rem(4);
                }
            }
            
            &__item {
                padding-bottom: rem(8);
                
                @include mq($from: screen-sm) {
                    padding-bottom: rem(4);
                }
                
                &__count {
                    color: $color-middle-grey;
                }
            }
            
            &__sublist {
                li {
                    padding-left: rem(15);
                }
            }

            &__clear-button {
                padding: rem(0) rem(24);
                text-decoration: underline;

                &:focus {
                    color: $color-dark-grey;
                }
            }
        }
    }

    &[aria-hidden="true"] {
        pointer-events: none;
        
        * {
            pointer-events: none;
            opacity: 0;
        }
    }

    &[aria-hidden="false"] {
        pointer-events: auto;

        .sitesearch__formarea {
            display: flex;
        }
    }

    @include mq($from: screen-sm) {
        &:focus-within {
            .sitesearch__formarea {
                border: 1px solid $color-white;
            }

            .sitesearch__backdrop {
                opacity: 1;
                pointer-events: auto;
            }

            .sitesearch__dropdown {
                opacity: 1;
                pointer-events: auto;
                
                &__view[aria-hidden="false"] {
                    pointer-events: auto;
                }
            }
        }
    }
    
    //
    //
    // BACKGROUND BACKDROP
    //
    //
    &__backdrop {
        transition: opacity $transition-fast;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        min-height: 150vh; // Cover it all, on mobiles
        z-index: 100;
        pointer-events: none;
        background: white;
        
        @include mq($from: screen-sm) {
            background: rgba(0,0,0,0.5);
        }
        
        .sitesearch[aria-hidden="false"] & {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

//
//
// PRODUCT CARD
//
//
#search-suggestions {
    .products {
        width: 100%;
        max-width: rem(480);
        
        background: $color-white;
        padding: rem(20) rem(24) rem(24);
        box-sizing: border-box;
        
        .category {
            font-style: normal;
        }
        
        .productitemcontainer {
            width: 100%;
            max-width: 100vw;
            margin-top: rem(24);

            &:first-of-type {
                margin-top: rem(6);
            }
            
            * {
                font-size: rem(15);
                line-height: 1.6;
                text-align: left;
                
                margin: 0;
                padding: 0;
                
                @include mq($from: screen-sm) {
                    font-size: rem(13);
                }
            }

            .productbadge {
                display: none;
            }
            
            .productitem {
                display: flex;
                
                .productimg {
                    display: inline-flex;
                    align-items: center;
                
                    width: rem(100);
                    height: rem(100);
                
                    margin-right: rem(15);
                
                    background: $color-white;
                    border: 1px solid $color-border;
                
                    .image {
                        position: relative;
                        width: 100%;
                        height: 100%;
                    
                        display: flex;
                        align-items: center;
                    
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            width: 100%;
                            height: 100%;
                        
                            img {
                                transition: transform .2s linear;
                                will-change: transform;
                                transform: scale(1.001);
                                
                                max-height: 68px;
                                max-width: 68px;
                                width: auto; 
                                
                                &.image-missing { // NOTE: something old, not sure if needed
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            
                .product-text-container {
                    width: calc(100% - rem(115)); // = 100% - imagewidth
                    margin-top: rem(-3);

                    @include mq($from: screen-sm) {
                        max-width: 22em;
                    }
                
                    div { // trunc _product name_ (TODO: make this target class name)
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .productname {
                        white-space: normal;
                        width: 100%;
                        display: block;
                        hyphens: auto;
                    }
                
                    .productprice {
                        font-weight: 600;
                        
                        .beforeprice {
                            color: $color-text;
                            font-weight: normal;
                        
                            display: inline-flex;
                            
                            span, b {
                                font-weight: normal;
                            }
                        
                            + b {
                                color: #D71B0D;
                                margin-left: rem(5);
                                
                                + span {
                                    color: #D71B0D;
                                }
                            }
                        }
                        
                        .goodieprice {
                            color: $color-text;
                            font-weight: normal;
                        
                            display: inline-flex;
                            
                            span, b {
                                font-weight: normal;
                            }
                        
                            + b {
                                color: #74C8CC;
                                margin-left: rem(5);
                            
                                + span {
                                    color: #74C8CC;
                                }
                            }
                        }
                    }
                }
            }
        
            &:focus-within, &:hover {
                img {
                    transform: scale(1.1) !important;
                }
            }
        }
    }
    
    .wishlist-wishbutton-listmenu { // NOTE: is this in use?
        position: relative;
    }
}
