.store-contact {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;

    & > div:first-child {
        width: 100%;
    }
    & > div:last-child {
        width: 100%;
    }

    @include mq($from: screen-md) {
        & > div:first-child {
            width: calc((100% - 1px) / 3);
        }
        & > div:last-child {
            width: calc((100% - 1px) / 3 * 2);
        }
    }

    &__block {
        padding: 0 rem(30);
        box-sizing: border-box;
    }

    hr {
        height: 1px;
        border: none;
        background-color: $color-border;

        margin: 0;
    }
}