// Opening carousel
//
// Standalone component.<br>
// Has no background color itself. Place this component inside a deck that has a background color.<br>
// Is composed of a list of content. The opening carousel is used to show opening hours. Each list item will be a 'slide' in the carousel.<br><br>
// Will always show the first item in bold, and with a different text.<br>
// Uses <a href="https://owlcarousel2.github.io/OwlCarousel2/">Owl Carousel</a>
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/opening-carousel.twig
//
// wrapperstyles: display:block; background-color: #eee;
//
// Weight: 1
//
// Style guide: components.opening-carousel

.opening-owl-carousel {
    // opacity: 0; // Get's animated in opening-owl-carousel.js

    position: relative !important; // '!important' added to overwrite _carousels.scss line 1016
    font-family: $font-sans-serif;
    width: 68% !important;
    max-height: rem(112);
    margin: 0 auto;
    box-sizing: border-box;
    
    font-size: rem(15);
    line-height: 1.6;
    @include mq($from: screen-sm) {
        width: 80% !important;
        max-height: rem(132);
    }
    @include mq($from: screen-xl) {
        width: 90% !important;
    }

    // &.visible {
    //     opacity: 1; // Get's animated in opening-owl-carousel.js
    // }

    // .owl-item:not([data-has-staggered]) {
    //     opacity: 0; // Get's animated in opening-owl-carousel.js
    // }
    
    &__item {
        padding: rem(20) 10%;
        
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        text-align: center;
        @include mq($from: screen-sm) {
            padding: rem(30) 10%;
        }
    }

    .openinghours {
        &__day {
            @include typeset(trumpet);

            margin-bottom: rem(3);
        }
    }

    .owl-nav {
        display: flex;
        justify-content: space-between;
        
        position: absolute;
        width: 120%;
        margin: 0 -10%;
        top: 50%;
        transform: translateY(-50%);

        box-sizing: border-box;

        pointer-events: none;
        @include mq($from: screen-sm) {
            width: 110%;
            margin: 0 -5%;
        }
        @include mq($from: screen-xl) {
            width: 104%;
            margin: 0 -2%;
        }

        > * {
            pointer-events: auto;
            background-color: transparent;
            border: none;

            &:focus {
                outline: 1px solid $color-background-grey;
            }
        }

        .disabled {
            opacity: .1;
        }
    }
}