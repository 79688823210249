// List with icons
//
// An un-ordered list with icons and text 
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/elements/list-icons.twig
//
// Weight: 1
//
// Style guide: elements.list-icons

.list-icons {
    li {
        @include typeset(body-small);

        display: flex;
        align-items: center;

        padding: 0;
    }

    img, svg {
        width: 9px;
        height: 9px;
        margin-right: .5em;
        margin-bottom: -.1em;

        fill: $color-dull-orange;
    }
}