.orderlist {
	margin-right: 14px;

	.orderlisttopbar {
		background-color: #ebebeb;
		padding: 5px 0px 5px 10px;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: bold;

		.datesort {
			float: left;
			width: 27%;
		}

		.ordernrsort {
			float: left;
			width: 36%;
		}

		.orderstsort {
			float: left;
			width: 20%;
		}

		.amountsort {
			width: 16%;
			text-align: right;
			float: left;
		}
	}

	.orderitem {
		border-bottom: 1px solid #d0cac4;
		padding: 15px 0px 15px 10px;

		.orderdatecoll {
			float: left;
			width: 27%;

			.orderdate {
				font-weight: bold;
			}
		}

		.numbercoll {
			float: left;
			width: 36%;

			.ordernumber {
				float: left;
				font-weight: bold;
				padding-right: 10px;
				min-width: 70px;
			}

			.primarylink {
				float: left;
				margin: 0px;
			}
		}

		.orderstatuscoll {
			float: left;
			width: 20%;

			.orderstatustext {
				float: left;
			}
		}

		.amountcoll {
			width: 16%;
			text-align: right;
			box-sizing: border-box;
			padding-right: 10px;
			float: right;

			.orderamount {
				text-align: right;
				font-weight: bold;
			}
		}
	}
}

.rightcontent {
	.navigationnm {
		margin-right: 0px;
		padding: 0px;
	}

	.paging {
		float: right;
		padding: 8px 0px 0px 0px;

		.results {
			float: left;
			padding: 3px 15px 0px 0px;
		}
	}

	&.rightcontent--delete-account {
		font-size: 16px;
		line-height: 18px;
		text-align: center;

		.error-msg {
			color: red;
		}

		h1.headline,
		h2.headline {
			@include typeface(serif);
			@include typeset(display-3);
			// font-family: $font-serif;
			// font-size: 27px;
			// font-style: normal;
			// text-transform: none;
			// line-height: 30px;
			text-align: center;
			padding: 0 0 10px;
			margin-bottom: 0;
		}

		p {
			margin-bottom: 36px;
		}

		ul {
			display: inline-block;
			text-align: left;
			margin-left: 1em;
			padding-left: 40px;

			li {
				list-style: disc;
				padding: 0;
			}
		}
	}
}

.confirm-delete-account {
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	padding: 50px 0px 50px 0;
	width: 100%;

	h1 {
		@include typeface(serif);
		@include typeset(body-large);
		// font-family: $font-serif;
		// font-size: 20px;
		// line-height: 25px;
		// font-weight: normal;
		// color: #292929;
		// text-transform: uppercase;
		// background-color: transparent;
		// font-style: italic;
		
		padding: 0px;
		margin: 0px;
		margin-bottom: 10px;

		img {
			margin-right: 10px;
			display: inline-block;
		}
	}

	p {
		margin-bottom: 36px;
	}
}

.profile {
	padding-right: 125px;

	.headlinebar {
		@include typeface(serif);
		@include typeset(display-3);
		background: none;
		color: #292929;
		// font-style: italic;
		// font-size: 25px;
		// text-transform: none;
		// line-height: 26px;
		
		border-bottom: 1px solid #000000;
		//	    margin: 20px 0 20px 0;
		margin: 0 0 20px 0;
		padding: 0 0 9px 0;
	}

	.leftfloat {
		float: none;
	}

	.profilecontent {
		margin: 0 -10px;
		padding: 0;

		.form-row {
			width: 50%;
			padding: 10px;
			box-sizing: border-box;
			float: left;

			.required-indicator {
				float: none;
				margin-left: 0;
				color: #292929;
			}

			.label {
				float: none;
				width: auto;
				display: inline-block;
				font-weight: bold;
				line-height: 16px;
				font-size: 13px;
				padding-bottom: 8px;
			}

			.input-text {
				width: 100%;
				height: 42px;
				border-radius: 0;
				background-color: #ffffff;
				border: 1px solid #797979;
				margin: 0;
				box-sizing: border-box;
				font-weight: normal;
				border-radius: 2px;
			}

			&.birthday {
				width: 60%;
			}

			&.gender {
				width: 40%;
			}

			.selector {
				background-image: none;
				height: 39px;
				box-sizing: border-box;
				width: 100%;
				border: 1px solid #797979;
				position: relative;
				overflow: initial;
				margin-left: 0;
				padding: 0;

				span {
					background-image: none;
					height: 37px;
					position: relative;
					line-height: 37px;
					padding: 0 47px 0px 10px;
					overflow: initial;

					&:after {
						content: "";
						width: 37px;
						height: 37px;
						display: inline-block;
						position: absolute;
						right: 0;
						border-left: 1px solid #797979;
					}
				}

				select {
					height: 37px;
					width: 100%;
				}

				&:after {
					content: "";
					display: inline-block;
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid #333333;
					position: absolute;
					right: 13px;
					z-index: -1;
					top: 16px;
				}

				&.disabled {
					border-color: #cbcbcb;
					color: #aaaaaa;

					&:after {
						border-top-color: #cbcbcb;
					}

					span {
						&:after {
							border-color: #cbcbcb;
						}
					}
				}
			}

			.dateselects {
				width: 100%;

				.selector {
					width: 31.66%;
					margin-right: 2.5%;
					box-sizing: border-box;
					margin-left: 0;
					border-radius: 2px;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.radios {
				width: 100%;
				padding-top: 10px;
				font-size: 13px;
				float: none;

				.radio {
					float: left;
					height: auto;
					width: auto;

					&.type2 {
						padding: 0px 25px 0px 0px;

						&:last-of-type {
							padding: 0;
						}

						span {
							background-size: 17px;
							width: 19px;
							height: 19px;
						}
					}
				}

				&:after {
					content: "";
					display: table;
					clear: both;
				}
			}
		}
	}

	.continuebutton {
		width: 100%;
		margin: 10px;

		.button {
			margin: 0;

			.large {
				background-image: none;

				button {
					@include typeset(body-normal);
					// font-size: 17px;
					// font-weight: normal;
					background-image: none;
					background-color: #292929;
					margin: 0;
					width: 160px;
					box-sizing: border-box;
					height: auto;
					float: right;
					line-height: 42px;
					border: none;
				}
			}
		}
	}

	.savedaddr {
		padding: 0;
		width: 100%;
		float: none;

		.addressheadline {
			padding-bottom: 20px;
		}

		.addr {
			padding-left: 0;
			width: 100%;
			font-style: normal;
			font-weight: bold;
			font-size: 13px;
		}

		.primarylink {
			text-decoration: none;
			margin-left: 0;
			padding: 0;
			margin-top: 15px;

			.address-edit,
			.address-delete {
				@include typeset(body-normal);
				// font-family: "Gill Sans Light", sans-serif;
				// font-style: normal;
				// font-weight: normal;
				// line-height: normal;
				background-color: #292929;
				color: #ffffff;
				min-width: 60px;
				text-transform: uppercase;
				padding: 5px;
				display: inline-block;
				text-align: center;
				box-sizing: border-box;
			}
		}
	}

	// sorthvid
	.deleteaccount {
		@include typeface(sans-serif);
		@include typeset(body-small);
		text-decoration: underline;
	}

	.primarylink {
		text-align: left;
		padding: 30px 0px 25px 0px;
		width: 100%;

		.address-create {
			@include typeface(sans-serif);
			@include typeset(body-small);
			// font-family: Arial, Helvetica, sans-serif;
			// font-size: 13px;
			// font-style: normal;
			// font-weight: normal;
			// color: #292929;
			// background-color: transparent;
			text-decoration: underline;
		}
	}

	.newsletterfieldset {
		.newsletterlist {
			.form-row {
				padding: 10px 0;
			}

			.label {
				width: 200px;
				margin-left: 20px;
				float: left;
				font-weight: bold;
			}

			.infotext {
				padding: 0 0 0 20px;
				clear: left;
			}

			.checkbox {
				margin-left: -219px;
				float: left;
			}
		}

		.psbuttons {
			margin: 0;
			float: right;

			.button {
				float: left;
				margin-left: 10px;

				.large {
					background-image: none;

					button {
						background-image: none;
						background-color: #292929;
						margin: 0;
						width: 160px;
						box-sizing: border-box;
						height: auto;
						float: right;
						font-size: 17px;
						line-height: 42px;
						border: none;
						font-weight: normal;
					}
				}
			}
		}
	}

	.goodiesignupcontent {
		.headline {
			@include typeset(display-4);
			// font-size: 13px;
			// font-weight: bold;
			padding: 0;
		}

		.text {
			padding-bottom: 20px;
		}

		.mobile.phone.image,
		.AppStore.links {
			display: inline-block;
			width: 50%;
			float: left;
			text-align: center;

			img {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}

			.linksdescription {
				@include typeface(serif)
				// font-family: Georgia;
				// font-style: italic;
				font-size: 16px;
				padding-bottom: 25px;
			}

			.AppleAppStore {
				float: right;
				width: 43.38%;
			}

			.GooglePlay {
				float: left;
				width: 43.38%;
			}
		}
	}

	&.orderflowcontent .form-row {
		padding: 10px;
		position: relative;

		.intl-tel-input {
			width: 100%;
		}
	}
}

.minustop {
	@include typeface(serif);
	@include typeset(body-large);
	// font-family: Georgia, serif;
	// font-style: italic;
	// font-size: 20px;
	padding: 45px 0px 20px 0px;
	text-align: center;
}

.goodiecard-input {
	.rewardprogramgoodiepadding {
		padding: 0 15px 0px 0;

		.goodieintro {
			text-align: center;
			padding: 30px 50px;

			h3 {
				font-size: 25px;
			}

			.text {
				padding-bottom: 25px;
				line-height: 20px;
			}
		}

		h3 {
			text-transform: none;
			font-size: 20px;
			padding: 0;
			line-height: 40px;
			padding-bottom: 5px;
		}

		.goodieappbox {
			background-color: #ececec;
			padding: 30px;

			.mobile.phone.image,
			.AppStore.links {
				display: inline-block;
				width: 50%;
				float: left;
				text-align: center;

				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
				}

				.linksdescription {
					@include typeface(serif);
					// font-family: Georgia;
					// font-style: italic;
					font-size: 18px;
					line-height: 30px;
					text-align: center;
					padding: 15% 0 30px;
				}

				.AppleAppStore {
					float: right;
					width: 43.38%;
				}

				.GooglePlay {
					float: left;
					width: 43.38%;
				}
			}
		}

		.owngoodiecard {
			padding: 0;

			.ownform {
				padding: 50px 0;
				border-bottom: 1px solid #bbbbbb;
				margin-bottom: 40px;

				.desc {
					width: 50%;
					display: inline-block;
					padding-bottom: 20px;
				}

				.cardimg {
					margin: 0;
					width: 50%;
					text-align: center;
					float: right;

					img {
						width: 50%;
						height: auto;
					}
				}

				.goodiecardassignment {
					width: 50%;

					.form-row {
						float: none;
						width: 80%;
						padding: 5px 0px 20px 0px;

						.leftfloat {
							float: none;
						}

						.label {
							float: none;
							width: auto;
							font-weight: bold;
							display: inline-block;
							padding-bottom: 6px;
						}

						.required-indicator {
							float: none;
							padding: 2px 0px 2px 0px;
						}

						input {
							@include typeface(sans-serif);
							// font-family: Arial;
							width: 100%;
							height: 42px;
							border-radius: 3px;
							box-sizing: border-box;
							border: 1px solid #989898;
							font-weight: bold;
							font-size: 12px;
							background-color: #ffffff;
							margin: -2px 0px 0px 0px;
							padding: 3px 5px 1px 5px;
						}

						.selector {
							background-image: none;
							height: 42px;
							width: 100%;
							border: 1px solid #989898;
							position: relative;
							overflow: initial;
							border-radius: 2px;
							margin-left: 0;

							span {
								background-image: none;
								height: 42px;
								position: relative;
								line-height: 42px;
								padding: 0 47px 0px 10px;
								overflow: initial;

								&:after {
									content: " ";
									width: 42px;
									height: 42px;
									display: inline-block;
									position: absolute;
									right: 0;
									border-left: 1px solid #bbbbbb;
								}
							}

							select {
								height: 42px;
								width: 100%;
							}

							&:after {
								content: "";
								display: inline-block;
								width: 0;
								height: 0;
								border-left: 5px solid transparent;
								border-right: 5px solid transparent;
								border-top: 5px solid #292929;
								position: absolute;
								right: 16px;
								z-index: -1;
								top: 19px;
							}
						}
					}
				}

				.ownbutton {
					text-align: left;
					padding-top: 15px;

					.button {
						margin: 0;
						display: inline-block;

						.large {
							background-image: none;

							button {
								background-image: none;
								background-color: #292929;
								width: 160px;
								height: 42px;
							}
						}
					}
				}
			}
		}
	}
}

.magasincard {
	padding-top: 20px;

	.top {
		img {
			width: 100%;
			height: auto;
		}
	}

	h3 {
		text-transform: none;
		padding: 30px 0 10px;
	}

	.rightapply {
		margin: 0;
		float: none;
		width: 100%;
		text-align: center;

		.applybutton {
			margin: 0;
			margin-top: 40px;

			.button {
				margin: 0;

				.large {
					background-image: none;
					margin: 0;

					button {
						background-image: none;
						margin: 0;
						background-color: #292929;
						width: 200px;
						height: 43px;
					}
				}
			}
		}
	}

	.advantages {
		padding-bottom: 40px;
		border-bottom: 1px solid #a19589;

		.divider {
			padding-top: 10px;
			text-align: center;
			height: 2px;
			margin: 50px 0;

			h3 {
				display: inline-block;
				top: -7px;
				position: relative;
				font-size: 14px;
				line-height: 20px;
				padding: 0 15px;
				background-color: #ffffff;
				color: #a19589;
			}
		}

		ul {
			li {
				&.checkmark {
					background-image: url(../images/cardpages/listcheckmark.png);
					background-position: left 0;
					padding: 0 0 40px 44px;
				}

				h3 {
					@include typeface(sans-serif);
					// font-family: Arial, Helvetica, sans-serif;
					font-size: 15px;
					font-weight: normal;
					font-style: normal;
					font-weight: bold;
					text-transform: none;
					padding: 0;
				}
			}
		}
	}

	.owngoodiecard {
		padding: 0;
		padding-top: 10px;

		.cardimg {
			margin: 0;
			width: 50%;
			text-align: center;
			float: right;

			img {
				width: 50%;
				height: auto;
			}
		}

		.ownform {
			padding: 20px 0 50px;
			border-bottom: 1px solid #a19589;
			margin-bottom: 10px;

			.goodiecardassignment {
				width: 50%;

				.form-row {
					float: none;
					width: 80%;
					padding: 5px 0px 20px 0px;

					.leftfloat {
						float: none;
					}

					.label {
						float: none;
						width: auto;
						font-weight: bold;
						display: inline-block;
						padding-bottom: 6px;
					}

					.required-indicator {
						float: none;
						padding: 2px 0px 2px 0px;
					}

					input {
						@include typeface(sans-serif);
						// font-family: Arial;
						width: 100%;
						height: 42px;
						border-radius: 3px;
						box-sizing: border-box;
						border: 1px solid #989898;
						font-weight: bold;
						font-size: 12px;
						background-color: #ffffff;
						margin: -2px 0px 0px 0px;
						padding: 3px 5px 1px 5px;
					}

					.selector {
						background-image: none;
						height: 42px;
						width: 100%;
						border: 1px solid #989898;
						position: relative;
						overflow: initial;
						border-radius: 2px;
						margin-left: 0;

						span {
							background-image: none;
							height: 42px;
							position: relative;
							line-height: 42px;
							padding: 0 47px 0px 10px;
							overflow: initial;

							&:after {
								content: " ";
								width: 42px;
								height: 42px;
								display: inline-block;
								position: absolute;
								right: 0;
								border-left: 1px solid #bbbbbb;
							}
						}

						select {
							height: 42px;
							width: 100%;
						}

						&:after {
							content: "";
							display: inline-block;
							width: 0;
							height: 0;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-top: 5px solid #292929;
							position: absolute;
							right: 16px;
							z-index: -1;
							top: 19px;
						}
					}
				}
			}

			.ownbutton {
				text-align: left;
				padding-top: 15px;

				.button {
					margin: 0;
					display: inline-block;

					.large {
						background-image: none;

						button {
							background-image: none;
							background-color: #292929;
							width: 160px;
							height: 42px;
						}
					}
				}
			}
		}
	}
}

.faq-accordion-container {
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: 17px;
	margin: 31px 0 50px 0;
	display: none;

	p+& {
		margin-top: 22px;
	}

	.faq-accordion-header {
		@include typeset(body-large);
		// font-family: "Gill Sans Light", sans-serif;
		// font-size: 18px;
		// font-weight: 300;
		// font-style: normal;
		// text-transform: none;
		position: relative;
		line-height: 30px;
		margin-top: 17px;
		cursor: pointer;
		border-top: 1px solid #e0e0e0;
		outline: none;
		padding: 22px 25px 5px 0px;
		width: auto;

		&.ui-state-default {
			&.ui-state-active {
				font-weight: 600;
			}

			&.ui-accordion-header-collapsed {
				.accordionheader-icon .outerdash .dash {
					transform: rotate(90deg);
				}
			}

			.accordionheader-icon {
				position: absolute;
				width: 13px;
				height: 13px;
				top: 23px;
				right: 0;

				.outerdash {
					position: absolute;
					height: 1.1px;
					width: 13px;
					background-color: #292929;
					transform-origin: center center;
					display: block;

					.dash {
						position: absolute;
						height: 1px;
						width: 13px;
						background-color: #292929;
					}
				}
			}
		}
	}

	.faq-accordion-infotext {
		@include typeset(body-normal);
		// font-size: 16px;
		// line-height: normal;
		// font-weight: 300;
		// font-family: "Gill Sans Light", sans-serif;
		padding-bottom: 8px;

		.infotext {
			margin-bottom: -23px;
		}
	}
}

a.address-make-default {
	text-decoration: underline;
}

a.address-delete.delete,
a.address-edit {
	text-decoration: none;
}

@media screen and (max-width: 992px) {
	.profile {
		padding-right: 0;
	}
}

@media screen and (max-width: 768px) {
	.profile .headlinebar {
		margin-top: 20px;
	}
}

@media screen and (max-width: 520px) {
	.orderlist {
		.orderlisttopbar {
			display: none;
		}

		.orderitem {
			padding: 15px 0px 15px 0px;
			position: relative;
			padding-bottom: 55px;

			.orderdatecoll {
				// font-style: italic;
				float: none;
				width: 100%;
				font-family: $font-serif;
				font-size: 15px;
				padding-bottom: 15px;
			}

			.numbercoll {
				float: none;
				width: 100%;
				padding-bottom: 15px;

				.ordernumber {
					float: none;
					padding-right: 0px;
					min-width: 100%;
				}

				.primarylink {
					float: none;
					margin: 0px;
					position: absolute;
					right: 0;
					bottom: 15px;
					margin: 0px;
					border: 1px solid #292929;
					padding: 10px 20px;
				}
			}

			.orderstatuscoll {
				float: none;
				width: 100%;
				padding-bottom: 15px;

				.orderstatustext {
					float: none;
				}
			}

			.amountcoll {
				width: 100%;
				text-align: left;
				padding-right: 0px;
				float: none;
				padding-bottom: 15px;

				.orderamount {
					text-align: left;
				}
			}
		}
	}

	.profile {
		.profilecontent {
			margin: 0;

			.form-row {
				width: 100%;
				padding: 10px 0;
				float: none;

				&.birthday {
					width: 100%;
				}

				&.gender {
					width: 100%;
				}
			}
		}

		.continuebutton {
			margin: 10px 0;

			.button {
				margin: 0;

				.large {
					margin: 0;

					button {
						width: 100%;
					}
				}
			}
		}

		.goodiesignupcontent {

			.mobile.phone.image,
			.AppStore.links {
				width: 100%;
				float: none;
			}
		}

		.newsletterfieldset {
			.psbuttons {
				float: none;
				margin: 10px 0;

				.button {
					float: none;
					margin: 0;

					.large {
						margin: 0;

						button {
							margin: 0;
							width: 100%;
							float: none;
						}
					}
				}
			}
		}
	}

	.goodiecard-input {
		.rewardprogramgoodiepadding {
			padding: 0;

			.goodieintro {
				padding: 30px 0;
			}

			.goodieappbox {

				.mobile.phone.image,
				.AppStore.links {
					display: block;
					width: 100%;
					float: none;

					.linksdescription {
						padding: 30px 0 30px;
					}
				}
			}

			.owngoodiecard {
				.ownform {
					.desc {
						width: 100%;
						display: block;
					}

					.cardimg {
						margin: 0;
						width: 100%;
						float: none;
					}

					.goodiecardassignment {
						width: 100%;

						.form-row {
							width: 100%;
						}
					}

					.ownbutton {
						.button {
							display: block;

							.large {
								button {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}

	.magasincard {
		.rightapply {
			.applybutton {
				.button {
					.large {
						button {
							width: 100%;
						}
					}
				}
			}
		}

		.owngoodiecard {
			.cardimg {
				width: 100%;
				float: none;
			}

			.ownform {
				.goodiecardassignment {
					width: 100%;

					.form-row {
						width: 100%;

						.ownbutton {
							.button {
								display: block;

								.large {
									button {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}