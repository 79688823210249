.service-grid-magasincard {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	.service-grid-content {
	    max-width: calc(50% - 17.5px);
	    max-width: -webkit-calc(50% - 17.5px);
	    max-width: -moz-calc(50% - 17.5px);
	    padding: 30px 0;
	    margin-right: 35px;
    	box-sizing: border-box;
	    border-top: 1px solid #E7E7E7;
	    border-bottom: 1px solid #E7E7E7;
		height: 170px;
	    &:nth-child(even) {
		    margin-right: 0;
		}
		&:first-child, &:nth-child(2){
			border-bottom: none;
		}
		a{
			color: #292929;
			font-size: 16px;
			h4{
				font-family: $font-serif;
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 10px;
				&:after {
				    content: ">";
				    float: right;
				    font-size: 13px;
				}
			}
		}
	}
	
	&:after {
	    content:  "";
	    display:  table;
	    clear:  both;
	}
}

@media screen and (max-width: 520px) {
	.service-grid-magasincard {
		.service-grid-content {
			max-width: 100%;
		    margin: 0;
		    &:nth-child(3){
				border-bottom: none;
			}
		}
	}
}
