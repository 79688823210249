// .maincontentbottom{
.inpagefooter {
    padding: 0;

    .headline {
        // sorthvid
        @include typeset(display-2);
        @include typeface(serif);
        // font-family: $font-serif;
        // font-size: 36px;
        // line-height: 40px;
        // font-style: normal;
        // font-weight: normal;
        margin-bottom: 30px;
        white-space: normal;
    }

    .newsletter {
        clear: both;
        padding: 72px 0;
        margin: auto;
        background-color: #f1f1f1;

        a {
            display: inline-block;
            font-size: 12px;
            vertical-align: top;
            padding: 0;
            color: #292929;
            text-decoration: underline;
        }

        .subheadline {
            @include typeset(display-3);
            // font-family: $font-sans-serif;
            // font-size: 22px;
            // font-weight: 300;
            // line-height: 27px;
            margin-bottom: 22px;
            white-space: normal;
        }

        .newsletter-container {
            max-width: 1170px;
            margin: auto;
            padding: 0 40px;
            text-align: justify;
            font-style: normal;

            .newsletter-subcontainer {
                display: inline-block;
                width: 58%;
                text-align: left;
                padding-right: 50px;
                white-space: nowrap;
                margin: -3px 0 0 -3px;

                .checker {
                    display: inline-block;
                    padding: 7px 10px 7px 0;
                    float: left;

                    span {
                        display: inline-block;
                        background-size: 15px 15px;
                        height: 20px;
                        width: 20px;
                        border: 1px solid #E3DFDF;
                        background-color: #FAFAFA;
                        box-sizing: border-box;

                        input {
                            width: 100%;
                            height: 100%;
                        }

                        .checked {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .text {
                    display: inline-block;
                    width: calc(100% - 30px);
                    max-width: 570px;
                    vertical-align: top;
                    padding: 7px 0 0 0;
                    line-height: 19px;
                    font-size: 13px;
                    text-align: left;
                    white-space: normal;
                    opacity: .6;

                    a {
                        display: inline-block;
                        font-size: 12px;
                        vertical-align: top;
                        padding: 0;
                        color: #292929;
                        text-decoration: underline;
                    }
                }
            }

            .formfields {
                width: 35%;
                float: right;
                display: inline-block;
                max-width: 400px;
                vertical-align: top;

                .anon {
                    font-size: 0;
                }

                .auth {
                    text-align: right;
                }

                .input-text {
                    // sorthvid
                    @include typeset(body-normal);
                    // font-family: $font-sans-serif;
                    // font-size: 16px;
                    padding: 0 24px;
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    margin: 0;
                    border: 1px solid #e3e3e3;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    border-radius: 0;
                }

                label {
                    display: block;
                    color: red;

                    &.error-text {
                        font-size: 13px;
                        text-align: left;
                    }
                }

                a {
                    margin-top: 17px;
                    width: 50%;
                    text-decoration: none;

                    &:only-of-type {
                        button {
                            width: 100%;
                        }
                    }
                }

                input[type='button'],
                button {
                    background-color: #98BCC1;
                    border: none;
                    color: #ffffff;
                    cursor: pointer;
                    font-size: 16px;
                    font-style: normal;
                    line-height: 14px;
                    margin: 0;
                    text-transform: none;
                    width: calc(100% - 5px);
                    height: 50px;
                    opacity: .6;
                    display: block;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    -webkit-border-radius: 0px;
                    border-radius: 0px;
                }

                button {
                    opacity: 1;
                }
            }
        }
    }

    .contact {
        background-color: #f4f4f4;
        border-bottom: 1px solid #e7e7e7;
        font-size: 0;
        line-height: 0px;

        .contact-image {
            width: 50%;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: bottom;
            text-align: right;
            padding-left: 30px;

            img {
                display: inline-block;
                width: 90%;
                max-width: 335px;
                height: auto;
                margin-right: 45px;
            }
        }

        .contact-container {
            width: 50%;
            padding: 97px 0 99px 15px;
            display: inline-block;
            text-align: left;
            line-height: 27px;
            box-sizing: border-box;

            // font-size: 0px;
            >p {
                // font-size: 14px;
                // font-weight: 300;
                margin-top: 39px;
                width: 70%
            }

            .contact-element {
                width: 35%;
                display: inline-block;
                vertical-align: top;

                .store {
                    @include typeset(body-medium);
                    // font-size: 18px;
                    // line-height: 30px;
                }

                .phone {
                    font-size: 23px;
                    line-height: 27px;
                    display: block;
                    font-weight: 600;
                    padding: 0 0 21px 0;
                }

                p {
                    @include typeset(body-small);
                    // font-size: 14px;
                    // font-weight: 300;
                }
            }

            .contact-footer {
                display: block;
                margin-top: 41px;
                // removed by sorthvid
                // .button-style {
                //     display: inline-block;
                //     height: 50px;
                //     width: 220px;
                //     background-color: #9FBDC3;
                //     color: #ffffff;
                //     font-size: 16px;
                //     line-height: 50px;
                //     text-align: center;
                //     &:hover{
                //         text-decoration: none;
                //     }
                // }
            }
        }
    }

    .footermenucontainer {
        overflow: hidden;
        background-color: #f5f5f5;
        padding: 0 35px;
        font-style: normal;

        .footermenu {
            margin: auto;
            position: relative;
            left: 48px;
            padding: 95px 0 41px 3px;
            border-width: 0;
            font-size: 0;
            // line-height: 0;
            max-width: 995px;

            .column {
                display: inline-block;
                width: 25%;
                vertical-align: top;

                ul {
                    max-width: 270px;
                    margin: 36px 20px 0 0;
                    vertical-align: top;
                    text-align: left;
                    box-sizing: border-box;

                    &:first-of-type {
                        margin-top: 0px;
                    }

                    li {
                        // color: #292929;
                        width: 100%;
                        padding: 0;

                        &.category {
                            // sorthvid
                            @include typeset(display-4);
                            // font-family: $font-serif;
                            // font-size: 24px;
                            // line-height: 32px;
                            margin-bottom: 16px;
                        }

                        a {
                            // sorthvid
                            @include typeset(body-normal);
                            // font-size: 16px;
                            // color: #292929;
                            // padding: 0;
                            line-height: 29px;
                        }
                    }
                }
            }
        }
    }

    .logo {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        background-color: #f5f5f5;
        padding-bottom: 30px;

        a {
            display: inline-block;
            width: 125px;

            img {
                width: 100%;
            }
        }
    }
}

// }
.cookies {
    &.open {
        text-align: center;
        color: #eeeeee;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 8000;
    }

    &.close {
        display: none;
    }

    .alert {
        padding: 8px 0 0 0;
        font-size: 12px;
        margin: auto;
        width: auto;
        line-height: 20px;

        a {
            font-style: italic;
            color: #eeeeee;
        }

        .closebutton {
            display: inline-block;
            margin: 0 0 5px 10px;
            line-height: 12px;
            width: 30px;
            font-size: 12px;

            .button {
                line-height: 12px;
                margin: -6px;

                .cookiebutton {
                    margin: 0 0 0 8px;
                    width: 27px;
                    padding: 0;

                    button {
                        height: 22px;
                        font-size: 10px;
                        padding: 1px 7px 0px 7px;
                        margin: 0 0 5px -8px;
                        cursor: pointer;
                        background-color: #ddd;
                        color: #000000;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1080px) and (min-width: 891px) {
    .inpagefooter .footermenucontainer .footermenu {
        left: 35px;
        ;
    }
}

@media screen and (max-width: 890px) {
    .inpagefooter .footermenucontainer .footermenu {
        left: 25px;
        ;
    }
}

@media screen and (max-width: 1032px) and (min-width: 640px) {

    // 	.maincontentbottom{
    .inpagefooter {
        .contact {
            .contact-image {
                width: 35%;
                text-align: right;
                margin-bottom: 85px;
                padding-right: 15px;
            }

            .contact-container {
                width: 65%;
                padding-left: 0;

                >p {
                    width: 80%;
                }

                .contact-element {
                    width: 40%;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }

    //    }
}

@media screen and (max-width: 769px) {
    .maincontentbottom {
        .inpagefooter {
            .newsletter {
                padding: 40px 0;

                .subheadline {
                    margin-bottom: 7px;
                }

                .newsletter-container {
                    .newsletter-subcontainer {
                        text-align: center;
                        display: block;
                        width: 100%;
                        padding-bottom: 25px;
                        padding-right: 0;
                    }

                    .formfields {
                        float: none;
                        clear: both;
                        width: 100%;
                        display: block;
                        max-width: none;
                    }
                }
            }

            .contact {
                .contact-container {
                    .headline {
                        // font-size: 26px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .maincontentbottom {
        .inpagefooter {
            .contact {
                .contact-image {
                    display: none;
                }

                .contact-container {
                    width: 100%;
                    //                    padding: 40px 20px 40px 20px;
                    padding: 40px 6.25%;
                    text-align: center;
                    line-height: 19px;

                    .headline {
                        font-size: 24px;
                        line-height: 28px;
                        padding: 0;
                        margin: -3px auto 32px auto;
                    }

                    >p {
                        width: 100%;
                    }

                    .contact-element {
                        padding-top: 20px;
                        width: 100%;
                        text-align: left;
                        padding: 0 0 40px 0;
                        margin-bottom: -4px;

                        .store {
                            font-size: 14px;
                            line-height: 20px;
                        }

                        .phone {
                            font-size: 16px;
                            line-height: 20px;
                            padding: 0;
                        }

                        .element-header {
                            width: 50%;
                            float: left;
                        }

                        .element-content {
                            width: 50%;
                            float: left;
                            text-align: right;
                        }

                        p {
                            font-size: 12px;
                            line-height: 17px;

                            span {
                                width: 65%;
                                float: right;
                                text-align: right;

                                &:first-child {
                                    width: 35%;
                                    float: left;
                                    text-align: left;
                                }
                            }

                            &:after {
                                content: "";
                                display: table;
                                clear: both;
                            }
                        }
                    }

                    //	               	.headline{
                    //            			font-size: 21px;
                    //            		}
                    .contact-footer {
                        margin-top: 0;
                    }
                }
            }

            .logo {
                padding: 40px 0 25px 0;
            }

            .footermenucontainer {
                display: none;
            }
        }
    }
}