.feedback-question {
    padding-top: rem(24);

    a {
        transition: color $transition-very-fast;
        color: $color-text;
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-border-hover; 
            text-decoration: none;
        }
    }
}