// CSS Grid
//
// Container/layout component.<br>
// Put the grid-class on the parent element, and its children will be layed out as an even grid.<br>
// Comes with a variety of modifier classes, depending on what the layout should be:
//
// DOD: wip
//
// Weight: 1
//
// Style guide: core.css-grid


// Gutter
//
// Has an even gutter between each element.<br>
//
// DOD: wip
//
// Markup:
//  <div class="css-grid css-grid--gutter css-grid--two css-grid-sm--three">
//{% for i in 1..12 %}
//      <div class="designsystem-box"></div>
//{% endfor %}
//  </div>
//
// Weight: 1
//
// Style guide: core.css-grid.gutter


// Collapse
//
// Has no gutter, and a small negative margin to give the elements an even seperation.<br>
//
// DOD: wip
//
// Markup:
//  <div class="css-grid css-grid--collapse css-grid--two css-grid-sm--three">
//{% for i in 1..12 %}
//      <div class="designsystem-box"></div>
//{% endfor %}
//  </div>
//
// Weight: 1
//
// Style guide: core.css-grid.collapse


// .css-grid {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     @include mq($from: screen-sm) {
//         grid-template-columns: repeat(3, 1fr);
//     }

//     &--gutter {
//         --gutter-size: rem(15);
        
//         grid-column-gap: var(--gutter-size);
//         grid-row-gap: var(--gutter-size);
//         @include mq($from: screen-sm) {
//             --gutter-size: rem(20);
//         }
//         @include mq($from: screen-md) {
//             --gutter-size: rem(30);
//         }
//     }

//     &--collapse {
//         > * {
//             margin: 0 -1px -1px 0;
//         }
//     }
// }

.css-grid {
    --gutter-size: 0;
	// @supports (display: grid){
        
    display: grid;
	//align-items: stretch; // vertical stretch
    //justify-items: stretch; // horizontal stretch
	//justify-content: stretch; //
    width: 100%;
    
    grid-gap: var(--gutter-size);
    
    // margin: var(--margin) 0 var(--margin);
    
    grid-template-columns: repeat(1, 1fr);
    
    > * { // In favour of the declaration above
        align-self: stretch;
        justify-self: stretch;
    }
    
    &--gutter {
        --gutter-size: rem(15);
        
        grid-gap: var(--gutter-size);
        @include mq($from: screen-sm) {
            --gutter-size: 20;
        }
        @include mq($from: screen-md) {
            --gutter-size: 30;
        }
        @include mq($from: screen-md) {
            grid-gap: var(--gutter-size);
            --gutter-size: rem(30);
        }
    }
    
    &--collapse {
        padding: 1px;
        grid-gap: 1px;
        box-sizing: border-box;
    }
    // } 
	&.css-grid--two {
        @include grid(2, 20, 20);
        //grid-template-columns: repeat(2, 1fr);
    }
    &.css-grid-sm--two {
        @include mq($from: screen-sm){
            @include grid(2, 0, 0);
            // @include grid(2, 30, 30);
        }
    }
    &.css-grid-md--two {
        @include mq($from: screen-md){
            @include grid(2, 0, 0);
        }
	}
	&.css-grid--three {
        @include grid(3, 0, 0);
    }
    &.css-grid-sm--three {
        @include mq($from: screen-sm){
            @include grid(3, 0, 0);
        }
    }
    &.css-grid-md--three {
        @include mq($from: screen-md){
            @include grid(3, 0, 0);
        }
	}
	&.css-grid--four {
		@include grid(4, 30, 50);
    }
    &.css-grid-sm--four {
        @include mq($from: screen-sm){
            @include grid(4, 20, 20);
        }
    }
    &.css-grid-md--four {
        @include mq($from: screen-md){
            @include grid(4, 0, 0);
        }
	}
	&.css-grid--five {
		@include grid(5, 30, 50);
    }
    &.css-grid-sm--five {
        @include mq($from: screen-sm){
            @include grid(5, 0, 0);
        }
    }
    &.css-grid-md--five {
        @include mq($from: screen-md){
            @include grid(5, 0, 0);
        }
	}
	&.css-grid--six {
        @include grid(6, 30, 50);
    }
    &.css-grid-sm--six {
        @include mq($from: screen-sm){
            @include grid(6, 0, 0);
        }
    }
    &.css-grid-md--six {
        @include mq($from: screen-md){
            @include grid(6, 0, 0);
        }
	}
}