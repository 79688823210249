.ajour-banner-container {
    position: relative;
    padding-top: 41px;
    clear: both;

    .ajour-banner {
        display: inline-block;
        position: absolute;
        background-color: #ffffff;
        padding: 6px;
        z-index: 100;
        left: calc(50% - 100px);
        top: -6px;

        .ajour-banner-border {
            width: 188px;
            height: 88px;
            box-sizing: border-box;
            border: 1px solid #dddddd;
            font-size: 0px;
            line-height: 0px;
            text-align: center;

            &:before {
                content: "";
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle;
            }

            img {
                display: inline-block;
                width: 140px;
                height: auot;
                vertical-align: middle;
            }
        }
    }

    .ajour-carousel-container {
        background-color: #f5f5f5;
        padding: 100px 0;
        overflow: hidden;

        .ajour-owl-carousel {
            max-width: 570px;
            margin: auto;
            position: relative !important;

            .owl-stage-outer {
                overflow: visible;

                .gtm-banner-element {
                    &:hover {
                        color: $color-text;
                    }
                }

                .cu-grid-item {
                    max-width: 570px;
                    width: 100%;

                    .cu-teaser-image-container {
                        position: relative;

                        &:after {
                            content: "";
                            display: block;
                            padding-top: 66.666%;
                        }

                        .cu-teaser-image-window {
                            display: block;
                            max-height: 380px;
                            background-color: #ffffff;
                            overflow: hidden;
                            position: absolute;
                            top: 0;
                            left: 0 !important;
                            right: 0 !important;
                            bottom: 0;

                            img {
                                width: 100%;
                                height: auto;
                            }

                        }
                    }

                    .cu-grid-item-content {
                        display: block;
                        text-align: center;
                        background-color: #ffffff;
                        height: 320px;
                        padding: 0 75px 40px 75px;
                        box-sizing: border-box;

                        &.cu-grid-item-content--related-mobile {
                            display: none;
                        }

                        &.cu-grid-item-content--mobile {
                            display: none;
                        }

                        &.cu-grid-item-content--desktop {
                            display: none;
                        }

                        .cu-tag {
                            @include typeset(display-5);
                            // font-size: 15px;
                            // font-weight: 600;
                            // color: #262626;
                            // letter-spacing: 1px;
                            // text-transform: uppercase;
                            
                            line-height: 32px;
                            border-bottom: 2px solid #292929;
                            display: inline-block;
                            margin: 39px 0 12px 0;
                        }

                        .card-headline {
                            @include typeface(serif);
                            @include typeset(display-3);
                            // font-family: $font-serif;
                            // font-size: 26px;
                            // line-height: 33px;
                            // color: #262626;
                            // text-decoration: none;
                            // background-color: transparent;
                            // text-transform: none;
                            //                            margin-bottom: 16px;
                            display: block;
                            margin: 3px auto 16px auto;
                        }

                        .card-text {
                            @include typeset(body-normal);
                            // font-size: 16px;
                            // line-height: 27px;
                            // color: #262626;
                            // color: #262626;
                            // text-decoration: none;
                            // background-color: transparent;
                            // text-transform: none;
                        }
                    }
                }
            }

            .owl-nav {
                .owl-prev {
                    right: calc(100% + 58px);
                    position: absolute;
                    top: calc(44.8% - -12px);
                }

                .owl-next {
                    left: calc(100% + 58px);
                    position: absolute;
                    top: calc(44.8% - -12px);
                }
            }
        }
    }
}

@media screen and (max-width: 1031px) {
    .ajour-banner-container {
        .ajour-carousel-container {
            .ajour-owl-carousel {
                max-width: 354px;
                margin: auto;

                .owl-stage-outer {
                    .cu-grid-item {
                        max-width: 354px;

                        .cu-grid-item-content {
                            height: 280px;
                            padding: 0 26px 35px 26px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .ajour-banner-container {
        //  margin-bottom: 40px;
        margin-top: 47px;
    }
}

@media screen and (max-width: 640px) {
    .ajour-banner-container {
        margin: 0 0 40px 0;
        padding: 0;

        .ajour-banner {
            width: auto;
            height: auto;
            box-sizing: content-box;
            padding: 6px;
            left: calc(50% - 54px);
            top: auto;
            bottom: 132px;

            .ajour-banner-border {
                width: 108px;
                height: 48px;
                padding: 0 10px;
                white-space: nowrap;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .ajour-carousel-container {
            padding: 0;
            border: 1px solid #f5f5f5;
            background-color: #ffffff;

            .ajour-owl-carousel {
                max-width: none;

                .owl-stage-outer {
                    .cu-grid-item {
                        max-width: none;

                        .cu-teaser-image-container {
                            .cu-teaser-image-window {
                                max-height: none;
                                box-sizing: border-box;
                            }
                        }

                        .cu-grid-item-content {
                            height: 162px;
                            padding: 0 5% 15px 5%;

                            .cu-tag {
                                display: none;
                            }

                            .card-headline {
                                // font-size: 24px;
                                // line-height: 30px;
                                padding: 46px 0 11px 0;
                                margin: auto;
                            }

                            .card-text {
                                // font-family: "Gill Sans Light", sans-serif;
                                // font-size: 14px;
                                // line-height: 18px; 
                            }
                        }
                    }
                }

                .owl-dots {
                    display: block;
                    text-align: center;
                    margin-top: 12px;

                    &.disabled {
                        display: none;
                    }

                    .owl-dot {
                        display: none;

                        //                        display: inline-block;
                        //                        font-size: 0px;
                        //                        line-height: 0;
                        //                        margin: 5px;
                        //                        vertical-align: middle;
                        span {
                            background-color: #E8E8E8;
                            height: 9px;
                            width: 9px;
                            display: inline-block;
                            border-radius: 17px;
                            display: inline-block;
                            box-sizing: border-box;
                        }

                        &.active {
                            span {
                                background-color: #292929;
                            }
                        }
                    }
                }
            }
        }
    }
}