// Accordion
//
// Accordions are used to show and hide content.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/accordion.twig
//
// Weight: 1
//
// examplelink: /examples/pages/accordion||Accordion examples
//
// Style guide: components.accordion


.accordionbox-container {
    .overlay-header {
        p {
            // untested
            @include typeface(sans-serif);
            // font-family: "Gill Sans Light", sans-serif;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            a {
                color: #292929;
                text-decoration: underline;
            }
        }
    }

    .accordionbox {
        margin-top: 31px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 17px;

        .accordion-container {
            padding-bottom: 17px;
            opacity: 0;
            transition: opacity .2s;
            margin: 31px 0px -17px;

            .accordionheader {
                // untested
                @include typeface(sans-serif);
                // font-family: "Gill Sans Light", sans-serif;
                font-size: 18px;
                line-height: 30px;
                margin-top: 17px;
                font-weight: 300;
                font-style: normal;
                text-transform: none;
                cursor: pointer;
                width: auto;
                border-top: 1px solid #e0e0e0;
                outline: none;
                padding: 22px 0px 5px;

                &.ui-state-active {
                    font-weight: 600;
                }

                &.ui-accordion-header-collapsed .accordionheader-icon .outerdash .dash {
                    transform: rotate(90deg);
                }

                background-image: none;
                // untested
                @include typeface(sans-serif);
                // font-family: "Gill Sans Light", sans-serif;
                font-size: 18px;
                letter-spacing: .8px;
                color: #292929;
                font-weight: 300;
                font-style: normal;
                text-transform: none;
                line-height: 30px;
                margin-top: 17px;

                .accordionheader-icon {
                    position: relative;
                    float: right;
                    width: 13px;
                    height: 13px;
                    padding-top: 14px;

                    .outerdash {
                        position: absolute;
                        height: 1px;
                        width: 13px;
                        background-color: #292929;
                        transform-origin: center center;

                        .dash {
                            position: absolute;
                            height: 1px;
                            width: 13px;
                            background-color: #292929;
                        }
                    }
                }
            }

            .ui-accordion-content {
                // untested
                @include typeface(sans-serif);
                // font-family: "Gill Sans Light", sans-serif;
                font-size: 16px;
                line-height: 24px;
                padding: 0 0 8px 0;
                margin: 0;
                background-color: transparent;
            }
        }
    }
}


.faq-accordion-container {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 17px;
    max-width: 780px;
    margin: 31px 0 75px 0;
    display: none;

    .faq-accordion-header {
        // untested
        @include typeface(sans-serif);
        // font-family: "Gill Sans Light", sans-serif;
        font-size: 18px;
        line-height: 30px;
        margin-top: 17px;
        font-weight: 300;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
        border-top: 1px solid #e0e0e0;
        outline: none;
        padding: 22px 0px 5px 0px;
        width: auto;

        &.ui-state-default {
            &.ui-state-active {
                font-weight: 600;
            }

            &.ui-accordion-header-collapsed {
                .accordionheader-icon .outerdash .dash {
                    transform: rotate(90deg);
                }
            }

            .accordionheader-icon {
                position: relative;
                float: right;
                width: 13px;
                height: 13px;
                padding-top: 14px;

                .outerdash {
                    position: absolute;
                    height: 1px;
                    width: 13px;
                    background-color: #292929;
                    transform-origin: center center;

                    .dash {
                        position: absolute;
                        height: 1px;
                        width: 13px;
                        background-color: #292929;
                    }
                }
            }
        }
    }

    .faq-accordion-infotext {
        // untested
        @include typeface(sans-serif);
        // font-family: "Gill Sans Light", sans-serif;
        padding-bottom: 8px;
        font-size: 16px;
        line-height: normal;
        font-weight: 300;
    }
}

// sorthvid accordion
.accordion {
    .new-font & {
        @include typeface(sans-serif);
    }
    @include typeset(body-normal);

    padding: rem(50) rem(0);
    
    @include mq($from: screen-md) {
        padding: rem(50) rem(0) rem(90);
    }

    &--no-padding-top {
        padding-top: 0;
    }
    
    &__trigger {
        transition: color $transition-fast;
        font-family: inherit;
        font-size: inherit;

        background: none;
        border: 0;
        outline: none;

        text-align: left;

        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: rem(24) rem(30) rem(24) rem(6);
        
        cursor: pointer;
        
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        
        position: relative;
        margin-top: -1px;
        
        @include mq($from: screen-xs) {
            padding: rem(24) rem(36) rem(24) rem(12);
        }

        &:hover
        // , &:focus 
        {
            z-index: 1;
            // border-top: 1px solid $color-border-hover;
            // border-bottom: 1px solid $color-border-hover;
            color: $color-dark-grey;
        }
    }
    
    &__title {
        display: block;
        /* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8295099/ */
        pointer-events: none;
    }
    
    &__icon {
        transition: transform $transition-very-fast;
        position: absolute;
        right: rem(6);
        width: 14px;
        height: 14px;
        
        pointer-events: none;

        opacity: .8;

        @include mq($from: screen-xs) {
            right: rem(12);
        }
        
        /*
         * Plus sign made with psuedo elements
         */
        // Horizontal line
        &:before {
            content: "";
            height: 2px;
            width: 100%;
            
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            
            background-color: $color-text;
        }
        
        // Vertical line
        &:after {
            transition: transform $transition-very-fast;
            content: "";
            height: 100%;
            width: 2px;
            
            position: absolute;
            left: 50%;
            transform: translateX(-50%) scaleY(1);
            
            background-color: $color-text;
        }
    }

    &__item {
        transition: height $duration-very-slow $ease-out; // Gets animated in accordion.js

        position: relative;
        z-index: 1;
        margin: -1px 0 0 0;
        padding: 0 rem(6);

        border-bottom: 1px solid $color-border;
        background-color: $color-white;

        overflow: hidden;

        @include mq($from: screen-xs) {
            padding: 0 rem(12);
        }
        
        &[hidden] {
            display: block;
        }
        
        &.closed {
            // display: none;
            height: 0px;
        }
        &.open {
            display: block;
        }
    }

    &__content {
        transition: opacity $transition-very-slow;
        // transition-delay: .2s;
        opacity: 0;
        max-width: rem(580);
        padding-bottom: rem(24);
    }

    &__footer {
        margin-top: rem(24);
    }


    /*
     * Open state - styling
     */
    &__trigger[aria-expanded="true"] {
        border-color: $color-border;
        // font-weight: 600;

        .accordion__icon {
            &:after {
                transform: translateX(-50%) scaleY(0);
            }
        }
    }

    &__item.open {
        .accordion__content {
            opacity: 1;
        }
    }
}