// Buttons
//
// In this section you will be able to find all the buttons available in Magasin UI. Go to the example page to see a selection of different hover styles.<br>
// First is a list of buttons by sorthvid.
// Below that is a list of the current buttons that Alpha solutions have created, and are used on magasin.dk today.<br>
//
// DOD: wip
//
// Weight: 1
//
// Style guide: elements.buttons


// New buttons by sorthvid
//
// New, uniformely styled button in different varieties. Will be the type of button to use from now on.<br>
// See link for different types of hover transitions. Until we settle for the one to use.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/elements/button.twig
//
// .button-style--light-button        - light
// .button-style--secondary           - secondary
// .button-style--highlight           - secondary
//
// examplelink: /examples/examples/buttons||Button examples
//
// Style guide: elements.buttons.button

/*
 * Old styling, by Alpha Solution
 * This is all the styling related to .button and .button-style, including modifiers. Located in the global scope.
 * Nothing is restyled, but only nested for better readability.
 */
// .button-style {
//     font-family: $font-sans-serif;
// 	display: inline-block;
//     background-color: #9FBDC3;
//     color: $color-white;
//     font-size: rem(16);
//     line-height: 36px;
//     text-align: center;
//     border-width: 0;
//     padding: rem(15) rem(50);
//     height: auto;
//     cursor: pointer;
//     -webkit-appearance: none;
//     border-radius: 0;

//     &:hover {
// 	    //color: $color-white;
// 	    text-decoration: none;
// 	    //background-color: #9FBDC3;
//     }

//     &--light {
//         background-color: $color-white;
//         border: 1px solid $color-text;
//         box-sizing: border-box;
//         color: $color-text;

//         &:hover {
//             background-color: $color-white;
//             color: $color-text;
//         }
//     }
// }

.button {
    margin: 0px -4px 0px -4px;

    input,
    button {
        text-transform: uppercase;
        background-color: transparent;
        color: $color-white;
        // font-weight: bold;
        padding: 0px;
        margin: 0px;
        overflow: visible;
        cursor: pointer;
    }

    .small {
        background-image: url(../images/button_small_bg.png);
        background-position: right top;
        margin: 0px 0px 0px 8px;
        padding: 0px;

        input,
        button {
            background-image: url(../images/button_small_left.png);
            background-repeat: no-repeat;
            background-color: transparent;
            color: $color-white;
            border: none;
            height: 26px;
            margin: 0px 0px 0px -8px;
            padding: 1px 10px 0px 10px;
            font-size: 10px;
        }
    }

    .large {
        background-image: url(../images/button_large_bg.png);
        background-position: right top;
        margin: 0px 0px 0px 15px;

        input,
        button {
            background-image: url(../images/button_large_left.png);
            background-repeat: no-repeat;
            background-color: transparent;
            //  background-position: left bottom;
            border: none;
            height: 56px;
            margin: 0px 0px 0px -15px;
            padding: 0px 20px 0px 20px;
            font-size: 14px;
            color: $color-white;
            font-weight: normal;
            zoom: 1;
            font-family: $font-sans-serif;
        }
    }

    .primary {

        input,
        button {
            background-image: url(../images/button_primary_left.png);
            background-repeat: no-repeat;
            background-color: transparent;
            color: $color-white;
            border: none;
            height: 32px;
            margin: 0px 0px 0px -8px;
            padding: 0px 8px 0px 8px;
            font-size: 12px;
        }
    }

    .lockimg {
        background-image: url(../images/button_lock_bg.png);

        button,
        input {
            padding-right: 40px;
        }
    }

    &--primary-style,
    &--teritary-style,
    &--highlight-style,
    &--action-style {
        @include typeset(body-normal);
        // font-size: 16px;
        line-height: 43px;
        text-transform: uppercase;

        color: $color-white;
        width: 170px;
        height: 43px;
        display: inline-block;
        text-align: center;
    }

    &--primary-style {
        background-color: #65ba9c;

        &:hover,
        &:focus,
        &:visited {
            color: $color-white;
        }
    }

    &--action-style {
        background-color: #a3bcc4;

        &:hover,
        &:focus,
        &:visited {
            color: $color-white;
        }
    }

    &--teritary-style {
        color: $color-text;
        border: 1px solid $color-border;
        background-color: $color-white;

        &:hover,
        &:focus,
        &:visited {
            color: $color-text;
            background-color: $color-white;
            border-color: $color-text;
            text-decoration: none;
        }
    }

    &--highlight-style {
        background-color: $color-text;

        &:hover,
        &:focus,
        &:visited {
            color: $color-white;
        }
    }
}

/* /Old styling */

:root {
    --background-color: null;
}

/* New styling */
.button-style {
    @include typeface(sans-serif);
    @include typeset(body-normal);
    
    --background-color: #{$color-button};
    --text-color: #{$color-white};
    --border-color: #{$color-button};

    transition:
        border $transition-fast,
        background-color $transition-fast,
        color $transition-fast,
        opacity $transition-fast;
	    
	border: none;
    border-radius: 0;
    box-sizing: border-box;
    
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;

    padding: rem(15) rem(50);
    margin: 0 auto;

    -webkit-appearance: none;

    cursor: pointer;
    
    background-color: $color-button;
    color: $color-white;
    
    
    &:hover, &:focus {
        background-color: $color-botticelli;
        color: $color-white;
        
        outline: none;
    }

    &:visited, &:active {
        color: $color-white;
    }

    &--inline {
        display: inline-block !important;
    }
    
    &--secondary {
        background-color: $color-white;
        color: $color-text;
        border: 1px solid $color-border-hover;
        
        &:hover, &:focus {
            background-color: $color-white;
            color: $color-text;
            border: 1px solid $color-text;
        }
    }

    &--light-button {
        background-color: $color-white;
        color: $color-text;
        
        &:hover, &:focus {
            background-color: $color-border;
        }
    }

    &--highlight {
        background-color: $color-black;
        color: $color-white;
        
        &:hover {
            background-color: rgba(41, 41, 41, .7); // sorry for not putting in variable
            color: $color-white;
        }
    }

    &--fullwidth {
        width: 100%;
    }

    &__text {
        position: relative;
        z-index: 3;
    }

    &__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        button {
            margin: rem(8);
        }
    }

    &__text-container {
        height: rem(24);

        white-space: nowrap;
        overflow: hidden;
    }
}
