// Media banner
//
// A media banner are commonly used in between products in a PLP.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/banner-media.twig
//
// Weight: 1
//
// Style guide: components.media-banner

.banner-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    width: 100%;
    margin-bottom: rem(24);
    
    @include mq($from: screen-sm) {
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(55);
    }

    &--not-positioned {
        display: none;
    }

    &__video, &__image {
        position: relative;
        width: 100%;
        height: 0;
        
        padding-bottom: calc(100% / 16 * 9);
        
        display: flex;
        align-items: center;
        
        background: $color-background-grey;
        
        @include mq($from: screen-md) {
            width: 55%;
            padding-bottom: calc(55% / 16 * 9);
        }

        @include mq($from: screen-lg) {
            width: 60%;
            padding-bottom: calc(60% / 16 * 9);
        }

        @include mq($from: screen-xl) {
            width: 66%;
            padding-bottom: calc(66% / 16 * 9);
        }

        &:hover {
            .js--embed-youtube--icon {
                opacity: 1;
            }
        }

        &[data-active] {
            + .banner-media__text {
                transform: translateY(rem(1)) // eliminates 1px overlap
            }
        }
        
        > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: auto;
                min-height: 100%;
            }
        }

        #thumbnail {
            cursor: pointer;
        } 

        .js--embed-youtube {
            &--trigger {
                transition: opacity $transition-normal;

                &.hidden {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &--icon {
                transition: opacity $transition-fast;
                opacity: .8;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
    
                width: rem(75);
                height: auto;
                
                fill: $color-white-smoke;
                
                @include mq($from: screen-xs) {
                    width: rem(100);
                }
                
                @include mq($from: screen-md) {
                    width: rem(75);
                }
                
                @include mq($from: screen-lg) {
                    width: rem(100);
                }
            }
        }
    }

    &__text {
        transition: transform $transition-slow;
        transform: translateY(rem(-24));

        position: relative;
        width: calc(100% - rem(48));

        margin: 0 rem(24);
        padding: rem(24);
        
        box-sizing: border-box;
        
        background: $color-white;
        
        
        @include mq($from: screen-sm) {
            padding: rem(30);
        }
        
        @include mq($from: screen-md) {
            transform: translateY(0);
            padding: rem(36);
            margin: 0;
            width: 45%;
        }
        
        @include mq($from: screen-lg) {
            padding: rem(48);
            width: 40%;
        }

        @include mq($from: screen-xl) {
            width: 33%;
        }
        
        h1 {
            @include typeface(serif);
            @include typeset(display-3);
            
            margin-bottom: rem(15);
            padding: 0;

            max-width: rem(250);

            text-transform: none;
            font-style: normal;

            @include mq($from: screen-xs) {
                font-size: rem(32);
            }

            @include mq($from: screen-md) {
                font-size: rem(27);
            }

            @include mq($from: screen-lg) {
                font-size: rem(32);
            }

            @include mq($from: screen-xl) {
                font-size: rem(36);
            }

            p {
                @include typeface(serif);
                @include typeset(display-3);
                
                margin-bottom: 0;
    
                @include mq($from: screen-xs) {
                    font-size: rem(32);
                }
    
                @include mq($from: screen-md) {
                    font-size: rem(27);
                }
    
                @include mq($from: screen-lg) {
                    font-size: rem(32);
                }
    
                @include mq($from: screen-xl) {
                    font-size: rem(36);
                }
            }
        }
        
        p {
            @include typeface(sans-serif);
            @include typeset(body-normal);

            margin-bottom: rem(24);
        }
    }
}