.fullhead.productsearch{
    h1 {
        // sorthvid
        @include typeset(display-1);
        @include typeface(serif);
        // font-family: $font-serif;
        // font-size: 36px;
        // line-height: 44px;
        // font-style: normal;
        
        width: 100%;
        text-align: center;
        padding: 0;
    }
    .paging {
        display: inline-block;
        vertical-align: bottom;
        padding: 14px 0;
        float: none;
    }

}

.navigationbar .onpagebottom .paging {
    text-align: center;
    margin: 50px auto 90px auto;
    padding: 0;
    float: none;
}

@media screen and (max-width: 1032px) {
    .fullhead.productsearch .selectors.selectmenu {
        padding-right: 20px;
    }
}
