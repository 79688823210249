// Link block, Image
//
// An image tile, will scale the image when hovered.<br>
// Here seen inside a parenting ```<div>``` with ```width: 300px;``` 
//
// DOD: wip
//
// .link-block-image--hover-fade      - Fades the image on hover
//
// Markup: ../uilibrary/templates/partials/elements/link-block-image.twig
//
// wrapperstyles: display:block; width: 300px; position: relative;
//
// Weight: 1
//
// Style guide: elements.link-block-image


.link-block-image {
	position: relative;
	display: flex;


	&:hover, &:focus {
		.link-block-image__media {
			img {
				transform: translate3d(-50%, 0, 0) scale(1.05);
			}
		}
	}

	&__media {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding-bottom: 100%;
		position: relative;
		overflow: hidden;

		img,
		video {
			transition: transform $transition-slow, opacity $transition-slow;
			position: absolute;
			top: 0;
			left: 50%;
			// width: 100%;
			// height: auto;
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
			transform: scale(1.0001);
		}

		img {
			z-index: 1;
			&.hidden {
				opacity: 0;
			}
		}
	}

	&__text {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		p {
			padding: 0;
			margin: 0;
			font-family: $font-sans-serif;
			font-size: rem(20);
			line-height: 1.2;
			text-shadow: $shadow-1px;
			
			letter-spacing: rem(1);
			font-weight: 600;
			color: $color-white;
			text-align: center;

			@include mq($from: screen-xs) {
				font-size: rem(30);
			}
			@include mq($from: screen-sm) {
				font-size: rem(20);
			}
			@include mq($from: screen-md) {
				font-size: rem(25);
			}
			@include mq($from: screen-lg) {
				font-size: rem(30);
			}
			@include mq($from: screen-xl) {
				font-size: rem(35);
			}
		}
	}

	&--hover-fade {
		img {
			transition: transform $transition-slow, opacity $transition-slow;
		}
		&:hover {
			img {
				opacity: 0;
			}
		}
	}
}