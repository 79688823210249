$herobannerLogocontainerSidePadding: 39;

.herobanner-container.herobanner-container--sc {
    margin: 0 -30px;
    padding: 0 0 100px 0;
    position: relative;
    font-size: 0;
    line-height: 0;

    font-weight: 600;

    .herobanner-topcontainer {
        position: relative;

        .herobanner-imglink {
            // background image displayed using inline styling
            display: block;
            position: relative;
            z-index: 0;
            background-position: center;
            background-size: cover;

            img {
                width: 100%;
                height: auto;
                min-height: 400px;
                opacity: 0;
            }
        }

        .herobanner-overlayouter {
            position: absolute;
            z-index: 1;
            text-align: center; //default
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            &:before {
                content: "";
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle;
            }

            &.herobanner-overlayouter--singlebutton {
                pointer-events: none; // One button version!

                .herobanner-overlayinner {
                    &.herobanner-overlayinner--left {

                        // left aligned
                        .herobanner-buttoncontainer {
                            width: 100%;
                            text-align: left;
                        }
                    }

                    &.herobanner-overlayinner--right {

                        // left aligned
                        .herobanner-buttoncontainer {
                            width: 100%;
                            text-align: right;
                        }
                    }
                }
            }

            .herobanner-overlayinner {
                display: inline-block;
                //                width: 100%;
                width: 778px;
                vertical-align: middle;
                text-align: center;
                margin-bottom: -20px; // Compensation for bottom padding for child el.

                >div,
                h1 {
                    // default + center: herobanner-overlayinner--center
                    width: 420px;
                    margin: auto;
                }

                &.herobanner-overlayinner--left {

                    .herobanner-introtitel,
                    .herobanner-headertitel,
                    .herobanner-headerlogo,
                    .herobanner-campaigntitel,
                    .herobanner-subtitel {
                        margin: auto auto auto -140px;
                    }
                }

                &.herobanner-overlayinner--right {

                    .herobanner-introtitel,
                    .herobanner-headertitel,
                    .herobanner-campaigntitel,
                    .herobanner-headerlogo,
                    .herobanner-subtitel {
                        margin: auto -140px auto auto;
                    }
                }

                .herobanner-introtitel {
                    padding-bottom: 20px;
                    text-align: center;

                    span {
                        @include typeset(hero-6);
                        // font-size: 22px;
                        // line-height: 1;
                        // font-weight: 600;
                        
                        display: inline-block;
                        margin: -4px auto -3px auto;
                    }
                }

                .herobanner-headerlogo {
                    font-weight: 600;
                    padding: 0 0 20px 0;
                    text-align: center;
                    font-size: 0;
                    line-height: 0;

                    img {
                        display: inline-block;
                        max-width: 420px;
                        max-height: 120px;
                        height: auto;
                        width: auto;
                    }
                }

                .herobanner-headertitel {
                    // h1
                    font-weight: 600;
                    padding: 0 0 20px 0;
                    text-align: center;
                    font-size: 0;
                    line-height: 0;

                    span {
                        @include typeset(hero-2);
                        // font: normal normal 600 80px/1 $font-sans-serif;
                        // text-transform: uppercase;
                        
                        display: inline-block;
                        margin: -14px 0 -11px 0;
                        vertical-align: middle;
                    }
                    
                    &.herobanner-headertitel--percentage {
                        .herobanner-percentage {
                            @include typeset(hero-1);
                            //font: normal normal 600 140px/1 $font-sans-serif;
                            
                            //                            font-size: 140px;
                            //                            line-height: 1;
                            margin: -23px 0 -20px 0;
                        }
                        
                        .herobanner-percentagesymbol {
                            @include typeset(hero-3);
                            // font: normal normal 600 72px/1 $font-sans-serif;
                            
                            // span
                            //                            font-size: 72px;
                            //                            line-height: 1;
                            margin: -12px auto -10px auto;
                        }
                    }
                }

                .herobanner-campaigntitel {
                    padding-bottom: 20px;
                    text-align: center;

                    span {
                        @include typeset(hero-4);
                        // font-size: 46px;
                        // font-weight: 600;
                        // letter-spacing: -.02em;
                        // line-height: 1;
                        // text-transform: uppercase;
                        
                        display: inline-block;
                        margin: -7px auto;
                    }
                }

                .herobanner-subtitel {
                    text-align: center;
                    padding-bottom: 20px;

                    span {
                        @include typeset(hero-5);
                        // font-size: 36px;
                        // line-height: 43px;
                        
                        display: inline-block;
                        margin: -9px auto;
                    }
                }

                .herobanner-buttoncontainer {
                    max-width: none;
                    padding: 20px 0;
                    width: 100%;
                    text-align: center;

                    &:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 0;
                    }

                    &.herobanner-buttoncontainer--2buttons {
                        width: perc(460, 778);
                    }

                    &.herobanner-buttoncontainer--2buttons,
                    &.herobanner-buttoncontainer--3buttons {
                        @include mq($from: screen-xs) {
                            text-align: justify;
                            text-justify: distribute;
                        }

                        @include mq($from: screen-xs, $until: screen-md) {
                            .button-style {
                                margin-bottom: rem(10);
                            }
                        }
                    }
                }
            }
        }
    }

    .herobanner-logoscontainer {
        width: 100%;
        text-align: justify;
        text-justify: distribute; // IE fix
        padding: 30px $herobannerLogocontainerSidePadding+0px;
        box-sizing: border-box;
        background-color: rgba(234, 246, 246, .15); // Fallback

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0;
        }

        .herobanner-logolink {
            display: inline-block;
            width: perc(160, 1440-2*$herobannerLogocontainerSidePadding);
            height: 40px;
            vertical-align: middle;
            text-align: center;
            white-space: nowrap;

            &:before {
                content: "";
                display: inline-block;
                width: 0;
                height: 100%;
                vertical-align: middle;
            }

            img {
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }
        }
    }

    .herobanner-disclaimercontainer {
        padding: 20px 20px 0 20px;

        .herobanner-disclaimertext {
            @include typeset(body-small);
            // font-size: 13px;
            // line-height: 1.2;
            
            margin: -3px auto -3px auto;
            text-align: center;

            .herobanner-disclaimermark {
                vertical-align: baseline;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .herobanner-container.herobanner-container--sc {
        padding: 0 0 60px 0;
        margin: 0 perc(-20, 768 - 2*20);

        .herobanner-imgcontainer {}

        .herobanner-topcontainer {

            .herobanner-overlayouter {
                &.herobanner-overlayouter--singlebutton {
                    .herobanner-overlayinner {
                        &.herobanner-overlayinner--left {

                            // left aligned
                            .herobanner-buttoncontainer {}
                        }

                        &.herobanner-overlayinner--right {

                            // left aligned
                            .herobanner-buttoncontainer {}
                        }
                    }
                }

                .herobanner-overlayinner {
                    margin-bottom: -15px;
                    width: perc(498, 768);

                    >div,
                    h1 {
                        // default - center: herobanner-overlayinner--center
                        width: 300px;
                    }

                    &.herobanner-overlayinner--left {

                        .herobanner-introtitel,
                        .herobanner-headertitel,
                        .herobanner-campaigntitel,
                        .herobanner-subtitel {
                            margin: auto auto auto -95px;
                        }
                    }

                    &.herobanner-overlayinner--right {

                        .herobanner-introtitel,
                        .herobanner-headertitel,
                        .herobanner-campaigntitel,
                        .herobanner-subtitel {
                            margin: auto -95px auto auto;
                        }
                    }

                    .herobanner-introtitel {
                        padding-bottom: 15px;

                        span {
                            // font-size: 16px;
                            margin: -3px auto -2px auto;
                        }
                    }

                    .herobanner-logotitel {
                        padding-bottom: 15px;

                        img {
                            max-height: 75px;
                        }
                    }

                    .herobanner-headertitel {
                        padding-bottom: 15px;

                        span {
                            // font-size: 56px;
                            margin: -10px auto -8px;
                        }

                        &.herobanner-headertitel--percentage {
                            .herobanner-percentage {
                                // font-size: 100px;
                                margin: -17px auto -14px auto;
                            }

                            .herobanner-percentagesymbol {
                                // font-size: 48px;
                                margin: -7px auto -8px;
                            }
                        }
                    }

                    .herobanner-campaigntitel {
                        padding-bottom: 15px;

                        span {
                            // font-size: 30px;
                            margin: -4px auto -5px auto;
                        }
                    }

                    .herobanner-subtitel {
                        padding-bottom: 15px;

                        span {
                            // font-size: 24px;
                            // line-height: 28px;
                            margin: -6px auto;
                        }
                    }

                    .herobanner-buttoncontainer {
                        padding: 15px 0;

                        &.herobanner-buttoncontainer--2buttons {
                            display: flex;
                            justify-content: center;
                            width: 100%;

                            &:after {
                                content: none;
                            }
                        }

                        &.herobanner-buttoncontainer--3buttons {}

                        .button-style {
                            font-size: rem(14);
                        }
                    }
                }
            }
        }

        .herobanner-logoscontainer {
            padding: 17px 20px; // 20px is not certain

            .herobanner-logolink {
                width: 16.66%;
                height: 35px;

                .tilelink-style {
                    font-size: 14px;
                    line-height: 34px;
                    border-width: 1px;
                    margin-top: -12px;
                }
            }
        }

        .herobanner-disclaimercontainer {
            padding: 15px 15px 0 15px;

            .herobanner-disclaimertext {
                font-size: 11px;
                margin: -2px auto -3px auto;

                .herobanner-disclaimermark {}
            }
        }
    }

}

@media screen and (max-width: 768px) {
    .herobanner-container.herobanner-container--sc {
        .herobanner-logoscontainer {
            .herobanner-logolink {
                width: perc(85, 768);
                height: 21px;
            }
        }
    }

}

@media screen and (max-width: 640px) {
    .herobanner-container.herobanner-container--sc {
        width: perc(320, 280);
        margin: 0 perc(-20, 280);
        padding: 0 0 40px 0;

        .imagesplash-container {
            z-index: 2;
        }

        .herobanner-topcontainer {
            float: left;
            margin-bottom: -52px;

            .herobanner-imglink {
                position: relative;
                z-index: 1;
                pointer-events: none;
                background-image: none;

                &.herobanner-imglink--singlebutton {
                    pointer-events: all;
                }

                img {
                    opacity: 1;
                    min-height: auto;
                }
            }

            .herobanner-overlayouter {
                position: relative;
                top: -60px;
                width: 87.5%;
                box-sizing: border-box;
                background-color: #ffffff;
                padding: 5px 5px 20px 5px;
                margin: auto;

                &.herobanner-overlayouter--singlebutton {
                    .herobanner-overlayinner {

                        &.herobanner-overlayinner--left,
                        // left aligned
                        &.herobanner-overlayinner--right {

                            // left aligned
                            .herobanner-buttoncontainer {
                                text-align: center;
                            }
                        }
                    }
                }

                .herobanner-overlayinner {
                    width: 100%;
                    padding: 20px 20px 10px 20px;
                    box-sizing: border-box;
                    border: 1px solid #a1a1a1;

                    >div,
                    h1 {
                        // default - center: herobanner-overlayinner--center
                        max-width: 240px;
                    }

                    &.herobanner-overlayinner--left,
                    &.herobanner-overlayinner--right {

                        .herobanner-introtitel,
                        .herobanner-headertitel,
                        .herobanner-headerlogo,
                        .herobanner-campaigntitel,
                        .herobanner-subtitel {
                            margin: auto;
                        }
                    }

                    .herobanner-introtitel {
                        padding-bottom: 10px;

                        span {
                            // font-size: 12px;
                            margin: -2px auto;
                        }
                    }

                    .herobanner-headerlogo {
                        padding-bottom: 10px;
                        width: 100%;

                        img {
                            max-height: perc(55, 228);
                            max-width: 100%;
                        }
                    }

                    .herobanner-headertitel {
                        // h1
                        padding: 0 0 10px 0;

                        span {
                            // font-size: 30px;
                            margin: -4px auto -5px;
                        }

                        &.herobanner-headertitel--percentage {
                            .herobanner-percentage {
                                // font-size: 40px;
                                margin: -6px auto;
                            }

                            .herobanner-percentagesymbol {
                                // font-size: 20px;
                                margin: -3px auto;
                            }
                        }
                    }

                    .herobanner-campaigntitel {
                        padding-bottom: 10px;

                        span {
                            // font-size: 19px;
                            margin: -3px auto;
                        }
                    }

                    .herobanner-subtitel {
                        padding-bottom: 10px;

                        span {
                            // font-size: 16px;
                            // line-height: 18px;
                            margin: -4px auto -3px;
                        }
                    }

                    // Special text color option cases
                    .herobanner-introtitel.herobanner-introtitel--mobile-black-text,
                    .herobanner-headertitel.herobanner-headertitel--mobile-black-text,
                    .herobanner-campaigntitel.herobanner-campaigntitel--mobile-black-text,
                    .herobanner-subtitel.herobanner-subtitel--mobile-black-text {
                        span {
                            color: #292929 !important;
                        }
                    }

                    .herobanner-buttoncontainer {
                        padding: 10px 0;

                        &.herobanner-buttoncontainer--2buttons {}

                        &.herobanner-buttoncontainer--3buttons {}

                        .button-style {
                            width: auto;
                            height: auto;
                            font: normal normal 600 14px/30px $font-sans-serif;
                            margin-top: -10px;
                            background-color: transparent !important;
                            border-bottom: 1px solid #292929 !important;
                            color: #292929 !important;
                            padding: 0;
                            margin: 0 1em .5em;
                        }
                    }
                }
            }
        }

        .herobanner-logoscontainer {
            position: relative;
            z-index: 0;
            padding: 8px perc(20, 320);

            .herobanner-logolink {
                width: perc(71, 280); // 25.35...%
                height: perc(18, 71); // 25.35... somthing else %
                padding: 13px 0;

                img {}
            }
        }
    }

}

// Generel styling
.herobanner-container.herobanner-container--sc {
    .herobanner-topcontainer {
        .herobanner-notemark {
            font-family: $font-sans-serif !important;
            font-size: .6em !important;
            font-weight: 300 !important;
            vertical-align: super !important;
            display: inline-block !important;
        }
    }
}