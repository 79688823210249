// Width delimiter
//
// CSS Class. <br>
// Use it by putting the correct w--[class] on an element that you want to limit the width on.<br>
// The different widths are corresponding to the responsive breakpoints.<br>
// A Width delimiter is responsive and will only be present when the container is bigger that the size of the Width delimiter.<br>
// Will by default center it's content.
//
// DOD: wip
//
// .w--xs           - Extra small
// .w--sm           - Small
// .w--sm-plus      - Small plus
// .w--md           - Medium
// .w--lg           - Large
// .w--xl           - Extra large
// .w--xxl          - Extra extra large
// .w--xxxl         - Extra extra extra large
//
// Markup:
// <div style="border: 1px dotted grey;">
//     A fullwidth container.
//     <div class="{{ modifier_class }}" style="background-color: lightgrey;">A div with class="{{ modifier_class }}"</div>
// </div>
//
// hidedefault: true
//
// Weight: 1
//
// Style guide: core.width-delimiter

.w {
    &--xs {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-xs);
        
    }
    &--sm {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-sm);
        
    }
    &--sm-plus {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-sm-plus);
        
    }
    &--md {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-md);
        
    }
    &--lg {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-lg);
        
    }
    &--xl {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-xl);
        
    }
    &--xxl {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-xxl);
        
    }
    &--xxxl {
        margin: 0 auto;
        max-width: map-get($mq-breakpoints, screen-xxxl);
        
    }
}