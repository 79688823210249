// SEO-banner
//
// Standalone component. <br>
// Is composed of a colored block, with text in it. Can have an image on top, titles in the text, and dotted lists. Will fill up all available horizontal space that it's placed within. <br><br>
// Has a 'read more' functionality. That, when closed, only shows a fixed height of its content. <br>
// Uses a small javascript snippet to remove the 'read more' button, if its content has a lesser height then the height of 'closed state'.
//
// DOD: wip
//
// Markup: ../uilibrary/templates/partials/components/seo-banner.twig
//
// examplelink: /examples/examples/seo-banner||SEO-banner examples
//
// Weight: 1
//
// Style guide: components.seo-banner

$content-max-width: rem(700);

.seo-banner {

    padding: rem(76) rem(24) rem(100);
    background: #F6F6F6;
    
    display: flex;
    align-items: center;
    flex-direction: column;
    
    input[type="checkbox"] {
        position: fixed;
        top: 99999px;
    }
    
    article {
        max-height: rem(240);
        overflow: hidden;

        & p + h2 { // h2's that's right after a p
            padding-top: rem(24);
        }
    }

    input:focus {
        & ~ label {
            color: $color-dull-orange;
        }
    }

    input:checked {
        & ~ article {
            max-height: none;
        }

        & ~ label {
            .read-more-text {
                display: none;
            }

            .read-less-text {
                display: block;
            }
        }
    }

    .read-more-button {
        width: $content-max-width;
        max-width: 100%;
        
        margin: rem(24) auto 0;
        box-sizing: border-box;

        font-family: $font-sans-serif;
        font-size: rem(15);
        line-height: 1.6;
        text-decoration: underline;

        cursor: pointer;

        .read-less-text {
            display: none;
        }
    }

    &__image {
        height: rem(96);
        margin: rem(24) 0 rem(48);
        
        display: flex;
        align-items: center;
        justify-content: center;

        & ~ article > h2, & ~ article > p {
            margin-top: 0; // If there's an img and h2, no margin on h2
        }

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%; 
        }
    }
    
    &__content {
        width: $content-max-width;
        max-width: 100%;

        h2, p strong {
            font-family: "Old Standard TT";
            text-align: left;
            margin: 0;
            padding: 0;

            font-size: rem(30);
            font-weight: lighter;
            line-height: 1.6;
        }

        p, a, ul {
            font-family: $font-sans-serif;
            font-size: rem(15);
            font-weight: lighter;
            line-height: 1.6;
            letter-spacing: rem(.5);
            color: $color-text;
        }

        ul {
            padding-left: 2em;
        }
        
        li { 
            list-style: disc;
        }

        a {
            text-decoration: underline;

            &:focus {
                color: $color-dull-orange;
            }
        }
    }
}