// Generel wish list component styling
.symbol-base {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
}

.symbol-heart {
    background-image: url(../images/wishlist/hjerte_solid.svg);
    width: 37px;
    height: 37px;
}

.wishlist-arrow {
    background-image: url(../images/wishlist/menu_pil.svg);
    height: 22px;
    width: 18px;
    background-position: center center;
    display: inline-block;
}

.symbol-facebook {
    background-image: url(../images/wishlist/del_facebook.svg);
    width: 37px;
    height: 37px;
}

.symbol-mail {
    background-image: url(../images/wishlist/del_mail.svg);
    width: 37px;
    height: 37px;
}

.symbol-link {
    background-image: url(../images/wishlist/del_link.svg);
    width: 37px;
    height: 37px;
}

.wishlist-arrow--down {
    transform: rotate(90deg);
}


.wishlist-dialog-container {
    display: none;
    font-size: 0;
    line-height: 0;
    position: absolute;
    z-index: 200;
    background-color: #f5f5f5;
    border: 1px solid #dddad7;
    box-sizing: border-box;
    &:before {
        content: "";
        display: block;
        background-color: #f5f5f5;
        border: 1px solid #dddad7;
        position: absolute;
        width: 14px;
        height: 14px;
        transform: rotate(45deg);
        right: 20px;
        top: -8px;
        border-bottom: 0px;
        border-right: 0px;
        z-index: -1;
    }
    p {
        font-size: 12px;
        text-align: center;
        line-height: 21px;
    }
    em {
        font-style: italic;
    }
    &.wishlist-menuselect-container:before {
        transform: rotate(-45deg);
        left: -8px;
        top: 8px;
        right: initial;
    }
    &.wishlist-dialog-container--link-message {
        display: none;
        right: 11px;
        top: 54px;
        width: 332px;
        padding: 12px 20px;
    }
    .productitemcontainer & {
        left: 38px;
        top: -7px;
    }
}

.wishlist-menuselect-create-list-message {
    padding: 14px 0 14px 13px;
}

.wishlist-wishbutton-container {
    .symbol-heart {
        background-image: url(../images/wishlist/hjerte_stroke.svg);
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        width: 23px;
        height: 25px;
        position: absolute;
        font-size: 0px;
        line-height: 0px;
        position: static;
        cursor: pointer;
    }
    &.wishlist-wishbutton-container--wish {
        .symbol-heart {
            background-image: url(../images/wishlist/hjerte_solid.svg);
        }
    }
}


.wishlist-menuselect-list label {
    font-size: 11px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    
}

.wishlist-menuselect-list-new-item {
	border-top: 1px solid #dddad7;
	margin-top: -1px;
}

.wishlist-menuselect-list-item {
    box-sizing: border-box;
    height: 37px;
    border-bottom: 1px solid #dddad7;
    font-size: 0;
    line-height: 0;
    input, label {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
    &:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    label {
        span {
            display: inline-block;
            vertical-align: middle;
            line-height: 15px;
            max-width: 100px;
        }
        input {
            width: 100px;
            height: 22px;
            border: 1px;
            font-size: 11px;
            padding-left: 8px;
            box-sizing: border-box;	
        }
        .label-spacer {
            height: 100%;
        }
    }
}

.wishlist-form {
    .wishlist-menuselect-create-list {
        font-size: 0px;
        line-height: 0;
        button {
            line-height: 14px;
            font-size: 11px;
            box-sizing: border-box;
            width: 56px;
            height: 18px;
            text-transform: none;
            vertical-align: middle;
            cursor: pointer;
        }
        .button--action-style {
            margin: 14px 7px 14px 10px;
            border-width: 0;
        }
        .button--teritary-style {
            border-width: 1px;
        }
    }
}

.wishlist-form [type="checkbox"]:not(:checked),
.wishlist-form [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    + label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            left: 10px;
            top: 12px;
            box-sizing: border-box;
            width: 12px;
            height: 12px;
            background-color: #ffffff;
            border: 1px solid #a19589;
        }
        &:after {
            content: "";
            position: absolute;
            left: 9px;
            top: 11px;
            box-sizing: border-box;
            width: 15px;
            height: 15px;
            background-image: url(../images/wishlist/check_mark.svg);
        }
    }
}

.wishlist-form .wishlist-menuselect-list-item.wishlist-menuselect-list-new-item label:before {
	top: 5px;
}

.wishlist-form .wishlist-menuselect-list-item.wishlist-menuselect-list-new-item label:after {
	top: 4px;
}


.wishlist-form [type="checkbox"] {
    &:not(:checked) + label:after {
        opacity: 0;
    }
    &:checked+label:after {
        opacity: 1;

    }
    &:disabled:not(:checked) + label:before,
    &:disabled:checked + label:before,
    &:disabled:checked + label:after,
    &:disabled + label {
        opacity: .5;
    }
    &:checked:focus + label:before,
    &:not(:checked):focus + label:before {
        border: 1px solid grey;
    }
}



.wishlist-menuselect-list-container {
    max-height: 222px;
    overflow: hidden;
    overflow-y: auto;
    .wishlist-menuselect-list-item {
        box-sizing: border-box;
        height: 37px;
        border-bottom: 1px solid #dddad7;
        font-size: 0;
        line-height: 0;
        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        &.wishlist-menuselect-list-new-item label:before {
            top: 5px;
        }
    }
    
}

.wishlist-menuselect-container.wishlist-dialog-container:before, .wishlist-menuselect-create-list-message:before {
    transform: rotate(-45deg);
    left: -8px;
    top: 8px;
    right: initial;
}



#acceptboxGuest.wishlist-dialog-container {
    text-align: left;
    width: 143px;
    a {
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
        color: #292929;
    }
}

#productlistsidepanelcontainer {
    display: none;
}

.wishlist-menuselect-container, .wishlist-menuselect-create-list-message {
    display: block;
    width: 141px;
    top: -7px;
    right: -158px;
    font-size: 0px;
    line-height: 0px;
}

@media screen and (max-width: 769px) {
    .productitemcontainer .wishlist-dialog-container {
        right: 0;
        left: auto;
        top: 45px;
        &:before {
            right: 8px;
            left: auto;
            top: -8px;
            transform: rotate(45deg);
        }
    }

    #acceptboxGuest.wishlist-dialog-container {
        text-align: center;
        padding: 14px 13px 14px 13px;
    }
    
}